import { createSlice } from "@reduxjs/toolkit";

const initValue = {
  type: "None",
  scenario: { id: "", name: "" },
  opportunity: { id: "", name: "" },
  dictionary: { id: "", name: "" },
  customer: { id: "", name: "" },

  tab: "0",
  status: "Optimization Pending",
  productline: [],
  probelocation: [],
  temperature: [],
  payload: [],
  preference: {},
};

export const ScenarioSlice = createSlice({
  name: "scenario",
  initialState: {
    value: initValue,
  },
  reducers: {
    scenarioControl: (state, action) => {
      state.value = action.payload;
    },
    scenarioType: (state, action) => {
      state.value.type = action.payload;
    },
    scenarioScenario: (state, action) => {
      state.value.scenario = action.payload;
    },
    scenarioDictionary: (state, action) => {
      state.value.dictionary = action.payload;
    },
    scenarioCustomer: (state, action) => {
      state.value.customer = action.payload;
    },
    scenarioTab: (state, action) => {
      state.value.tab = action.payload;
    },
    scenarioStatus: (state, action) => {
      state.value.status = action.payload;
    },
    scenarioPoductline: (state, action) => {
      state.value.productline = action.payload;
    },
    scenarioProbelocation: (state, action) => {
      state.value.probelocation = action.payload;
    },
    scenarioTemperature: (state, action) => {
      state.value.temperature = action.payload;
    },
    scenarioPayload: (state, action) => {
      state.value.payload = action.payload;
    },
    scenarioPreference: (state, action) => {
      state.value.preference = action.payload;
    },
    scenarioReset: (state) => {
      state.value = initValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(scenarioReset, (state, action) => {
      state.value = initValue;
    });
    builder.addDefaultCase((state, action) => {
      state.otherActions++;
    });
  },
});

export const {
  scenarioSectionSaveStatus,
  scenarioControl,
  scenarioType,
  scenarioScenario,
  scenarioDictionary,
  scenarioCustomer,
  scenarioTab,
  scenarioStatus,
  scenarioPoductline,
  scenarioProbelocation,
  scenarioTemperature,
  scenarioPayload,
  scenarioPreference,
  scenarioReset,
} = ScenarioSlice.actions;

export const scenarioData = (state) => state?.scenario?.value;

export default ScenarioSlice.reducer;
