import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CircleIcon from "@mui/icons-material/Circle";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import * as ConstantsList from "../../../src/constants/";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  scenarioControl,
  scenarioData,
  scenarioScenario,
  scenarioType,
} from "../Scenario/ScenarioSlice";
import { Backdrop } from "@mui/material";
import Loader from "../core/Loader/Loader";
import { useEffect } from "react";

import { loginData } from "../../components/Login/LoginSlice";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric ? UnfoldMoreIcon : null}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const columns = [
  {
    id: "select",
    label: "",
    minWidth: 80,
    width: "8%",
    align: "center",
    numeric: false,
  },
  {
    id: "scenario_name",
    label: "Scenario",
    width: "20%",
    align: "left",
    numeric: true,
  },
  {
    id: "created_on",
    label: "Creation Date",
    minWidth: 200,
    width: "15%",
    align: "left",
    numeric: true,
  },
  {
    id: "updated_on",
    label: "Last Edited Date",
    minWidth: 200,
    width: "15%",
    align: "left",
    numeric: true,
  },
  {
    id: "scenario_status",
    label: "Scenario Status",
    width: "15%",
    align: "left",
    numeric: true,
  },
  {
    id: "selected_proposal",
    label: "Selected Proposal",
    width: "15%",
    align: "center",
    numeric: true,
  },
  {
    id: "viewdetails",
    label: "View Details",
    width: "-webkit-fill-available",
    align: "center",
    numeric: false,
  },
];

const ListView = (props) => {
  const loginDetails = useSelector(loginData);
  const scenarioDetails = useSelector(scenarioData);
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    data,
    selectedScenarios,
    setSelectedScenarios,
    selectedScenariosList,
    setSelectedScenariosList,
    search,
    isScenarioProposed,
    setNotify,
    setNotifyMsg,
    setReload,
    setSeverity,
  } = props;

  const handleChange = (event, id, name, dictId, dictName) => {
    const selectedIndex = selectedScenarios.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedScenarios, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedScenarios.slice(1));
    } else if (selectedIndex === selectedScenarios.length - 1) {
      newSelected = newSelected.concat(selectedScenarios.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedScenarios.slice(0, selectedIndex),
        selectedScenarios.slice(selectedIndex + 1)
      );
    }
    setSelectedScenarios(newSelected);

    let dataTest = [];

    data.map((dataItem, index) => {
      return newSelected.includes(dataItem.id)
        ? dataTest.push(dataItem)
        : false;
    });
    setSelectedScenariosList(dataTest);
  };

  useEffect(() => {}, []);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("scenario_name");
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selectedScenarios.indexOf(name) !== -1;

  const filtered = search
    ? data.filter((user) => {
        return user.scenario_name === search.scenario_name;
      })
    : data;

  const [openLoad, setOpenLoad] = useState(false);
  const handleClose = () => {
    setOpenLoad(false);
  };

  const handleClickArrow = (
    e,
    id,
    name,
    dictId,
    dictName,
    custID,
    oppID,
    status
  ) => {
    let payload = {
      ...scenarioDetails,
      scenario: { id: id, name: name },
      dictionary: { id: dictId, name: dictName },
      customer: { ...scenarioDetails?.customer, id: custID },
      opportunity: { ...scenarioDetails?.opportunity, id: oppID },
      type: "View",

      tab: "0",
      status: status,
      productline: [],
      probelocation: [],
      temperature: [],
      payload: [],
      preference: {},
    };

    if (dispatchfunction(payload)) {
      history.push("/dashboard/opportunity/scenario");
    }
  };

  const dispatchfunction = (payload) => {
    return dispatch(scenarioControl(payload));
  };

  const handleSelectedProposal = (event, id, name, proposalStatus) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        scenario_id: id,
        selected_proposal: !proposalStatus,
      }),
    };
    fetch(
      ConstantsList.API_URL + "scenario/scenario-selected-proposal/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setSeverity(true);
            setNotify(true);
            setNotifyMsg(data.data);
            setReload(true);
          } else if (data.status_code === 400) {
            setSeverity(false);
            setNotify(true);
            setNotifyMsg(data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const colorStatus = (color) => {
    switch (color) {
      case "Optimization Pending":
        return "#CBCCCD";
      case "Optimization In-progress":
        return "#F26322";
      case "Optimization Completed":
        return "#408515";
      case "Optimization Cancelled":
        return "#ED9900";
      case "Optimization Failed":
        return "#DA0000";
      case "Report Created":
        return "#0f78db";
      default:
        return "#CBCCCD";
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",

            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.data.length}
          />
          <TableBody>
            {filtered.length === 0 && (
              <NoRecordFound title="No scenario added yet" description="" />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                let selctedId = row.id;
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleChange(
                        event,
                        row.id,
                        row.scenario_name,
                        row.shipperdict_id,
                        row.shipperdict_name
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                    }}
                  >
                    {columns.map((column, i) => {
                      let value = row[column.id];

                      return i === 0 ? (
                        <TableCell
                          key={`${row.id}-${i}`}
                          padding="checkbox"
                          align={column.align}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                            padding: 0,
                          }}
                        >
                          <Stack direction="row" p={0}>
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id,
                                    row.scenario_name,
                                    row.shipperdict_id,
                                    row.shipperdict_name
                                  )
                                }
                                value={selctedId}
                                name={`checkbox-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : i === 4 ? (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={column.align}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                        >
                          <Stack direction="row" alignItems="center">
                            <CircleIcon
                              sx={{
                                align: "left",
                                cursor: "pointer",
                                width: "10px",
                                height: "auto",
                                mr: 1,
                                color: colorStatus(value),
                              }}
                            />
                            {value}
                            {value === "Optimization Failed" && (
                              <BootstrapTooltip title={row.error_msg}>
                                <InfoOutlinedIcon
                                  sx={{
                                    marginLeft: "8px",
                                    color: colorStatus(value),
                                    width: "16px",
                                    height: "16px",
                                    //cursor: "pointer",
                                  }}
                                />
                              </BootstrapTooltip>
                            )}
                          </Stack>
                        </TableCell>
                      ) : i === 5 ? (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={column.align}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                        >
                          {value ? (
                            <TaskAltOutlinedIcon
                              onClick={(event) =>
                                handleSelectedProposal(
                                  event,
                                  row.id,
                                  row.scenario_name,
                                  row.selected_proposal
                                )
                              }
                              sx={{
                                align: "left",
                                cursor:
                                  row["scenario_status"] === "Report Created"
                                    ? "pointer"
                                    : "auto",
                                width: "20px",
                                height: "auto",
                                mr: 1,
                                color: "#408515",
                              }}
                            />
                          ) : isScenarioProposed === false &&
                            row["scenario_status"] === "Report Created" ? (
                            <CircleOutlinedIcon
                              onClick={(event) =>
                                handleSelectedProposal(
                                  event,
                                  row.id,
                                  row.scenario_name,
                                  row.selected_proposal
                                )
                              }
                              sx={{
                                align: "left",
                                cursor: "pointer",
                                width: "20px",
                                height: "auto",
                                mr: 1,
                                color: "#B1B2B4",
                                disabled: "disabled",
                              }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              sx={{
                                align: "left",
                                cursor: "auto",
                                width: "20px",
                                height: "auto",
                                mr: 1,
                                color: "#B1B2B4",
                                disabled: "disabled",
                                opacity: "0.3",
                              }}
                            />
                          )}
                          {/* {value} */}
                        </TableCell>
                      ) : i === 6 ? (
                        <TableCell
                          id={labelId}
                          key={`${row.id}-${i}`}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: column.width }}
                        >
                          {loginDetails.role !== "Account Manager" && (
                            <ChevronRightIcon
                              onClick={(event) =>
                                handleClickArrow(
                                  event,
                                  row.id,
                                  row.scenario_name,
                                  row.shipperdict_id,
                                  row.shipperdict_name,
                                  row.customer_id,
                                  row.opportunity_id,
                                  row.scenario_status
                                )
                              }
                              sx={{ align: "center", cursor: "pointer" }}
                            />
                          )}
                          {loginDetails.role === "Account Manager" &&
                            row.scenario_status === "Report Created" && (
                              <ChevronRightIcon
                                onClick={(event) =>
                                  handleClickArrow(
                                    event,
                                    row.id,
                                    row.scenario_name,
                                    row.shipperdict_id,
                                    row.shipperdict_name,
                                    row.customer_id,
                                    row.opportunity_id,
                                    row.scenario_status
                                  )
                                }
                                sx={{ align: "center", cursor: "pointer" }}
                              />
                            )}
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={`${row.id}-${i}`}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {openLoad && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoad}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Paper>
  );
};

export default ListView;
