import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Stack,
  Typography,
} from "@mui/material";

import { Create, Launch } from "@mui/icons-material";

import { useEffect, useState } from "react";

import EditProductLine from "./EditProductline";
import SnackBar from "../../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../constants";
import { useSelector, useDispatch } from "react-redux";

import {
  resetData,
  shipperData,
  productLineUpdate,
  productLineNameUpdate,
  shipperDetailsControl,
  editShipperControl,
} from "./EditShipperSlice";
import Loader from "../../core/Loader/Loader";
import * as Config from "../../../config";
import NoImage from "../../../assets/images/noimage.png";

const ProductLine = (props) => {
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const shipperID = shipperDetails?.id;

  const [open, setOpen] = useState(false);
  const [proDetails, setProDetails] = useState({
    id: 0,
    name: "",
    image: "",
    link: "",
    description: "",
  });

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [selected, setSelected] = useState(shipperDetails?.productline);

  const handleCheck = (e, id, name, descr, image, link) => {
    if (selected === e.target.value.toString()) {
      setSelected("");
      setImageURL("");
      setProDetails();
      dispatch(productLineUpdate(""));
      dispatch(productLineNameUpdate("----"));
    } else {
      setImageURL(image.toString());
      setSelected(e.target.value.toString());
      dispatch(productLineUpdate(id.toString()));
      dispatch(productLineNameUpdate(name.toString()));
      setProDetails({
        id: id,
        name: name,
        image: image,
        link: link,
        description: descr,
      });
    }
  };

  const isCheck = (name) => selected === name;

  const createNewProduct = () => {
    setOpen(true);
  };

  const [loading, setLoading] = useState(true);
  const [productLineData, setProductLineData] = useState(null);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ shipperdict_id: shipperID }),
    };
    fetch(ConstantsList.API_URL + "prodline/productline_type/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProductLineData(data.data);
          setLoading(false);
          setReload(false);
        }
      });

    if (shipperID) {
      fetch(ConstantsList.API_URL + "shipdict/shipper/" + shipperID)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setData(data.data);
          }
        });
    }

    setImageURL(shipperDetails?.productline?.path?.toString());
    setSelected(shipperDetails?.productline?.id?.toString());
    dispatch(productLineUpdate(shipperDetails?.productline?.id?.toString()));
    dispatch(
      productLineNameUpdate(shipperDetails?.productline?.name?.toString())
    );

    if(shipperDetails?.productline)
    {
      setProDetails({
        id: shipperDetails?.productline?.id,
        name: shipperDetails?.productline?.name,
        image: shipperDetails?.productline?.image,
        link: shipperDetails?.productline?.product_sheet_link,
        description: shipperDetails?.productline?.description,
        type: shipperDetails?.productline?.type,
      });
    }

    
  }, [reload, shipperID, shipperDetails?.productline?.name, shipperDetails?.productline?.product_sheet_link, shipperDetails?.productline?.description]);

  useEffect(() => {
    if (data) {
      dispatch(shipperDetailsControl(data));
    }
  }, [dispatch, data]);

  const isSelected = selected?.length > 0 ? true : false;

  const [loaderOpen, setLoaderOpen] = useState(true);
  const handleClose = () => {
    setLoaderOpen(false);
  };

  return (
    <Box p={0} maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <Stack direction="row" width={1} pt={3} spacing={1}>
        <Stack
          direction="column"
          // width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 3.5 / 5, xl: 3.5 / 5 }}
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 1.5 / 5, xl: 1.5 / 5 }}
        >
          {isSelected && (
            <Stack
              sx={{
                height: "100%",
                overflow: "hidden",
                width: "90%",
                backgroundColor: "#F2F2F3",
                margin: "24px",
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                p={5}
                spacing={4}
              >
                <Stack
                  direction="column"
                  pt={2}
                  spacing={3}
                  sx={{ marginTop: "0" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      p={0}
                      component="img"
                      src={
                        imageURL === "" ||
                        imageURL === null ||
                        imageURL === undefined
                          ? NoImage
                          : ConstantsList.API_URL + imageURL
                      }
                      alt="Product Line"
                      sx={{
                        width: "219px",
                        height: "auto",
                      }}
                      tabIndex={0}
                    />
                  </Stack>
                </Stack>

                <Stack
                  sx={{ alignItems: "center" }}
                  direction="column"
                  spacing={2.4}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#ccc",
                    }}
                  >
                    Product Type
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "22px",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    {proDetails?.type}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack
          spacing={3}
          paddingRight="20px"
          direction="column"
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 3.5 / 5, xl: 3.5 / 5 }}
        >
          <Stack direction="column" spacing={2.4} sx={{ marginLeft: "50px" }}>
            <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
              <Button
                onClick={createNewProduct}
                sx={{
                  width: "145px",
                  height: "44px",
                  borderRadius: "6px",
                  padding: "0px, 20px, 0px, 20px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#FFFFFF",
                }}
                data-testid="create-product-btn"
                color="primary"
                startIcon={<Create />}
                style={{
                  textTransform: "none",
                  border: "1px solid #B1B2B4",
                }}
              >
                Edit
              </Button>
            </Stack>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: "26px",
                lineHeight: "32px",
                color: "#000000",
              }}
            >
              {proDetails?.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#000000",
              }}
            >
              {/* {proDetails.description}{" "} */}
              <Stack>{proDetails?.description} </Stack>
            </Typography>
            <Stack direction="row">
              <Button
                disabled={
                  proDetails?.link
                    ? proDetails?.link?.toString()?.length > 0
                      ? false
                      : true
                    : true
                }
                onClick={() => window.open(proDetails?.link)}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: "15.25rem",
                  height: "2.75rem",
                  padding: "0px, 20px, 0px, 20px",
                  borderRadius: "6px",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#000000",
                  backgroundColor: "#FFFFFF",
                  lineHeight: "24px",
                  border: "1px solid #B1B2B4",
                }}
                startIcon={<Launch />}
                data-testid="view-product-btn"
              >
                View Product Sheet
              </Button>
            </Stack>
          </Stack>

          <Box
            px={3}
            maxWidth="false"
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          ></Box>
        </Stack>
      </Stack>

      {open && (
        <EditProductLine
          open={open}
          setOpen={setOpen}
          shipperID={shipperID}
          productLineData={shipperDetails?.productline}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Box>
  );
};

export default ProductLine;
