import { createSlice } from "@reduxjs/toolkit";

export const ShipperDictionarySlice = createSlice({
  name: "shipperDictionary",
  initialState: {
    value: {
      id: "",
      name: "",
    },
  },
  reducers: {
    shipperDictionaryControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { shipperDictionaryControl } = ShipperDictionarySlice.actions;

export const shipperDictionaryData = (state) => state?.shipperDictionary?.value;

export default ShipperDictionarySlice.reducer;
