import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Doughnut } from "react-chartjs-2";

const ShipperWeight = (props) => {
  const { data } = props;
  const [labelsShipments, setLabelsShipments] = useState([]);
  const [chartShipmentsData, setChartShipmentsData] = useState([]);

  useEffect(() => {
    if (data?.shipper_summary) {
      const Labels = data?.shipper_summary.map(
        (shipper) => shipper?.shipper_name
      );

      setLabelsShipments(Labels);
      const Shipments = data?.shipper_summary.map((shipper) =>
        parseInt(shipper?.weight)
      );
      setChartShipmentsData(Shipments);
    }
  }, [data]);

  const config = {
    type: "doughnut",
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        offset: 2,
        rotation: -90,
        anchor: "end",
        font: { size: "7" },
      },
    },
  };

  const dataset = {
    labels: labelsShipments,
    datasets: [
      {
        data: chartShipmentsData,
        backgroundColor: [
          "rgba(78, 115, 190, 1)",
          "rgba(251, 144, 72, 1)",
          "rgba(134, 136, 133, 1)",
          "rgba(121, 197, 75, 1)",
          "rgba(124, 192, 255, 1)",
        ],
        hoverOffset: 4,
      },
    ],
  };
  return (
    <Stack direction="column" width={1} spacing={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "48px", backgroundColor: "#CCCCCC" }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "22px",
            color: "#000000",
          }}
        >
          Weight Shipped by Shipper
        </Typography>
      </Stack>
      <Stack alignItems="center" justifyItems="center" height="250px">
        <Doughnut options={config} data={dataset} />
      </Stack>
    </Stack>
  );
};

export default ShipperWeight;
