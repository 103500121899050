import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useHistory } from "react-router-dom";
import * as ConstantsList from "../../../src/constants/";
import * as ADAConfig from "../../../src/config/ADAConfig";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

export default function EditUsers(props) {
  let history = useHistory();
  const [snackopen, setsnackOpen] = React.useState(true);
  const handlesnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackOpen(false);
  };

  const {
    onClose,
    open,
    username,
    email,
    role,
    setCount,

    setNotify,
    setNotifyMsg,
    setUserName,
    setEmail,
    setRole,
    setReload,
    setSelectValue,
    selectValue,
  } = props;
  const [uname, setUname] = useState(selectValue ? selectValue?.name : "");
  const [uemail, setUemail] = useState(selectValue ? selectValue?.email : "");
  const [urole, setUrole] = useState(selectValue ? selectValue?.role : "");
  const [validEmail, setValidEmail] = useState(false);
  const [save, setSave] = useState(true);
  const [validate, setValidate] = useState(false);
  const [data, setData] = useState();
  const [unameError, setUnameError] = useState(false);
  const [unameErrorMsg, setUnameErrorMsg] = useState("");
  const [uemailError, setUemailError] = useState(false);
  const [uemailErrorMsg, setUemailErrorMsg] = useState("");

  // const changeuname = (e) => {
  //   setUname(e.target.value);
  //   setUnameError(false);
  //   setUnameErrorMsg("");
  //   if (uname.length !== 0 && uemail.length !== 0) {
  //     setSave(false);
  //   } else {
  //     setSave(true);
  //   }
  // };

  const changeuname = (event, eventType) => {
    const value =
      eventType === "change" ? event.target.value : event.target.value.trim();

    const re = /^[A-Za-z0-9 ]+$/;
    if (value === "" || re.test(value)) {
      setUname(value);
    }

    if (uname.toString().length !== 0 && uemail.toString().length !== 0) {
      setUnameError(false);
      setUnameErrorMsg("");
      setSave(false);
    } else {
      setUnameError(true);
      setUnameErrorMsg("Please Enter User Name!");
      setSave(true);
    }
  };

  const changeurole = (e) => {
    setUrole(e.target.value);
  };

  const handleClose = () => {
    onClose(false);
  };

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const changeButton = () => {
    if (uname.length !== 0) {
      setUnameError(false);
      setUnameErrorMsg("");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: uname, email: uemail, role: urole }),
      };
      fetch(ConstantsList.API_URL + "trufit/modusr/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setNotify(true);
          onClose(false);
          setNotifyMsg(data.Status_msg);
          setReload(true);
          setSelectValue({
            email: uemail,
            name: uname,
            role: urole,
          });
        })
        .catch((err) => console.log(err));
    } else {
      setUnameError(true);
      setUnameErrorMsg("Please Enter User Name!");
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      changeButton();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-btn"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Edit User
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                [ * ] Required fields
              </Typography>
              <ThemeProvider theme={checkboxTheme}>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    User Name *
                  </Typography>
                  <TextField
                    fullWidth
                    error={unameError}
                    helperText={unameErrorMsg}
                    //onChange={changeuname}
                    onChange={(event) => changeuname(event, "change")}
                    onBlur={(event) => changeuname(event, "blur")}
                    onKeyUp={(event) => handleKeyUp(event)}
                    value={uname}
                    placeholder="Enter User Name"
                    id="uname"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Email
                  </Typography>
                  <TextField
                    disabled={true}
                    fullWidth
                    value={uemail}
                    placeholder="Enter user email"
                    id="emailId"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        { padding: "8.2px 14px" },
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                        {
                          backgroundColor: "#F8F8F9",
                          borderColor: "#F2F2F3",
                          color: "#F2F2F3",
                        },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
              </ThemeProvider>
              <Stack spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Role *
                </Typography>
                <ThemeProvider theme={checkboxTheme}>
                  <RadioGroup
                    onChange={changeurole}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectValue?.role?.toString()}
                    name="radio-buttons-group"
                    sx={{
                      "& .css-roxw9t-MuiButtonBase-root-MuiRadio-root": {
                        padding: "4px 9px",
                      },
                    }}
                  >
                    <FormControlLabel
                      value="Admin"
                      control={<Radio />}
                      label="Admin"
                    />
                    <FormControlLabel
                      value="Sales Engineer"
                      control={<Radio />}
                      label="Sales Engineer"
                    />
                    <FormControlLabel
                      value="Account Manager"
                      control={<Radio />}
                      label="Account Manager"
                    />
                  </RadioGroup>
                </ThemeProvider>
              </Stack>
              <Button
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                onClick={changeButton}
                aria-label={ADAConfig.ADA.users.edit_btn}
                data-testid="save-btn"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
}
