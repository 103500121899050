import React, { useEffect, useState } from "react";
import {
  Box,
  createTheme,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useSelector, useDispatch } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";
import * as Config from "../../../config";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const PCMTab = (props) => {
  const enableData = true;
  const shipperDetails = useSelector(shipperDetailsData);
  const shipperClassPCMEnd = shipperDetails?.shipperclass?.pcm?.end;
  const shipperClassPCMSide = shipperDetails?.shipperclass?.pcm?.side;
  const shipperClassPCMTop = shipperDetails?.shipperclass?.pcm?.top;
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box maxWidth="false" sx={{ width: "100%", display: "flex" }}>
        <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
          <Typography
            component="h6"
            variant="h6"
            sx={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "22px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            Multiwall Detail Definition
          </Typography>
          <Stack direction="column" sx={{ width: "100%" }}>
            {/* Head Row */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#F2F2F3",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              ></Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Top
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Side
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  End
                </Typography>
              </Stack>
            </Stack>

            {/* Row 1  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  OD Length
                </Typography>
                <BootstrapTooltip
                  title={Config.CONFIGLIST.shipperDetails.tooltip.pcm.length}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMTop?.od_length}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMSide?.od_length}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMEnd?.od_length}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>

            {/* Row 2  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  OD Width
                </Typography>
                <BootstrapTooltip
                  title={Config.CONFIGLIST.shipperDetails.tooltip.pcm.width}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMTop?.od_width}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMSide?.od_width}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "11.5rem",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    type="number"
                    value={shipperClassPCMEnd?.od_width}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default PCMTab;
