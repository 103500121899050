import { Box } from "@mui/material";
import React from "react";
import ScenarioListComponent from "../../components/Scenario/ScenarioList";

const index = () => {
  return (
    <Box
      maxWidth="false"
      sx={{
        width: "100%",
        minHeight: {
          xs: "calc(100vh - 160px)",
          sm: "calc(100vh - 160px)",
          md: "calc(100vh - 160px)",
          lg: "calc(100vh - 200px)",
          xl: "calc(100vh - 224px)",
        },
        height: "auto",
        backgroundColor: "#F2F2F3",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-testid="box-outer-container"
    >
      <ScenarioListComponent />
    </Box>
  );
};

export default index;
