import { Box, createTheme, Stack, ThemeProvider } from "@mui/material";
import React from "react";
import BreadCrumb from "../../components/core/BreadCrumb";
import OppLayout from "../../components/Opportunities";

const Opportunity = (props) => {
  const { customer, setReload, data } = props;
  const breadcrumbData = [
    {
      id: 2,
      title: customer.name,
      link: "/dashboard",
    },
    { id: 3, title: "Opportunities ", link: "/dashboard/opportunity" },
    {
      id: 1,
      title: "Home / Customers",
      link: "/dashboard",
    },
  ];

  const theme2 = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });
  return (
    <>
      <Box
        maxWidth="false"
        sx={{
          width: "100%",
          minHeight: {
            xs: "calc(100vh - 160px)",
            sm: "calc(100vh - 160px)",
            md: "calc(100vh - 160px)",
            lg: "calc(100vh - 200px)",
            xl: "calc(100vh - 224px)",
          },
          height: "auto",
          backgroundColor: "#F2F2F3",
          direction: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        data-testid="box-outer-container"
      >
        <ThemeProvider theme={theme2}>
          <Stack px={6} pt={6} direction="column" sx={{ width: "100%" }}>
            <BreadCrumb data={breadcrumbData} />
          </Stack>
        </ThemeProvider>
        <Box maxWidth="false" p={6}>
          {data && (
            <OppLayout data={data} customer={customer} setReload={setReload} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Opportunity;
