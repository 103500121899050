import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import ShipperShipments from "./ShipperShipments";
import ShipperWeight from "./ShipperWeight";
import ShipperEfficiency from "./ShipperEfficiency";
import PayloadShipments from "./PayloadShipments";
import PayloadWeight from "./PayloadWeight";
import PayloadEfficiency from "./PayloadEfficiency";
import { useState } from "react";
import { useEffect } from "react";
import * as ConstantsList from "../../../constants";

const Summary = (props) => {
  const { title, data } = props;
  const [datasets, setDatasets] = useState(null);
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    var raw = JSON.stringify({
      scenario_id: parseInt(data?.scenario),
      set_size: parseInt(data?.setsize),
      risk: data?.risk,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "summary/solution_set/ ", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setDatasets(data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [data]);

  useEffect(() => {
    if (datasets) {
      setSummaryData(datasets);
    }
  }, [datasets]);

  return (
    <Stack
      direction="column"
      pt={3.8}
      pb={6}
      className="solChart"
      id="solSummaryChart"
    >
      <Stack direction="column" pb={2} spacing={4}>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          data-testid="stack-outer-container"
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "20px",
              color: "#3F4042",
              fontWeight: "500",
              lineHeight: "22px",
            }}
            data-testid="typography-container"
          >
            {title}
          </Typography>
        </Stack>

        {/* Shipper Summary */}
        <Stack
          direction="row"
          width={1}
          height="308px"
          pt={6}
          data-testid="stack-inner-container-01"
        >
          {/* Shipments */}
          <Stack width={1 / 3}>
            <ShipperShipments data={summaryData} />
          </Stack>
          {/* Weight */}
          <Stack width={1 / 3}>
            <ShipperWeight data={summaryData} />
          </Stack>
          {/* Efficiency */}
          <Stack width={1 / 3}>
            <ShipperEfficiency data={summaryData} />
          </Stack>
        </Stack>

        {/* Payload Summary */}
        <Stack
          direction="row"
          width={1}
          height="auto"
          pt={6}
          data-testid="stack-inner-container-02"
        >
          {/* Shipments */}
          <Stack width={1 / 3}>
            <PayloadShipments data={summaryData} />
          </Stack>
          {/* Weight */}
          <Stack width={1 / 3}>
            <PayloadWeight data={summaryData} />
          </Stack>
          {/* Efficiency */}
          <Stack width={1 / 3}>
            <PayloadEfficiency data={summaryData} />
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default Summary;
