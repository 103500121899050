import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as ConstantsList from "../../../constants";
import TableData from "../TableData";

const MaxLoad = (props) => {
  const { data } = props;
  const [shippedWeigth, setShippedWeight] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    var raw = JSON.stringify({
      scenario_id: parseInt(data?.scenario),
      set_size: parseInt(data?.setsize),
      risk: data?.risk,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "summary/solution_set/ ", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setShippedWeight(data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [data]);

  useEffect(() => {
    if (shippedWeigth?.solution_set) {
      let noPayload = shippedWeigth?.solution_set?.no_payload;
      let noShipper = shippedWeigth?.solution_set?.no_shipper;
      let DataForChart = [];
      let Rows = [];
      for (let i = 0; i < noPayload; i++) {
        let data = { x: (i + 1).toString() };
        let rowValue = { 0: `Payload ${i + 1}` };
        let total = 0;
        for (let j = 0; j < noShipper; j++) {
          rowValue[0] =
            shippedWeigth?.solution_set?.shipper[j]?.payload[i]?.name;
          total =
            total +
            parseInt(
              shippedWeigth?.solution_set?.shipper[j]?.payload[i]?.max_load
            );
        }

        for (let j = 0; j < noShipper; j++) {
          data[j.toString()] =
            (parseInt(
              shippedWeigth?.solution_set?.shipper[j]?.payload[i]?.max_load
            ) /
              total) *
            100;
          rowValue[(j + 1).toString()] =
            shippedWeigth?.solution_set?.shipper[j]?.payload[i]?.max_load;
        }
        DataForChart.push(data);
        Rows.push(rowValue);
      }
      setRows(Rows);
      let BarData = [];
      let Columns = ["Payload Name"];
      let DatasetsTest = [];
      for (let i = 0; i < shippedWeigth?.solution_set?.shipper.length; i++) {
        DatasetsTest.push({
          label: shippedWeigth?.solution_set?.shipper[i]?.name,
          data: DataForChart,
          parsing: {
            yAxisKey: i.toString(),
          },
          stack: "Stack 0",
          barThickness: 6,
        });
      }

      for (let i = 0; i < shippedWeigth?.solution_set?.shipper.length; i++) {
        let payload = { x: (i + 1).toString() };

        Columns.push(shippedWeigth?.solution_set?.shipper[i]?.name);
        for (
          let j = 0;
          j < shippedWeigth?.solution_set?.shipper[i]?.payload.length;
          j++
        ) {
          payload[j.toString()] =
            shippedWeigth?.solution_set?.shipper[i].payload[j]?.max_load;
        }
        BarData = [...BarData, payload];
      }
      setColumns(Columns);
    }
  }, [shippedWeigth]);

  return (
    <Stack direction="column" pt={3.8} pb={3} className="solChart">
      <Stack direction="column" spacing={4}>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "24px",
              color: "#3F4042",
              fontWeight: "500",
              lineHeight: "24px",
            }}
          >
            MaxLoad
          </Typography>
        </Stack>
      </Stack>

      <Stack width={1} direction="row" justifyContent="center" pt={2}>
        <Stack width="auto" direction="row" alignItems="center">
          <TableData columns={columns} rows={rows} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MaxLoad;
