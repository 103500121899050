import React from "react";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  width: "395px",
};

const CancelRecord = (props) => {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="cancel-icon-record"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Cancel PCM Creation
              </Typography>

              <Typography component="h6" variant="h6" sx={labelStyle}>
                Please confirm you want to cancel the PCM creation, all data
                entered will be lost, do you want to proceed?
              </Typography>

              <Button
                href="/dashboard/shipper/"
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                data-testid="cancel-confirm-btn"
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default CancelRecord;
