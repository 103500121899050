import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { SaveOutlined, ExpandMore, CheckOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "../../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../constants";
import * as Config from "../../../config";

import ProductLine from "../ProductLine";
import ProbeLocation from "../ProbeLocation";
import TemperatureProfile from "../TemperatureProfile";
import Payload from "../Payload";
import Preferences from "../Preferences";

import { scenarioControl, scenarioTab, scenarioData } from "../ScenarioSlice";
import { useHistory } from "react-router";
import Loader from "../../core/Loader/Loader";

const Parameters = (props) => {
  const { setOptimizeClicked } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [accordion, setAccordion] = useState(true);
  const scenarioDetails = useSelector(scenarioData);
  const text = Config?.CONFIGLIST?.scenario?.accordion;
  const [updateReload, setUpdateReload] = useState(false);
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });
  const buttonContainerStyle = {
    alignItems: "flex-end",
  };
  const AccordStyle = {
    boxShadow:
      "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
  };
  const AccordSummaryStyle = {
    paddingLeft: "24px",
    borderRadius: "6px",
    backgroundColor: "#235984",
    color: "#FFFFFF",
    "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
      color: "#FFFFFF",
    },
  };

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [saveData, setData] = useState(null);
  const [productLineEnable, setProductLineEnable] = useState(false);

  const [isOptimize, setIsOptimize] = useState(true);
  //const sectionNo = useRef(1);
  const [sectionNo, setSectionNo] = useState(1);

  const handlesave = () => {
    if (scenarioDetails?.scenario?.id && saveData) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: saveData,
      };
      fetch(ConstantsList.API_URL + "scenario/preference/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setIsOptimize(false);
              setNotify(true);
              setNotifyMsg(data.data.toString());
              setSeverity("success");
              setSectionNo(6);
            } else if (data.status_code === 400) {
              setNotify(true);
              setNotifyMsg(data.data.toString());
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      setNotify(true);
      setNotifyMsg(
        "Save the Thermal setting in order to enable the optimize scenario button"
      );
      setSeverity("error");
    }
  };

  const [datagg, setDatagg] = useState(null);

  const handleOptimize = () => {
    setOptimizeClicked(true);
    var raw = JSON.stringify({
      scenario_id: scenarioDetails?.scenario?.id,
      shipper_dict_id: scenarioDetails?.dictionary?.id,
      opportunity_id: scenarioDetails?.opportunity?.id,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(
      ConstantsList.API_URL + "Trufit_optimization/optimize/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((err) => console.log(err));
    setIsOptimize(true);
    dispatch(scenarioTab("1"));
  };

  useEffect(() => {
    setLoading(true);
    if (scenarioDetails?.scenario?.id) {
      fetch(
        ConstantsList.API_URL +
          "scenario/scenario/" +
          scenarioDetails?.scenario?.id
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setDatagg(data.data);
              setLoading(false);
            } else {
              setDatagg(data?.data);
              setLoading(false);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [scenarioDetails?.scenario?.id, loading]);

  const ScenarioType = scenarioDetails?.type;

  useEffect(() => {
    setLoading(true);
    if (datagg) {
      let preference = datagg?.preference ?? {};

      let predata = {
        id: preference?.id ?? null,
        limit_releaseproduct: preference?.limit_releaseproduct ?? false,
        max_numofshipper: parseInt(preference?.max_numofshipper ?? "4"),
        min_numofyear_shipper: preference?.min_numofyear_shipper ?? "1200",
        max_numofshipper_pership: preference?.max_numofshipper_pership ?? "2",
        performance_bins: {
          not_met:
            preference && preference.performance_bins
              ? preference?.performance_bins[0]?.not_met ?? "-12"
              : "",
          med_risk:
            preference && preference.performance_bins
              ? preference?.performance_bins[0]?.med_risk ?? "0"
              : "",
          no_risk:
            preference && preference.performance_bins
              ? preference?.performance_bins[0]?.no_risk ?? "12"
              : "",
          exceed:
            preference && preference.performance_bins
              ? preference?.performance_bins[0]?.exceed ?? "36"
              : "",
        },
        thermal: preference?.thermal?.id ?? null,
        initialThermal: preference?.thermal?.insulatorinitial ?? "20",
      };

      let OrderData = datagg?.payload ?? [];
      let OrderReduxData = [];
      for (let i = 0; i < OrderData.length; i++) {
        let data = OrderData[i];
        let OrderdataValue = {
          id: data?.id,
          checkbox: false,
          payload: { id: data?.payload_id?.id, name: data?.payload_id?.name },
          length: data?.payload_id?.length,
          width: data?.payload_id?.width,
          height: data?.payload_id?.height,
          weight: data?.payload_id?.weight,
          orderprofile: {
            id: data?.quantities_shipped?.id ?? null,
            orientation:
              data?.quantities_shipped?.orientation?.map((data) => data?.id) ??
              [],
            orderprofile: data?.quantities_shipped?.orderprofile ?? [],
          },
          mintemp: data?.min_range,
          maxtemp: data?.max_range,
          duration: data?.duration,
          pcm: data?.pcm_type,
          summer: data?.summer_profile,
          winter: data?.winter_profile,
          loadtemp: data?.payload_temp,
        };
        OrderReduxData.push(OrderdataValue);
      }

      let dataTemp = [];
      for (let i = 0; i < datagg?.temperature_profile.length; i++) {
        let dataValue = datagg?.temperature_profile[i];
        let data = {
          id: dataValue?.id,
          default: dataValue?.default,
          checkbox: false,
          selectValue: "",
          profile: parseInt(dataValue?.tp_id?.id),
          season: parseInt(dataValue?.profile_season),
          cycles: parseInt(dataValue?.cycles),
          hours: dataValue?.hours,
          temperature: dataValue?.temperature,
        };
        dataTemp.push(data);
      }

      if (
        dispatch(
          scenarioControl({
            ...scenarioDetails,
            opportunity: {
              id: datagg?.opportunity_id?.id,
              name: datagg?.opportunity_id?.opportunity_name,
            },
            customer: {
              id: datagg?.customer_id?.id,
              name: datagg?.customer_id?.customer_name,
            },
            productline:
              datagg?.productline?.map((data) => data?.id.toString()) ?? [],
            probelocation: datagg?.probelocation?.map((data) => data?.id) ?? [],
            temperature: datagg?.temperature_profile ? dataTemp : [],
            payload: datagg?.payload ? OrderReduxData : [],
            preference: datagg?.preference ? predata : {},
            status: datagg?.scenario_status ?? "Optimization Pending",
          })
        )
      ) {
        setLoading(false);
        setUpdateReload(true);
      }
    }
    if (scenarioDetails?.type === "New") {
      setLoading(false);
    }
  }, [datagg, ScenarioType, loading]);

  const handleAccordion = (panel) => (event, isExpanded) => {
    setAccordion(isExpanded ? panel : false);
  };

  let isDefaultExpend =
    scenarioDetails?.status === "Optimization Pending" ||
    scenarioDetails?.status === "Optimization Failed" ||
    scenarioDetails?.status === "Optimization Cancelled"
      ? false
      : true;

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose = () => {
    setOpenLoad(false);
  };

  const [updateProbe, setUpdateProbe] = useState(false);

  return (
    <>
      <ThemeProvider theme={theme}>
        {!loading && datagg && (
          <Stack spacing={3} direction="column" pb={5}>
            <Stack spacing={6}>
              {/* Product Line */}
              <Accordion
                sx={AccordStyle}
                defaultExpanded={sectionNo === 1 ? true : false}
                disabled={sectionNo === 1 ? "" : "disabled"}
                expanded={sectionNo === 1 ? true : false}
              >
                <AccordionSummary
                  sx={AccordSummaryStyle}
                  expandIcon={<ExpandMore />}
                  aria-controls="Product Line"
                  id="Scenario-ProductLine"
                >
                  <Typography>{text?.productLine}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <ProductLine
                    setEnable={setProductLineEnable}
                    setNotify={setNotify}
                    setNotifyMsg={setNotifyMsg}
                    setSectionNo={setSectionNo}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Probe Location */}

              <Accordion
                sx={AccordStyle}
                defaultExpanded={isDefaultExpend}
                disabled={
                  isDefaultExpend === true
                    ? ""
                    : sectionNo === 2
                    ? ""
                    : "disabled"
                }
                expanded={
                  isDefaultExpend === true
                    ? true
                    : sectionNo === 2
                    ? true
                    : false
                }
              >
                <AccordionSummary
                  sx={AccordSummaryStyle}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="Scenario-ProbeLocation"
                >
                  <Typography>{text?.probeLocation}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <ProbeLocation
                    setSectionNo={setSectionNo}
                    setUpdateProbe={setUpdateProbe}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Temperature Profile */}
              <Accordion
                sx={AccordStyle}
                defaultExpanded={isDefaultExpend}
                //disabled={handleDisabled("tempProfiles", "probeLocation")}
                disabled={
                  isDefaultExpend === true
                    ? ""
                    : sectionNo === 3
                    ? ""
                    : "disabled"
                }
                expanded={
                  isDefaultExpend === true
                    ? true
                    : sectionNo === 3
                    ? true
                    : false
                }
              >
                <AccordionSummary
                  sx={AccordSummaryStyle}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="Scenario-TemperatureProfile"
                >
                  <Typography>{text?.temperatureProfile}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TemperatureProfile
                    updateProbe={updateProbe}
                    setUpdateReload={setUpdateReload}
                    setSectionNo={setSectionNo}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Payload */}
              <Accordion
                sx={AccordStyle}
                defaultExpanded={isDefaultExpend}
                //disabled={handleDisabled("payload", "tempProfiles")}
                disabled={
                  isDefaultExpend === true
                    ? ""
                    : sectionNo === 4
                    ? ""
                    : "disabled"
                }
                expanded={
                  isDefaultExpend === true
                    ? true
                    : sectionNo === 4
                    ? true
                    : false
                }
              >
                <AccordionSummary
                  sx={AccordSummaryStyle}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="Scenario-Payload"
                >
                  <Typography>{text?.payload}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Payload
                    updateReload={updateReload}
                    setUpdateReload={setUpdateReload}
                    setSectionNo={setSectionNo}
                  />
                </AccordionDetails>
              </Accordion>

              {/* Preferences */}
              <Accordion
                sx={AccordStyle}
                defaultExpanded={isDefaultExpend}
                //disabled={handleDisabled("preference", "payload")}
                disabled={
                  isDefaultExpend === true
                    ? ""
                    : sectionNo === 5
                    ? ""
                    : "disabled"
                }
                expanded={
                  isDefaultExpend === true
                    ? true
                    : sectionNo === 5
                    ? true
                    : false
                }
              >
                <AccordionSummary
                  sx={AccordSummaryStyle}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="Scenario-Preferences"
                >
                  <Typography>{text?.preferences}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Preferences
                    saveData={saveData}
                    setData={setData}
                    setSectionNo={setSectionNo}
                  />
                </AccordionDetails>
              </Accordion>
            </Stack>
            {(scenarioDetails?.status === "Optimization Pending" ||
              scenarioDetails?.status === "Optimization Failed" ||
              scenarioDetails?.status === "Optimization Cancelled") &&
              sectionNo >= 5 && (
                <Stack
                  direction="row"
                  spacing={1}
                  sx={buttonContainerStyle}
                  style={{ justifyContent: "flex-end" }}
                >
                  {sectionNo === 5 && (
                    <Button
                      onClick={handlesave}
                      sx={{
                        width: "145px",
                        height: "44px",
                        borderRadius: "6px",
                        padding: "0px, 20px, 0px, 20px",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        backgroundColor: "#FFFFFF",
                      }}
                      data-testid="editbutton"
                      startIcon={<SaveOutlined />}
                      style={{
                        textTransform: "none",
                        border: "1px solid #B1B2B4",
                      }}
                    >
                      Save
                    </Button>
                  )}
                  {sectionNo === 6 && (
                    <Button
                      onClick={handleOptimize}
                      sx={{
                        width: "202px",
                        height: "44px",
                        borderRadius: "6px",
                        padding: "0px, 20px, 0px, 20px",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        backgroundColor: "#F26322",
                        "&:disabled": {
                          opacity: "0.3",
                        },
                      }}
                      data-testid="addbutton"
                      color="primary"
                      startIcon={<CheckOutlined />}
                      style={{
                        textTransform: "none",
                        border: "1px solid #F26322",
                      }}
                    >
                      Optimize Scenario
                    </Button>
                  )}
                </Stack>
              )}
          </Stack>
        )}

        {loading && (
          <Box sx={{ display: "flex" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoad}
              onClick={handleClose}
            >
              <Loader />
            </Backdrop>
          </Box>
        )}

        {notify && (
          <SnackBar
            open={notify}
            setOpen={setNotify}
            message={notifyMsg}
            severity={severity}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default Parameters;
