import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import BreadCrumb from "../core/BreadCrumb";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useSelector, useDispatch } from "react-redux";
import {
  scenarioControl,
  scenarioData,
  scenarioTab,
  scenarioStatus,
} from "./ScenarioSlice";
import Parameters from "./Parameters";

import Output from "../../../src/components/Scenario/Output";
import ReportScenario from "../../components/Scenario/ReportScenario";
import TimeLine from "../../components/Scenario/TimeLine";
import { ClearOutlined } from "@mui/icons-material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Loader from "../core/Loader/Loader";

import * as ConstantsList from "../../../src/constants";
import SnackBar from "../core/SnackBar/SnackBar";

const Scenario = () => {
  const dispatch = useDispatch();
  const scenarioDetails = useSelector(scenarioData);
  const [reload, setReload] = useState(false);
  const [optimizeClicked, setOptimizeClicked] = useState(false);
  const selectedTab = scenarioDetails?.tab ?? "";
  const tabLabel = {
    tabView1: "Parameters",
    tabView2: "Output",
    tabView3: "Report",
  };

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);

  const breadcrumbData = [
    {
      id: 4,
      title: scenarioDetails?.scenario?.name,
      link: "/dashboard/opportunity/scenario",
    },
    { id: 3, title: "Scenario", link: "/dashboard/opportunity/scenario-list" },
    {
      id: 2,
      title: scenarioDetails?.opportunity?.name,
      link: "/dashboard/opportunity/",
    },
    {
      id: 1,
      title: "Home / " + scenarioDetails?.customer?.name,
      link: "/dashboard",
    },
  ];

  const handleChange = (event, newValue) => {
    dispatch(scenarioTab(newValue.toString() || "0"));
  };

  const styles = {
    tab: {
      color: "#000103",
    },
    tabItemContainer: {
      background: "none",
    },
  };

  const checkTab = (id) => {
    switch (id) {
      case "1":
        return scenarioDetails?.status === "Optimization Completed" ||
          scenarioDetails?.status === "Optimization In-progress" ||
          scenarioDetails?.status === "No Solution Found" ||
          scenarioDetails?.status === "Report Created"
          ? false
          : true;
      case "2":
        return scenarioDetails?.status === "Optimization Completed" ||
          scenarioDetails?.status === "Report Created"
          ? false
          : true;
      default:
        return true;
    }
  };

  const colorStatus = (color) => {
    switch (color) {
      case "Optimization Pending":
        return "#CBCCCD";
      case "Optimization In-progress":
        return "#F26322";
      case "Optimization Completed":
        return "#408515";
      case "Optimization Cancelled":
        return "#ED9900";
      case "Optimization Failed":
        return "#DA0000";
      case "Report Created":
        return "#0f78db";
      default:
        return "#CBCCCD";
    }
  };

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches?.keys().then((names) => {
      names?.forEach((name) => {
        caches?.delete(name);
      });
    });
  };

  const handleGenerate = () => {
    var raw = JSON.stringify({
      scenario_id: scenarioDetails?.scenario?.id,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "summary/generate_report/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          //dispatch(scenarioPayload(rows));
          if (data.status_code === 200) {
            //setNotify(true);
            //setNotifyMsg(data.data.toString());
            dispatch(
              scenarioControl({
                ...scenarioDetails,
                tab: "2",
                status: "Report Created",
                //status: data?.data?.toString(),
              })
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCancelOptimization = () => {
    var scenarioId = scenarioDetails?.scenario?.id;

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      ConstantsList.API_URL +
        "Trufit_optimization/cancel_optimize/" +
        scenarioId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          //dispatch(scenarioPayload(rows));
          if (data.status_code === 200) {
            setNotify(true);
            setNotifyMsg(data.data.toString());

            if (dispatch(scenarioStatus("Optimization Cancelled"))) {
              dispatch(scenarioTab("0"));
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    clearCacheData();
    if (scenarioDetails.status === "Optimization In-progress") {
      dispatch(scenarioTab("1"));
    }
  }, []);

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose = () => {
    setOpenLoad(false);
  };

  return (
    <>
      <Stack
        px={6}
        pt={6}
        direction="column"
        sx={{ width: "100%", backgroundColor: "#F2F2F3" }}
      >
        <BreadCrumb data={breadcrumbData} />
      </Stack>

      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "#F2F2F3",
          width: "100%",
          "& .MuiAppBar-positionSticky": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Stack
          px={6}
          pt={6}
          pb={3.5}
          direction="column"
          spacing={3}
          sx={{ width: "100%" }}
        >
          <Box
            maxWidth="false"
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "32px",
                lineHeight: "38px",
                color: "#19191A",
              }}
            >
              {scenarioDetails?.scenario?.name}
            </Typography>
            <Typography
              sx={{
                padding: "8px 16px",
                borderRadius: "6px",
                border: "1px solid #E6E7E8",
                backgroundColor: "#E6E7E8",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#646568",
                marginRight: "5px",
              }}
            >
              Shipper Dictionary:
              <strong>{` ${scenarioDetails?.dictionary?.name}`}</strong>
            </Typography>
          </Box>
        </Stack>

        {scenarioDetails?.tab && scenarioDetails?.tab === "1" ? (
          <Stack
            px={6}
            pt={1}
            pb={1}
            direction="row"
            spacing={3}
            sx={{ width: "100%", paddingLeft: "0px" }}
          >
            <Box
              maxWidth="false"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "-webkit-fill-available",
                height: "auto",
                color: "#000000",
              }}
            >
              <TimeLine
                setOptimizeClicked={setOptimizeClicked}
                optimizeClicked={optimizeClicked}
              ></TimeLine>
            </Box>
            <Box
              maxWidth="false"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "auto",
                height: "auto",
                alignItems: "flex-end",
              }}
            >
              <Box
                maxWidth="false"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "auto",
                  height: "auto",
                  marginRight: "5px",
                  textAlign: "right",
                }}
              >
                {scenarioDetails?.status !== "Optimization Completed" &&
                  scenarioDetails?.status !== "Optimization Failed" &&
                  scenarioDetails?.status !== "Optimization Cancelled" &&
                  scenarioDetails?.status !== "No Solution Found" &&
                  scenarioDetails?.status !== "Report Created" && (
                    <Button
                      onClick={handleCancelOptimization}
                      sx={{
                        width: "216px",
                        height: "44px",
                        borderRadius: "6px",
                        padding: "0px, 20px, 0px, 20px",
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        backgroundColor: "#FFFFFF",
                        marginRight: "10px",
                      }}
                      data-testid="editbutton"
                      startIcon={<ClearOutlined />}
                      style={{
                        textTransform: "none",
                        border: "1px solid #B1B2B4",
                        color: "#000000",
                      }}
                    >
                      Cancel Optimization
                    </Button>
                  )}

                {scenarioDetails?.status === "Optimization Completed" && (
                  <Button
                    onClick={handleGenerate}
                    sx={{
                      width: "216px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#F26322",
                      "&:disabled": {
                        opacity: "0.3",
                        color: "#000000",
                      },
                    }}
                    data-testid="addbutton"
                    color="primary"
                    startIcon={<CheckOutlinedIcon />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #F26322",
                      color: "#000000",
                    }}
                  >
                    Generate Report
                  </Button>
                )}
              </Box>
            </Box>
          </Stack>
        ) : (
          <></>
        )}
      </AppBar>

      <Stack
        px={6}
        pb={3.5}
        direction="column"
        spacing={3}
        sx={{ width: "100%", backgroundColor: "#F2F2F3" }}
      >
        <Box
          maxWidth="false"
          sx={{
            width: "auto",
            textAligh: "right",
            backgroundColor: "#F2F2F3",
            padding: "0px",
            position: "relative",
          }}
        >
          <TabContext
            value={selectedTab}
            sx={{
              "&.Mui-selected": { color: "#19191A", fontWeight: "600" },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F2F2F3",
                borderBottom: 0,
                borderColor: "none",
                mb: "24px",
                display: "flex",
                justifyContent: "space-between",
                top: "-80px",
                zIndex: "9999",
                width: "-webkit-fill-available",
              }}
            >
              <Box
                p={3}
                sx={{
                  display: "inline-flex",
                  height: "56px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "6px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "max-content",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#646568",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  Status:
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#000000",
                    marginRight: "5px",
                  }}
                >
                  {scenarioDetails?.status}
                </Typography>
                <Box
                  component="span"
                  sx={{
                    width: "24px",
                    height: "8px",
                    background: scenarioDetails?.status
                      ? colorStatus(scenarioDetails?.status)
                      : "#CBCCCD",
                    borderRadius: "8px",
                    display: "inline-block",
                  }}
                ></Box>
              </Box>

              <TabList
                fontWeight={600}
                textColor="secondary"
                TabIndicatorProps={{
                  sx: {
                    "& .MuiButtonBase-root": { padding: 0 },
                    "& .MuiTabs-indicator": { display: "none !important" },
                    display: "none !important",
                    hidden: true,
                  },
                  style: { display: "none", hidden: true },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  style={styles?.tab}
                  color="#19191A"
                  label={tabLabel.tabView1}
                  sx={{
                    color: "#19191A",
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    marginBottom: "22px",
                    pl: 0,
                    "& .Mui-selected": {
                      color: "#19191A!important",
                      fontWeight: "600",
                    },
                  }}
                  value="0"
                />
                <Tab
                  //disabled={checkTab("1")}

                  style={styles?.tab}
                  label={tabLabel.tabView2}
                  color="#19191A"
                  sx={{
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    marginBottom: "22px",
                    pl: 0,
                    color: "#19191A",
                    opacity: checkTab("1") ? "0.3" : "1",
                    "& .Mui-selected": {
                      color: "#19191A!important",
                      fontWeight: "600",
                    },
                    "& .Mui-disabled": { color: "#969697 !important" },
                  }}
                  value="1"
                  disabled={checkTab("1") ? "disabled" : ""}
                />
                <Tab
                  //disabled={checkTab("2")}
                  style={styles?.tab}
                  label={tabLabel.tabView3}
                  color="#19191A"
                  sx={{
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    marginBottom: "22px",
                    pl: 0,
                    color: "#19191A",
                    opacity: checkTab("2") ? "0.3" : "1",
                    "& .Mui-selected": {
                      color: "#19191A!important",
                      fontWeight: "600",
                    },

                    "& .Mui-disabled": { color: "#969697 !important" },
                  }}
                  value="2"
                  disabled={checkTab("2") ? "disabled" : ""}
                />
              </TabList>
            </Box>
            <TabPanel
              value="0"
              sx={{
                padding: "0",
              }}
            >
              <Parameters
                setOptimizeClicked={setOptimizeClicked}
                optimizeClicked={optimizeClicked}
              />
            </TabPanel>
            <TabPanel
              value="1"
              sx={{
                padding: "0",
                boxShadow:
                  "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
              }}
            >
              <Output
                setOptimizeClicked={setOptimizeClicked}
                optimizeClicked={optimizeClicked}
              />
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                padding: "0",
                boxShadow:
                  "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
              }}
            >
              <ReportScenario
                setOptimizeClicked={setOptimizeClicked}
                optimizeClicked={optimizeClicked}
              />
            </TabPanel>
          </TabContext>
        </Box>
        {notify && (
          <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
        )}
      </Stack>
    </>
  );
};

export default Scenario;
