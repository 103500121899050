import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Radio,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";
import CreateProductLine from "./CreateProductLine";
import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  productLineUpdate,
  productLineNameUpdate,
  shipperData,
} from "../NewShipper/NewShipperSlice";
import { shipperDetailsData } from "../ViewShipper/ViewShipperSlice";
import Loader from "../core/Loader/Loader";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import NoImage from "../../assets/images/noimage.png";
const ProductLine = (props) => {
  const { type } = props;
  const isView = type && type === "view" ? true : false;
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const viewShipperDetails = useSelector(shipperDetailsData);
  const ShipperID = isView
    ? viewShipperDetails?.shipperdict_id?.id
    : shipperDetails?.shipperdict_id;

  const [open, setOpen] = useState(false);
  const [proDetails, setProDetails] = useState({
    id: 0,
    name: "",
    image: "",
    link: "",
    description: "",
  });

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [selected, setSelected] = useState(shipperDetails?.productline_id);

  const handleCheck = (e, id, name, descr, image, link) => {
    if (selected === e.target.value.toString()) {
      setSelected("");
      setImageURL("");
      setProDetails();
      dispatch(productLineUpdate(""));
      dispatch(productLineNameUpdate("----"));
    } else {
      setImageURL(image?.toString());
      setSelected(e.target.value.toString());
      dispatch(productLineUpdate(id?.toString()));
      dispatch(productLineNameUpdate(name?.toString()));
      setProDetails({
        id: id,
        name: name,
        image: image,
        link: link,
        description: descr,
      });
    }
  };

  const isCheck = (name) => selected === name;

  const createNewProduct = () => {
    setOpen(true);
  };

  const [loading, setLoading] = useState(true);
  const [productLineData, setProductLineData] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ shipperdict_id: ShipperID }),
    };
    fetch(ConstantsList.API_URL + "prodline/productline_type/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProductLineData(data.data);
          setLoading(false);
          setReload(false);
        }
      });
  }, [reload, ShipperID]);

  const isSelected = selected?.length > 0 ? true : false;

  const [loaderOpen, setLoaderOpen] = useState(true);
  const handleClose = () => {
    setLoaderOpen(false);
  };

  const [imageURL, setImageURL] = useState("");
  const [isSelectedAny, setIsSelectedAny] = useState(false);

  return (
    <Box
      p={0}
      maxWidth="false"
      sx={{ width: "100%", height: "auto" }}
      data-testid="box-outer-container"
    >
      <Stack direction="row" width={1} pt={5} spacing={1}>
        <Stack
          direction="column"
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 3.5 / 5, xl: 3.5 / 5 }}
        >
          <Stack
            direction="row"
            spacing={4}
            pl={3}
            pb={7}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {productLineData &&
              productLineData?.map((line, i) => {
                return (
                  <>
                    {line?.type_details?.length > 0 && (
                      <Stack
                        key={i}
                        direction="column"
                        spacing={3}
                        sx={{ height: "auto" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "22px",
                            marginBottom: "12px",
                          }}
                        >
                          {line?.type}
                        </Typography>
                        {line?.type_details?.map((list) => {
                          let IsChecked = isCheck(list?.id?.toString());
                          if (
                            !isSelectedAny &&
                            line?.type === "Reusable Parcel"
                          ) {
                            IsChecked = true;
                            setImageURL(list?.path?.toString());
                            setSelected(list?.id?.toString());
                            dispatch(productLineUpdate(list?.id?.toString()));
                            dispatch(
                              productLineNameUpdate(list?.name?.toString())
                            );
                            setProDetails({
                              id: list.id,
                              name: list.name,
                              image: list.path,
                              link: list.product_sheet_link,
                              description: list.description,
                            });
                            setIsSelectedAny(true);
                          }

                          return (
                            <Stack
                              key={list?.id}
                              direction="row"
                              sx={{
                                width: "17.5rem",
                                height: "7.5rem",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: IsChecked ? "0px" : "1px solid #E6E7E8",
                                backgroundColor: IsChecked
                                  ? "#F2F2F3"
                                  : "#ffffff",
                                borderRadius: "8px",
                                padding: "8px 8px 8px 8px",
                              }}
                            >
                              <Box
                                p={0}
                                component="img"
                                src={
                                  list?.path === "" || list?.path === null
                                    ? NoImage
                                    : ConstantsList.API_URL + list?.path
                                }
                                alt="Product Line"
                                sx={{
                                  width: "5rem",
                                  height: "4.838rem",
                                }}
                              />
                              <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {list?.name}
                                </Typography>
                              </Stack>

                              <Stack sx={{ height: "100%" }}>
                                <ThemeProvider theme={checkboxTheme}>
                                  <Radio
                                    onClick={(e) =>
                                      handleCheck(
                                        e,
                                        list.id,
                                        list.name,
                                        list.description,
                                        list.path,
                                        list.product_sheet_link
                                      )
                                    }
                                    sx={{ padding: 0 }}
                                    checked={IsChecked}
                                    value={list?.id?.toString()}
                                    name={"1"}
                                    inputProps={{ "aria-label": "A" }}
                                  />
                                </ThemeProvider>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}
                  </>
                );
              })}
          </Stack>
        </Stack>

        <Stack
          spacing={3}
          direction="column"
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 1.5 / 5, xl: 1.5 / 5 }}
        >
          <Box
            px={3}
            maxWidth="false"
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={createNewProduct}
              sx={{
                width: "145px",
                height: "44px",
                borderRadius: "6px",
                padding: "0px, 20px, 0px, 20px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
              }}
              data-testid="addbutton"
              color="primary"
              startIcon={<AddIcon />}
              style={{
                textTransform: "none",
                border: "1px solid #B1B2B4",
              }}
            >
              Create New
            </Button>
          </Box>
          {isSelected && (
            <Stack
              sx={{
                backgroundColor: "#F2F2F3",
                overflow: "auto",
                maxHeight: "400px",
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                p={5}
                spacing={4}
              >
                <Stack direction="column" pt={4} spacing={3}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      p={0}
                      component="img"
                      src={ConstantsList.API_URL + imageURL}
                      alt="Product Line"
                      sx={{
                        width: "219px",
                        height: "auto",
                      }}
                      tabIndex={0}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      disabled={
                        proDetails?.link
                          ? proDetails?.link?.toString()?.length > 0
                            ? false
                            : true
                          : true
                      }
                      onClick={() => window.open(proDetails.link)}
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        width: "15.25rem",
                        height: "2.75rem",
                        padding: "0px, 20px, 0px, 20px",
                        borderRadius: "6px",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#000000",
                        backgroundColor: "#FFFFFF",
                        lineHeight: "24px",
                        border: "1px solid #B1B2B4",
                      }}
                      startIcon={<LaunchIcon />}
                      data-testid="view-product-btn"
                    >
                      View Product Sheet
                    </Button>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={2.4}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      fontSize: "26px",
                      lineHeight: "32px",
                      color: "#000000",
                    }}
                  >
                    {proDetails.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  >
                    {proDetails.description}{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {productLineData &&
        productLineData?.map((line, i) => {
          return (
            <>
              {line?.count === 0 && (
                <NoRecordFound
                  table={true}
                  title="No product line added yet"
                  description="Click Create New to start creating Product Line"
                />
              )}
            </>
          );
        })}

      {open && (
        <CreateProductLine
          open={open}
          setOpen={setOpen}
          shipperID={ShipperID}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Box>
  );
};

export default ProductLine;
