import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Shipper from "../../pages/Shipper/Shipper";
import PCM from "../../pages/PCM/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { tab, selectTab } from "./navigateSlice";
import Breadcrumb from "../../components/Breadcrumb/index";
import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";
import * as ConstantsList from "../../constants";

const Navigator = () => {
  const selectedTab = useSelector(selectTab);
  const dispatch = useDispatch();
  const shipperDictionary = useSelector(shipperDictionaryData);
  const [shipperTabDisable, setShipperTabDisable] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });

  const handleChange = (event, newValue) => {
    dispatch(tab(newValue.toString() || "0"));
  };

  const text = {
    shipperDict: "Shippers",
    pcmList: "PCMs",
  };

  useEffect(() => {
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ shipperdict_id: shipperDictionary?.id }),
    };
    fetch(ConstantsList.API_URL + "pcm/getall-pcm/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code.toString() === "200") {
          if (data.data.pcm_count > 0) {
            setShipperTabDisable(false);
          } else {
            setShipperTabDisable(true);
            dispatch(tab("1"));
          }
        }
      });
  }, [shipperDictionary]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        maxWidth="false"
        sx={{ width: "100%", typography: "body1", backgroundColor: "#F2F2F3" }}
      >
        <TabContext
          value={selectedTab}
          sx={{
            "&.Mui-selected": {
              color: "#19191A",
              fontWeight: "600",
              backgroundColor: "#F2F2F3",
            },
          }}
        >
          <Box
            sx={{
              borderBottom: 0,
              borderColor: "none",
            }}
          >
            <Breadcrumb
              fromPage="Shipper Dictionaries"
              selectedVal={{ ...shipperDictionary }}
            />
            <TabList
              fontWeight={600}
              textColor="secondary"
              TabIndicatorProps={{
                sx: { display: "none !important", hidden: true },
                style: { display: "none", hidden: true },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label={text.shipperDict}
                sx={{
                  textTransform: "none",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "24px",
                  marginBottom: "22px",
                  pl: 0,
                  color: "#19191A",
                  "& .Mui-selected": { color: "#19191A", fontWeight: "600" },
                }}
                value="0"
                disabled={shipperTabDisable ? "disabled" : ""}
              />
              <Tab
                label={text.pcmList}
                sx={{
                  textTransform: "none",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "24px",
                  marginBottom: "22px",
                  color: "#19191A",
                  "& .Mui-selected": { color: "#19191A", fontWeight: "600" },
                }}
                value="1"
              />
            </TabList>
          </Box>
          <TabPanel value="0" sx={{ margin: "0px", padding: "0" }}>
            <Shipper text="Shipper" />
          </TabPanel>
          <TabPanel value="1" sx={{ margin: "0px", padding: "0" }}>
            <PCM text="PCM" />
          </TabPanel>
        </TabContext>
      </Box>
    </ThemeProvider>
  );
};

export default Navigator;
