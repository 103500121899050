import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch } from "react-redux";
import { custControl } from "../../components/Customer/CustomerSlice";
import { useHistory } from "react-router";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric ? UnfoldMoreIcon : null}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const columns = [
  {
    id: "select",
    label: "",
    minWidth: 80,
    width: "10%",
    align: "center",
    numeric: false,
  },
  {
    id: "customer_name",
    label: "Customer Name",
    width: "40%",
    align: "left",
    numeric: true,
  },
  {
    id: "opportunity_count",
    label: "Opportunities",
    width: "35%",
    align: "center",
    numeric: true,
  },
  {
    id: "viewdetails",
    label: "View Details",
    width: "-webkit-fill-available",
    align: "center",
    numeric: false,
  },
];

const ListView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data,
    search,
    setCustomerName,
    selectedCustomers,
    setSelectedCustomers,
    setSelectedCustomersList,
  } = props;

  const handleChange = (event, id, name, row) => {
    const isChecked = event.target.checked;
    let newSelected = [...selectedCustomers];
    if (isChecked) {
      newSelected.push(id);
    } else if (!isChecked) {
      newSelected = newSelected.filter((arrayItem) => arrayItem !== id);
    }
    if (newSelected.length > 0) {
    }
    setSelectedCustomers(newSelected);
    if (newSelected?.length === 1 && event.target.checked) {
      setCustomerName(row);
    } else {
      setCustomerName("");
    }

    setSelectedCustomersList([]);
    const multipleCus = [];
    data.map((dataItem, index) => {
      if (newSelected.includes(dataItem.id)) {
        multipleCus.push(dataItem);
      }
    });
    setSelectedCustomersList([...multipleCus]);
  };

  const handleClick = (event, id, name, row) => {
    let newSelected = [...selectedCustomers];

    if (selectedCustomers.includes(id)) {
      newSelected = newSelected.filter((arrayItem) => arrayItem !== id);
    } else {
      newSelected.push(id);
    }
    setSelectedCustomers(newSelected);
    if (newSelected?.length === 1 && event.target.checked) {
      setCustomerName(row);
    } else {
      setCustomerName("");
    }

    setSelectedCustomersList([]);
    const multipleCus = [];
    data.map((dataItem, index) => {
      if (newSelected.includes(dataItem.id)) {
        multipleCus.push(dataItem);
      }
    });
    setSelectedCustomersList([...multipleCus]);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer_name");
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const isSelected = (name) => selectedCustomers.indexOf(name) !== -1;

  const filtered = search
    ? data.filter((user) => {
        return user.customer_name === search.customer_name;
      })
    : data;

  const handleCustomer = (e, id, name) => {
    dispatch(custControl({ id: id, name: name }));

    setTimeout(() => {
      history.push("/dashboard/opportunity");
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      for (let i = 0; i < 10000; i++) {
        window.clearInterval(i);
      }
    }, 10000);
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",

            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props?.data?.length}
          />
          <TableBody>
            {filtered?.length === 0 && (
              <NoRecordFound title="No result found" description="" />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                const selctedId = row.id;
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(event, row.id, row.customer_name, row)
                    }
                    role="checkbox"
                    data-testid="customer-table-row"
                    aria-checked={isItemSelected}
                    tabIndex={index}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                    }}
                  >
                    {columns.map((column, i) => {
                      const value = row[column.id];

                      return i === 0 ? (
                        <TableCell
                          key={`${row.id}-${i}`}
                          padding="checkbox"
                          align={column.align}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                            padding: 0,
                          }}
                        >
                          <Stack direction="row" p={0}>
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id,
                                    row.customer_name,
                                    row
                                  )
                                }
                                value={selctedId}
                                name={`checkbox-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : i === 3 ? (
                        <TableCell
                          id={labelId}
                          key={`${row.id}-${i}`}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: column.width }}
                        >
                          <ChevronRightIcon
                            onClick={(event) =>
                              handleCustomer(event, row.id, row.customer_name)
                            }
                            sx={{ align: "center", cursor: "pointer" }}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={`${row.id}-${i}`}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListView;
