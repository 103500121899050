import React from "react";
import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Checkbox,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ProductImg from "../../../assets/images/product_line.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";
import * as ConstantsList from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../core/Loader/Loader";
import NoRecordFound from "../../core/NoRecordFound/NoRecordFound";
import * as Config from "../../../config";

import NoImage from "../../../assets/images/noimage.png";

import { checkboxClasses } from "@mui/material/Checkbox";

import {
  scenarioData,
  scenarioPoductline,
  scenarioSectionSaveStatus,
} from "../ScenarioSlice";

const ProductLine = (props) => {
  const text = Config?.CONFIGLIST?.scenario?.accordion;
  const scenarioDetails = useSelector(scenarioData);
  const dispatch = useDispatch();
  const { setEnable, setNotifyMsg, setNotify, setSectionNo } = props;
  const isView = scenarioData?.type === "view" ? true : false;

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
        contrastText: "#000",
      },
      neutral: {
        main: "#FFFFFF",
        contrastText: "#000",
      },
    },
  });

  const [selectedProducts, setSelectedProducts] = useState(
    scenarioDetails?.productline ?? []
  );

  useEffect(() => {
    if (scenarioDetails?.productline)
      setSelectedProducts(scenarioDetails?.productline);
  }, [scenarioDetails?.productline]);

  const selectProductHandle = (e, id) => {
    const selectedIndex = selectedProducts.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProducts, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProducts.slice(1));
    } else if (selectedIndex === selectedProducts.length - 1) {
      newSelected = newSelected.concat(selectedProducts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProducts.slice(0, selectedIndex),
        selectedProducts.slice(selectedIndex + 1)
      );
    }
    setSelectedProducts(newSelected);
  };

  const isCheck = (id) => selectedProducts.indexOf(id) !== -1;
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [productLines, setProductLines] = useState(null);
  const isSave = selectedProducts.length > 0 ? false : true;
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (scenarioDetails?.dictionary?.id) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          shipperdict_id: scenarioDetails?.dictionary?.id,
        }),
      };
      fetch(
        ConstantsList.API_URL + "prodline/productline_type/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setProductLines(data.data);
              setLoading(false);
              setReload(false);
            }
          }
        });
    }
  }, [reload, scenarioDetails?.dictionary?.id]);

  const [loaderOpen, setLoaderOpen] = useState(true);
  const handleClose = () => {
    setLoaderOpen(false);
  };

  const saveProductLines = () => {
    if (scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
        scenario_productline_id: selectedProducts,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(
        ConstantsList.API_URL + "scenario/scenario-productline/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setEnable(true);
              setNotify(true);
              setNotifyMsg(data.data.toString());
              setSectionNo(2);
            } else if (data.status_code === 400) {
              setNotify(true);
              setNotifyMsg(data.data.toString());
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const ProductData = [];
    if (selectedProducts.length > 0 && productLines) {
      for (let i = 0; i < productLines.length; i++) {
        if (i !== 0) {
          for (let j = 0; j < productLines[i]?.type_details.length; j++) {
            if (isCheck(productLines[i]?.type_details[j]?.id.toString())) {
              let data = {
                id: productLines[i]?.type_details[j]?.id,
                name: productLines[i]?.type_details[j]?.name,
                image: productLines[i]?.type_details[j]?.path,
                link: productLines[i]?.type_details[j]?.product_sheet_link,
                description: productLines[i]?.type_details[j]?.description,
              };
              ProductData.push(data);
            }
          }
        }
      }
    }
    setImageList(ProductData);
  }, [selectedProducts, productLines]);

  useEffect(() => {
    if (selectedProducts) {
      let productLine = selectedProducts;
      dispatch(scenarioPoductline(productLine));
    }
  }, [dispatch, selectedProducts]);

  return (
    <Box pl={3} pb={3} maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <Stack direction="row" width={1}>
        <Stack
          pt={5}
          spacing={5}
          direction="column"
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 3.5 / 5, xl: 3.5 / 5 }}
        >
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "24px",
                color: "#000000",
                fontWeight: "500",
                lineHeight: "30px",
              }}
            >
              Select Product Line(s)
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={4}
            pl={3}
            pb={7}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {productLines &&
              productLines?.map((line, i) => {
                return (
                  <React.Fragment key={i}>
                    {line?.type_details?.length > 0 && i !== 0 && (
                      <Stack
                        key={`Line-${i}`}
                        direction="column"
                        spacing={3}
                        sx={{ height: "auto" }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "22px",
                            marginBottom: "12px",
                          }}
                        >
                          {line?.type}
                        </Typography>
                        {line?.type_details?.map((list) => {
                          const IsChecked = isCheck(list?.id.toString());
                          return (
                            <Stack
                              key={list?.id}
                              direction="row"
                              sx={{
                                width: "17.5rem",
                                height: "7.5rem",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                border: "1px solid #E6E7E8",
                                backgroundColor: "#F2F2F3",
                                borderRadius: "8px",
                                padding: "8px 8px 8px 8px",
                              }}
                            >
                              <Box
                                p={0}
                                component="img"
                                src={
                                  list?.path === "" || list?.path === null
                                    ? NoImage
                                    : ConstantsList.API_URL + list?.path
                                }
                                alt="Product Line"
                                sx={{
                                  width: "5rem",
                                  height: "4.838rem",
                                }}
                                tabIndex={0}
                              />
                              <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {list?.name}
                                </Typography>
                              </Stack>

                              <Stack sx={{ height: "100%" }}>
                                <ThemeProvider theme={checkboxTheme}>
                                  <Checkbox
                                    color="primary"
                                    sx={{ padding: 0 }}
                                    onClick={(e) =>
                                      selectProductHandle(e, list.id.toString())
                                    }
                                    checked={IsChecked}
                                    value={list?.id.toString()}
                                    name="checkbox"
                                    id={`Checkbox-ID-${list.id}-${i}`}
                                    inputProps={{
                                      "aria-label": "Product Line",
                                    }}
                                  />
                                </ThemeProvider>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}
                  </React.Fragment>
                );
              })}
          </Stack>
        </Stack>

        <Stack
          spacing={3}
          direction="column"
          width={{ xs: 1 / 2, sm: 1 / 2, md: 1 / 2, lg: 1.5 / 5, xl: 1.5 / 5 }}
        >
          <Box
            width={1}
            component="div"
            sx={{
              overflow: "auto",
              maxHeight: "400px",
            }}
          >
            {imageList.length > 0 &&
              imageList?.map((data) => {
                return (
                  <Stack
                    key={`Image-${data?.id}`}
                    sx={{
                      width: "100%",
                      backgroundColor: "#F2F2F3",
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="center"
                      p={5}
                      spacing={4}
                    >
                      <Stack direction="column" spacing={3}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {data?.image ? (
                            <Box
                              p={0}
                              component="img"
                              src={ConstantsList.API_URL + data?.image}
                              alt="Product Line"
                              sx={{
                                width: "219px",
                                height: "auto",
                              }}
                              tabIndex={0}
                            />
                          ) : (
                            <Box
                              p={0}
                              component="img"
                              src={NoImage}
                              alt="Product Line"
                              sx={{
                                width: "219px",
                                height: "auto",
                              }}
                              tabIndex={0}
                            />
                          )}
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            onClick={() => window.open(data?.link)}
                            variant="outlined"
                            sx={{
                              textTransform: "none",
                              width: "15.25rem",
                              height: "2.75rem",
                              padding: "0px, 20px, 0px, 20px",
                              borderRadius: "6px",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#000000",
                              backgroundColor: "#FFFFFF",
                              lineHeight: "24px",
                              border: "1px solid #B1B2B4",
                            }}
                            data-testid="view-product-btn"
                            startIcon={<LaunchIcon />}
                          >
                            View Product Sheet
                          </Button>
                        </Stack>
                      </Stack>

                      <Stack direction="column" spacing={2.4}>
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "700",
                            fontSize: "26px",
                            lineHeight: "32px",
                            color: "#000000",
                          }}
                        >
                          {data?.name}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                          }}
                        >
                          {data?.description}{" "}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
          </Box>
        </Stack>
      </Stack>
      {productLines && productLines[0]?.count === 0 && (
        <NoRecordFound
          table={true}
          title="No product line added yet"
          description=""
        />
      )}
      {(scenarioDetails?.status === "Optimization Pending" ||
        scenarioDetails?.status === "Optimization Failed" ||
        scenarioDetails?.status === "Optimization Cancelled") && (
        <Stack
          direction="row"
          sx={{
            borderTop: "1px solid #ccc",
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="column" pt={3} pr={3}>
            <Button
              disabled={isSave}
              onClick={() => saveProductLines()}
              sx={{
                width: "145px",
                height: "44px",
                borderRadius: "6px",
                padding: "0px, 20px, 0px, 20px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
              }}
              color="primary"
              style={{
                textTransform: "none",
                border: "1px solid #B1B2B4",
              }}
              data-testid="next-btn"
            >
              Next
            </Button>
          </Stack>
        </Stack>
      )}
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
            data-testid="backdrop"
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Box>
  );
};

export default ProductLine;
