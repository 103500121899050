import React from "react";
import Box from "@mui/material/Box";
import logo from "../../assets/images/logo.svg";
import Avatar from "@mui/material/Avatar";
import ProfilePic from "../../assets/avatar/user-circle.svg";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { loginControl, loginData } from "../../components/Login/LoginSlice";
import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import * as ADAConfig from "../../../src/config/ADAConfig";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #F26322",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 158,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "0px 29px 11px rgba(0, 0, 0, 0.01), 0px 16px 10px rgba(0, 0, 0, 0.03), 0px 7px 7px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      height: "44px",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#F2F2F3",
      },
      "&:hover": {
        backgroundColor: "#F2F2F3",
      },
    },
  },
}));

const Header = (props) => {
  let history = useHistory();
  const userData = useSelector(loginData);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { oktaAuth } = useOktaAuth();

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const handleSignOut = () => {
    const UserData = {
      username: "",
      email: "",
      role: "",
      active_role: "",
      loggedIn: false,
    };
    setAnchorEl(null);
    dispatch(loginControl(UserData));
    logout();
  };

  const Shadow = userData?.role
    ? "0px 23px 14px rgba(0, 0, 0, 0.01), 0px 10px 10px rgba(0, 0, 0, 0.02), 0px 3px 6px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)"
    : "none";

  return (
    <Box
      maxWidth="false"
      sx={{
        width: "100%",
        height: {
          xs: "80px",
          sm: "80px",
          md: "80px",
          lg: "100px",
          xl: "112px",
        },
        backgroundColor: props.bgColor,
        display: "flex",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: Shadow,
      }}
    >
      <Link href="/" underline="none" color="inherit">
        <Box
          id="pelicanlogo"
          component="img"
          src={logo}
          alt="uii"
          sx={{ width: "217.67px", height: "3.06125rem", margin: "2rem 3rem" }}
          aria-label={ADAConfig.ADA.headerPage.logo_image}
        />
      </Link>
      {userData?.role && (
        <Box
          sx={{
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "right",
          }}
        >
          <Stack
            spacing={0}
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Typography
              component="h4"
              variant="h4"
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#323334",
                textAlign: "right",
              }}
            >
              {userData?.username}
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "20px",
                color: "#646568",
              }}
            >
              {userData?.role}
            </Typography>
          </Stack>
          <Avatar
            onClick={handleClickProfile}
            sx={{
              width: {
                xs: "48px",
                sm: "48px",
                md: "48px",
                lg: "64px",
                xl: "64px",
              },
              height: {
                xs: "48px",
                sm: "48px",
                md: "48px",
                lg: "64px",
                xl: "64px",
              },
              margin: "24px 48px 24px 24px",
              cursor: "pointer",
            }}
            alt="Profile Pic"
            src={ProfilePic}
          />

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Link
              href="/view-profile"
              sx={{ color: "#000", textDecoration: "none" }}
            >
              <MenuItem disableRipple>Profile</MenuItem>
            </Link>

            <MenuItem onClick={handleSignOut} disableRipple>
              Sign Out
            </MenuItem>
          </StyledMenu>
        </Box>
      )}
    </Box>
  );
};

export default Header;
