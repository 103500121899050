import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import Radio from "@mui/material/Radio";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Redirect, useHistory, Link } from "react-router-dom";
import {
  shipperDictionaryControl,
  shipperDictionaryData,
} from "./ShipperDictionarySlice";
import { useSelector, useDispatch } from "react-redux";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric && UnfoldMoreIcon}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                    display: "none",
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const columns = [
  {
    id: "select",
    label: "",
    // minWidth: 80,
    width: "6%",
    align: "center",
    numeric: false,
  },
  {
    id: "name",
    label: "Name",
    // minWidth: 200,
    width: "22%",
    align: "left",
    numeric: true,
  },
  {
    id: "created_date",
    label: "Creation Date",
    // minWidth: 250,
    width: "20%",
    align: "left",
    numeric: true,
  },
  {
    id: "modified_date",
    label: "Last Edited Date",
    // minWidth: 130,
    width: "40%",
    align: "left",
    numeric: true,
  },
  {
    id: "viewdetails",
    label: "View Details",
    width: "-webkit-fill-available",
    // minWidth: 120,
    align: "center",
    numeric: false,
  },
];

const ListView = (props) => {
  const shipperDictionary = useSelector(shipperDictionaryData);
  const dispatch = useDispatch();
  const {
    data,
    dataCount,
    dataShipperName,
    dataCreationDate,
    dataLastEditedDate,
    dataShipperDictSelected,
    search,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = (event, id, name, createdDate, modifiedDate) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      dataCount(true);

      dataShipperName("");
      dataCreationDate("");
      dataLastEditedDate("");
      dataShipperDictSelected({
        id: "",
        name: "",
        createdDate: "",
        modifiedDate: "",
      });
    } else {
      // setSelectedValue(event.target.value);
      setSelectedValue(id);
      dataCount(false);
      dataShipperName(name);
      dataCreationDate(createdDate);
      dataLastEditedDate(modifiedDate);
      dataShipperDictSelected({
        id: id,
        name: name,
        createdDate: dataCreationDate,
        modifiedDate: dataLastEditedDate,
      });
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };
  const [check, setCheck] = useState(false);

  const changeCheck = (event, name) => {
    setCheck(!check);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id, name, createdDate, modifiedDate) => {
    // let selctedId = name; //.replace(/\s+/g, "").toLowerCase();
    // if (selctedId === event.target.value) {
    if (selectedValue === id) {
      setSelectedValue(null);
      dataCount(true);
      dataShipperName("");
      dataCreationDate("");
      dataLastEditedDate("");
      dataShipperDictSelected({
        id: "",
        name: "",
        createdDate: "",
        modifiedDate: "",
      });
    } else {
      setSelectedValue(id);
      dataCount(false);
      dataShipperName(name);
      dataCreationDate(createdDate);
      dataLastEditedDate(modifiedDate);
      dataShipperDictSelected({
        id: id,
        name: name,
        createdDate: createdDate,
        modifiedDate: modifiedDate,
      });
    }
  };
  const isSelected = (name) => (selectedValue === name ? true : false);

  const filtered = search
    ? data.filter((user) => {
        return user.name === search.name;
      })
    : data;

  const viewDetails = (event, id, name) => {
    dispatch(
      shipperDictionaryControl({
        id: id,
        name: name,
      })
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",
            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
              padding: 0,
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props?.data?.length}
          />
          <TableBody>
            {filtered?.length === 0 && (
              <NoRecordFound
                title="No result found"
                description="Click create new to start creating shipper dictionary"
              />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                let selctedId = row.name.replace(/\s+/g, "").toLowerCase();
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(
                        event,
                        row.id,
                        row.name.toString(),
                        row.created_date,
                        row.modified_date
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-g9ktl7-MuiTableCell-root": { padding: 0 },
                    }}
                  >
                    {columns.map((column, i) => {
                      let value = row[column.id];

                      value =
                        i === 2 || i === 3
                          ? moment(value).format("MMM D, YYYY")
                          : value;
                      value =
                        i === 4 ? (
                          <Link underline="none" to="/dashboard/shipper">
                            <ChevronRightIcon
                              sx={{
                                align: "center",
                                cursor: "pointer",
                                color: "#000",
                              }}
                              onClick={(event) =>
                                viewDetails(event, row.id, row.name)
                              }
                            />
                          </Link>
                        ) : (
                          value
                        );
                      let cellWidth = i === 0 ? "10%" : i === 2 ? "25%" : "20%";
                      return i === 0 ? (
                        <TableCell
                          padding="checkbox"
                          align={column.align}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                        >
                          <Stack direction="row">
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Radio
                                //checked={selectedValue === selctedId}
                                checked={selectedValue === row.id}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id,
                                    row.name.toString(),
                                    row.created_date,
                                    row.modified_date
                                  )
                                }
                                sx={{ padding: "20px" }}
                                value={selctedId}
                                name={`radio-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={i}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{
                            width: column.width,
                            minWidth: column.minWidth,
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListView;
