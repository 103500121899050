import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";

import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";
import { useSelector } from "react-redux";
import * as ConstantsList from "../../../constants";
import LaunchIcon from "@mui/icons-material/Launch";
import NoImage from "../../../assets/images/noimage.png";

const ProductLine = () => {
  const shipperDetails = useSelector(shipperDetailsData);
  const productLine = shipperDetails?.productline;

  return (
    <Box p={4} maxWidth="false" sx={{ width: "100%", height: "auto" }} data-testid="box-outer-container">
      <Stack direction="row" width={1} spacing={7}>
        <Stack
          direction="column"
          width={{ xs: 1 / 4, sm: 1 / 4, md: 1 / 4, lg: 1 / 4, xl: 1 / 4 }}
        >
          <Stack
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "#F2F2F3",
            }}
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Box
                p={0}
                component="img"
                src={
                  productLine?.path === "" || productLine?.path === null
                    ? NoImage
                    : ConstantsList.API_URL + productLine?.path
                }
                alt="Product Line"
                sx={{
                  width: "219px",
                  height: "auto",
                }}
                tabIndex={0}
              />
            </Stack>
            <Stack direction="column" alignItems="center" spacing={0.6}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                  letterSpacing: "5%",
                }}
              >
                Product Type
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "22px",
                }}
              >
                {productLine?.type}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          spacing={3}
          direction="column"
          width={{ xs: 3 / 4, sm: 3 / 4, md: 3 / 4, lg: 3 / 4, xl: 3 / 4 }}
        >
          <Stack direction="column" spacing={2}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: "26px",
                lineHeight: "32px",
              }}
            >
              {productLine?.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {productLine?.description}
            </Typography>
            <Button
              disabled={
                productLine?.product_sheet_link
                  ? productLine?.product_sheet_link?.toString()?.length > 0
                    ? false
                    : true
                  : true
              }
              onClick={() => window.open(productLine?.product_sheet_link)}
              variant="outlined"
              sx={{
                textTransform: "none",
                width: "15.25rem",
                height: "2.75rem",
                padding: "0px, 20px, 0px, 20px",
                borderRadius: "6px",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "400",
                color: "#000000",
                backgroundColor: "#FFFFFF",
                lineHeight: "24px",
                border: "1px solid #B1B2B4",
              }}
              startIcon={<LaunchIcon />}
              data-testid="view-product-btn"
            >
              View Product Sheet
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProductLine;
