import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { FormControl, List, ListItem, MenuItem, Select } from "@mui/material";
import UploadPic from "../../../assets/images/upload_cloud.svg";
import * as ConstantsList from "../../../constants";
import FormHelperText from "@mui/material/FormHelperText";
import { shipperDictionaryData } from "../../../components/ShipperDictionary/ShipperDictionarySlice";
import { useSelector, useDispatch } from "react-redux";
import { getImageSize } from "react-image-size";
import validator from "validator";
import NoImage from "../../../assets/images/noimage.png";

import {
  resetData,
  shipperData,
  productLineNameUpdate
} from "./EditShipperSlice";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const CreateProductLine = (props) => {
  const {
    open,
    setOpen,
    shipperID,
    productLineData,
    setReload,
    setNotify,
    setNotifyMsg,
  } = props;
  const shipperDictionary = useSelector(shipperDictionaryData);
  const [file, setFile] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  const [productType, setProductType] = useState(productLineData?.type);
  const [productLink, setProductLink] = useState(
    productLineData?.product_sheet_link
  );
  const [productLineName, setProductLineName] = useState(productLineData?.name);
  const [productLineDescription, setProductLineDescription] = useState(
    productLineData?.description
  );

  const [proLineNameErr, setProLineNameErr] = useState(false);
  const [proLineDescriptionErr, setProLineDescriptionErr] = useState(false);
  const [productTypeErr, setProductTypeErr] = useState(false);
  const [productLinkErr, setProductLinkErr] = useState(false);

  const [productLineNameErrMsg, setproductLineNameErrMsg] = useState("");
  const [productDescriptionErrMsg, setproductDescriptionErrMsg] = useState("");
  const [productTypeErrMsg, setProductTypeErrMsg] = useState("");
  const [productLinkErrMsg, setProductLinkErrMsg] = useState("");
  const [productImageErrMsg, setProductImageErrMsg] = useState("");

  const inputRef = React.useRef(null);

  const handleProductLineName = (e) => {
    let value = e.target.value;
    let re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setProductLineName(value);
    }
    if (e.target.value.length > 0) {
      setProLineNameErr(false);
      setproductLineNameErrMsg("");
    }
  };

  const handleProductLineDescrip = (e) => {
    let value = e.target.value;
    let re = /^[A-Za-z0-9 ]+$/;
    // if (value === "" || re.test(value)) {
    //   setProductLineDescription(value);
    // }
    setProductLineDescription(value);
    if (e.target.value.trim() > 0) {
      setProLineDescriptionErr(false);
      setproductDescriptionErrMsg("");
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = parseFloat((bytes / k).toFixed(dm));
    return i;
  }

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  const handleChange = (e) => {
    //setFile(URL.createObjectURL(e.target.files[0]))
    const fileImage = URL.createObjectURL(e.target.files[0]);
    const fileType = getExtension(e.target.files[0].name).toLowerCase();
    const fileSize = formatBytes(e.target.files[0].size);

    if ((fileType === "png" || "jpeg" || "jpg") && fileType.length > 0) {
      if (fileSize < 300) {
        getImageSize(fileImage)
          .then(({ width, height }) => {
            if (width.toString() === "512" && height.toString() === "512") {
              setFile(e.target.files[0]);
              setProductImageErrMsg("");
            } else {
              setProductImageErrMsg("Please select 512x512 !");
            }
          })
          .catch((errorMessage) => console.log(errorMessage));
      } else {
        setProductImageErrMsg("Please select below 300kb !");
      }
    } else {
      setProductImageErrMsg("Please Select png or jpeg format image!");
    }
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const selectProductType = (e) => {
    setProductType(e.target.value.toString());
    setProductTypeErr(false);
    setProductTypeErrMsg("");
  };

  const productLinkHandle = (e) => {
    setProductLink(e.target.value);
    if (isValidUrl(e.target.value.toString())) {
      setProductLinkErr(false);
      setProductLinkErrMsg("");
    } else {
      setProductLinkErr(true);
      setProductLinkErrMsg("Please Enter Valid Link!");
    }
  };

  const dispatch = useDispatch();

  const handleSave = () => {
    if (validator.trim(productLineName).length !== 0) {
      if (productType !== "Select" && productType.length > 0) {
        let productLineId = productLineData.id;
        const data = new FormData();
        if (file) {
          data.append("image", file);
        }
        data.append("shipperdict_id", shipperDictionary.id);
        data.append("name", validator.trim(productLineName));
        data.append("type", productType ? productType : "");
        data.append(
          "description",
          productLineDescription ? productLineDescription : ""
        );
        data.append("product_sheet_link", productLink ? productLink : "");

        fetch(ConstantsList.API_URL + "prodline/productline/" + productLineId, {
          method: "PUT",
          body: data,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status_code.toString() === "200") {
              setNotify(true);
              setNotifyMsg(data.data);
              setOpen(false);
              setReload(true);
              setProLineNameErr(false);
              setproductLineNameErrMsg("");
              setProductTypeErr(false);
              setProductTypeErrMsg("");
              dispatch(productLineNameUpdate(productLineName.trim()))
            } else {
              setProLineNameErr(true);
              setproductLineNameErrMsg(data.data);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setProductTypeErr(true);
        setProductTypeErrMsg("Please Select Product Type!");
      }
    } else {
      setProLineNameErr(true);
      setproductLineNameErrMsg("Please Enter Product Line Name!");
    }
  };

  const isValidUrl = (urlString) => {
    
    // var urlPattern = new RegExp(
    //   "^(https?:\\/\\/)?" +
    //     "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    //     "((\\d{1,3}\\.){3}\\d{1,3}))" +
    //     "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    //     "(\\?[;&a-z\\d%_.~+=-]*)?" +
    //     "(\\#[-a-z\\d_]*)?$",
    //   "i"
    // );
    // return !!urlPattern.test(urlString);

    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi; 
    var urlPattern = new RegExp(expression);
    return !!urlPattern.test(urlString);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <Dialog
        maxWidth="false"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ThemeProvider theme={checkboxTheme}>
          <Box maxWidth="false" sx={{ height: "auto" }} px={3} py={5}>
            <Stack direction="column" width={1} spacing={5}>
              <Stack>
                <Typography variant="h6">Edit Product Line</Typography>
              </Stack>
              <Stack direction="row" spacing={5}>
                <Stack width={1 / 3} direction="column" spacing={3}>
                  {!file && (
                    <Box
                      onClick={onButtonClick}
                      maxWidth="false"
                      sx={{
                        border: "1px solid none",
                        width: "250px",
                        height: "250px",
                        backgroundColor: "#F8F8F9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        gap: "20px",
                      }}
                    >
                      {/* <Box
                        component="img"
                        src={UploadPic}
                        alt="Upload Pic"
                        sx={{
                          width: "64px",
                          height: "64px",
                        }}
                      /> */}
                      <Box
                        p={0}
                        component="img"
                        src={
                          productLineData?.path === "" ||
                          productLineData?.path === null ||
                          productLineData?.path === undefined
                            ? NoImage
                            : ConstantsList.API_URL + productLineData?.path
                        }
                        alt="Edit Product Line"
                        sx={{
                          width: "219px",
                          height: "auto",
                          cursor: "pointer",
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          px: "30px",
                          fontFamily: "Roboto",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "20px",
                          letterSpacing: "5%",
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        {/*  Drop the product image here or click to upload */}
                        <input
                          hidden
                          ref={inputRef}
                          accept="image/png, image/jpeg"
                          multiple
                          type="file"
                          onChange={(e) => handleChange(e)}
                        />
                      </Typography>
                    </Box>
                  )}

                  {file && (
                    <Box
                      p={0}
                      component="img"
                      src={URL.createObjectURL(file)}
                      alt="Product Line"
                      sx={{
                        width: "250px",
                        height: "250px",
                      }}
                    />
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      sx={{
                        color: "#E34949",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: "400",
                        letterSpacing: "5%",
                      }}
                    >
                      {productImageErrMsg}
                    </Typography>
                  </Stack>

                  <Stack direction="column" px={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: "400",
                        letterSpacing: "5%",
                      }}
                    >
                      Image must to comply with the following characteristics:
                    </Typography>
                    <List sx={{ px: "16px" }}>
                      <ListItem
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "24px",
                          letterSpacing: "5%",
                          padding: 0,
                          listStyleType: "disc",
                          display: "list-item",
                        }}
                      >
                        In JPEG or PNG format
                      </ListItem>
                      <ListItem
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "24px",
                          letterSpacing: "5%",
                          padding: 0,
                          listStyleType: "disc",
                          display: "list-item",
                        }}
                      >
                        512px x 512px
                      </ListItem>
                      <ListItem
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "24px",
                          letterSpacing: "5%",
                          padding: 0,
                          listStyleType: "disc",
                          display: "list-item",
                        }}
                      >
                        Maximum weight 300kb
                      </ListItem>
                    </List>
                  </Stack>
                </Stack>
                <Stack width={2 / 3} direction="column">
                  <Stack spacing={4} p={0} direction="column">
                    <Stack spacing={4} p={0} direction="column">
                      <Typography component="h6" variant="h6" sx={testStyle1}>
                        [ * ] Required fields
                      </Typography>
                      <Stack spacing={2} p={0} direction="row">
                        <Stack
                          spacing={1}
                          p={0}
                          direction="column"
                          width={1 / 2}
                        >
                          <Typography
                            component="h6"
                            variant="h6"
                            sx={labelStyle}
                          >
                            Product Line Name *
                          </Typography>

                          <TextField
                            fullWidth
                            onChange={handleProductLineName}
                            value={productLineName}
                            helperText={productLineNameErrMsg}
                            error={proLineNameErr}
                            placeholder="Enter Product Line Name"
                            id="uname"
                            size="small"
                            variant="outlined"
                            sx={{
                              "& .MuiOutlinedInput-input": {
                                padding: "8px, 12px, 8px, 12px",
                              },
                              "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                                { borderRadius: "6px", height: "44px" },
                              "& .css-1wc848c-MuiFormHelperText-root.Mui-error":
                                {
                                  marginLeft: "0px",
                                },
                              "& .css-k4qjio-MuiFormHelperText-root": {
                                marginLeft: "0px",
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          spacing={1}
                          p={0}
                          direction="column"
                          width={1 / 2}
                        >
                          <Typography
                            component="h6"
                            variant="h6"
                            sx={labelStyle}
                          >
                            Product Type *
                          </Typography>
                          <FormControl size="small">
                            <Select
                              helperText={productTypeErrMsg}
                              error={productTypeErr}
                              onChange={selectProductType}
                              labelId="demo-select-small"
                              id="demo-select-small"
                              defaultValue="Select"
                              value={productType}
                            >
                              <MenuItem value="Select">
                                <em style={{ fontStyle: "normal" }}>Select</em>
                              </MenuItem>
                              <MenuItem value={"Reusable Parcel"}>
                                Reusable Parcel
                              </MenuItem>
                              <MenuItem value={"Single-Use Parcel"}>
                                Single-Use Parcel
                              </MenuItem>
                              <MenuItem value={"Reusable Bulk"}>
                                Reusable Bulk
                              </MenuItem>
                              <MenuItem value={"Single-Use Bulk"}>
                                Single-Use Bulk
                              </MenuItem>
                            </Select>
                            <FormHelperText
                              sx={{
                                "& .css-k4qjio-MuiFormHelperText-root": {
                                  marginLeft: "0 !important",
                                },
                                color: "red",
                                marginLeft: "0px !important",
                              }}
                            >
                              {productTypeErrMsg}
                            </FormHelperText>
                          </FormControl>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack spacing={1} p={0} direction="column">
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Description
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        onChange={handleProductLineDescrip}
                        value={productLineDescription}
                        helperText={productDescriptionErrMsg}
                        error={proLineDescriptionErr}
                        rows={5}
                        size="small"
                        placeholder="Enter Product Line Description"
                        id="emailId"
                        variant="outlined"
                        sx={{
                          "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                            { borderRadius: "6px", height: "44px" },
                          "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                            marginLeft: "0px !important",
                          },
                        }}
                        inputProps={{
                          maxLength: 1024,
                        }}
                      />
                    </Stack>

                    <Stack spacing={1} p={0} direction="column">
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Product Sheet Link
                      </Typography>
                      <TextField
                        required
                        type="url"
                        onChange={productLinkHandle}
                        onPaste={productLinkHandle}
                        helperText={productLinkErrMsg}
                        error={productLinkErr}
                        fullWidth
                        value={productLink}
                        size="small"
                        variant="outlined"
                        placeholder="Paste the link of the Product Sheet"
                        id="Product Sheet"
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            padding: "8px, 12px, 8px, 12px",
                          },
                          "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                            { borderRadius: "6px", height: "44px" },
                          "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                            marginLeft: "0px !important",
                          },
                        }}
                        inputProps={{
                          maxLength: 1024,
                        }}
                      />
                    </Stack>

                    <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
                      <Button
                        onClick={handleClose}
                        sx={{
                          textTransform: "none",
                          padding: "0px, 20px, 0px, 20px",
                          width: "50%",
                          border: "1px solid #B1B2B4",
                          borderRadius: "6px",
                          height: "44px",
                          backgroundColor: "#FFFFFF",
                          color: "#3F4042",
                          "&:hover": { backgroundColor: "#B1B2B4" },
                        }}
                        data-testid="cancel-btn"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSave}
                        sx={{
                          padding: "0px, 20px, 0px, 20px",
                          width: "50%",
                          border: "1px solid #B1B2B4",
                          borderRadius: "6px",
                          height: "44px",
                          backgroundColor: "#F26322",
                          color: "#3F4042",
                          textTransform: "none",
                          "&:hover": { backgroundColor: "#F26322" },
                        }}
                        data-testid="save-btn"
                      >
                        Save
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </ThemeProvider>
      </Dialog>
    </div>
  );
};

export default CreateProductLine;
