import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NoRecordImg from "../../../assets/images/norecordfound.png";

const NoRecordFound = (props) => {
  const { title, description, count, table } = props;
  const isTable = table ? table : false; // && table;
  return (
    <>
      {!isTable && (
        <TableRow role="grid" tabIndex={0}>
          <TableCell colSpan={count ? count : 8} align="center">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "450px",
              }}
            >
              <Box
                component="img"
                src={NoRecordImg}
                alt="No Record Found"
                sx={{ width: "97.96px;", height: "96px" }}
              />
              <Typography
                component="h4"
                variant="h4"
                sx={{
                  paddingTop: "16px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                  fontSize: "26px",
                  lineHeight: "32px",
                  color: "#323334",
                }}
              >
                {title}
              </Typography>
              <Typography
                component="h4"
                variant="h4"
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#3F4042",
                }}
              >
                {description}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )}
      {isTable && (
        <Box
          sx={{
            width: "100%",
            height: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={NoRecordImg}
            alt="No Record Found"
            sx={{ width: "97.96px;", height: "96px" }}
          />
          <Typography
            component="h4"
            variant="h4"
            sx={{
              paddingTop: "16px",
              paddingBottom: "8px",
              fontWeight: "700",
              fontSize: "26px",
              lineHeight: "32px",
              color: "#323334",
            }}
          >
            {title}
          </Typography>
          <Typography
            component="h4"
            variant="h4"
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#3F4042",
            }}
          >
            {description}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NoRecordFound;
