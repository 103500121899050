import { AppBar, Checkbox, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ShippedWeight from "./ShippedWeight";
import Shipments from "./Shipments";
import MaxLoadEffiency from "./MaxLoadEffiency";
import MaxLoad from "./MaxLoad";
import Summary from "../Summary";
import { useEffect } from "react";
import * as ConstantsList from "../../../constants";

import { scenarioData } from "../../Scenario/ScenarioSlice";
import { useSelector } from "react-redux";

const ShipperSolution = (props) => {
  const { setData, solutionInitRisk, solutionInitSets } = props;
  const scenarioDetails = useSelector(scenarioData);

  const checkBoxFont = {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#3F4042",
  };

  const shipperSetFont = {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "22px",
    color: "#3F4042",
  };

  const [solutionSets, setSolutionSets] = useState([]);

  const [setSize, setSetSize] = useState([]);
  const [risks, setRisks] = useState([]);

  const [selectedSetSize, setSelectedSetSize] = useState(solutionInitSets);
  const [selected, setSelected] = useState(solutionInitRisk);

  useEffect(() => {
    const raw = JSON.stringify({
      scenario_id: scenarioDetails?.scenario?.id,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "summary/get_risk_size/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            let Temp = data?.data?.risk;
            const TempRisks = ["No Risk", "Low Risk", "Med Risk", "High Risk"];
            let TempRisk = [];
            TempRisks.forEach((RiskName) =>
              Temp.forEach(
                (risks) => risks === RiskName && TempRisk.push(risks)
              )
            );
            setRisks(TempRisk);

            setSetSize(data?.data?.set_size.map((sets) => sets.toString()));
          }
        }
      })
      .catch((err) => console.log("Err", err));
  }, [scenarioDetails?.scenario?.id]);

  useEffect(() => {
    if (solutionInitRisk) {
      setSelected(solutionInitRisk);
    }
  }, [solutionInitRisk]);

  useEffect(() => {
    if (solutionInitSets) {
      setSelectedSetSize(solutionInitSets);
    }
  }, [solutionInitSets]);

  const isAllSelected = risks.length > 0 && selected.length === risks.length;

  const isAllSelectedSetSize =
    setSize.length > 0 && selectedSetSize.length === setSize.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setSelected(selected.length === risks.length ? [] : risks);
      return;
    }
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
  };

  const handleChangeSetSize = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setSelectedSetSize(
        selectedSetSize.length === setSize.length ? [] : setSize
      );
      return;
    }
    const listSet = [...selectedSetSize];
    const index = listSet.indexOf(value);
    index === -1 ? listSet.push(value) : listSet.splice(index, 1);
    listSet.sort();
    setSelectedSetSize(listSet);
  };

  const listItem = risks.map((option) => {
    return (
      <Stack key={option} direction="row" spacing={1} alignItems="center">
        <Checkbox
          value={option}
          onChange={handleChange}
          checked={selected.includes(option)}
          sx={{ padding: 0 }}
        />
        <Typography sx={checkBoxFont}>{option}</Typography>
      </Stack>
    );
  });

  const listSetSizeItem = setSize.map((option) => {
    return (
      <Stack key={option} direction="row" spacing={1} alignItems="center">
        <Checkbox
          value={option}
          onChange={handleChangeSetSize}
          checked={selectedSetSize.includes(option)}
          sx={{ padding: 0 }}
        />
        <Typography sx={checkBoxFont}>{option}</Typography>
      </Stack>
    );
  });

  const numberText = {
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
    6: "Six",
    7: "Seven",
    8: "Eight",
    9: "Nine",
    10: "Ten",
    11: "Eleven",
    12: "Twelve",
    13: "Thirteen",
    14: "Fourteen",
    15: "Fifteen",
    16: "Sixteen",
    17: "Seventeen",
    18: "Eighteen",
    19: "Nineteen",
    20: "Twenty",
    30: "Thirty",
    40: "Forty",
    50: "Fifty",
    60: "Sixty",
    70: "Seventy",
    80: "Eighty",
    90: "Ninety",
    100: "Hundred",
    1000: "Thousand",
  };
  const numberValues = Object.keys(numberText)
    .map((val) => Number(val))
    .sort((a, b) => b - a);

  const convertNumberToText = (n) => {
    if (n === 0) return "zero";
    if (n < 0) return "negative " + convertNumberToText(-n);
    let num = n;
    let text = "";
    for (const numberValue of numberValues) {
      const count = Math.trunc(num / numberValue);
      if (count < 1) continue;
      if (numberValue >= 100) text += convertNumberToText(count) + " ";
      text += numberText[numberValue] + " ";
      num -= count * numberValue;
    }
    if (num !== 0) throw Error("Something went wrong!");
    return text.trim();
  };

  useEffect(() => {
    if (selected.length > 0 && selectedSetSize.length > 0) {
      let dataset = [];
      const TempRisks = ["No Risk", "Low Risk", "Med Risk", "High Risk"];
      let TempRisk = [];
      TempRisks.forEach((RiskName) =>
        selected.forEach((risks) => risks === RiskName && TempRisk.push(risks))
      );

      selectedSetSize.sort().map((setsize) => {
        return TempRisk.map((risk) => {
          return dataset.push({
            risk: risk,
            setsize: parseInt(setsize),
            scenario: scenarioDetails?.scenario?.id,
          });
        });
      });
      setSolutionSets(dataset);
    } else {
      setSolutionSets([]);
    }
  }, [selected, selectedSetSize, scenarioDetails?.scenario?.id]);

  useEffect(() => {
    let Data = {
      solution_set: selectedSetSize,
      risk_selected: {
        no_risk: selected.find((data) => data === "No Risk") ? 1 : 0,
        low_risk: selected.find((data) => data === "Low Risk") ? 1 : 0,
        med_risk: selected.find((data) => data === "Med Risk") ? 1 : 0,
        high_risk: selected.find((data) => data === "High Risk") ? 1 : 0,
      },
    };
    setData(Data);
  }, [selectedSetSize, selected]);

  return (
    <Stack direction="column" width={1} pt={3} pb={3}>
      <Stack direction="column" spacing={4}>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: "#F2F2F3",
            width: "100%",
            "& .MuiAppBar-positionSticky": {
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            id="solutionSetHeaderChart"
            className="solChart"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  value="all"
                  onChange={handleChange}
                  checked={isAllSelected}
                  sx={{ padding: 0 }}
                />
                <Typography sx={checkBoxFont}>All</Typography>
              </Stack>
              {listItem}
            </Stack>

            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center">
                <Typography sx={shipperSetFont}>Shipper Set Size:</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  value="all"
                  onChange={handleChangeSetSize}
                  checked={isAllSelectedSetSize}
                  sx={{ padding: 0 }}
                />
                <Typography sx={checkBoxFont}>All</Typography>
              </Stack>
              {listSetSizeItem}
            </Stack>
          </Stack>
        </AppBar>

        {solutionSets.length > 0 &&
          solutionSets.map((solution, i) => {
            return (
              <Stack
                key={`Solution-Set-${i}-${solution?.risk}-${solution?.setsize}-${solution?.scenario}`}
                direction="column"
                width={1}
                pb={4}
                id="testPDF"
              >
                <Stack
                  id="solutionSetNameChart"
                  className="solChart"
                  direction="row"
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "26px",
                      color: "#235984",
                      fontWeight: "700",
                      lineHeight: "32px",
                    }}
                  >
                    {`${convertNumberToText(
                      parseInt(solution?.setsize)
                    )} Shipper Solution Set - ${solution?.risk}`}
                  </Typography>
                </Stack>
                <ShippedWeight
                  data={solution}
                  selected={selected}
                  sets={selectedSetSize}
                />
                <Divider />
                <Shipments
                  data={solution}
                  selected={selected}
                  sets={selectedSetSize}
                />
                <Divider />
                <MaxLoadEffiency
                  data={solution}
                  selected={selected}
                  sets={selectedSetSize}
                />
                <Divider />
                <MaxLoad data={solution} />
                <Divider />
                <Summary
                  title={`Summary: ${convertNumberToText(
                    parseInt(solution?.setsize)
                  )} Shipper Solution Set - ${solution?.risk}`}
                  data={solution}
                />
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default ShipperSolution;
