import React from "react";
import { Footer, Header } from "../../layouts";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ViewProfile from "../../layouts/ViewProfile";

const Profile = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header bgColor="#FFFFFF" />
        <ViewProfile />
        <Footer bgColor="#1D3869" />
      </ThemeProvider>
    </>
  );
};

export default Profile;
