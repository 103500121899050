import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Footer, Header, Scenario as ScenarioLayout } from "../../layouts";

const Scenario = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  return (
    <ThemeProvider theme={theme}>
      <Header bgColor="#FFFFFF" />
      <ScenarioLayout />
      <Footer bgColor="#1D3869" />
    </ThemeProvider>
  );
};

export default Scenario;
