import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import footerLogo from "../../assets/images/footerLogo.png";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import * as ConstantsList from "../../../src/constants";
import { loginData } from "../../components/Login/LoginSlice";
import { useSelector, useDispatch } from "react-redux";
import * as ADAConfig from "../../../src/config/ADAConfig";
import { tab } from "../../components/Navigator/navigateSlice";

const Footer = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(loginData);
  const text = {
    copyRightsText: ConstantsList.COPYRIGHT,
    bgColor: props.bgColor ? props.bgColor : "#1D3869",
  };

  let isLoggedIn = userData?.loggedIn ? true : false;
  const handleLink = (linkName) => {
    if (linkName === "Home/Shipper Dictionaries") {
      dispatch(tab("0"));
    }

    if (linkName === "Users") {
      dispatch(tab("1"));
    }

    if (linkName === "Customers") {
      dispatch(tab("2"));
    }

    if (linkName === "FAQ") {
    }
  };
  return (
    <Box
      p={6}
      maxWidth="false"
      sx={{
        width: "100%",
        height: {
          xs: "80px",
          sm: "80px",
          md: "80px",
          lg: "100px",
          xl: "112px",
        },
        backgroundColor: text.bgColor,
        display: "flex",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
        bottom: 0,
      }}
    >
      {isLoggedIn && (
        <Stack sx={{ width: "35%", display: "block" }}>
          {userData.active_role === "Admin" && (
            <div>
              <Link
                to="/"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                  marginRight: "30px",
                }}
                onClick={(e) => handleLink("Home/Shipper Dictionaries")}
                aria-label={ADAConfig.ADA.footer.home_dictionaries}
              >
                Home/Shipper Dictionaries
              </Link>
              <Link
                to="/"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                  marginRight: "30px",
                }}
                onClick={(e) => handleLink("Users")}
                aria-label={ADAConfig.ADA.footer.users}
              >
                Users
              </Link>
              <Link
                to="/"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                  marginRight: "30px",
                }}
                onClick={(e) => handleLink("Customers")}
                aria-label={ADAConfig.ADA.footer.customers}
              >
                Customers
              </Link>
              <Link
                to="/faq"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                }}
                aria-label={ADAConfig.ADA.footer.faq}
              >
                FAQ
              </Link>
            </div>
          )}
          {(userData.active_role === "Sales Engineer" ||
            userData.role === "Account Manager") && (
            <div>
              <Link
                to="/"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                  marginRight: "30px",
                }}
                aria-label={ADAConfig.ADA.footer.home_dictionaries}
              >
                Home
              </Link>

              <Link
                to="/faq"
                align="left"
                style={{
                  color: "#FFFFFF",
                  fontSize: "14px",
                  fontWeight: 600,
                  m: 1,
                  textDecoration: "none",
                }}
                aria-label={ADAConfig.ADA.footer.faq}
              >
                FAQ
              </Link>
            </div>
          )}
        </Stack>
      )}
      <Stack sx={{ width: "30%", display: "block" }}>
        <Typography
          noWrap={true}
          variant="h6"
          component="h6"
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "5%",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          {text.copyRightsText}
        </Typography>
      </Stack>
      <Stack sx={{ width: "35%", display: "block" }}>
        <Box
          component="img"
          src={footerLogo}
          alt="Footer Logo"
          align="right"
          sx={{ width: "48px", height: "49.76px" }}
        />
      </Stack>
    </Box>
  );
};

export default Footer;
