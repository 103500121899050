import React, { useEffect, useState } from "react";
import GraphProfile from "../GraphProfile";
import {
  Box,
  Checkbox,
  Grid,
  OutlinedInput,
  InputAdornment,
  Stack,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Avatar,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import LineChart from "../../../assets/images/line-chart.svg";
import * as ConstantsList from "../../../../src/constants";
import EditIcon from "@mui/icons-material/Edit";
import { scenarioData } from "../ScenarioSlice";
import { useSelector } from "react-redux";

const mainTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
  marginBottom: "35px",
};

const errorStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "red",
};

const AmbientProfile = (props) => {
  const { onClose, setReload, setNotify, setNotifyMsg } = props;
  const scenarioDetails = useSelector(scenarioData);
  const [eshdMeltCheck, setESHDMeltCheck] = useState(true);
  const [formMeltValues, setFormMeltValues] = useState([
    { temperature: "", hours: "" },
  ]);
  const [existingName, setExistingName] = useState("");

  let removeFormFields = (eshdType, i) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      let newFormMeltValues = [...formMeltValues];
      newFormMeltValues.splice(i, 1);
      setFormMeltValues(newFormMeltValues);
    }
  };
  let addFormFields = (eshdType) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      setFormMeltValues([...formMeltValues, { temperature: "", hours: "" }]);
    }
  };

  const [errorStatus, setErrorStatus] = useState({
    pcmName: false,
    tcd: false,
    eshdMelt: false,
    eshdFreeze: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    pcmName: "",
    tcd: "",
    eshdMelt: "",
    eshdFreeze: "",
  });

  const [showGraph, setShowGraph] = useState(false);
  const [showAmbient, setShowAmbient] = useState(true);

  const handleGraphShow = () => {
    setShowGraph(true);
    setShowAmbient(false);
  };

  // API - fetch value
  const [loading, setLoading] = useState(true);
  const [tempProfileName, settempProfileName] = useState("");
  const [tempProfileStyle, settempProfileStyle] = useState("Step");
  const [tempProfileCheck, settempProfileCheck] = useState(false);
  const [savebutton, setSavebutton] = useState(false);
  const [pcmPhase, setPCMPhase] = useState("");
  const [tempProfileNameExist, setTempProfileNameExist] = useState(false);
  const [tempProfileNameExistError, setTempProfileNameExistError] =
    useState("");

  const handleChange = (eshdType, i, e) => {
    if (eshdType === "Melt") {
      let value = e.target.value;
      let re = /^[0-9.-]+$/;
      if (value === "" || re.test(value)) {
        let newFormMeltValues = [...formMeltValues];
        if (e.target.name === "hours") {
          newFormMeltValues[i][e.target.name] = parseInt(e.target.value);
          setFormMeltValues(newFormMeltValues);
        } else {
          newFormMeltValues[i][e.target.name] = e.target.value;
          setFormMeltValues(newFormMeltValues);
        }
      }
    }

    if (eshdType === "CheckboxMelt") {
      let changeCheckStatus = !eshdMeltCheck;
      setESHDMeltCheck(changeCheckStatus);
      setPCMPhase("");
      if (!changeCheckStatus) {
        setFormMeltValues([{ temperature: "", hours: parseInt("") }]);
      }
    }

    if (eshdType === "ProfileStyle") {
      if (e.target.value !== "") {
        settempProfileStyle(e.target.value);
        setSavebutton(false);
      } else {
        setSavebutton(true);
      }
    }

    if (eshdType === "ProfileName") {
      setTempProfileNameExist(false);
      const value = e.target.value;

      const re = /^[A-Za-z ]+$/;
      if (value === "" || re.test(value)) {
        settempProfileName(value);
      } else {
        setSavebutton(true);
      }
      settempProfileCheck(e.target.checked);

      setTempProfileNameExist(false);
      setTempProfileNameExistError("");
    }

    let data = formMeltValues;

    data.map((value, i) => {
      return <></>;
    });
  };

  const [profileValue, setProfileValue] = useState("new-profile");

  const [isExist, setIsExist] = useState(false);

  const handleProfile = (e) => {
    setProfileValue(e.target.value);
    setCustomerSpecific(false);
    if (e.target.value === "exist-profile") {
      setIsExist(true);
      setFormMeltValues([{ temperature: "", hours: "" }]);
    } else {
      setIsExist(false);
      setIsEdit(false);
      setFormMeltValues([{ temperature: "", hours: "" }]);
      setSelecteExistProfile(null);
    }
  };

  const hoursHandle = (e, index) => {
    let newValue = [...formMeltValues];
    newValue[index].hours =
      e.target.value?.toString()?.trim().length > 0
        ? parseInt(e.target.value)
        : "";
    setFormMeltValues(newValue);
    let errorStateTemp = { ...errorStatus, eshdMelt: false };
    let errorMessageTemp = {
      ...errorMessage,
      eshdMelt: "",
    };

    setErrorStatus(errorStateTemp);
    setErrorMessage(errorMessageTemp);
  };

  const temperatureHandle = (e, index) => {
    let newValue = [...formMeltValues];
    newValue[index].temperature =
      e.target.value?.toString()?.trim().length > 0
        ? parseFloat(e.target.value)
        : "";
    setFormMeltValues(newValue);
    let errorStateTemp = { ...errorStatus, eshdMelt: false };
    let errorMessageTemp = {
      ...errorMessage,
      eshdMelt: "",
    };

    setErrorStatus(errorStateTemp);
    setErrorMessage(errorMessageTemp);
  };

  const [listProfile, setListProfile] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_id: scenarioDetails?.customer?.id,
      }),
    };
    fetch(ConstantsList.API_URL + "cust/get_definetemp/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            const removeConst = [];
            data?.data?.profile?.map((menu) => {
              // Removed Constant in Dropdown list
              if (menu.profile_name !== "Constant") {
                removeConst.push(menu);
              }
            });
            setListProfile(removeConst);
            setReload(false);
          }
        }
      });
  }, [scenarioDetails?.customer?.id, setReload]);

  const [listValue, setListValue] = useState([]);

  useEffect(() => {
    if (listProfile) {
      const Data = [];
      setListValue(Data);
    }
  }, [listProfile]);

  const [selectExistProfile, setSelecteExistProfile] = useState(null);
  const [selectExistProfileData, setSelecteExistProfileData] = useState(null);

  const handleExistSelect = (e) => {
    setSelecteExistProfile(e.target.value);
  };

  useEffect(() => {
    if (selectExistProfile) {
      fetch(ConstantsList.API_URL + "cust/addtempprofile/" + selectExistProfile)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setSelecteExistProfileData(data.data);
              settempProfileStyle(data?.data?.style);
            }
          }
        });
    }
  }, [selectExistProfile]);

  const handleSave = () => {
    let pro_name = isExist || isEdit ? existingName : tempProfileName;
    let checkForm = true;
    formMeltValues.map((formdata) => {
      checkForm =
        formdata?.temperature?.toString()?.trim()?.length > 0 &&
        formdata?.hours?.toString()?.trim()?.length > 0;
      return false;
    });

    if (pro_name.trim().length > 0) {
      if (checkForm) {
        let requestOptions = {};
        if (isExist || isEdit) {
          requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              customer_id: scenarioDetails?.customer?.id,
              profile_name: isExist || isEdit ? existingName : tempProfileName,
              style: tempProfileStyle,
              specific_cust_check: customerSpecific,
              graph_profile: "Summer Profile",
              temp_data: [...formMeltValues],
            }),
          };
          fetch(
            ConstantsList.API_URL + "cust/addtempprofile/" + selectExistProfile,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.status_code === 200) {
                setReload(true);
                setNotify(true);
                setNotifyMsg(data?.data?.toString());
                onClose(false);
                setLoading(false);
              } else if (data.status_code === 400) {
                setTempProfileNameExist(true);
                setTempProfileNameExistError(data?.data?.toString());
              }
            });
        } else {
          requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              customer_id: scenarioDetails?.customer?.id,
              profile_name: isExist || isEdit ? existingName : tempProfileName,
              style: tempProfileStyle,
              specific_cust_check: customerSpecific,
              graph_profile: "Summer Profile",
              temp_data: [...formMeltValues],
            }),
          };
          fetch(ConstantsList.API_URL + "cust/addtempprofile/", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.status_code === 200) {
                setReload(true);
                setNotify(true);
                setNotifyMsg(data?.data?.toString());
                onClose(false);
                setLoading(false);
              } else if (data.status_code === 400) {
                setTempProfileNameExist(true);
                setTempProfileNameExistError(data?.data?.toString());
              }
            });
        }
      } else {
        let errorStateTemp = { ...errorStatus, eshdMelt: true };
        let errorMessageTemp = {
          ...errorMessage,
          eshdMelt: "Temperature data is required",
        };

        setErrorStatus(errorStateTemp);
        setErrorMessage(errorMessageTemp);
      }
    } else {
      setTempProfileNameExist(true);
      setTempProfileNameExistError("Please fill Temperature Name!");
    }
  };

  const [isEdit, setIsEdit] = useState(false);

  const handleProfileOpen = (e) => {
    setIsEdit(true);
  };

  const handleProfileCancel = () => {
    setExistingName(selectExistProfileData?.name);
    setIsEdit(false);
  };

  const [customerSpecific, setCustomerSpecific] = useState(false);

  const handleCustomerSpecific = () => {
    setCustomerSpecific(!customerSpecific);
  };

  useEffect(() => {
    if (selectExistProfileData) {
      setCustomerSpecific(selectExistProfileData?.specific_cust_check);
      setFormMeltValues(selectExistProfileData?.temp_data);
      setExistingName(selectExistProfileData?.name);
    }
  }, [selectExistProfileData]);

  const handleExtingName = (e) => {
    let value = e.target.value;
    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setExistingName(value);
    }
  };

  const handleGraphStyle = (e) => {
    settempProfileStyle(e.target.value);
  };

  return (
    <Box>
      {!showGraph && (
        <Stack>
          <Stack>
            <Typography component="h3" variant="h3" sx={mainTitle}>
              Ambient Profile
            </Typography>
          </Stack>

          <Stack direction="row">
            <Stack
              direction="column"
              sx={{
                width: 1 / 2,
                borderRight: "1px solid #ccc",
                marginRight: "20px",
              }}
            >
              <FormControl>
                <RadioGroup
                  value={profileValue}
                  onChange={handleProfile}
                  sx={{ marginBottom: "30px" }}
                  defaultValue="new-profile"
                  row
                  aria-labelledby="Temperature Profile"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="new-profile"
                    control={
                      <Radio
                        sx={{
                          color: "#F26322",
                          "&.Mui-checked": {
                            color: "#F26322",
                          },
                        }}
                      />
                    }
                    label="New Profile"
                  />
                  <FormControlLabel
                    value="exist-profile"
                    control={
                      <Radio
                        sx={{
                          color: "#F26322",
                          "&.Mui-checked": {
                            color: "#F26322",
                          },
                        }}
                      />
                    }
                    label="Existing Profile"
                  />
                </RadioGroup>
                <Stack
                  spacing={1}
                  p={0}
                  direction="column"
                  width="90%"
                  sx={{ marginBottom: "30px" }}
                >
                  <Typography>Profile Name</Typography>

                  {isExist ? (
                    <Stack
                      width={1}
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {isEdit ? (
                        <TextField
                          value={existingName}
                          onChange={(e) => handleExtingName(e)}
                          fullWidth
                          placeholder="Enter Profile Name"
                          id="newProfileName"
                          size="small"
                          variant="outlined"
                          sx={{ width: "100%" }}
                        />
                      ) : (
                        <FormControl fullWidth>
                          <Select
                            labelId="profile-select-label"
                            id="profile-select"
                            value={selectExistProfile}
                            onChange={handleExistSelect}
                          >
                            {listProfile.map((menu) => {
                              return (
                                <MenuItem value={menu.id}>
                                  {menu?.profile_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}

                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {isEdit ? (
                          <IconButton
                            aria-label="delete"
                            onClick={handleProfileCancel}
                            data-testid="close-icon"
                          >
                            <CloseIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={selectExistProfile ? false : true}
                            aria-label="delete"
                            onClick={handleProfileOpen}
                            data-testid="edit-icon"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Stack>
                  ) : (
                    <TextField
                      value={tempProfileName}
                      onChange={(e) => handleChange("ProfileName", 0, e)}
                      fullWidth
                      placeholder="Enter Profile Name"
                      id="newProfileName"
                      size="small"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  )}
                  {tempProfileNameExist && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {tempProfileNameExistError}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  p={0}
                  direction="column"
                  width="100%"
                  sx={{ marginBottom: "30px" }}
                >
                  <Typography>Style</Typography>
                  <FormControl fullWidth>
                    <Select
                      placeholder="Select a Style"
                      labelId="charts-style-label"
                      id="charts-style-select"
                      value={tempProfileStyle}
                      onChange={handleGraphStyle}
                    >
                      <MenuItem value="Step">Step</MenuItem>
                      <MenuItem value="Ramp">Ramp</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="tempProfileCheck"
                        onChange={handleCustomerSpecific}
                        checked={customerSpecific}
                      />
                    }
                    label="This is a customer specific profile"
                  />
                </Stack>
                <Stack
                  direction="column"
                  sx={{ width: "93%", paddingTop: "30%" }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      marginBottom: "16px",
                      textTransform: "none",
                    }}
                    startIcon={
                      <Avatar sx={{ width: "20px" }} src={LineChart} />
                    }
                    onClick={handleGraphShow}
                    variant="outlined"
                    data-testid="graph-btn"
                  >
                    Graph Profile
                  </Button>
                  {(scenarioDetails?.status === "Optimization Pending" ||
                    scenarioDetails?.status === "Optimization Failed" ||
                    scenarioDetails?.status === "Optimization Cancelled") && (
                    <Button
                      onClick={handleSave}
                      sx={{
                        padding: "0px, 20px, 0px, 20px",
                        width: "100%",
                        border: "1px solid #B1B2B4",
                        borderRadius: "6px",
                        height: "44px",
                        backgroundColor: "#F26322",
                        color: "#3F4042",
                        textTransform: "none",
                        "&:hover": { backgroundColor: "#F26322" },
                      }}
                      data-testid="save-btn"
                    >
                      Save
                    </Button>
                  )}
                </Stack>
              </FormControl>
            </Stack>
            <Stack direction="column" sx={{ width: 1 / 2 }}>
              <Grid item xs={12}>
                <Stack>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        width: "40px",
                      }}
                    >
                      &nbsp;
                    </Grid>
                    <Grid item xs={5} sx={{ p: 1 }}>
                      Hours
                    </Grid>
                    <Grid item xs={5} sx={{ p: 1 }}>
                      Temperature (°C)
                    </Grid>
                    <Grid item xs={1} sx={{ p: 1 }}>
                      &nbsp;
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    sx={{
                      margin: "10px 0",
                      maxHeight: "500px",
                      overflowY: "auto",
                    }}
                  >
                    {/* ~~~~~~~~~~~~~~~~~~~~~ Melt Start ~~~~~~~~~~~~~~~~~~~~~ */}
                    {formMeltValues?.map((element, index) => (
                      <Grid
                        container
                        sx={{ background: "#fff" }}
                        key={`Element-${index}`}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {index + 1}
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl sx={{ m: 1 }} variant="outlined">
                            <OutlinedInput
                              type="number"
                              id="outlined-adornment-temperature"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Hour(s)
                                </InputAdornment>
                              }
                              aria-describedby="outlined-temperature-helper-text"
                              inputProps={{
                                "aria-label": "hours",
                                maxLength: 2,
                                style: {
                                  padding: 8,
                                },
                              }}
                              disabled={eshdMeltCheck ? false : true}
                              name="hours"
                              value={element.hours}
                              onChange={(e) => hoursHandle(e, index)}
                              onBlur={(e) => hoursHandle(e, index)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl sx={{ m: 1 }} variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-value"
                              type="number"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  °C
                                </InputAdornment>
                              }
                              aria-describedby="outlined-value-helper-text"
                              inputProps={{
                                "aria-label": "Temperature",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              disabled={eshdMeltCheck ? false : true}
                              name="temperature"
                              value={element.temperature}
                              onChange={(e) => temperatureHandle(e, index)}
                              onBlur={(e) => temperatureHandle(e, index)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={1} sx={{ display: "flex" }}>
                          <Box
                            component="div"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            {formMeltValues.length - 1 !== index ? (
                              <CloseIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => removeFormFields("Melt", index)}
                              ></CloseIcon>
                            ) : (
                              <AddIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => addFormFields("Melt")}
                              ></AddIcon>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                    {/* ~~~~~~~~~~~~~~~~~~~~~ Melt end ~~~~~~~~~~~~~~~~~~~~~ */}

                    {errorStatus.eshdMelt && (
                      <Typography component="h6" variant="h6" sx={errorStyle}>
                        {errorMessage.eshdMelt}
                      </Typography>
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      )}

      {showGraph && (
        <GraphProfile
          name={isExist === false ? tempProfileName : existingName}
          data={formMeltValues}
          stepped={tempProfileStyle}
          setShowAmbient={setShowAmbient}
          setShowGraph={setShowGraph}
        />
      )}
    </Box>
  );
};

export default AmbientProfile;
