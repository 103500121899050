import { createSlice } from "@reduxjs/toolkit";

export const PCMListViewSlice = createSlice({
  name: "pcmListView",
  initialState: {
    value: {
      id: "",
      name: "",
      color: "",
    },
  },
  reducers: {
    pcmListViewControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { pcmListViewControl } = PCMListViewSlice.actions;

export const pcmListViewData = (state) => state?.pcmListView?.value;

export default PCMListViewSlice.reducer;
