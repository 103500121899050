import { createSlice, createAction } from "@reduxjs/toolkit";

const updatevalue = createAction("update");

const data = {
  type: "none",
  shipper: {
    id: "",
    name: "",
  },
  shipperdict_id: {
    id: "",
    name: "",
  },
  productline_id: {
    id: "",
    name: "",
  },
  shipperclass: {},
  pcmtype_id: {},
  ticconfig: {},
};

export const ShipperDetailsSlice = createSlice({
  name: "shipper",
  initialState: {
    value: data,
  },
  reducers: {
    shipperControl: (state, action) => {
      state.value = action.payload;
    },
    resetData: (state) => {
      state.value = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatevalue, (state, action) => {});
    builder.addDefaultCase((state, action) => {
      state.otherActions++;
    });
  },
});

export const { shipperControl, resetData } = ShipperDetailsSlice.actions;

export const shipperData = (state) => state?.shipper?.value;

export default ShipperDetailsSlice.reducer;
