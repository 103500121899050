import { React, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  createTheme,
  ThemeProvider,
  ButtonGroup,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TextField,
  Backdrop,
} from "@mui/material";
import { Add, ConstructionOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import NoRecordFound from "../../core/NoRecordFound/NoRecordFound";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import * as ConstantsList from "../../../constants";
import TemperatureProfileModel from "../TemperatureProfileDialog";
import SnackBar from "../../core/SnackBar/SnackBar";
import ViewGraph from "../ViewGraph";
import { scenarioTemperature, scenarioData } from "../ScenarioSlice";
import { useSelector, useDispatch } from "react-redux";
import * as ADAConfig from "../../../../src/config/ADAConfig";
import Loader from "../../core/Loader/Loader";

const mainContainer = {
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
};
const mainTitle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "30px",
};
const buttonContainerStyle = {
  alignItems: "flex-end",
};

const ButtonStyle = {
  border: "1px solid #B1B2B4",
};
const ViewProfileIcon = {
  border: "0",
};
const tableContainerStyle = {
  maxHeight: "410px",
  "&::-webkit-scrollbar": {
    height: 5,
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#98A2B3",
    borderRadius: 8,
  },
};

const tableStyle = {
  borderSpacing: "0 0",
  height: "max-content",

  "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
    {
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      border: 1,
    },
  "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
  "& .Mui-selected": {
    borderWidth: "1px",
    backgroundColor: "#FFFFFF",
  },
  "& .Mui-selected td": {
    borderTop: "1px solid #F26322",
    borderBottom: "1px solid #F26322",
    backgroundColor: "#FFFFFF",
  },
  "& .Mui-selected td:first-of-type ": {
    borderLeft: "1px solid #F26322",
  },
  "& .Mui-selected td:last-child ": {
    borderRight: "1px solid #F26322",
  },
};

const tableRowStyle = {
  "& td:first-of-type ": {
    padding: 0,
    width: "10px",
  },
  "&:hover td:first-of-type ": {
    borderLeft: "1px solid #F26322",
    width: "10px",
  },
  "&:hover td:last-child ": {
    borderRight: "1px solid #F26322",
  },
  "& .Mui-selected td:first-of-type ": {
    padding: 0,
  },
  "& .Mui-selected td": {
    borderLeft: "1px solid #F26322",
    padding: "0 12px",
  },
  "&:hover td ": {
    borderTop: "1px solid #F26322",
    borderBottom: "1px solid #F26322",
    backgroundColor: "#FFFFFF",
  },
  "&:selected": { backgroundColor: "#FFFFFF" },
};

const tableCellStyle = {
  "& .css-3y1n0w-MuiTableRow-root td": {
    padding: "0 12px",
  },
};

const columns = [
  {
    id: "select",
    label: "",
    value: "",
    data: "",
    minWidth: 80,
    numeric: false,
  },
  {
    id: "tempProfileSno",
    label: "Select Value",
    value: "",
    data: "",
    minWidth: 80,
    numeric: true,
    align: "center",
  },
  {
    id: "tempProfile",
    label: "Profile",
    value: "",
    data: "",
    minWidth: 186,
    numeric: false,
  },
  {
    id: "tempProfileSeason",
    label: "Profile Season",
    value: "",
    data: "",
    minWidth: 190,
    numeric: false,
  },
  {
    id: "tempProfileCycles",
    label: "Cycles",
    value: "",
    data: "",
    minWidth: 88,
    numeric: false,
  },
  {
    id: "tempProfileHours",
    label: "Hours",
    value: "",
    data: "",
    minWidth: 88,
    numeric: false,
    align: "center",
  },
  {
    id: "tempProfileTemperature",
    label: "Temperature (°C)",
    value: "",
    data: "",
    minWidth: 120,
    numeric: true,
    align: "center",
  },
  {
    id: "tempProfileViewProfile",
    label: "View Profile",
    value: "",
    data: "",
    minWidth: 80,
    numeric: true,
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const selectBg = {
  width: "10px",
  height: "64px",
  backgroundColor: "#F26322",
};
const noselectBg = {
  width: "10px",
  height: "64px",
  backgroundColor: "#FFFFFF",
};

const theme = createTheme({
  palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
});

const TemperatureProfile = (props) => {
  const { setUpdateReload, setSectionNo, updateProbe } = props;
  const scenarioDetails = useSelector(scenarioData);
  const [loading, setLoading] = useState(true);
  const dataValue = {
    id: null,
    default: false,
    checkbox: false,
    selectValue: "",
    profile: "",
    season: "",
    cycles: "",
    hours: "",
    temperature: "0",
  };

  const [indexID, setIndexID] = useState(null);
  const [cycleUpdate, setCycleUpdate] = useState(false);
  const [rows, setRows] = useState(scenarioDetails?.temperature ?? []);

  useEffect(() => {
    if (scenarioDetails?.temperature) {
      setRows(scenarioDetails?.temperature);
    }
  }, [scenarioDetails?.temperature]);

  const [rowSelected, setRowSelected] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [profileCycle, setProfileCycle] = useState(null);
  const [profileGraph, setProfileGraph] = useState(null);
  const [constantData, setConstantData] = useState({
    hours: "0",
    temperature: "",
  });
  const [constant, setContant] = useState(false);

  const handleDefineNewProfileModel = (
    e,
    index,
    hours,
    constantValue,
    temperature
  ) => {
    if (rows[index]["cycles"] && rows[index]["profile"]) {
      setProfileCycle(rows[index]["cycles"]);
      setProfileGraph(rows[index]["profile"]);
      setContant(constantValue);
      setShowGraph(true);
      setConstantData({ hours: hours, temperature: temperature });
      setContant(constantValue);
      setShowGraph(true);
    }
  };

  const handleDefineNewProfile = () => {
    setShowModel(true);
  };

  const handleAddRow = () => {
    setRows([...rows, { id: null, ...dataValue }]);
  };

  const isSelected = (name) => name.toString() === rowSelected.toString();

  const handleChange = (event, id, name) => {
    if (rowSelected === id.toString()) {
      setRowSelected("");
    } else {
      setRowSelected(id.toString());
    }
  };

  const [profileListData, setProfileListData] = useState(null);

  const [reload, setReload] = useState(false);
  const [cycleData, setCycleData] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (scenarioDetails?.scenario?.id) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          scenario_id: scenarioDetails?.scenario?.id,
        }),
      };

      fetch(ConstantsList.API_URL + "cust/temperature_data/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setCycleData(data.data);
              setLoading(false);
            }
          }
        });
    }
  }, [scenarioDetails?.scenario?.id]);

  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "cust/addtempprofile/")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setProfileListData(data.data);
            setReload(false);
            setLoading(false);
          }
        }
      });
  }, [reload]);

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [severity, setSeverity] = useState("success");
  const dispatch = useDispatch();

  const profileNameSelect = (event, index) => {
    const { value } = event.target;
    let newRows = [...rows];
    newRows[index] = { ...newRows[index], profile: value };
    setRows(newRows);
    setIndexID(index);
    setCycleUpdate(true);
  };

  const seasonSelect = (event, index) => {
    const { value } = event.target;
    let newRows = [...rows];
    newRows[index] = { ...newRows[index], season: value };
    setRows(newRows);
    setIndexID(index);
    setCycleUpdate(true);
  };

  const cycleSelect = (event, index) => {
    const { value } = event.target;
    let newRows = [...rows];
    newRows[index] = { ...newRows[index], cycles: value };
    setRows(newRows);
    setIndexID(index);
    setCycleUpdate(true);
  };

  const hoursHandle = (event, index) => {
    const { value } = event.target;
    let newRows = [...rows];
    newRows[index] = { ...newRows[index], hours: value };
    setRows(newRows);
    setIndexID(index);
    setCycleUpdate(true);
  };

  const temperatureHandle = (event, index) => {
    const { value } = event.target;
    let newRows = [...rows];
    newRows[index] = { ...newRows[index], temperature: value };
    setRows(newRows);
  };

  useEffect(() => {
    if (cycleUpdate) {
      let newRows = [...rows];
      if (newRows && newRows[indexID]?.profile) {
        const ID = newRows[indexID]?.profile;
        let Hours = 0;
        fetch(ConstantsList.API_URL + "cust/addtempprofile/" + ID)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              if (data.status_code === 200) {
                if (data?.data?.name !== "Constant") {
                  data.data?.temp_data.map(
                    (value) => (Hours = Hours + parseInt(value.hours))
                  );
                  let cycle =
                    rows[indexID]?.cycles.toString().length > 0
                      ? rows[indexID]?.cycles
                      : "0";
                  newRows[indexID] = {
                    ...newRows[indexID],
                    hours: parseInt(cycle) * parseInt(Hours),
                  };
                  setRows(newRows);
                  setCycleUpdate(false);
                } else {
                  newRows[indexID] = {
                    ...newRows[indexID],
                    cycles: 1,
                  };
                  setRows(newRows);
                  setCycleUpdate(false);
                }
              }
            }
          });
      }
    }
  }, [indexID, cycleUpdate]);

  const [nextButton, setNextButton] = useState(true);

  const saveTemperatureProfile = () => {
    let data = true;
    for (let j = 0; j < rows.length; j++) {
      const ProfileTest = rows[j]?.profile.toString().length > 0 ? true : false;
      const SeasonTest = rows[j]?.season.toString().length > 0 ? true : false;
      const CyclesTest = rows[j]?.cycles.toString().length > 0 ? true : false;
      const HoursTest = rows[j]?.hours.toString().length > 0 ? true : false;
      const TemperatureTest =
        rows[j]?.temperature.toString().length > 0 ? true : false;
      if (
        ProfileTest &&
        SeasonTest &&
        CyclesTest &&
        HoursTest &&
        TemperatureTest
      ) {
      } else {
        data = false;
      }
    }

    if (data && !nextButton) {
      let temp_data = [];
      for (let i = 0; i < rows.length; i++) {
        let value = {
          id: parseInt(rows[i]["id"]),
          profile_id: rows[i]["profile"],
          profile_season_id: rows[i]["season"],
          cycle_id: rows[i]["cycles"],
          hours: rows[i]["hours"],
          temperature: rows[i]["temperature"],
        };
        temp_data.push(value);
      }

      var raw = JSON.stringify({
        customer_id: scenarioDetails?.customer?.id,
        scenario_id: scenarioDetails?.scenario?.id,
        temp_profile: temp_data,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "cust/temp_data/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setUpdateReload(true);
              setNotify(true);
              setNotifyMsg(data.data.toString());
              setSectionNo(4);
              setSeverity("success");
            } else if (data.status_code === 400) {
              setNotify(true);
              setNotifyMsg(data.data.toString());
              setSeverity("error");
            }
          }
        })
        .catch((err) => console.log(err));
      dispatch(scenarioTemperature(rows));
    } 
    else if(nextButton)
    {
      setNotify(true);
      setNotifyMsg("Atleast one summer or winter profile should chosen to enable payload section");
      setSeverity("error");
    }
    else {
      setNotify(true);
      setNotifyMsg("Please fill all the required fields");
      setSeverity("error");
    }
  };


  useEffect(() => {
    let DataValue = [...rows];
    if (rows.length < 2) {
      DataValue.push({
        id: null,
        default: true,
        checkbox: false,
        selectValue: "",
        profile: 1,
        season: 1,
        cycles: 4,
        hours: "96",
        temperature: "0",
      });
      DataValue.push({
        id: null,
        default: true,
        checkbox: false,
        selectValue: "",
        profile: 2,
        season: 2,
        cycles: 4,
        hours: "96",
        temperature: "0",
      });
      setRows(DataValue);
    }
  }, [loading, updateProbe]);


  const [proData, setProData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_id: scenarioDetails?.customer?.id,
      }),
    };

    fetch(ConstantsList.API_URL + "cust/get_definetemp/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setProData(data.data);
            setReload(false);
          }
        }
      });
  }, [scenarioDetails?.customer?.id, reload]);

  const removeValue = () => {
    let newRows = [...rows];
    newRows.splice(rowSelected, 1);
    setRows(newRows);
    setRowSelected("");
  };

  const isEnabled = (id) => {
    return proData
      ? proData?.profile.find((data) =>
          data?.profile_name === "Constant" &&
          data?.id.toString() === id.toString()
            ? true
            : false
        )
      : false;
  };

  

  useEffect(() => {
    if (proData) {
      let season = [];
      proData?.season_data.map((data) => season.push(data));
      const winterCheck =
        rows.find(
          (row) =>
            row?.season ===
            season?.find((seasons) => seasons?.name === "Winter")?.id
        ) ?? false;
      const summerCheck =
        rows.find(
          (row) =>
            row?.season ===
            season?.find((seasons) => seasons?.name === "Summer")?.id
        ) ?? false;
      const CheckSeason = winterCheck && summerCheck;
      setNextButton(!CheckSeason);
    }
  }, [proData, rows]);

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose = () => {
    setOpenLoad(false);
  };

  return (
    <>
      <Box
        maxWidth="false"
        sx={mainContainer}
        px={3}
        py={4}
        data-testid="box-outer-container"
      >
        <Stack direction="row">
          <Stack width={1 / 2}>
            <Stack alignItems="left">
              <Typography variant="h5" sx={mainTitle}>
                Define Temperature Profiles
              </Typography>
            </Stack>
          </Stack>
          <Stack
            width={1 / 2}
            sx={buttonContainerStyle}
            data-testid="stack-container"
          >
            {(scenarioDetails?.status === "Optimization Pending" ||
              scenarioDetails?.status === "Optimization Failed" ||
              scenarioDetails?.status === "Optimization Cancelled") && (
              <ThemeProvider theme={theme}>
                <ButtonGroup>
                  <Button
                    startIcon={<Add />}
                    sx={ButtonStyle}
                    onClick={handleAddRow}
                    data-testid="add-btn"
                  >
                    Add Row
                  </Button>
                  <Button
                    onClick={removeValue}
                    startIcon={<DeleteOutlinedIcon />}
                    disabled={!rowSelected ? true : false}
                    sx={ButtonStyle}
                    data-testid="remove-btn"
                  >
                    Remove
                  </Button>
                  <Button
                    startIcon={<Add />}
                    sx={ButtonStyle}
                    onClick={handleDefineNewProfile}
                    data-testid="define-profile-btn"
                  >
                    Define New Profile
                  </Button>
                </ButtonGroup>
              </ThemeProvider>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ marginTop: "50px" }}
          data-testid="stack-table-container"
        >
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={tableContainerStyle}>
              <Table stickyHeader aria-label="sticky table" sx={tableStyle}>
                <EnhancedTableHead />

                <TableBody>
                  {rows && rows.length === 0 && (
                    <NoRecordFound title="No result found" description="" />
                  )}
                  {rows.map((row, index) => {
                    const data = row["profile"] || "";
                    const isConstant = isEnabled(data) ? true : false;

                    let selctedId = index;
                    const isItemSelected = isSelected(index);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        key={index}
                        sx={tableRowStyle}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell sx={tableCellStyle} padding={"none"}>
                          <Stack direction="row" p={0}>
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Checkbox
                                disabled={row["default"]}
                                checked={isItemSelected}
                                onChange={(event) => handleChange(event, index)}
                                value={selctedId}
                                name={"checkbox"}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>

                        <TableCell
                          align="center"
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                          name={"selectValue"}
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                        >
                          <FormControl
                            sx={{ m: 1, minWidth: 186 }}
                            size="small"
                          >
                            <Select
                              defaultValue=""
                              name={"profile"}
                              id="Temperature-Profile-Profile"
                              value={(proData && row?.profile) ?? ""}
                              onChange={(event) =>
                                profileNameSelect(event, index)
                              }
                            >
                              <MenuItem disabled value="">
                                <em>Select Profile</em>
                              </MenuItem>
                              {proData &&
                                proData?.profile.map((data, i) => {
                                  return (
                                    <MenuItem key={data?.id} value={data?.id}>
                                      {data?.profile_name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                        >
                          <FormControl
                            sx={{ m: 1, minWidth: 186 }}
                            size="small"
                          >
                            <Select
                              defaultValue=""
                              id="Temperature-Profile-Season"
                              name={"season"}
                              value={(proData && row?.season) ?? ""}
                              onChange={(event) => seasonSelect(event, index)}
                            >
                              <MenuItem disabled value="">
                                Select Season
                              </MenuItem>
                              {proData &&
                                proData?.season_data.map((data) => {
                                  return (
                                    <MenuItem key={data.id} value={data.id}>
                                      {data.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                        >
                          {!isConstant ? (
                            <FormControl
                              sx={{ m: 1, minWidth: "44px", maxWidth: "88px" }}
                              size="small"
                            >
                              <Select
                                id="Temperature-Profile-Cycles"
                                name={"cycles"}
                                value={
                                  isConstant
                                    ? 0
                                    : (proData && row?.cycles) ?? ""
                                }
                                onChange={(event) => cycleSelect(event, index)}
                              >
                                {proData &&
                                  proData?.cycle_data.map((data, i) => {
                                    return (
                                      <MenuItem key={data.id} value={data.id}>
                                        {data.value}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          ) : (
                            <FormControl
                              sx={{ m: 1, minWidth: "44px", maxWidth: "88px" }}
                              size="small"
                            >
                              <Select
                                disabled
                                defaultValue={1}
                                id="Temperature-Profile-Cycles"
                                name={"cycles"}
                                value={1}
                                onChange={(event) => cycleSelect(event, index)}
                              >
                                <MenuItem disabled value="">
                                  Select Cycles
                                </MenuItem>
                                <MenuItem disabled value={1}>
                                  N/A
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          align="center"
                          sx={tableCellStyle}
                        >
                          {!isConstant ? (
                            row?.hours ?? ""
                          ) : (
                            <TextField
                              sx={{ minWidth: "44px", maxWidth: "88px" }}
                              disabled={!isConstant}
                              defaultValue={"0"}
                              name={"temperature"}
                              variant="outlined"
                              value={row?.hours ?? ""}
                              onChange={(event) => hoursHandle(event, index)}
                              size="small"
                            />
                          )}
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                          align="center"
                        >
                          {isConstant ? (
                            <TextField
                              sx={{ minWidth: "44px", maxWidth: "120px" }}
                              disabled={!isConstant}
                              defaultValue={"0"}
                              name={"temperature"}
                              variant="outlined"
                              value={row?.temperature ?? ""}
                              onChange={(event) =>
                                temperatureHandle(event, index)
                              }
                              size="small"
                            />
                          ) : (
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                sx={{ minWidth: "44px", maxWidth: "120px" }}
                              >
                                N/A
                              </Typography>
                            </Stack>
                          )}
                        </TableCell>

                        <TableCell
                          padding={"none"}
                          sx={tableCellStyle}
                          id={labelId}
                        >
                          <Button
                            startIcon={<VisibilityOutlinedIcon />}
                            sx={ViewProfileIcon}
                            onClick={(e) =>
                              handleDefineNewProfileModel(
                                e,
                                index,
                                row?.hours,
                                isConstant,
                                row?.temperature
                              )
                            }
                            aria-label={
                              ADAConfig.ADA.temperature_profiles.viewbtn
                            }
                          ></Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Stack>

        {(scenarioDetails?.status === "Optimization Pending" ||
          scenarioDetails?.status === "Optimization Failed" ||
          scenarioDetails?.status === "Optimization Cancelled") && (
          <Stack
            direction="row"
            sx={{
              borderTop: "1px solid #ccc",
              marginTop: "47px",
              padding: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="column">
              <Button
                onClick={() => saveTemperatureProfile()}
                sx={{
                  width: "145px",
                  height: "44px",
                  borderRadius: "6px",
                  padding: "0px, 20px, 0px, 20px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#FFFFFF",
                }}
                color="primary"
                style={{
                  textTransform: "none",
                  border: "1px solid #B1B2B4",
                }}
                data-testid="next-btn"
              >
                Next
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
      {showModel && (
        <TemperatureProfileModel
          open={showModel}
          reload={reload}
          setReload={setReload}
          setShowModel={setShowModel}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar
          open={notify}
          setOpen={setNotify}
          message={notifyMsg}
          severity={severity}
        />
      )}

      {showGraph && (
        <ViewGraph
          cycle={profileCycle}
          open={showGraph}
          onClose={setShowGraph}
          data={profileGraph}
          constant={constant}
          constantData={constantData}
        />
      )}

      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoad}
            onClick={handleClose}
            data-testid="backdrop"
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </>
  );
};

export default TemperatureProfile;
