import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import * as ConstantsList from "../../../constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
Chart.register(...registerables);

const mainTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
  marginBottom: "40px",
};

const options = {
  type: "line",
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
      color: "black",
      align: "top",
      offset: 2,
      rotation: -90,
      anchor: "end",
      font: { size: "7" },
    },
  },
  scales: {
    x: {
      type: "linear",
      position: "bottom",
      title: {
        display: true,
        text: "Time (Hours)",
        color: "#000000",
      },
      ticks: {
        stepSize: 24,
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Temperature (°C)",
        color: "#000000",
      },
    },
  },
};

const GraphProfile = (props) => {
  const {
    setShowGraph,
    id,
    cycle,
    data,
    stepped,
    name,
    constant,
    constantData,
  } = props;

  const [lineData, setLineData] = useState(null);
  useEffect(() => {
    if (id) {
      fetch(ConstantsList.API_URL + "cust/addtempprofile/" + id)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setLineData(data.data);
            }
          }
        });
    }
  }, [id]);

  const [hoursData, setHoursData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [isStep, setIsStep] = useState(false);

  const dataValue = {
    labels: hoursData,
    datasets: [
      {
        axis: "x",
        label: "Sales",
        data: temperatureData,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        stepped: isStep,
      },
    ],
  };

  const [hours, setHours] = useState(0);
  const [avgtemp, setAvgTemp] = useState(0);

  useEffect(() => {
    if (!constant) {
      if (data) {
        const Hours = [];
        const Temperature = [];

        if (stepped === "Step") {
          let Hr = 0;
          for (let i = 0; i < data.length; i++) {
            Hours.push(Hr);
            Hr = Hr + data[i].hours;
            Hours.push(parseInt(Hr));
          }
          for (let j = 0; j < data.length; j++) {
            Temperature.push(data[j].temperature);
            Temperature.push(parseFloat(data[j].temperature));
          }
        } else {
          let Hr = 0;
          Hours.push(0);
          Temperature.push(data[0].temperature);
          for (let i = 0; i < data.length; i++) {
            Hr = Hr + data[i].hours;
            Hours.push(parseInt(Hr));
          }

          for (let j = 0; j < data.length; j++) {
            Temperature.push(parseFloat(data[j].temperature));
          }
        }
        let totalTempHr = 0;
        let sumofHours = 0;
        totalTempHr = data
          .map((value) => value?.hours * value?.temperature)
          .reduce((adder, value) => adder + value);
        sumofHours = data
          .map((value) => value?.hours)
          .reduce((adder, value) => adder + value);
        let average = 0;
        average = totalTempHr / sumofHours;
        setAvgTemp(parseFloat(isNaN(average) ? "0" : average).toFixed(2));

        setHoursData(Hours);
        setHours(Hours[Hours.length - 1]);
        setTemperatureData(Temperature);
        setIsStep(stepped === "Step" ? true : false);
      }
    }
  }, [data, stepped, constant]);

  useEffect(() => {
    if (!constant) {
      if (lineData) {
        const Hours = [];
        const Temperature = [];

        if (lineData?.style === "Step") {
          let Hr = 0;

          for (let k = 0; k < cycle; k++) {
            for (let i = 0; i < lineData?.temp_data?.length; i++) {
              Hours.push(Hr);
              Hr = Hr + lineData?.temp_data[i].hours;
              Hours.push(parseInt(Hr));
            }
            for (let j = 0; j < lineData?.temp_data.length; j++) {
              Temperature.push(lineData?.temp_data[j].temperature);
              Temperature.push(parseFloat(lineData?.temp_data[j].temperature));
            }
          }
        } else {
          let Hr = 0;
          Hours.push(0);
          Temperature.push(lineData?.temp_data[0].temperature);
          for (let k = 0; k < cycle; k++) {
            for (let i = 0; i < lineData?.temp_data.length; i++) {
              Hr = Hr + lineData?.temp_data[i].hours;
              Hours.push(parseInt(Hr));
            }
            for (let j = 0; j < lineData?.temp_data.length; j++) {
              Temperature.push(parseFloat(lineData?.temp_data[j].temperature));
            }
          }
        }
        let totalTempHr = 0;
        let sumofHours = 0;
        if (lineData?.temp_data) {
          totalTempHr = lineData?.temp_data
            ?.map((value) => value?.hours * value?.temperature)
            ?.reduce((adder, value) => adder + value);
          sumofHours = lineData?.temp_data
            ?.map((value) => value?.hours)
            ?.reduce((adder, value) => adder + value);
        }
        let average = 0;
        average = totalTempHr / sumofHours;
        setAvgTemp(parseFloat(isNaN(average) ? "0" : average).toFixed(2));
        setHoursData(Hours);
        setHours(Hours[Hours.length - 1]);
        setTemperatureData(Temperature);
        setIsStep(lineData?.style === "Step" ? true : false);
      }
    } else {
      if (constant) {
        let hour = [0];
        hour.push(parseInt(constantData?.hours));
        let temperature = [parseFloat(constantData?.temperature).toFixed(2)];
        temperature.push(parseFloat(constantData?.temperature).toFixed(2));
        setHoursData(hour);
        setTemperatureData(temperature);
      }
    }
  }, [lineData, cycle, constant, id, constantData]);

  return (
    <Stack>
      <Typography component="h3" variant="h3" sx={mainTitle}>
        {name ? name : lineData?.name} {!cycle ? "" : "(x " + cycle + ")"}
      </Typography>
      <Typography>{`Duration: ${
        constant ? constantData?.hours : hours
      } Hours, Average Temp.: ${
        constant ? constantData?.temperature : avgtemp
      }°C`}</Typography>
      <Stack direction="column" width={1} spacing={5}>
        <Stack direction="row">
          <Stack
            direction="column"
            sx={{ width: "100%", border: "1px solid #ccc" }}
          >
            <Line data={dataValue} options={options} />
          </Stack>
        </Stack>
        <Button
          sx={{ width: "131px" }}
          variant="outlined"
          data-testid="back-button-breadcrumb"
          startIcon={<ArrowBackIcon />}
          style={{
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            color: "black",
          }}
          onClick={() => {
            setShowGraph(false);
          }}
        >
          Back
        </Button>
      </Stack>
    </Stack>
  );
};

export default GraphProfile;
