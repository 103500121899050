import {
  Backdrop,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateShipperClass from "./CreateShipperClass";
import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../constants";
import Insulation from "../Insulation";

import { useSelector, useDispatch } from "react-redux";
import {
  shipperClassTypeUpdate,
  shipperClassIDUpdate,
  outerDimensionsUpdate,
  dryGoodsUpdate,
  shipperData,
  pcmTabUpdate,
  insulationUpdate,
} from "../NewShipper/NewShipperSlice";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createTheme, Tab, ThemeProvider } from "@mui/material";
import PCMTab from "../PCMTab";
import Loader from "../core/Loader/Loader";

import * as Config from "../../config";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ShipperClass = (props) => {
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const isEnable = shipperDetails?.productline_id?.length > 0 ? true : false;
  const ProID = shipperDetails?.productline_id;
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip?.shipperClass;
  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [classData, setClassData] = useState(null);
  const [selectedClassType, setSelectedClassType] = useState("Select");
  const [classChanged, setClassChanged] = useState(false);
  const [changedPCMData, setChangedPCMData] = useState(
    shipperDetails?.shipperclass?.pcm
  );
  const [changedInsulationData, setChangedInsulationData] = useState(
    shipperDetails?.shipperclass?.pcm
  );

  const handleShipperClass = () => {
    setOpen(true);
  };
  
  useEffect(() => {
    if (isEnable) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          productline_id: ProID,
        }),
      };
      fetch(
        ConstantsList.API_URL + "shipdict/shipper_class_productline/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setClassData(data.data);
            setReload(false);
          }
        });
    }
  }, [ProID, reload, isEnable]);

  const handleSelectClass = (e, id, name) => {
    setSelectedClassType(e.target.value);
    const classDataVal = classData && classData.shippers;

    if (e.target.value !== "Select") {
      let index = classDataVal.find(
        (x) => x.class_type === e.target.value.toString()
      );
      setEnableData(false);
      dispatch(shipperClassTypeUpdate(e.target.value.toString()));
      dispatch(shipperClassIDUpdate(index.id.toString()));

      // ~~~~~~~~~ Class Change Bind data Start ~~~~~~~~~~~~~~~//
      setClassChanged(true);
      classData.shippers.map((classType) => {
        if (classType.class_type === e.target.value.toString()) {
          dispatch(
            outerDimensionsUpdate({
              length: classType?.outer_dimension?.length?.toString(),
              width: classType?.outer_dimension?.width?.toString(),
              height: classType?.outer_dimension?.height?.toString(),
              outside_area:
                classType?.outer_dimension?.outside_area?.toString(),
            })
          );

          dispatch(
            dryGoodsUpdate({
              weight: classType?.dry_goods?.weight?.toString(),
              cost: classType?.dry_goods?.cost?.toString(),
            })
          );

          const PCMData = {
            top: {
              od_length: classType.pcm.top?.od_length?.toString(),
              od_width: classType.pcm.top?.od_width?.toString(),
            },
            side: {
              od_length: classType?.pcm.side.od_length?.toString(),
              od_width: classType?.pcm.side.od_width?.toString(),
            },
            end: {
              od_length: classType?.pcm.end.od_length?.toString(),
              od_width: classType?.pcm.end.od_width?.toString(),
            },
          };

          dispatch(pcmTabUpdate(PCMData));
          setChangedPCMData(PCMData);
          const InsulationData = {
            top: {
              thickness: classType.insulation.top.thickness,
              specific_heat: classType.insulation.top.specific_heat,
              density: classType.insulation.top.density,
            },
            side: {
              thickness: classType.insulation.side.thickness,
              specific_heat: classType.insulation.side.specific_heat,
              density: classType.insulation.side.density,
            },
            end: {
              thickness: classType.insulation.end.thickness,
              specific_heat: classType.insulation.end.specific_heat,
              density: classType.insulation.end.density,
            },
          };
          dispatch(insulationUpdate(InsulationData));
          setChangedInsulationData(InsulationData);

          setOuterLength({
            name: classType?.outer_dimension?.length?.toString(),
            error: false,
            message: "",
          });

          setOuterWidth({
            name: classType?.outer_dimension?.width?.toString(),
            error: false,
            message: "",
          });

          setOuterHeight({
            name: classType?.outer_dimension?.height?.toString(),
            error: false,
            message: "",
          });

          setOuterOutsideArea({
            name: classType?.outer_dimension?.outside_area?.toString(),
            error: false,
            message: "",
          });

          setDryWidth({
            name: classType?.dry_goods?.weight?.toString(),
            error: false,
            message: "",
          });

          setDryCost({
            name: classType?.dry_goods?.cost?.toString(),
            error: false,
            message: "",
          });
        }
      });

      // ~~~~~~~~~ Class Change Bind data End ~~~~~~~~~~~~~~~//

    } else {
      dispatch(shipperClassTypeUpdate("----"));
      dispatch(shipperClassIDUpdate(""));
      setEnableData(true);
    }
  };

  const [selectTab, setSlectTab] = useState("0");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });
  const handleTab = (e, newValue) => {
    setSlectTab(newValue);
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [outerLength, setOuterLength] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.length,
    error: false,
    message: "",
  });
  const [outerWidth, setOuterWidth] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.width,
    error: false,
    message: "",
  });
  const [outerHeight, setOuterHeight] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.height,
    error: false,
    message: "",
  });
  const [outerOutsideArea, setOuterOutsideArea] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.outside_area,
    error: false,
    message: "",
  });

  const [dryWeight, setDryWidth] = useState({
    name: shipperDetails?.shipperclass?.dry_goods.weight,
    error: false,
    message: "",
  });
  const [dryCost, setDryCost] = useState({
    name: shipperDetails?.shipperclass?.dry_goods.cost,
    error: false,
    message: "",
  });

  const outerLengthHandle = (e) => {
    const target = e.target;
    let value = target.value;
    setOuterLength({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const outerWidthHandle = (e) => {
    const target = e.target;
    let value = target.value;
    setOuterWidth({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const outerHeightHandle = (e) => {
    const target = e.target;
    let value = target.value;

    setOuterHeight({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const outerOutsideAreaHandle = (e) => {
    const target = e.target;
    let value = target.value;
    setOuterOutsideArea({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const dryWeightHandle = (e) => {
    const target = e.target;
    let value = target.value;
    setDryWidth({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const dryCostHandle = (e) => {
    const target = e.target;
    let value = target.value;
    setDryCost({
      name: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
      error: false,
      message: "",
    });
  };

  const [enableData, setEnableData] = useState(true);

  useEffect(() => {
    dispatch(
      outerDimensionsUpdate({
        length: outerLength?.name?.toString(),
        width: outerWidth?.name?.toString(),
        height: outerHeight?.name?.toString(),
        outside_area: outerOutsideArea?.name?.toString(),
      })
    );
    dispatch(
      dryGoodsUpdate({
        weight: dryWeight?.name?.toString(),
        cost: dryCost?.name?.toString(),
      })
    );
  }, [
    outerLength,
    outerWidth,
    outerHeight,
    outerOutsideArea,
    dryWeight,
    dryCost,
    dispatch,
  ]);

  const isTextFieldEnable = isEnable ? (enableData ? true : false) : false;

  const handleTextField = (e) => {};

  return (
    <Box maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <Stack direction="column" spacing={5}>
        <Box
          maxWidth="false"
          px={3}
          pt={3}
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ThemeProvider theme={customTheme}>
            <Stack spacing={1.5} p={0} direction="row" sx={{ width: "100%" }}>
              <Stack
                sx={{ maxHeight: "44px" }}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Class Name
                </Typography>
              </Stack>

              <FormControl
                size="small"
                sx={{
                  width: "200px",
                  padding: "8px, 12px, 8px, 12px",
                }}
              >
                <Select
                  onChange={handleSelectClass}
                  disabled={!isEnable}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue="Select"
                  value={selectedClassType}
                >
                  <MenuItem value="Select">
                    <em style={{ fontStyle: "normal" }}>Select</em>
                  </MenuItem>
                  {classData &&
                    classData.shippers.map((classType) => {
                      return (
                        <MenuItem
                          key={classType.id}
                          value={classType.class_type}
                          datadetails={classType}
                        >
                          {classType.class_type}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </ThemeProvider>

          <Button
            disabled={!isEnable}
            onClick={handleShipperClass}
            sx={{
              width: "145px",
              height: "44px",
              borderRadius: "6px",
              padding: "0px, 20px, 0px, 20px",
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              backgroundColor: "#FFFFFF",
            }}
            data-testid="addbutton"
            color="primary"
            startIcon={<AddIcon />}
            style={{
              textTransform: "none",
              border: "1px solid #B1B2B4",
            }}
          >
            Create New
          </Button>
        </Box>
        <ThemeProvider theme={customTheme}>
          <Stack
            direction="row"
            spacing={7}
            px={3}
            pb={5}
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
            }}
          >
            <Stack width={1 / 2} direction="column" spacing={2}>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Outer Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerLengthHandle}
                        value={outerLength.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerLength.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerLength.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerWidthHandle}
                        onPaste={outerWidthHandle}
                        value={outerWidth.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerWidth.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerWidth.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerHeightHandle}
                        onPaste={outerHeightHandle}
                        value={outerHeight.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerHeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerHeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Outside Area
                      </Typography>
                      <BootstrapTooltip title={tooltips?.outsideArea}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerOutsideAreaHandle}
                        onPaste={outerOutsideAreaHandle}
                        value={outerOutsideArea.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm&sup2;
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerOutsideArea.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerOutsideArea.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Dry Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip title={tooltips?.weigth}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={dryWeightHandle}
                        onPaste={dryWeightHandle}
                        value={dryWeight.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              kg
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {dryWeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {dryWeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={dryCostHandle}
                        onPaste={dryCostHandle}
                        value={dryCost.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              $
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {dryCost.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {dryCost.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem p={0} m={0} />
            <Stack width={1 / 2} direction="column" spacing={2}>
              <ThemeProvider theme={theme}>
                <Box
                  maxWidth="false"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TabContext
                    value={selectTab}
                    sx={{
                      "&.Mui-selected": { color: "#19191A", fontWeight: "600" },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: 0,
                        borderColor: "none",
                        mb: "24px",
                      }}
                    >
                      <TabList
                        fontWeight={600}
                        textColor="secondary"
                        TabIndicatorProps={{
                          sx: {
                            "& .MuiButtonBase-root": { padding: 0 },
                            "& .MuiTabs-indicator": {
                              display: "none !important",
                            },
                            display: "none !important",
                            hidden: true,
                          },
                          style: { display: "none", hidden: true },
                        }}
                        onChange={handleTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          disableRipple={true}
                          label={"PCM"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="0"
                        />
                        <Tab
                          disableRipple={true}
                          label={"Insulation"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="1"
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="0"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <PCMTab
                        enableData={isTextFieldEnable}
                        shipperDataDetails={changedPCMData}
                        classChanged={classChanged}
                        setClassChanged={setClassChanged}
                      />
                    </TabPanel>
                    <TabPanel
                      value="1"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <Insulation
                        enableData={isTextFieldEnable}
                        shipperDataDetails={changedInsulationData}
                        classChanged={classChanged}
                        setClassChanged={setClassChanged}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </ThemeProvider>
            </Stack>
          </Stack>
        </ThemeProvider>
      </Stack>

      {open && ProID && (
        <CreateShipperClass
          open={open}
          setOpen={setOpen}
          productID={ProID}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default ShipperClass;
