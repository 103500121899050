import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "login",
  initialState: {
    value: {
      username: "",
      email: "",
      role: "",
      active_role: "",
      loggedIn: false,
    },
  },
  reducers: {
    loginControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { loginControl } = LoginSlice.actions;
export const loginData = (state) => state?.login?.value;
export default LoginSlice.reducer;
