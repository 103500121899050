import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
  Typography,
  Button,
  AppBar,
  Backdrop,
} from "@mui/material";
import {
  SaveOutlined,
  ExpandMore,
  ImportExportOutlined,
  ClearOutlined,
} from "@mui/icons-material";
import Duplicate from "@mui/icons-material/ContentCopy";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProductLine from "../Shipper/ViewShipper/ProductLine";
import ShipperClass from "../Shipper/ViewShipper/ShipperClass";
import TicConfiguration from "../TicConfiguration";
import BreadCrumb from "../core/BreadCrumb";
import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  shipperDetailsControl,
  shipperDetailsData,
} from "../ViewShipper/ViewShipperSlice";
import * as ConstantsList from "../../constants";
import SnackBar from "../core/SnackBar/SnackBar";
import EditIcon from "@mui/icons-material/Edit";
import PCMType from "../Shipper/ViewShipper/PCMType";
import Configuration from "../Shipper/ViewShipper/Configuration";
import ProbeConfiguration from "../Shipper/ViewShipper/ProbeConfiguration";
import { editShipperControl } from "../EditShipper/EditShipperSlice";
import Loader from "../core/Loader/Loader";
import { useParams } from "react-router-dom";

const ViewShipper = (props) => {
  const params = useParams();

  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperDetailsData);
  const [loading, setLoading] = useState(true);

  const shipperID = shipperDetails?.id;
  let history = useHistory();
  const [productLine, setProductLine] = useState("----");
  const [shipperClass, setShipperClass] = useState("----");
  const [series, setSeries] = useState("----");
  const [config, setConfig] = useState("----");
  const shipperDictionary = useSelector(shipperDictionaryData);
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const AccordStyle = {
    boxShadow:
      "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
  };

  const AccordSummaryStyle = {
    paddingLeft: "24px",
    borderRadius: "6px",
    backgroundColor: "#235984",
    color: "#FFFFFF",
    "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
      color: "#FFFFFF",
    },
  };

  const breadcrumbData = [
    { id: 3, title: "View Shipper", link: "" },
    {
      id: 2,
      title: shipperDictionary?.name,
      link: "/dashboard/shipper",
    },
    {
      id: 1,
      title: "Home / Shipper Dictionaries",
      link: "/dashboard",
    },
  ];

  const editButton = () => {
    if (params?.id) {
      history.push("/dashboard/shipper/edit/" + params?.id);
    }
  };

  useEffect(() => {
    setProductLine(shipperDetails?.productline?.name ?? "----");
    setShipperClass(shipperDetails?.shipperclass?.class_type ?? "----");
    setSeries(shipperDetails?.pcmtype?.pcm_name ?? "----");
    setConfig(shipperDetails?.ticconfig?.tic_type ?? "----");
  }, [shipperDetails]);

  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (params?.id) {
      fetch(ConstantsList.API_URL + "shipdict/shipper/" + params?.id)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setData(data.data);
            setLoading(false);
          }
        });
    }
  }, [params?.id]);

  useEffect(() => {
    if (data) {
      dispatch(shipperDetailsControl(data));
      dispatch(editShipperControl(data));
    }
  }, [dispatch, data]);

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose = () => {
    setOpenLoad(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <>
        <ThemeProvider theme={theme}>
          <Stack px={6} pt={6} direction="column" sx={{ width: "100%" }}>
            <BreadCrumb data={breadcrumbData} />
          </Stack>
        </ThemeProvider>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: "#F2F2F3",
            width: "100%",
            "& .MuiAppBar-positionSticky": {
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Stack
            px={6}
            pt={6}
            pb={3.5}
            direction="column"
            spacing={3}
            sx={{ width: "100%" }}
          >
            <Box
              maxWidth="false"
              sx={{
                position: "sticky",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  fontSize: "32px",
                  lineHeight: "38px",
                  color: "#19191A",
                }}
              >
                View Shipper
              </Typography>
              <ThemeProvider theme={theme}>
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={editButton}
                    sx={{
                      width: "145px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#FFFFFF",
                    }}
                    data-testid="editbutton"
                    startIcon={<EditIcon />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{
                      width: "145px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#FFFFFF",
                    }}
                    data-testid="deletebutton"
                    startIcon={<Duplicate />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                    }}
                  >
                    Duplicate
                  </Button>
                </Stack>
              </ThemeProvider>
            </Box>

            <Box
              maxWidth="false"
              p={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "56px",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#000000",
                  fontFamily: "Roboto",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                View Shipper
              </Typography>
              <Typography
                sx={{
                  color: "#646568",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >{`${productLine} / ${shipperClass} / ${series} / ${config}`}</Typography>
            </Box>
          </Stack>
        </AppBar>
      </>
      <ThemeProvider theme={theme}>
        <Stack spacing={3} direction="column" px={6} pb={5}>
          <Stack spacing={6}>
            {/* Product Line */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="Product Line"
                id="Product-Line-Accordion"
              >
                <Typography>Product Line</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ProductLine />
              </AccordionDetails>
            </Accordion>

            {/* Shipper Class */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Class</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ShipperClass />
              </AccordionDetails>
            </Accordion>

            {/* PCM Type */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>PCM Type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PCMType />
              </AccordionDetails>
            </Accordion>

            {/* Configuration */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Configuration />
              </AccordionDetails>
            </Accordion>

            {/* Probe Configuration */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Probe Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ProbeConfiguration />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
        {loading && (
          <Box sx={{ display: "flex" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoad}
              onClick={handleClose}
            >
              <Loader />
            </Backdrop>
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};

export default ViewShipper;
