import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = (props) => {
  const { message, setOpen, open, severity } = props;
  const isSeverity = severity ? severity : "success";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const errorTheme = {
    borderLeft: "15.48px solid #9e0b12",
    width: "391px",
    height: "auto",
    backgroundColor: "#E34949",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    padding: "9px 16px",
    borderRadius: "6px",
    boxShadow:
      "0px 172px 69px rgba(0, 0, 0, 0.01), 0px 97px 58px rgba(0, 0, 0, 0.05), 0px 43px 43px rgba(0, 0, 0, 0.09), 0px 11px 24px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
  };

  const successTheme = {
    borderLeft: "15.48px solid #3A7813",
    width: "391px",
    height: "auto",
    backgroundColor: "#408515",
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
    padding: "9px 16px",
    borderRadius: "6px",
    boxShadow:
      "0px 172px 69px rgba(0, 0, 0, 0.01), 0px 97px 58px rgba(0, 0, 0, 0.05), 0px 43px 43px rgba(0, 0, 0, 0.09), 0px 11px 24px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1)",
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          icon={false}
          severity={isSeverity}
          onClose={handleClose}
          sx={isSeverity === "success" ? successTheme : errorTheme}
        >
          <AlertTitle
            sx={{
              fontWeight: "500",
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "22px",
              color: "#FFFFFF",
            }}
          >
            {isSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackBar;
