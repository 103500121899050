import React, { useState } from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import * as ConstantsList from "../../../src/constants";
import * as ADAConfig from "../../../src/config/ADAConfig";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const AddCustomers = (props) => {
  const {
    open,
    setOpen,
    setNotify,
    setNotifyMsg,
    setReload,
    setSales,
    setCustomer,
  } = props;
  const [savebutton, setSavebutton] = useState(true);
  const [oppCheck, setOppCheck] = useState(false);
  const [name, setName] = useState("");
  const [oppName, setOppName] = useState("");
  const [oppDescription, setOppDescription] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [oppNameErr, setOppNameErr] = useState(false);
  const [oppDesErr, setOppDesErr] = useState(false);

  const [nameErrMsg, setNameErrMsg] = useState("");
  const [oppNameErrMsg, setOppNameErrMsg] = useState("");
  const [oppDesErrMsg, setOppDesErrMsg] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (
      (!oppCheck && name.length !== 0) ||
      (oppCheck && name.length !== 0 && oppName.length !== 0)
    ) {
      setNameErrMsg("");
      setNameErr(false);

      let PayLoad = {};
      if (oppCheck) {
        PayLoad = {
          customer_name: name,
          opportunity_name: oppName,
          description: oppDescription,
        };
      } else {
        PayLoad = { customer_name: name };
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(PayLoad),
      };

      fetch(ConstantsList.API_URL + "cust/customer/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setNotify(true);
            setNotifyMsg(data.data.message);
            setOpen(false);
            setReload(true);
            setSales(false);
            setCustomer({ id: data?.data?.customer_id, name: name });
          } else if (data.status_code.toString() === "400") {
            setNameErr(true);
            setNameErrMsg(data.data.toString());
          } else {
            setNameErr(true);
            setNameErrMsg(data.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (name.length === 0) {
        setNameErr(true);
        setNameErrMsg("Please Enter Customer Name!");
      }

      if (oppCheck && oppName.length === 0) {
        setOppNameErr(true);
        setOppNameErrMsg("Please Enter Opportunity Name!");
      }
    }
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSave();
    }
  };

  const handleChange = (event, changeType, eventType) => {
    if (changeType === "Checkbox") {
      setOppCheck(event.target.checked);
      if (!event.target.checked && name !== "") {
        setSavebutton(false);
      } else {
        setSavebutton(true);
      }
    } else if (changeType === "CustomerName") {
      const value =
        eventType === "change" ? event.target.value : event.target.value.trim();

      const re = /^[A-Za-z ]+$/;
      if (value === "" || re.test(value)) {
        setName(value);
      }

      if (!oppCheck && name !== "") {
        setNameErrMsg("");
        setNameErr(false);
        setSavebutton(false);
      } else {
        setSavebutton(true);
      }
    } else if (changeType === "OpportunityName") {
      const value =
        eventType === "change" ? event.target.value : event.target.value.trim();
      const re = /^[A-Za-z0-9 ]+$/;
      if (value === "" || re.test(value)) {
        setOppName(value);
      }

      if (oppCheck && name !== "" && oppName !== "") {
        setOppNameErrMsg("");
        setOppNameErr(false);
        setSavebutton(false);
      } else {
        setSavebutton(true);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="dialog-outer-container"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
          data-testid="box-outer-container"
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
                data-testid="box-inner-container"
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-button"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                New Customer
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                [ * ] Required fields
              </Typography>
              <ThemeProvider theme={checkboxTheme}>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Customer Name *
                  </Typography>
                  <TextField
                    fullWidth
                    onChange={(event) =>
                      handleChange(event, "CustomerName", "change")
                    }
                    onBlur={(event) =>
                      handleChange(event, "CustomerName", "blur")
                    }
                    onKeyUp={(event) => handleKeyUp(event)}
                    helperText={nameErrMsg}
                    error={nameErr}
                    value={name}
                    placeholder="Enter Customer Name"
                    id="uname"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1} p={0} direction="column">
                  <Stack
                    spacing={1}
                    p={0}
                    direction="row"
                    sx={{
                      display: "none",
                      direction: "row",
                      alignItems: "center",
                      "& .css-98tcq9-MuiButtonBase-root-MuiCheckbox-root": {
                        padding: 0,
                      },
                    }}
                  >
                    <Checkbox
                      p={0}
                      checked={oppCheck}
                      onChange={(event) =>
                        handleChange(event, "Checkbox", "change")
                      }
                      onBlur={(event) =>
                        handleChange(event, "Checkbox", "blur")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography component="h6" variant="h6" sx={labelStyle}>
                      Create New Opportunity
                    </Typography>
                  </Stack>
                </Stack>
                {oppCheck && (
                  <Stack spacing={1} p={0} direction="column">
                    <Typography component="h6" variant="h6" sx={labelStyle}>
                      Opportunity Name *
                    </Typography>
                    <TextField
                      fullWidth
                      error={oppNameErr}
                      helperText={oppNameErrMsg}
                      onChange={(event) =>
                        handleChange(event, "OpportunityName", "change")
                      }
                      onBlur={(event) =>
                        handleChange(event, "OpportunityName", "blur")
                      }
                      value={oppName}
                      placeholder="Enter Opportunity Name"
                      id="opportunityName"
                      sx={{
                        "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                          { borderRadius: "6px", height: "44px" },
                        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                          margin: "0px !important",
                        },
                      }}
                    />
                  </Stack>
                )}

                {oppCheck && (
                  <Stack spacing={1} p={0} direction="column">
                    <Typography component="h6" variant="h6" sx={labelStyle}>
                      Description
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      error={oppDesErr}
                      helperText={oppDesErrMsg}
                      onChange={(event) =>
                        handleChange(event, "Description", "change")
                      }
                      onBlur={(event) =>
                        handleChange(event, "Description", "blur")
                      }
                      value={oppDescription}
                      placeholder="Enter Opportunity Description"
                      id="description"
                      sx={{
                        "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                          { borderRadius: "6px", height: "44px" },
                        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                          margin: "0px !important",
                        },
                      }}
                      inputProps={{
                        maxLength: 64,
                      }}
                    />
                  </Stack>
                )}
              </ThemeProvider>

              <Button
                onClick={handleSave}
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                aria-label={
                  ADAConfig.ADA.Opportunities.createNewCustomeSave_button
                }
                data-testid="add-customer-save-btn"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default AddCustomers;
