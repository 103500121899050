import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, Button, ButtonGroup } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SnackBar from "../../components/core/SnackBar/SnackBar";
import Loader from "../../components/core/Loader/Loader";

import CreateNew from "../../components/PCM/CreateNew";
import ListView from "../../components/PCM/ListView";
import * as ConstantsList from "../../constants";
import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";

import { loginData } from "../../components/Login/LoginSlice";
import { useSelector } from "react-redux";

const PCM = () => {
  const loginDetails = useSelector(loginData);
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pcmList, setPCMList] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const isReload = cddelopen || cdopen;
  const shipperDictionary = useSelector(shipperDictionaryData);

  useEffect(() => {
    setLoading(true);

    var shipperDictionaryId = shipperDictionary?.id;

    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ shipperdict_id: shipperDictionaryId }),
    };

    fetch(ConstantsList.API_URL + "pcm/getall-pcm/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code.toString() === "200") {
          setPCMList(data);
          setLoading(false);
        } else {
        }
      });
  }, [isReload, shipperDictionary?.id]);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [pcmColor, setPCMColor] = useState("");
  const [pcmName, setPCMName] = useState("");
  const [pcmPhase, setPCMPhase] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [lastEditedDate, setLastEditedDate] = useState("");

  const [notifyMsg, setNotifyMsg] = useState("");

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const [notify, setNotify] = useState(false);

  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            p: 3,
          }}
        >
          List of PCMs
        </Typography>
        <Box sx={{}}>
          {loginDetails.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#000000",
                  color: "#000000",
                  p: 3,
                }}
              >
                <Button
                  href="/dashboard/pcm/edit"
                  data-testid="editbutton"
                  disabled={count}
                  startIcon={<EditIcon />}
                  style={{ textTransform: "none", border: "1px solid #B1B2B4" }}
                >
                  Edit
                </Button>

                <Button
                  href="/dashboard/pcm/new"
                  data-testid="addbutton"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none", border: "1px solid #B1B2B4" }}
                >
                  New PCM
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>

      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      {pcmList && (
        <ListView
          data={pcmList.data.pcm}
          dataCount={setCount}
          dataPCMColor={setPCMColor}
          dataPCMName={setPCMName}
          dataPCMPhase={setPCMPhase}
          dataCreationDate={setCreationDate}
          dataLastEditedDate={setLastEditedDate}
        />
      )}
      {cdopen && (
        <CreateNew
          open={cdopen}
          onClose={handleCdClose}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setCount={setCount}
        />
      )}

      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default PCM;
