import {
    Box,
    createTheme,
    FormControl,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    Stack,
    styled,
    ThemeProvider,
    Tooltip,
    tooltipClasses,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import { useSelector, useDispatch } from "react-redux";
  import { insulationUpdate, shipperData } from "./EditShipperSlice";
  import * as Config from "../../../config";
  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
  ))(({ theme, ownerState }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: "4px 4px 4px 0px",
      backgroundColor: "#005A88",
      margin: 0,
    },
  }));
  
  const Insulation = (props) => {
    const dispatch = useDispatch();
    const shipperDetails = useSelector(shipperData);
    const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip?.insulation;
    const { enableData } = props;
  
    const customTheme = createTheme({
      palette: {
        primary: {
          main: "#F26322",
        },
      },
    });
  
    const [thicknessTop, setThicknessTop] = useState({
      name: shipperDetails?.shipperclass?.insulation?.top?.thickness,
      error: false,
      message: "",
    });
  
    const [thicknessSide, setThicknessSide] = useState({
      name: shipperDetails?.shipperclass?.insulation?.side?.thickness,
      error: false,
      message: "",
    });
  
    const [thicknessEnd, setThicknessEnd] = useState({
      name: shipperDetails?.shipperclass?.insulation?.end?.thickness,
      error: false,
      message: "",
    });
  
    const [heatTop, setHeatTop] = useState({
      name: shipperDetails?.shipperclass?.insulation.top?.specific_heat,
      error: false,
      message: "",
    });
  
    const [heatSide, setHeatSide] = useState({
      name: shipperDetails?.shipperclass?.insulation?.side?.specific_heat,
      error: false,
      message: "",
    });
  
    const [heatEnd, setHeatEnd] = useState({
      name: shipperDetails?.shipperclass?.insulation?.end?.specific_heat,
      error: false,
      message: "",
    });
  
    const [densityTop, setDensityTop] = useState({
      name: shipperDetails?.shipperclass?.insulation?.top?.density,
      error: false,
      message: "",
    });
  
    const [densitySide, setDensitySide] = useState({
      name: shipperDetails?.shipperclass?.insulation?.side?.density,
      error: false,
      message: "",
    });
  
    const [densityEnd, setDensityEnd] = useState({
      name: shipperDetails?.shipperclass?.insulation?.end?.density,
      error: false,
      message: "",
    });
  
    const thicknessTopHandle = (e) => {
      let value = e.target.value;
      setThicknessTop({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const thicknessSideHandle = (e) => {
      let value = e.target.value;
      setThicknessSide({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const thicknessEndHandle = (e) => {
      let value = e.target.value;
      setThicknessEnd({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const heatTopHandle = (e) => {
      let value = e.target.value;
      setHeatTop({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const heatSideHandle = (e) => {
      let value = e.target.value;
      setHeatSide({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const heatEndHandle = (e) => {
      let value = e.target.value;
      setHeatEnd({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const densityTopHandle = (e) => {
      let value = e.target.value;
      setDensityTop({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const densitySideHandle = (e) => {
      let value = e.target.value;
      setDensitySide({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    const densityEndHandle = (e) => {
      let value = e.target.value;
      setDensityEnd({
        name: !isNaN(parseFloat(value))?parseFloat(value):"",
        error: false,
        message: "",
      });
    };
  
    useEffect(() => {
      const InsulationData = {
        top: {
          thickness: thicknessTop?.name,
          specific_heat: heatTop?.name,
          density: densityTop?.name,
        },
        side: {
          thickness: thicknessSide?.name,
          specific_heat: heatSide?.name,
          density: densitySide?.name,
        },
        end: {
          thickness: thicknessEnd?.name,
          specific_heat: heatEnd?.name,
          density: densityEnd?.name,
        },
      };
      dispatch(insulationUpdate(InsulationData));
    }, [
      thicknessTop,
      heatTop,
      densityTop,
      thicknessSide,
      heatSide,
      densitySide,
      thicknessEnd,
      heatEnd,
      densityEnd,
      dispatch,
    ]);
  
    return (
      <ThemeProvider theme={customTheme}>
        <Box maxWidth="false" sx={{ width: "100%", display: "flex" }}>
          <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
            <Typography
              component="h6"
              variant="h6"
              sx={{
                fontFamily: "Roboto",
                fontSize: "20px",
                lineHeight: "22px",
                color: "#000000",
                fontWeight: "500",
              }}
            >
              Multiwall Detail Definition
            </Typography>
            <Stack direction="column" sx={{ width: "100%" }}>
              {/* Head Row */}
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  height: "64px",
                  backgroundColor: "#F2F2F3",
                }}
              >
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                ></Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    Top
                  </Typography>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    Side
                  </Typography>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    End
                  </Typography>
                </Stack>
              </Stack>
  
              {/* Row 1  */}
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  height: "64px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    Thickness
                  </Typography>
                  <BootstrapTooltip title={tooltips?.thickness}>
                    <InfoOutlinedIcon
                      sx={{
                        color: "#7D7F82",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </BootstrapTooltip>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={thicknessTopHandle}
                      onPaste={thicknessTopHandle}
                      value={thicknessTop?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          mm
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {thicknessTop.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {thicknessTop?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={thicknessSideHandle}
                      onPaste={thicknessSideHandle}
                      value={thicknessSide?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          mm
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {thicknessSide.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {thicknessSide?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={thicknessEndHandle}
                      onPaste={thicknessEndHandle}
                      value={thicknessEnd?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          mm
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {thicknessEnd.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {thicknessEnd?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
  
              {/* Row 2  */}
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  height: "64px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    Specific Heat
                  </Typography>
                  <BootstrapTooltip title={tooltips?.specificHeat}>
                    <InfoOutlinedIcon
                      sx={{
                        color: "#7D7F82",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </BootstrapTooltip>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={heatTopHandle}
                      onPaste={heatTopHandle}
                      value={heatTop?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          J/gK
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {heatTop.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {heatTop?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={heatSideHandle}
                      onPaste={heatSideHandle}
                      value={heatSide?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          J/gK
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {heatSide?.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {heatSide?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={heatEndHandle}
                      onPaste={heatEndHandle}
                      value={heatEnd?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          J/gK
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {heatEnd?.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {heatEnd?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
  
              {/* Row 3  */}
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  height: "64px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                      fontWeight: "400",
                    }}
                  >
                    Density
                  </Typography>
                  <BootstrapTooltip title={tooltips?.density}>
                    <InfoOutlinedIcon
                      sx={{
                        color: "#7D7F82",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </BootstrapTooltip>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={densityTopHandle}
                      onPaste={densityTopHandle}
                      value={densityTop?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          kg/m^3
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {densityTop.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {densityTop?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={densitySideHandle}
                      onPaste={densitySideHandle}
                      value={densitySide?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          kg/m^3
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {densitySide.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {densitySide?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
  
                <Stack
                  width={1 / 4}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ width: "184px", height: "44px" }}
                    variant="outlined"
                  >
                    <OutlinedInput
                    type="number"
                      disabled={enableData}
                      onChange={densityEndHandle}
                      onPaste={densityEndHandle}
                      value={densityEnd?.name}
                      id="outlined-adornment-weight"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: "6px, 12px, 6px, 12px",
                            height: "28px",
                            width: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          kg/m^3
                        </InputAdornment>
                      }
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                    {densityEnd?.error && (
                      <FormHelperText sx={{ m: 0 }}>
                        {densityEnd?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </ThemeProvider>
    );
  };
  
  export default Insulation;
  