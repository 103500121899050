import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import * as ADAConfig from "../../../src/config/ADAConfig";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.scenario_name,
});
const _filterOptions = createFilterOptions();
export default function Search(props) {
  const { data, search } = props;
  const [popShow, setPopShow] = useState(false);
  const searchData =
    data &&
    data.filter(
      (obj, index) =>
        data.findIndex((item) => item.scenario_name === obj.scenario_name) ===
        index
    );
  const autoTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
        light: "#42a5f5",
        dark: "#1565c0",
        contrastText: "#fff",
      },
    },
  });
  const [valid, setValid] = useState(false);
  const [open, setOpen] = useState(false);

  const filterOptions = React.useCallback((options, state) => {
    const results = _filterOptions(options, state);
    if (results.length === 0) {
      setPopShow(false);
    } else {
      setPopShow(true);
    }

    return results;
  }, []);

  return (
    <Box
      p={3}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        "& .MuiOutlinedInput-root": { padding: 0 },
      }}
    >
      <ThemeProvider theme={autoTheme}>
        <Autocomplete
          open={open}
          freeSolo={valid}
          noOptionsText={"No result found"}
          fullWidth
          id="search-bar"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          forcePopupIcon
          autoHighlight
          popupindicatoropen="false"
          disableClearable={false}
          options={searchData}
          popupIcon={!valid && <SearchIcon />}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                display: popShow ? "block" : "none",
                border: "1px solid #F26322",
                marginTop: "8px",
                "&:hover": { background: "#FFFFFF" },
              }}
            >
              {children}
            </Paper>
          )}
          onClick={(e) => {
            setValid(false);
          }}
          onClose={() => setOpen(false)}
          onChange={(event, newValue) => {
            search(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setValid(newInputValue.length > 0 ? true : false);
            if (newInputValue.length === 0) {
              if (open) setOpen(false);
            } else {
              if (!open) setOpen(true);
            }
          }}
          getOptionLabel={(option) => option.scenario_name ?? option}
          filterOptions={filterOptions}
          sx={{
            borderRadius: "6px",
            height: "auto",
            width: "35.813rem",
            borderWidth: "1px",
            "& .MuiAutocomplete-popupIndicator": { transform: "none" },
            "& .MuiOutlinedInput-root": { padding: "3px" },
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              variant="outlined"
              placeholder="Search Scenario"
              aria-label={ADAConfig.ADA.Opportunities.searchScenario_input}
            />
          )}
        />
      </ThemeProvider>
    </Box>
  );
}
