import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import * as Config from "../../../config";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import * as ADAConfig from "../../../../src/config/ADAConfig";

const BreadCrumb = (props) => {
  const { data } = props;
  let history = useHistory();
  const breadCrumbText = Config?.CONFIGLIST?.breadcrumb;

  data.sort((a, b) => a.id - b.id);

  const handleClick = (event) => {
    event.preventDefault();
  };

  const handleLink = (e, link) => {
    history.push(link);
  };

  return (
    <Box
      role="presentation"
      onClick={handleClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "24px",
      }}
    >
      <Button
        onClick={(e) => {
          history.push(data[data.length - 2]?.link);
        }}
        variant="outlined"
        data-testid="back-button-breadcrumb"
        startIcon={<ArrowBackIcon />}
        style={{
          border: "1px solid #B1B2B4",
          backgroundColor: "#FFFFFF",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 400,
          color: "black",
        }}
      >
        {breadCrumbText?.backText}
      </Button>
      <Breadcrumbs
        maxItems={4}
        aria-label={ADAConfig.ADA.Opportunities.back_button}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {data &&
          data.map((link, i) => {
            const isActive = i === data.length - 1;
            return (
              <Link
                onClick={(e) => {
                  handleLink(e, link?.link);
                }}
                value={link.link}
                key={link.id}
                underline="hover"
                color={isActive ? "#000000" : "inherit"}
                sx={{ cursor: isActive ? "default" : "pointer" }}
              >
                {link.title}
              </Link>
            );
          })}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadCrumb;
