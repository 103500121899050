import React, { useState, useEffect } from "react";
import {
  Stack,
  Box,
  Typography,
  Divider,
  Paper,
  createTheme,
  ThemeProvider,
  Radio,
} from "@mui/material";
import * as Config from "../../../config";
import * as ConstantsList from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";

const PCMType = (props) => {
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperDetailsData);
  const shipperID = shipperDetails?.shipperdict_id?.id;

  const { CONFIGLIST } = Config;
  const text = {
    pcmType: CONFIGLIST.newShipper.pcmTypeText,
    selectPCMType: CONFIGLIST.newShipper.selectPCMTypeText,
    aboveZero: CONFIGLIST.newShipper.aboveZeroText,
    belowZero: CONFIGLIST.newShipper.belowZeroText,
  };
  const [selected, setSelected] = useState("");
  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#646568",
      },
    },
  });

  useEffect(() => {
    if (shipperDetails?.pcmtype?.id) {
      setSelected(shipperDetails?.pcmtype?.id);
    }
  }, [shipperDetails?.pcmtype?.id]);

  const isSelected = (name) => selected.toString() === name.toString();

  const [loading, setLoading] = useState(false);
  const [pcmTypeData, setPCMTypeData] = useState(null);
  const [reload, setReload] = useState(false);

  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    if (shipperID?.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ shipperdict_id: shipperID }),
    };

    fetch(ConstantsList.API_URL + "pcm/getphase-pcm/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setTempData(data.data);
      });
    }
  }, [shipperID]);

  return (
    <Box
      maxWidth="false"
      sx={{ width: "100%" }}
      data-testid="box-outer-container"
    >
      <Stack direction="column" sx={{ width: "100%" }}>
        <Box
          maxWidth="false"
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            gap: "56px",
          }}
        >
          {tempData &&
            tempData.map((data, i) => {
              return (
                <>
                  {i === 0 && (
                    <Stack
                      key={`Above-0-${i}`}
                      width={1 / 2}
                      direction="column"
                    >
                      <Stack direction="row" p={1} alignItems="center">
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "22px",
                          }}
                        >
                          {data.phase}
                        </Typography>
                      </Stack>

                      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                        {data &&
                          data.phase_details.map((pcmtype, j) => {
                            const IsSelected = isSelected(pcmtype.id);
                            return IsSelected ? (
                              <Stack
                                key={`ST-${pcmtype.id}-${j}`}
                                direction="row"
                                spacing={2}
                                m={1}
                                width={1 / 3}
                                px={1.5}
                                py={2}
                                sx={{
                                  border: "1px solid #CBCCCD",
                                  backgroundColor: IsSelected
                                    ? "#F2F2F3"
                                    : "rgba(242, 242, 243, 0.5)",
                                  height: "55px",
                                  maxWidth: "268px",
                                  borderRadius: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <Paper
                                  width={1 / 4}
                                  elevation={0}
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: pcmtype.color,
                                  }}
                                ></Paper>
                                <Typography
                                  width={2 / 4}
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {pcmtype.pcm_name}
                                </Typography>
                                <Stack
                                  width={1 / 4}
                                  direction="row"
                                  justifyContent="flex-end"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Radio
                                      sx={{ padding: 0 }}
                                      checked={IsSelected}
                                      value={pcmtype.id}
                                      name={"1"}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                </Stack>
                              </Stack>
                            ) : (
                              <React.Fragment
                                key={`ST-${pcmtype.id}-${j}`}
                              ></React.Fragment>
                            );
                          })}
                      </Stack>
                    </Stack>
                  )}
                  {i === 0 && (
                    <Divider
                      key={`div-1-${i}-Divider`}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                  {i === 1 && (
                    <Stack
                      key={`Stack-1-${i}`}
                      width={1 / 2}
                      direction="column"
                    >
                      <Stack direction="row" p={1} alignItems="center">
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            lineHeight: "22px",
                          }}
                        >
                          {data.phase}
                        </Typography>
                      </Stack>

                      <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                        {data &&
                          data.phase_details.map((pcmtype, j) => {
                            const IsSelected = isSelected(pcmtype.id);
                            return IsSelected ? (
                              <Stack
                                key={`SR-${pcmtype.id}-${j}`}
                                direction="row"
                                spacing={2}
                                m={1}
                                width={1 / 3}
                                px={1.5}
                                py={2}
                                sx={{
                                  border: "1px solid #CBCCCD",
                                  backgroundColor: "#FFFFFF",
                                  height: "55px",
                                  maxWidth: "268px",
                                  borderRadius: "8px",
                                  alignItems: "center",
                                }}
                              >
                                <Paper
                                  width={1 / 4}
                                  elevation={0}
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    backgroundColor: pcmtype.color,
                                  }}
                                ></Paper>
                                <Typography
                                  width={2 / 4}
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                  }}
                                >
                                  {pcmtype.pcm_name}
                                </Typography>
                                <Stack
                                  width={1 / 4}
                                  direction="row"
                                  justifyContent="flex-end"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Radio
                                      sx={{ padding: 0 }}
                                      checked={IsSelected}
                                      value={pcmtype.id}
                                      name={"1"}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                </Stack>
                              </Stack>
                            ) : (
                              <React.Fragment
                                key={`SR-${pcmtype.id}-${j}`}
                              ></React.Fragment>
                            );
                          })}
                      </Stack>
                    </Stack>
                  )}
                </>
              );
            })}
        </Box>
      </Stack>
    </Box>
  );
};

export default PCMType;
