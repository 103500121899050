import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccessDeniedImage from "../../../src/assets/images/401.svg";
import * as Config from "../../config";
import { Link } from "react-router-dom";

export default function AccessDenied() {
  return (
    <Box
      sx={{
        minWidth: "100%",
        height: "calc(100vh - 176px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#F2F2F3",
        p: "56px",
      }}
    >
      <Box component="div" sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          align="center"
          alignItems="center"
          sx={{ width: "250px", height: "auto" }}
          alt="Access Denied"
          src={AccessDeniedImage}
        />
      </Box>
      <Typography
        align="center"
        variant="h6"
        component="div"
        sx={{ fontWeight: 500, fontSize: "24px", mt: "16px" }}
      >
        {Config.CONFIGLIST.accessDenied.message1}
      </Typography>
      <Typography
        align="center"
        variant="h6"
        component="div"
        sx={{ fontWeight: 500, fontSize: "20px", m: 1 }}
      >
        {Config.CONFIGLIST.accessDenied.message2}
      </Typography>
      <Link
        to="/"
        align="center"
        variant="h6"
        component="div"
        style={{ color: "#4C8CB6", fontSize: "16px", fontWeight: 600, m: 1 }}
      >
        Home/Shipper Dictionaries
      </Link>
    </Box>
  );
}
