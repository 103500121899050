import React, { useState } from "react";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

export default function ToolbarComponent() {
  const [value, setValue] = useState("");
  const handleText = (e) => {
    let value = e.target.value;
    const re = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/gm;
    if (value === "" || re.test(value)) {
      setValue(value);
    } else {
      setValue("");
    }

    //value2.value = value2.value.replace(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/gm, "");
    // console.log("Re", value2.value.replace(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/gm, ""))
    // setValue(value2?.value)
  };

  return (
    <Box m={1}>
      <TextField
        type="number"
        size="small"
        value={value}
        onInput={(e) => setValue(e.target.value)}
        onKeyPress={(event) => {
          if (event?.key === "-" || event?.key === "+") {
            event.preventDefault();
          }
        }}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />
    </Box>
  );
}
