import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import * as ConstantsList from "../../../constants";
import { scenarioData } from "../../Scenario/ScenarioSlice";
import { useSelector } from "react-redux";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const EditReport = (props) => {
  const scenarioDetails = useSelector(scenarioData);
  const [snackopen, setsnackOpen] = React.useState(true);
  const handlesnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackOpen(false);
  };

  const {
    onClose,
    open,
    setNotify,
    setNotifyMsg,
    setReload,
    setSeverity,
    setReportName,
    selectedReport,
    setSelectedReport,
  } = props;
  const [uname, setUname] = useState(selectedReport.name);
  const [uemail, setUemail] = useState(selectedReport.notes);
  const [urole, setUrole] = useState("Admin");
  const [validEmail, setValidEmail] = useState(false);
  const [save, setSave] = useState(true);
  const [data, setData] = useState();
  const [unameError, setUnameError] = useState(false);
  const [unameErrorMsg, setUnameErrorMsg] = useState("");
  const [uemailError, setUemailError] = useState(false);
  const [uemailErrorMsg, setUemailErrorMsg] = useState("");

  const newUserError = (newName, newEmail) => {
    if (String(newName).length !== 0 && String(newEmail).length !== 0) {
      setSave(false);
    } else {
      setSave(true);
    }
  };

  const changeuname = (e) => {
    const newUname = e.target.value;
    newUserError(newUname, uemail);
    setUname(e.target.value);
    setUnameError(false);
    setUnameErrorMsg("");
  };

  const changeuemail = (e) => {
    const newEmail = e.target.value;
    newUserError(uname, newEmail);
    var mailformat =
      /^((?!\.)(?!\-)(?!\_)[a-z0-9]*([\.\-\_]?[a-z0-9\-]*)?[^\-`_.#()+/=!@$%^&*:'"<>])(@(?!\.)(?!\-)(?!\_)[\w-]+)(\.\w+(\.\w+)?[^.\W])$/i;
    if (e.target.value.match(mailformat)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    setUemail(e.target.value);
    setUemailError(false);
    setUemailErrorMsg("");
  };

  const handleClose = () => {
    onClose(false);
  };

  const changeButton = () => {
    let Data = {
      scenario_id: scenarioDetails?.scenario?.id,
      report_name: uname,
      notes: uemail,
    };

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(Data),
    };
    fetch(
      ConstantsList.API_URL +
        "summary/getparticular_report/" +
        selectedReport?.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setData(data);
          if (data.status_code === 200) {
            setNotify(true);
            setNotifyMsg(data.data);
            setReload(true);
            onClose(false);
            //setReportName(null);
            setSelectedReport({
              id: selectedReport.id,
              name: selectedReport.name,
              notes: uemail,
              shared_with_customer: selectedReport.shared_with_customer,
            });
          } else if (data.status_code === 400) {
            setUnameError(true);
            setUnameErrorMsg(data.data);
          } else {
            setData(data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Edit Report
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                [ * ] Required fields
              </Typography>
              <ThemeProvider theme={checkboxTheme}>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Report Name *
                  </Typography>
                  <TextField
                    fullWidth
                    onChange={(e) => changeuname(e)}
                    value={uname}
                    placeholder="Enter Report Name"
                    id="uname"
                    disabled="disabled"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Notes
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    error={uemailError}
                    helperText={uemailErrorMsg}
                    onChange={(e) => changeuemail(e)}
                    value={uemail}
                    placeholder="Enter Notes"
                    id="emailId"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
              </ThemeProvider>
              <Stack direction="row" spacing={2}>
                <Button
                  sx={{
                    maxWidth: "189.5px",
                    width: "395px",
                    height: "44px",
                    border: "1px solid #B1B2B4",
                    backgroundColor: "#FFFFFF",
                    color: "#3F4042",
                    textTransform: "none",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={save}
                  sx={{
                    maxWidth: "189.5px",
                    width: "395px",
                    height: "44px",
                    backgroundColor: "#F26322",
                    color: "#3F4042",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#F26322" },
                  }}
                  onClick={changeButton}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default EditReport;
