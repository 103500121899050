import {
  Backdrop,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useSelector, useDispatch } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createTheme, Tab, ThemeProvider } from "@mui/material";
import PCMTab from "./PCMTab";
import Insulation from "./Insulation";
import * as Config from "../../../config";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ShipperClass = (props) => {
  const shipperDetails = useSelector(shipperDetailsData);
  const shipperClass = shipperDetails?.shipperclass;
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip?.shipperClass;
  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };
  const [selectTab, setSlectTab] = useState("0");
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });
  const handleTab = (e, newValue) => {
    setSlectTab(newValue);
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });
  const isTextFieldEnable = true;
  return (
    <Box maxWidth="false" sx={{ width: "100%", height: "auto" }} data-testid="box-outer-container">
      <Stack direction="column" spacing={5}>
        <Box
          maxWidth="false"
          px={3}
          pt={3}
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ThemeProvider theme={customTheme}>
            <Stack spacing={1.5} p={0} direction="row" sx={{ width: "100%" }}>
              <Stack
                sx={{maxHeight: "44px"}}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Class Name
                </Typography>
              </Stack>

              <FormControl
                size="small"
                sx={{
                  width: "200px",
                  padding: "8px, 12px, 8px, 12px",
                  background: "#F2F2F370",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <OutlinedInput
                  type="text"
                  value={shipperClass?.class_type}
                  disabled={isTextFieldEnable}
                  readonly={isTextFieldEnable}
                  id="shipperclassName"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "Class Name",
                  }}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#3F4042",
                  }}
                />
              </FormControl>
            </Stack>
          </ThemeProvider>
        </Box>
        <ThemeProvider theme={customTheme}>
          <Stack
            direction="row"
            spacing={7}
            px={3}
            pb={5}
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
            }}
          >
            <Stack width={1 / 2} direction="column" spacing={2}>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Outer Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.outer_dimension?.length}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassODLenght"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.outer_dimension?.width}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassODWidth"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.outer_dimension?.height}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassODHeight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Outside Area
                      </Typography>
                      <BootstrapTooltip title={tooltips?.outsideArea}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.outer_dimension?.outside_area}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassODOuterArea"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm&sup2;
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Dry Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip title={tooltips?.weigth}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.dry_goods?.weight}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassDGWeight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              kg
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        width: "11.5rem",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        value={shipperClass?.dry_goods?.cost}
                        disabled={isTextFieldEnable}
                        readonly={isTextFieldEnable}
                        id="shipperClassODCost"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              $
                            </Typography>
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem p={0} m={0} />
            <Stack width={1 / 2} direction="column" spacing={2}>
              <ThemeProvider theme={theme}>
                <Box
                  maxWidth="false"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TabContext
                    value={selectTab}
                    sx={{
                      "&.Mui-selected": { color: "#19191A", fontWeight: "600" },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: 0,
                        borderColor: "none",
                        mb: "24px",
                      }}
                    >
                      <TabList
                        fontWeight={600}
                        textColor="secondary"
                        TabIndicatorProps={{
                          sx: {
                            "& .MuiButtonBase-root": { padding: 0 },
                            "& .MuiTabs-indicator": {
                              display: "none !important",
                            },
                            display: "none !important",
                            hidden: true,
                          },
                          style: { display: "none", hidden: true },
                        }}
                        onChange={handleTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          disableRipple={true}
                          label={"PCM"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="0"
                        />
                        <Tab
                          disableRipple={true}
                          label={"Insulation"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="1"
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="0"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <PCMTab />
                    </TabPanel>
                    <TabPanel
                      value="1"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <Insulation />
                    </TabPanel>
                  </TabContext>
                </Box>
              </ThemeProvider>
            </Stack>
          </Stack>
        </ThemeProvider>
      </Stack>
    </Box>
  );
};

export default ShipperClass;
