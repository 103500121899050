import {
  Box,
  Button,
  Checkbox,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateTicConfiguration from "./CreateTicConfiguration";
import SnackBar from "../core/SnackBar/SnackBar";

import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";
import * as ConstantsList from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  ticTypeUpdate,
  ticConfigurationUpdate,
  shipperData,
  outerDimensionsUpdate,
} from "../NewShipper/NewShipperSlice";
import * as Config from "../../config";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const TicConfiguration = (props) => {
  const { setReleased } = props;
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip;
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const shipperDictionary = useSelector(shipperDictionaryData);
  const ShipID = shipperDetails && shipperDetails?.shipperclass?.id;
  const isEnable = ShipID?.length > 0 ? false : true;
  const [configChanged, setConfigChanged] = useState(false);

  const isTextEnabled =
    shipperDetails?.ticconfig?.tic_type === "----" ? true : false;

  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const handleCreateTic = () => {
    setOpen(true);
  };

  const [innerLength, setInnerLength] = useState({
    name: shipperDetails?.ticconfig?.configurations?.length,
    error: false,
    message: "",
  });
  const [innerWidth, setInnerWidth] = useState({
    name: shipperDetails?.ticconfig?.configurations?.width,
    error: false,
    message: "",
  });
  const [innerHeight, setInnerHeight] = useState({
    name: shipperDetails?.ticconfig?.configurations?.height,
    error: false,
    message: "",
  });

  const [wetWeight, setWetWeight] = useState({
    name: shipperDetails?.ticconfig?.configurations?.weight,
    error: false,
    message: "",
  });
  const [wetCost, setWetCost] = useState({
    name: shipperDetails?.ticconfig?.configurations?.cost,
    error: false,
    message: "",
  });
  const [wetTopPCM, setWetTopPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.top_pcm,
    error: false,
    message: "",
  });
  const [wetSidePCM, setWetSidePCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.side_pcm,
    error: false,
    message: "",
  });
  const [wetEndPCM, setWetEndPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.end_pcm,
    error: false,
    message: "",
  });
  const [wetTotalPCM, setWetTotalPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.total_pcm,
    error: false,
    message: "",
  });

  const [envCo2, setEnvCo2] = useState({
    name: shipperDetails?.ticconfig?.configurations?.emissions,
    error: false,
    message: "",
  });
  const [envLandfill, setEnvLandfill] = useState({
    name: shipperDetails?.ticconfig?.configurations?.landfill,
    error: false,
    message: "",
  });

  const [multiTopWall, setMultiTopWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.top_wall,
    error: false,
    message: "",
  });
  const [multiSideWall, setMultiSideWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.side_wall,
    error: false,
    message: "",
  });
  const [multiEndWall, setMultiEndWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.end_wall,
    error: false,
    message: "",
  });

  const co2Handle = (e) => {
    setEnvCo2({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const landfillHandle = (e) => {
    setEnvLandfill({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiTopHandle = (e) => {
    setMultiTopWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiSideHandle = (e) => {
    setMultiSideWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiEndHandle = (e) => {
    setMultiEndWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const innerLengthHandle = (e) => {
    setInnerLength({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const innerWidthHandle = (e) => {
    setInnerWidth({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      err: false,
      message: "",
    });
  };

  const innerHeightHandle = (e) => {
    setInnerHeight({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetWeightHandle = (e) => {
    setWetWeight({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetCostHandle = (e) => {
    setWetCost({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetTopPCMHandle = (e) => {
    setWetTopPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetSidePCMHandle = (e) => {
    setWetSidePCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      err: false,
      message: "",
    });
  };

  const wetEndPCMHandle = (e) => {
    setWetEndPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetTotalPCMHandle = (e) => {
    setWetTotalPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const [selectedType, setSelectedType] = useState("Select");

  const handleSelect = (e) => {
    if (e.target.value !== "Select") {
      dispatch(ticTypeUpdate(e.target.value.toString()));
      setSelectedType(e.target.value.toString());

      // ~~~~~~~~~ Tic Change Bind data Start ~~~~~~~~~~~~~~~//
      setConfigChanged(true);
      ticConfigData.tic_configuration.map((tic) => {
        if (tic.tic_type === e.target.value.toString()) {
          const ticConfig = tic?.configurations;
          const TicConfig = {
            weight: ticConfig.weight?.toString(),
            emissions: ticConfig?.emissions?.toString(),
            landfill: ticConfig?.landfill?.toString(),
            cost: ticConfig?.cost?.toString(),
            top_pcm: ticConfig?.top_pcm?.toString(),
            side_pcm: ticConfig?.side_pcm?.toString(),
            end_pcm: ticConfig?.end_pcm?.toString(),
            total_pcm: ticConfig?.total_pcm?.toString(),
            length: ticConfig?.length?.toString(),
            width: ticConfig?.width?.toString(),
            height: ticConfig?.height?.toString(),
            top_wall: ticConfig?.top_wall[0]?.toString(),
            side_wall: ticConfig?.side_wall[0]?.toString(),
            end_wall: ticConfig?.end_wall[0]?.toString(),
          };
          dispatch(ticConfigurationUpdate(TicConfig));

          setEnvCo2({
            name: ticConfig?.emissions?.toString(),
            error: false,
            message: "",
          });

          setEnvLandfill({
            name: ticConfig?.landfill?.toString(),
            error: false,
            message: "",
          });

          setMultiTopWall({
            name: ticConfig?.top_wall[0]?.toString(),
            error: false,
            message: "",
          });

          setMultiSideWall({
            name: ticConfig?.side_wall[0]?.toString(),
            error: false,
            message: "",
          });

          setMultiEndWall({
            name: ticConfig?.end_wall[0]?.toString(),
            error: false,
            message: "",
          });

          setInnerLength({
            name: ticConfig?.length?.toString(),
            error: false,
            message: "",
          });

          setInnerWidth({
            name: ticConfig?.width?.toString(),
            err: false,
            message: "",
          });

          setInnerHeight({
            name: ticConfig?.height?.toString(),
            error: false,
            message: "",
          });

          setWetWeight({
            name: ticConfig?.weight?.toString(),
            error: false,
            message: "",
          });

          setWetCost({
            name: ticConfig?.cost?.toString(),
            error: false,
            message: "",
          });

          setWetTopPCM({
            name: ticConfig?.top_pcm?.toString(),
            error: false,
            message: "",
          });

          setWetSidePCM({
            name: ticConfig?.side_wall[0]?.toString(),
            err: false,
            message: "",
          });

          setWetEndPCM({
            name: ticConfig?.landfill?.toString(),
            error: false,
            message: "",
          });

          setWetTotalPCM({
            name: ticConfig?.total_pcm?.toString(),
            error: false,
            message: "",
          });
        }
      });
      // ~~~~~~~~~ Tic Change Bind data End ~~~~~~~~~~~~~~~//
    } else {
      setSelectedType("Select");
      dispatch(ticTypeUpdate("----"));
    }
  };

  const [ticConfigData, setTicConfigData] = useState(null);

  useEffect(() => {
    if (ShipID) {      
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          shipperclass_id: ShipID,
        }),
      };
      fetch(
        ConstantsList.API_URL + "shipdict/tic_configuration_shipperclass/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setTicConfigData(data.data);
            setReload(false);
          }
        });
    }
  }, [ShipID, reload]);
  useEffect(() => {
    const TicConfig = {
      weight: wetWeight?.name?.toString(),
      emissions: envCo2?.name?.toString(),
      landfill: envLandfill?.name?.toString(),
      cost: wetCost?.name?.toString(),
      top_pcm: wetTopPCM?.name?.toString(),
      side_pcm: wetSidePCM?.name?.toString(),
      end_pcm: wetEndPCM?.name?.toString(),
      total_pcm: wetTotalPCM?.name?.toString(),
      length: innerLength?.name?.toString(),
      width: innerWidth?.name?.toString(),
      height: innerHeight?.name?.toString(),
      top_wall: multiTopWall?.name?.toString(),
      side_wall: multiSideWall?.name?.toString(),
      end_wall: multiEndWall?.name?.toString(),
    };
    dispatch(ticConfigurationUpdate(TicConfig));
  }, [
    wetWeight,
    envCo2,
    envLandfill,
    wetCost,
    wetTopPCM,
    wetSidePCM,
    wetEndPCM,
    wetTotalPCM,
    innerLength,
    innerWidth,
    innerHeight,
    multiTopWall,
    multiSideWall,
    multiEndWall,
    dispatch,
  ]);
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [check, setCheck] = useState(false);

  const releasedHandle = () => {
    setCheck(!check);
    setReleased(!check);
  };

  return (
    <Box p={0} maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <Box
        px={3}
        pt={3}
        maxWidth="false"
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack
          spacing={1.5}
          direction="row"
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography component="h6" variant="h6" sx={labelStyle}>
            Configuration Name
          </Typography>
          <FormControl
            size="small"
            sx={{
              width: "200px",
              height: "44px",
              padding: "8px, 12px, 8px, 12px",
            }}
          >
            <Select
              disabled={isEnable}
              onChange={handleSelect}
              labelId="demo-select-small"
              id="demo-select-small"
              value={selectedType}
              defaultValue="Select"
            >
              <MenuItem value="Select">
                <em style={{ fontStyle: "normal" }}>Select</em>
              </MenuItem>
              {ticConfigData &&
                ticConfigData.tic_configuration.map((tic) => {
                  return (
                    <MenuItem key={tic.id} value={tic.tic_type}>
                      {tic.tic_type}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row">
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  name="shipperReleaded"
                  onChange={releasedHandle}
                  checked={check}
                />
              }
              label="Released"
            />
          </Stack>

          <Button
            disabled={isEnable}
            onClick={handleCreateTic}
            sx={{
              width: "145px",
              height: "44px",
              borderRadius: "6px",
              padding: "0px, 20px, 0px, 20px",
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              backgroundColor: "#FFFFFF",
            }}
            data-testid="addbutton"
            color="primary"
            startIcon={<AddIcon />}
            style={{
              textTransform: "none",
              border: "1px solid #B1B2B4",
            }}
          >
            Create New
          </Button>
        </Stack>
      </Box>
      <ThemeProvider theme={customTheme}>
        <Stack
          pt={5}
          direction="row"
          spacing={7}
          px={3}
          pb={5}
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
          }}
        >
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Inner Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => innerLengthHandle(e)}
                        disabled={isTextEnabled}
                        value={innerLength.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerLength.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerLength.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onInput={(e) => innerWidthHandle(e)}
                        value={innerWidth.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerWidth.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerWidth.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => innerHeightHandle(e)}
                        disabled={isTextEnabled}
                        value={innerHeight.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerHeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerHeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Wet Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.weigtht}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetWeightHandle(e)}
                        disabled={isTextEnabled}
                        value={wetWeight?.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetWeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetWeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetCostHandle(e)}
                        disabled={isTextEnabled}
                        value={wetCost.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            $
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetCost.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetCost.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.topPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetTopPCMHandle(e)}
                        disabled={isTextEnabled}
                        value={wetTopPCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetTopPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetTopPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sidePCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetSidePCMHandle(e)}
                        disabled={isTextEnabled}
                        value={wetSidePCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetSidePCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetSidePCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.endPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetEndPCMHandle(e)}
                        disabled={isTextEnabled}
                        value={wetEndPCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetEndPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetEndPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Total PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.totalPCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      //focused={true}
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => wetTotalPCMHandle(e)}
                        disabled={isTextEnabled}
                        value={wetTotalPCM.name}
                        id="totalPCM"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "Total PCM",
                        }}
                      />
                      {wetTotalPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetTotalPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem p={0} m={0} />
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Environmental Impacts
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        CO2 Emissions
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.co2Emissions}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => co2Handle(e)}
                        disabled={isTextEnabled}
                        value={envCo2.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {envCo2.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {envCo2.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Landfill
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.landfill}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => landfillHandle(e)}
                        disabled={isTextEnabled}
                        value={envLandfill.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {envLandfill.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {envLandfill.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Multiwall Detail Definition
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.topWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => multiTopHandle(e)}
                        disabled={isTextEnabled}
                        value={multiTopWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiTopWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiTopWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sideWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => multiSideHandle(e)}
                        disabled={isTextEnabled}
                        value={multiSideWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiSideWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiSideWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.endWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={(e) => multiEndHandle(e)}
                        disabled={isTextEnabled}
                        value={multiEndWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiEndWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiEndWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>

      {open && shipperDictionary && (
        <CreateTicConfiguration
          open={open}
          setOpen={setOpen}
          shipperID={shipperDictionary.id}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default TicConfiguration;
