import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import NoRecordFound from "../../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import { scenarioData } from "../../Scenario/ScenarioSlice";
import { Route } from "react-router";
import { useHistory } from "react-router";
import Reports from "../../Reports";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import * as ConstantsList from "../../../../src/constants/";
import SnackBar from "../../core/SnackBar/SnackBar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const scenarioDetails = useSelector(scenarioData);
  const dispatch = useDispatch();

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric ? UnfoldMoreIcon : null}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const columns = [
  {
    id: "select",
    label: "",
    minWidth: 80,
    width: "5%",
    align: "center",
    numeric: false,
  },
  {
    id: "report_name",
    label: "Report Name",
    width: "15%",
    align: "left",
    numeric: true,
  },
  {
    id: "creation_date",
    label: "Creation Date",
    width: "13%",
    align: "left",
    numeric: true,
  },
  {
    id: "notes",
    label: "Notes",
    width: "25%",
    align: "left",
    numeric: true,
  },
  {
    id: "shared_with_customer",
    label: "Shared with Customer",
    width: "18%",
    align: "center",
    numeric: true,
  },

  {
    id: "viewdetails",
    label: "View Details",
    width: "-webkit-fill-available",
    align: "center",
    numeric: false,
  },
];

const ListView = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    data,
    search,
    setReportName,
    selectedReport,
    setSelectedReport,
    setNotify,
    setNotifyMsg,
    setReload,
    setSeverity,
  } = props;
  const [rowSelected, setRowSelected] = useState("");

  // const handleChange = (event, id, name, row) => setSelectedReport{
  //   if (rowSelected === id.toString()) {
  //     setRowSelected("");
  //   } else {
  //     setRowSelected(id.toString());
  //   }
  // };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("report_name");
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [selectedValue, setSelectedValue] = React.useState();

  //const isSelected = (name) => setSelectedReport.indexOf(name) !== -1;
  const isSelected = (name) => (selectedValue === name ? true : false);
  const filtered = search
    ? data.filter((user) => {
        return user.report_name === search.report_name;
      })
    : data;

  const handleChartView = (e, id, name) => {
    setTimeout(() => {
      history.push(
        "/dashboard/opportunity/scenario/reports/" + id + "/" + name
      );
    }, 2000);
  };

  const handleCustShared = (event, id, name, custStatus) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        report_id: id,
        shared_with_customer: !custStatus,
      }),
    };
    fetch(ConstantsList.API_URL + "summary/report_customer/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setSeverity(true);
            setNotify(true);
            setNotifyMsg(data.data);
            setReload(true);
          } else if (data.status_code === 400) {
            setSeverity(false);
            setNotify(true);
            setNotifyMsg(data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, id, name, row) => {
    if (id !== "0") {
      if (selectedValue === id) {
        setSelectedValue(id);
        setReportName(null);
        setSelectedReport(null);
      } else {
        setSelectedValue(id);
        setReportName(name);
        setSelectedReport({
          id: id,
          name: name,
          notes: row.notes,
          shared_with_customer: row.shared_with_customer,
        });
      }
    } else {
      setReportName(null);
      setSelectedReport(null);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
      data-testid="paper-container"
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
        data-testid="table-container"
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",

            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props?.data?.length}
          />
          <TableBody>
            {filtered?.length === 0 && (
              <NoRecordFound title="No result found" description="" />
            )}
            {filtered?.map((row, index) => {
              let selctedId = row.id;
              const isItemSelected = isSelected(row.id.toString());
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) =>
                    handleChange(event, row.id, row.report_name, row)
                  }
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover td:first-of-type ": {
                      borderLeft: "1px solid #F26322",
                      width: "10px",
                    },
                    "&:hover td:last-child ": {
                      borderRight: "1px solid #F26322",
                    },
                    "& .Mui-selected td:first-of-type ": {
                      borderLeft: "1px solid #F26322",
                    },
                    "&:hover td ": {
                      borderTop: "1px solid #F26322",
                      borderBottom: "1px solid #F26322",
                      backgroundColor: "#FFFFFF",
                    },
                    "&:selected": { backgroundColor: "#FFFFFF" },
                    "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                  }}
                >
                  {columns.map((column, i) => {
                    let value = row[column.id];

                    return i === 0 ? (
                      <TableCell
                        key={`Column-${row.id}-${i}`}
                        padding="checkbox"
                        align={column.align}
                        style={{
                          width: column.width,
                          minWidth: column.minWidth,
                          padding: 0,
                        }}
                      >
                        <Stack direction="row" p={0}>
                          <Box
                            sx={isItemSelected ? selectBg : noselectBg}
                          ></Box>
                          <ThemeProvider theme={checkboxTheme}>
                            {index !== 0 && (
                              <Radio
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id.toString(),
                                    row.report_name,
                                    row
                                  )
                                }
                                value={selctedId}
                                name={`checkbox-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            )}
                          </ThemeProvider>
                        </Stack>
                      </TableCell>
                    ) : i === 4 ? (
                      <TableCell
                        id={labelId}
                        key={`Column-${row.id}-${i}`}
                        align={column.align}
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#3F4042",
                          fontStyle: "normal",
                        }}
                        style={{ width: column.width }}
                      >
                        {row.shared_with_customer === false &&
                          row.report_name !== "Master Report" && (
                            <CircleOutlinedIcon
                              onClick={(event) =>
                                handleCustShared(
                                  event,
                                  row.id,
                                  row.report_name,
                                  row.shared_with_customer
                                )
                              }
                              sx={{
                                align: "left",
                                cursor: "pointer",
                                width: "20px",
                                height: "auto",
                                mr: 1,
                                color: "#B1B2B4",
                              }}
                              data-testid="circle-outlined-icon"
                            />
                          )}
                        {row.shared_with_customer === true &&
                          row.report_name !== "Master Report" && (
                            <TaskAltOutlinedIcon
                              onClick={(event) =>
                                handleCustShared(
                                  event,
                                  row.id,
                                  row.report_name,
                                  row.shared_with_customer
                                )
                              }
                              sx={{
                                align: "left",
                                cursor: "pointer",
                                width: "20px",
                                height: "auto",
                                mr: 1,
                                color: "#408515",
                              }}
                              data-testid="task-alt-outlined-icon"
                            />
                          )}
                      </TableCell>
                    ) : i === 5 ? (
                      <TableCell
                        id={labelId}
                        key={`Column-${row.id}-${i}`}
                        align={column.align}
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#3F4042",
                          fontStyle: "normal",
                        }}
                        style={{ width: column.width }}
                      >
                        <ChevronRightIcon
                          onClick={(event) =>
                            handleChartView(event, row.id, row.report_name)
                          }
                          sx={{ align: "center", cursor: "pointer" }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        id={labelId}
                        key={`Column-${row.id}-${i}`}
                        align={column.align}
                        sx={{
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#3F4042",
                          fontStyle: "normal",
                        }}
                        style={{ width: column.width }}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListView;
