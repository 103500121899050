import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AmbientProfile from "../AmbientProfile";
import GraphProfile from "../GraphProfile";

const mainTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
  marginBottom: "40px",
};

const subTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000000",
  margin: "40px 0 16px 0",
};

const tableContainerStyle = {
  "& .MuiTableCell-head": {
    border: 0,
  },
  "& .css-1ex1afd-MuiTableCell-root": {
    border: 0,
  },
};

const ButtonStyle = {
  width: "100%",
  height: "44px",
  backgroundColor: "#F26322",
  color: "#3F4042",
  textTransform: "none",
  "&:hover": { backgroundColor: "#F26322" },
  cursor: "pointer",
  margin: "40px 0",
};

const ViewGraph = ({ onClose, open, data, cycle, constant, constantData }) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        data-testid="dialog-outer-container"
      >
        <Box
          maxWidth="true"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            borderRadius: "6px",
            width: "900px",
          }}
          data-testid="box-outer-container"
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px", width: "100%" }}
            data-testid="stack-outer-container"
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
                data-testid="box-inner-container"
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-btn"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}

            <GraphProfile
              id={data}
              cycle={cycle}
              setShowAmbient={open}
              setShowGraph={onClose}
              constant={constant}
              constantData={constantData}
            />
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default ViewGraph;
