import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import * as ConstantsList from "../../../constants";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = (props) => {
  Chart.register(ChartDataLabels);
  const { ID, setData, barInit } = props;
  const [barRiskList, setBarRiskList] = useState([]);
  const [barChart, setBarChart] = useState(null);
  const [barChartDataset, setBarChartDataset] = useState([]);
  const [labelChart, setLableChart] = useState([]);

  useEffect(() => {
    if (ID) {
      fetch(ConstantsList.API_URL + "summary/bar_chart/" + ID)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setBarChart(data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [ID]);

  const removeDuplicateData = (arr) => {
    let unique = [];
    for (let i = 0; i < arr.length; i++) {
      if (unique.indexOf(arr[i]) === -1) {
        unique.push(arr[i]);
      }
    }
    return unique;
  };

  const RiskStack = {
    "No Risk": "Stack 0",
    "Low Risk": "Stack 1",
    "High Risk": "Stack 2",
    "Med Risk": "Stack 3",
  };

  const [selected, setSelected] = useState(barInit);
  useEffect(() => {
    if (barInit) {
      setSelected(barInit);
    }
  }, [barInit]);

  const getRiskData = (risk) => {
    let Data = [];
    for (let i = 0; i < barChart?.datasets.length; i++) {
      for (let j = 0; j < barChart?.datasets[i]?.set_data.length; j++) {
        if (barChart?.datasets[i]?.set_data[j]?.risk === risk) {
          let setSize = barChart?.datasets[i]?.set_data[j]?.set_size.toString();
          let dataPass = { x: setSize };
          for (
            let p = 0;
            p < barChart?.datasets[i]?.set_data[j]?.data.length;
            p++
          ) {
            let weigth =
              barChart?.datasets[i]?.set_data[j]?.data[p]?.total_shipped_weight;
            dataPass[p.toString()] = weigth;
          }
          Data.push(dataPass);
        }
      }
    }
    return Data;
  };

  const colorValues = [
    "rgba(245, 66, 209)",
    "rgba(224, 16, 40)",
    "rgba(242, 227, 10)",
    "rgba(14, 227, 31)",
    "rgba(27, 94, 209)",
    "#FAC50A",
    "#9AD9A7",
    "#DDD7D7",
  ];

  const barData = {
    labels: labelChart,
    datasets: barChartDataset,
  };

  const barConfig = {
    type: "bar",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "black",
        align: "top",
        offset: 2,
        rotation: -90,
        anchor: "end",
        font: { size: "7" },
        formatter: function (value, context) {
          // let datasetArray = [];
          // context.chart.data.datasets.forEach(
          //   (dataset) =>
          //     dataset.data[context.dataIndex] !== undefined &&
          //     datasetArray.push(dataset.data[context.dataIndex])
          // );

          switch (context.dataset.stack) {
            case "Stack 0":
              return `No Risk`;
            case "Stack 1":
              return `Low Risk`;
            case "Stack 2":
              return `High Risk`;
            case "Stack 3":
              return `Medium Risk`;
            default:
              return "";
          }
        },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Number of Shippers in Solution",
          color: "#000000",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Total Shipped Weight (kg)",
          color: "#000000",
        },
      },
    },
  };

  const isAllSelected =
    barRiskList.length > 0 && selected.length === barRiskList.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setSelected(selected.length === barRiskList.length ? [] : barRiskList);
      return;
    }
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
  };

  const checkBoxFont = {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#3F4042",
  };

  const listItem = barRiskList.map((option) => {
    return (
      <Stack key={option} direction="row" spacing={1} alignItems="center">
        <Checkbox
          value={option}
          onChange={handleChange}
          checked={selected.includes(option)}
          sx={{ padding: 0 }}
        />
        <Typography sx={checkBoxFont}>{option}</Typography>
      </Stack>
    );
  });

  const realData = (setSize, risk) => {
    return barChart?.datasets
      .find((dataset) => dataset?.set_size === setSize)
      ?.set_data.find((data) => data?.risk === risk)
      ?.data.map((payload, i) => {
        return {
          label: payload?.payload_name,
          data: getRiskData(risk),
          parsing: {
            yAxisKey: i.toString(),
          },
          stack: RiskStack[risk],
          backgroundColor: colorValues[i],
          barPercentage: 0.5,
          barThickness: 16,
          maxBarThickness: 8,
        };
      });
  };

  useEffect(() => {
    if (barChart?.datasets) {
      setLableChart(
        barChart?.datasets.map((data) => data?.set_size.toString())
      );

      const TempRisks = ["No Risk", "Low Risk", "Med Risk", "High Risk"];
      let TempRisk2 = [];
      TempRisks.forEach((RiskName) =>
        selected.forEach((risks) => risks === RiskName && TempRisk2.push(risks))
      );

      let Datasets = [];
      if (TempRisk2.length > 0) {
        TempRisk2.forEach((selected) => {
          return realData(1, selected)?.map((data) => Datasets.push(data));
        });
      }

      setBarChartDataset(Datasets);
      const Data = barChart?.datasets.map((sets) => {
        return sets?.set_data.map((data, i) => data?.risk);
      });

      let Risks = [];
      for (let k = 0; k < Data.length; k++) {
        for (let i = 0; i < Data[k].length; i++) {
          Risks.push(Data[k][i]);
        }
      }

      let TempRisk = [];
      TempRisks.forEach((RiskName) =>
        removeDuplicateData(Risks).forEach(
          (risks) => risks === RiskName && TempRisk.push(risks)
        )
      );
      setBarRiskList(TempRisk);
    }
  }, [barChart, selected]);

  useEffect(() => {
    let data = {
      no_risk: selected.find((data) => data === "No Risk") ? 1 : 0,
      low_risk: selected.find((data) => data === "Low Risk") ? 1 : 0,
      med_risk: selected.find((data) => data === "Med Risk") ? 1 : 0,
      high_risk: selected.find((data) => data === "High Risk") ? 1 : 0,
    };

    setData(data);
  }, [selected]);

  const [colorData, setColorData] = useState([]);

  useEffect(() => {
    if (barChartDataset) {
      let DER = [];
      barChartDataset.forEach((data) => {
        return DER.push(data?.label);
      });
      let DataS = [];
      removeDuplicateData(DER).forEach((data, i) =>
        DataS.push({ name: data, color: colorValues[i] })
      );

      setColorData(DataS);
    }
  }, [barChartDataset]);

  return (
    <Stack direction="column" pt={3} pb={3} id="barChartPrint">
      <Stack direction="column" spacing={4}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Checkbox
              value="all"
              onChange={handleChange}
              checked={isAllSelected}
              sx={{ padding: 0 }}
            />
            <Typography sx={checkBoxFont}>All</Typography>
          </Stack>
          {listItem}
        </Stack>

        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "26px",
              color: "#235984",
              fontWeight: "700",
              lineHeight: "32px",
            }}
          >
            Bar Chart for number of Shippers in Solution Vs Total Shipped Weight
            (kg)
          </Typography>
          <Stack direction="row" spacing={1}>
            {colorData &&
              colorData.map((data, i) => {
                return (
                  <React.Fragment key={`Stack-${i}`}>
                    {
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Box
                          sx={{
                            backgroundColor: data?.color,
                            width: "17.25px",
                            height: "17.25px",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            color: "#000000",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {data.name}
                        </Typography>
                      </Stack>
                    }
                  </React.Fragment>
                );
              })}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
      >
        <Stack
          pt={10}
          sx={{
            width: "890.23px",
            height: "514.68px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bar options={barConfig} data={barData} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BarChart;
