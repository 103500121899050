import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Radio,
  Checkbox,
  Stack,
  ThemeProvider,
  Typography,
  Grid,
  ButtonGroup,
} from "@mui/material";

import ProductImg from "../../assets/images/product_line.svg";
import LaunchIcon from "@mui/icons-material/Launch";

import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../core/Loader/Loader";
import { useHistory, redirect } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ListView from "./Report/ListView";
import EditRecord from "../../components/Reports/SaveReport/Edit";
import AddCustomers from "../../components/Customers/AddCustomers";

import { scenarioData } from "./ScenarioSlice";

const ReportScenario = (props) => {
  const { setOptimizeClicked, optimizeClicked } = props;
  const [open, setOpen] = useState(false);
  const scenarioDetails = useSelector(scenarioData);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState(null);
  // Search Users
  const [listDataSearch, setListDataSearch] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const [cdeditopen, setCdeditopen] = React.useState(false);
  const [severity, setSeverity] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  //const isReload = cddelopen || cdopen;

  const mainContainer = {
    width: "100%",
    backgroundColor: "#ffffff",
  };
  const buttonContainerStyle = {
    alignItems: "flex-end",
  };

  const ButtonStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize",
    padding: "10px 20px",
    width: "290px",
    boxSizing: "border-box",
    border: "1px solid #B1B2B4",
    borderRadius: "6px",
    color: "#000000",
  };

  const listTextStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000",
  };

  const tableStyle = {
    "&.MuiTable-root td": {
      padding: "0.75rem 1.875rem 0.75rem 0",
      border: "0",
    },
    "&.MuiTable-root td:fist-child": {
      width: "60%",
    },
    "&.MuiTable-root td:last-child": {
      width: "150px",
    },
    "& .MuiFormControl-root > .MuiInputBase-root > .MuiSelect-select": {
      padding: "0.75rem",
      borderRadius: "6px",
      border: "1px solid #E6E7E8",
    },
  };

  const binTableStyle = {
    marginTop: "40px",
    "& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root":
      {
        border: "0",
        textAlign: "center",
        width: "40px",
        padding: "0 5px",
        verticalAlign: "bottom",
      },
    "& .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root":
      {
        width: "49px",
        height: "44px",
        marginBottom: "15px",
      },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root":
      {
        borderRadius: "8px",
        border: "1px solid #348DBA",
        width: "49px",
        height: "44px",
      },
  };

  const textFieldStyle = {
    "& .css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root input": {
      padding: "8px 12px",
      width: "107px",
      height: "30px",
    },
  };

  const labelStyle = {
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#3F4042",
    marginBottom: "15px",
  };

  // Theme styles here
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
      }),
    };
    fetch(ConstantsList.API_URL + "summary/report-scenario/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setListData(data);
          }
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
    setReload(false);
  }, [loading, scenarioDetails?.scenario?.id, reload]);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (listData) {
      let dataa = [
        {
          id: "0",
          report_name: "Master Report",
          creation_date: "-",
          notes: "Master Report generated",
          shared_with_customer: false,
          download_report: " ",
        },
      ];

      listData?.data.map((data) => {
        return dataa.push({
          id: data?.id,
          report_name: data?.report_name,
          creation_date: data?.created_on,
          notes: data?.notes,
          shared_with_customer: data?.shared_with_customer,
          download_report: "",
        });
      });
      setDataList(dataa);
    }
  }, [listData]);

  const handleClose = () => {
    setOpen(false);
  };

  const [reportName, setReportName] = useState({});
  const [selectedReport, setSelectedReport] = useState(null);

  const handleCdOpen = () => {
    setCdopen(true);
  };

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const handleCdeditOpen = () => {
    setCdeditopen(true);
  };

  const handleCdeditClose = (value) => {
    setCdeditopen(false);
  };
  const isEdit = selectedReport !== null ? false : true;

  //const isEnabled = selectedReport.length > 0 ? false : true;
  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            pt: 3,
            pl: 3,
            pr: 3,
          }}
        >
          Report Templates
        </Typography>
        <Box sx={{}}>
          <ThemeProvider theme={theme}>
            <ButtonGroup
              variant="outlined"
              aria-label="outlined button group"
              sx={{
                borderColor: "#000000",
                color: "#000000",
                p: 3,
              }}
            >
              <Button
                onClick={handleCdeditOpen}
                data-testid="editbutton"
                disabled={isEdit}
                startIcon={<EditIcon />}
                style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
              >
                Edit
              </Button>

              {/* <Button
                onClick={handleCdOpen}
                data-testid="addbutton"
                color="primary"
                startIcon={<AddIcon />}
                style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
              >
                Add Report
              </Button> */}
            </ButtonGroup>
          </ThemeProvider>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {" "}
      </Box>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      {listData && (
        <ListView
          data={dataList}
          setReportName={setReportName}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          search={listDataSearch !== null ? listDataSearch : false}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
          setSeverity={setSeverity}
        />
      )}

      {cdeditopen && (
        <EditRecord
          open={cdeditopen}
          onClose={handleCdeditClose}
          setReportName={setReportName}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
          setSeverity={setSeverity}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default ReportScenario;
