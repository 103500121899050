import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

import {
  Paper,
  Box,
  Typography,
  Stack,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableSortLabel,
  Checkbox,
  TextField,
  Grid,
  Radio,
} from "@mui/material";

import { FileUploadOutlined, ClearOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { scenarioData } from "../ScenarioSlice";
import { useSelector } from "react-redux";
import lwh3d from "../../../assets/images/3D-LWH.svg";
import wlh3d from "../../../assets/images/3D-WLH.svg";
import lhw3d from "../../../assets/images/3D-LHW.svg";
import hlw3d from "../../../assets/images/3D-HLW.svg";
import whl3d from "../../../assets/images/3D-WHL.svg";
import hwl3d from "../../../assets/images/3D-HWL.svg";
import upto2 from "../../../assets/images/3D-upto2.svg";
import upto3 from "../../../assets/images/3D-upto3.svg";
import * as ConstantsList from "../../../../src/constants/";
import * as ADAConfig from "../../../../src/config/ADAConfig";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { visuallyHidden } from "@mui/utils";
import NoRecordFound from "../../core/NoRecordFound/NoRecordFound";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

const mainTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
  marginBottom: "40px",
};

const errorTitle = {
  fontSize: "12px",
  color: "red",
  marginBottom: "5px",
  paddingLeft: "3px",
};

const subTitle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000000",
  margin: "0 0 24px 0",
};

const buttonContainerStyle = {
  alignItems: "flex-end",
};

const modelContainerStyle = {
  "& .MuiPaper-root": {
    width: "1140px",
  },
};

const boxStyle = {
  border: "1px solid #CBCCCD",
  borderRadius: "8px",
  width: "160px",
  height: "200px",
  position: "relative",
};

const boxImagestyle = {
  height: "150px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
};

const boxFooterStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "40px",
  background: "#F8F8F9",
  height: "40px",
  alignItems: "center",
  borderRadius: "0 0 8px 8px",
  position: "absolute",
  width: "100%",
  bottom: "0",
  "&:hover": {
    background: "#CBE3F4",
  },
};

const errorStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "red",
};

const ShipmentDetail = ({ open, onClose, rows, setRows, inValue }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const scenarioDetails = useSelector(scenarioData);
  const [eshdMeltCheck, setESHDMeltCheck] = useState(true);
  const [selectedCheckboxLen, setSelectedCheckboxLen] = useState(
    rows[inValue]?.orderprofile?.orientation.length > 0 ? 0 : 6
  );

  const [selectedOrderData, setSelectedOrderData] = useState([]);
  const [payloadId, setPayloadId] = useState(
    rows[inValue]?.payload?.id ? rows[inValue].payload.id : ""
  );

  const [norecord, setNorecord] = useState([
    {
      title: "No opportunities yet",
      description: "",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchuserData, setSearchUserData] = useState(null);
  const [search, setSearch] = useState(false);

  const [ori, setOri] = useState(
    rows[inValue]?.orderprofile?.orientation.length > 0
      ? rows[inValue]?.orderprofile?.orientation
      : [1, 2, 3, 4, 5, 6, 7, 8]
  );
  const DataValue = {
    payload: "",
    shipment: "",
  };
  const [orderRows, setOrderRows] = useState([]);

  const [formMeltValues, setFormMeltValues] = useState(
    rows[inValue]?.orderprofile?.orderprofile.length > 0
      ? rows[inValue]?.orderprofile?.orderprofile.map((data) => data)
      : [{ payload: "", shipment: "" }]
  );
  const [errorStatus, setErrorStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isDuplicatePayQty, setIsDuplicatePayQty] = useState(false);
  const [importProfile, setImportProfile] = useState(false);
  const [importProfileData, setImportProfileData] = useState([]);
  const handleClose = () => {
    setImportProfile(false);
    onClose(false);
  };

  const handleImportCancel = () => {
    setImportProfile(false);
  };
  const handleImportSave = () => {
    if (selectedOrderData.length > 0) {
      setFormMeltValues(selectedOrderData);
      setImportProfile(false);
    }
  };

  let removeFormFields = (eshdType, i) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      let newFormMeltValues = [...formMeltValues];
      newFormMeltValues.splice(i, 1);
      setFormMeltValues(newFormMeltValues);
    }
  };

  const addFormFields = (e, eshdType) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      setFormMeltValues([...formMeltValues, { payload: "", shipment: "" }]);
    }
  };

  const onChangeOrderProfile = (event, index) => {
    const { value, name } = event.target;

    const re = /^[0-9.]+$/;

    if (value === "" || re.test(value)) {
      const newRows = [...formMeltValues];
      newRows[index] = { ...newRows[index], [name]: value };
      setFormMeltValues(newRows);
    }
    setErrorMsg("");
  };

  const handleBlur = (event, index, fieldName) => {
    const { value, name } = event.target;
    const newRows = [...formMeltValues];
    if (fieldName === "payload") {
      let valLenght = 0;

      newRows.forEach((rowVal, rowIndex) => {
        if (rowVal.payload === value) {
          valLenght = valLenght + 1;
        }
      });
      if (valLenght === 2) {
        newRows[index][name] = "";
        setFormMeltValues(newRows);
        setIsDuplicatePayQty(true);
      } else {
        setIsDuplicatePayQty(false);
      }
    }
  };

  const handleImport = (event) => {
    setImportProfile(!importProfile);
  };

  const handleTest = (e, id) => {
    const selectedIndex = ori.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(ori, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(ori.slice(1));
    } else if (selectedIndex === ori.length - 1) {
      newSelected = newSelected.concat(ori.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        ori.slice(0, selectedIndex),
        ori.slice(selectedIndex + 1)
      );
    }
    setOri(newSelected);
    setCheckUpto(newSelected);
  };

  const setCheckUpto = (arrayVal) => {
    let checkLen = 0;
    let posOnly = [1, 2, 3, 4, 5, 6];
    arrayVal.map((val, index) => {
      return posOnly.includes(val) ? (checkLen = checkLen + 1) : checkLen;
    });
    setSelectedCheckboxLen(checkLen);
  };

  useEffect(() => {
    let dataLen = ori;
    if (selectedCheckboxLen > 0) {
      if (selectedCheckboxLen < 2) {
        dataLen = dataLen.filter((item) => item !== 7);
      }
      if (selectedCheckboxLen < 3) {
        dataLen = dataLen.filter((item) => item !== 8);
      }
    }
    setOri(dataLen);
  }, [selectedCheckboxLen]);

  const isSelected = (name) => {
    return ori.indexOf(parseInt(name)) !== -1;
  };

  const handleSave = () => {
    let DataValue = true;
    for (let j = 0; j < formMeltValues.length; j++) {
      if (
        formMeltValues[j]?.payload.length > 0 &&
        formMeltValues[j]?.shipment.length > 0
      ) {
      } else {
        DataValue = false;
      }
    }

    if (DataValue && ori.length !== 0) {
      const newRows = [...rows];
      newRows[inValue] = {
        ...newRows[inValue],
        orderprofile: {
          ...newRows[inValue].orderprofile,
          orientation: ori,
          orderprofile: formMeltValues,
        },
      };
      setRows(newRows);
      onClose(false);
    } else {
      setErrorMsg("Please fill all the required fields");
    }
  };

  useEffect(() => {
    setCheckUpto(ori);
  }, [ori]);

  useEffect(() => {
    var raw = JSON.stringify({
      payload_id: payloadId,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(
      ConstantsList.API_URL + "scenario/import-order-profile/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setImportProfileData(data?.data);
          } else if (data.status_code === 400) {
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~//

  const columns = [
    {
      id: "select",
      label: "",
      minWidth: 80,
      width: "7%",
      align: "center",
      numeric: false,
    },
    {
      id: "opportunity_name",
      label: "Opportunity Name",
      minWidth: 200,
      width: "20%",
      align: "left",
      numeric: false,
    },
    {
      id: "scenario_name",
      label: "Scenario",
      minWidth: 200,
      width: "20%",
      align: "left",
      numeric: false,
    },
    {
      id: "updated_on",
      label: "Last Edited Date",
      minWidth: 200,
      width: "15%",
      align: "left",
      numeric: true,
    },

    // {
    //   id: "view_details",
    //   label: "View Details",
    //   width: "-webkit-fill-available",
    //   minWidth: "-webkit-fill-available",
    //   align: "center",
    //   numeric: false,
    // },
  ];

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updated_on");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [selectedValue, setSelectedValue] = React.useState();

  const [selected, setSelected] = useState([]);
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {}
          {columns.map((headCell) => (
            <TableCell
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#000000",
                fontStyle: "normal",
                backgroundColor: "#F2F2F3",
              }}
              align={headCell.align}
              style={{ width: headCell.width, minWidth: headCell.minWidth }}
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.numeric ? (
                <TableSortLabel
                  sx={{
                    "& MuiTableSortLabel-icon": { opacity: 1 },
                    "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                      opacity: 1,
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "#000000 !important",
                    },
                  }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={headCell.numeric ? UnfoldMoreIcon : false}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel
                  sx={{
                    "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                    "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                      opacity: 1,
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "#000000 !important",
                      display: "none",
                    },
                  }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const isRowSelected = (name) => (selectedValue === name ? true : false);

  const filtered =
    searchuserData && importProfileData.length > 0
      ? importProfileData.filter((user) => {
          return user.opportunity_name === search.opportunity_name;
        })
      : importProfileData;

  const handleClick = (
    e,
    id,
    opportunity_name,
    scenario_name,
    updated_on,
    view_details
  ) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      setSelectedItem(null);
    } else {
      setSelectedValue(id);
      setSelectedItem({
        id: id,
        opportunity_name: opportunity_name,
        updated_on: updated_on,
        view_details: view_details,
      });

      setSelectedOrderData(view_details);
    }
  };

  const viewDetails = (
    event,
    scenario_id,
    opportunity_name,
    scenario_name,
    updated_on,
    view_details
  ) => {
    setSelectedOrderData(view_details);
  };

  return (
    <div>
      {importProfile === false ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xl"
          sx={modelContainerStyle}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              direction: "row",
              borderRadius: "6px",
              width: "100%",
            }}
          >
            <Stack
              spacing={0}
              p={3}
              direction="column"
              sx={{ padding: "0", width: "100%" }}
            >
              {open ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      width: "12px",
                      height: "12px",
                      justifyContent: "flex-end",
                      padding: 0,
                      color: "#000000",
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                    }}
                    data-testid="icon-btn"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : null}

              <Stack direction="row" sx={{ minHeight: "200px" }}>
                <Stack
                  sx={{ width: "800px", padding: "40px 24px 24px 24px" }}
                  direction="column"
                >
                  <Typography component="h3" variant="h3" sx={mainTitle}>
                    Shipment Detail
                  </Typography>

                  <Typography
                    sx={subTitle}
                    component="h3"
                    variant="h3"
                    style={{ padding: "0", margin: "0" }}
                  >
                    Select allowable payload orientation(s):
                  </Typography>

                  <Box
                    component="div"
                    sx={{
                      marginTop: "24px",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "16px",
                    }}
                  >
                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("1")}
                          value={ori}
                          onChange={(e) => handleTest(e, 1)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${lwh3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>L x W x H</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("2")}
                          value={ori}
                          onChange={(e) => handleTest(e, 2)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${wlh3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>W x L x H</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("3")}
                          value={ori}
                          onChange={(e) => handleTest(e, 3)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${lhw3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>L x H x W</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("4")}
                          value={ori}
                          onChange={(e) => handleTest(e, 4)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${hlw3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>H x L x W</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("5")}
                          value={ori}
                          onChange={(e) => handleTest(e, 5)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${whl3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>W x H x L</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("6")}
                          value={ori}
                          onChange={(e) => handleTest(e, 6)}
                          name="orientationCheck"
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${hwl3d})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>H x W x L</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("7")}
                          value={ori}
                          onChange={(e) => handleTest(e, 7)}
                          name="orientationCheck2"
                          disabled={selectedCheckboxLen >= 2 ? "" : "disabled"}
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${upto2})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>Up to 2</Stack>
                    </Box>

                    <Box component="div" sx={boxStyle}>
                      <Stack sx={{ alignItems: "flex-end" }}>
                        {" "}
                        <Checkbox
                          defaultChecked={false}
                          checked={isSelected("8")}
                          value={ori}
                          onChange={(e) => handleTest(e, 8)}
                          name="orientationCheck3"
                          disabled={selectedCheckboxLen > 2 ? "" : "disabled"}
                        />{" "}
                      </Stack>
                      <Box
                        component="div"
                        style={{ backgroundImage: `url(${upto3})` }}
                        sx={boxImagestyle}
                      ></Box>
                      <Stack sx={boxFooterStyle}>Up to 3</Stack>
                    </Box>
                  </Box>
                </Stack>

                <Stack
                  sx={{
                    width: "40%",
                    borderLeft: "1px solid #CBCCCD",
                    padding: "40px 24px 0 24px",
                  }}
                  direction="column"
                >
                  <Typography component="h3" variant="h3" sx={mainTitle}>
                    Order Profile
                  </Typography>
                  <Typography sx={{ color: "red" }}>{errorMsg}</Typography>
                  <Typography component="span" variant="span" sx={errorTitle}>
                    {isDuplicatePayQty &&
                      "One or more payload quantity values are same"}
                  </Typography>

                  <Stack>
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          width: "40px",
                        }}
                      >
                        &nbsp;
                      </Grid>
                      <Grid item xs={5} sx={{ p: 1 }}>
                        Payload Qty
                      </Grid>
                      <Grid item xs={5} sx={{ p: 1 }}>
                        Shipment Qty
                      </Grid>
                      <Grid item xs={1} sx={{ p: 1 }}>
                        &nbsp;
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      sx={{ maxHeight: "420px", overflowY: "auto" }}
                    >
                      <Grid container>
                        {formMeltValues.map((element, index) => {
                          return (
                            <Grid
                              container="row"
                              sx={{ background: "#fff", marginBottom: "8px" }}
                              key={index}
                            >
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {index + 1}
                              </Grid>

                              <Grid item xs={5}>
                                <FormControl>
                                  <TextField
                                    name="payload"
                                    value={element["payload"]}
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "118px" }}
                                    onChange={(e) =>
                                      onChangeOrderProfile(e, index)
                                    }
                                    onBlur={(e) =>
                                      handleBlur(e, index, "payload")
                                    }
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={5}>
                                <FormControl>
                                  <TextField
                                    name="shipment"
                                    value={element["shipment"]}
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "118px" }}
                                    onChange={(e) =>
                                      onChangeOrderProfile(e, index)
                                    }
                                  />
                                </FormControl>
                              </Grid>

                              <Grid item xs={1} sx={{ display: "flex" }}>
                                <Box
                                  component="div"
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  {formMeltValues.length - 1 !== index ? (
                                    <CloseIcon
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        removeFormFields("Melt", index)
                                      }
                                    ></CloseIcon>
                                  ) : (
                                    <AddIcon
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => addFormFields(e, "Melt")}
                                    ></AddIcon>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>

                      {errorStatus.eshdMelt && (
                        <Typography component="h6" variant="h6" sx={errorStyle}>
                          {errorMessage.eshdMelt}
                        </Typography>
                      )}
                    </Grid>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          {(scenarioDetails?.status === "Optimization Pending" ||
            scenarioDetails?.status === "Optimization Failed" ||
            scenarioDetails?.status === "Optimization Cancelled") && (
            <Stack
              direction="row"
              spacing={1}
              sx={buttonContainerStyle}
              style={{
                justifyContent: "flex-end",
                padding: "24px",
                borderTop: "1px solid #CBCCCD",
              }}
            >
              {payloadId !== "" && (
                <Button
                  sx={{
                    width: "213px",
                    height: "44px",
                    borderRadius: "6px",
                    padding: "0px, 20px, 0px, 20px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    backgroundColor: "#FFFFFF",
                  }}
                  data-testid="import-profile-btn"
                  startIcon={<FileUploadOutlined />}
                  style={{
                    textTransform: "none",
                    border: "1px solid #B1B2B4",
                  }}
                  onClick={(e) => handleImport(e)}
                >
                  Import Order Profile
                </Button>
              )}
              <Button
                sx={{
                  width: "145px",
                  height: "44px",
                  borderRadius: "6px",
                  padding: "0px, 20px, 0px, 20px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#FFFFFF",
                }}
                data-testid="cancelbutton"
                startIcon={<ClearOutlined />}
                style={{
                  textTransform: "none",
                  border: "1px solid #B1B2B4",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                sx={{
                  width: "180px",
                  height: "44px",
                  borderRadius: "6px",
                  padding: "0px, 20px, 0px, 20px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#F26322",
                  "&:disabled": {
                    opacity: "0.3",
                  },
                }}
                data-testid="addbutton"
                color="primary"
                style={{
                  textTransform: "none",
                  border: "1px solid #F26322",
                }}
              >
                Save
              </Button>
            </Stack>
          )}
        </Dialog>
      ) : (
        // Import Order Profile
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xl"
          sx={modelContainerStyle}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              direction: "row",
              borderRadius: "6px",
              width: "100%",
            }}
          >
            <Stack
              spacing={0}
              p={3}
              direction="column"
              sx={{ padding: "0", width: "100%" }}
            >
              {open ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      width: "12px",
                      height: "12px",
                      justifyContent: "flex-end",
                      padding: 0,
                      color: "#000000",
                      position: "absolute",
                      right: "20px",
                      top: "20px",
                    }}
                    data-testid="icon-btn"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : null}

              <Stack direction="row" sx={{ minHeight: "200px" }}>
                <Stack
                  sx={{ width: "100%", padding: "40px 24px 24px 24px" }}
                  direction="column"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h2"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "32px",
                        lineHeight: "30px",
                        color: "#000000",
                        pt: 3,
                        pl: 3,
                        pr: 3,
                        pb: 3,
                      }}
                    >
                      Import Order Profile
                    </Typography>
                    <Box sx={{ pb: 0 }}>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={buttonContainerStyle}
                        style={{
                          justifyContent: "flex-end",
                          padding: "24px",
                        }}
                      >
                        <Button
                          sx={{
                            width: "auto",
                            height: "44px",
                            borderRadius: "6px",
                            padding: "0px, 20px, 0px, 20px",
                            fontFamily: "Roboto",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                            backgroundColor: "#3F4042",
                          }}
                          data-testid="actbutton"
                          style={{
                            textTransform: "none",
                            border: "1px solid #B1B2B4",
                          }}
                        >
                          <b>Importing to : </b>{" "}
                          {rows[inValue]?.payload?.name
                            ? rows[inValue].payload.name
                            : ""}
                        </Button>
                        <Button
                          sx={{
                            width: "145px",
                            height: "44px",
                            borderRadius: "6px",
                            padding: "0px, 20px, 0px, 20px",
                            fontFamily: "Roboto",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            backgroundColor: "#FFFFFF",
                          }}
                          data-testid="cancelbutton"
                          startIcon={<ClearOutlined />}
                          style={{
                            textTransform: "none",
                            border: "1px solid #B1B2B4",
                          }}
                          onClick={handleImportCancel}
                        >
                          Cancel
                        </Button>
                        {importProfileData?.length > 0 &&
                          selectedValue?.length > 0 && (
                            <Button
                              onClick={handleImportSave}
                              sx={{
                                width: "180px",
                                height: "44px",
                                borderRadius: "6px",
                                padding: "0px, 20px, 0px, 20px",
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                backgroundColor: "#F26322",
                                "&:disabled": {
                                  opacity: "0.3",
                                },
                              }}
                              data-testid="addbutton"
                              color="primary"
                              style={{
                                textTransform: "none",
                                border: "1px solid #F26322",
                              }}
                            >
                              Confirm
                            </Button>
                          )}
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <Typography
                      component="h6"
                      variant="h6"
                      sx={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: "22px",
                        color: "#000000",
                        pt: 3,
                        pl: 3,
                        pr: 3,
                      }}
                    >
                      Select the scenario from which you want to import the
                      Order Profile
                    </Typography>
                  </Box>

                  <Box
                    component="div"
                    sx={{
                      marginTop: "24px",
                      // display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "16px",
                    }}
                  >
                    {/* ~~~~~~~~~~~~~~ Table  Start ~~~~~~~~~~~~~~ */}
                    <Paper
                      elevation={0}
                      sx={{
                        overflow: "hidden",
                        borderRadius: 0,
                      }}
                    >
                      <TableContainer
                        sx={{
                          maxHeight: "auto",
                          "&::-webkit-scrollbar": {
                            width: 5,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "none",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#98A2B3",
                            borderRadius: 8,
                          },
                        }}
                      >
                        <Table
                          data-testid="userlisttable"
                          stickyHeader
                          aria-label="sticky table"
                          sx={{
                            borderSpacing: "0 0",
                            height: "max-content",
                            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
                              {
                                backgroundColor: "#FFFFFF",
                                cursor: "pointer",
                                border: 1,
                              },
                            "& .MuiTableRow-root": {
                              border: 1,
                              backgroundColor: "#FFFFFF",
                            },
                            "& .Mui-selected": {
                              borderWidth: "1px",
                              backgroundColor: "#FFFFFF",
                            },
                            "& .Mui-selected td": {
                              borderTop: "1px solid #F26322",
                              borderBottom: "1px solid #F26322",
                              backgroundColor: "#FFFFFF",
                            },
                            "& .Mui-selected td:first-of-type ": {
                              borderLeft: "1px solid #F26322",
                            },
                            "& .Mui-selected td:last-child ": {
                              borderRight: "1px solid #F26322",
                            },

                            tableLayout: "fixed",
                            width: "100%",
                          }}
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                          />
                          <TableBody>
                            {importProfileData.length !== 0 ? (
                              filtered.length === 0 ? (
                                <NoRecordFound
                                  count={columns.length}
                                  title={"No result found"}
                                  description={norecord.description}
                                />
                              ) : (
                                <></>
                              )
                            ) : (
                              <NoRecordFound
                                count={columns.length}
                                title={"No payload added yet"}
                                description={norecord.description}
                              />
                            )}
                            {stableSort(
                              filtered,
                              getComparator(order, orderBy)
                            ).map((row, index) => {
                              const isItemSelected = isRowSelected(
                                row.scenario_id.toString()
                              );
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) =>
                                    handleClick(
                                      event,
                                      row.scenario_id.toString(),
                                      row.opportunity_name,
                                      row.scenario_name,
                                      row.updated_on,
                                      row.view_details
                                    )
                                  }
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.scenario_id}
                                  selected={isItemSelected}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    "&:hover td:first-of-type ": {
                                      borderLeft: "1px solid #F26322",
                                      width: "10px",
                                    },
                                    "&:hover td:last-child ": {
                                      borderRight: "1px solid #F26322",
                                    },
                                    "& .Mui-selected td:first-of-type ": {
                                      borderLeft: "1px solid #F26322",
                                    },
                                    "&:hover td ": {
                                      borderTop: "1px solid #F26322",
                                      borderBottom: "1px solid #F26322",
                                      backgroundColor: "#FFFFFF",
                                    },
                                    "&:selected": {
                                      backgroundColor: "#FFFFFF",
                                    },
                                    "& .css-1nf681w-MuiTableCell-root": {
                                      padding: 0,
                                    },
                                  }}
                                  aria-label={
                                    ADAConfig.ADA.Opportunities.selectedRow
                                  }
                                >
                                  {columns.map((bodyCell, i) => {
                                    let value =
                                      bodyCell.id === "scenario_name"
                                        ? row.scenario_name
                                        : row[bodyCell.id];

                                    return i === 0 ? (
                                      <TableCell
                                        padding="checkbox"
                                        align={bodyCell.align}
                                        style={{
                                          padding: 0,
                                          width: bodyCell.width,
                                          minWidth: bodyCell.minWidth,
                                        }}
                                      >
                                        <Stack direction="row">
                                          <Box
                                            sx={
                                              isItemSelected
                                                ? selectBg
                                                : noselectBg
                                            }
                                          ></Box>
                                          <ThemeProvider theme={checkboxTheme}>
                                            <Radio
                                              checked={
                                                selectedValue ===
                                                row.scenario_id.toString()
                                              }
                                              value={row.scenario_id.toString()}
                                              name={`radio-${row.scenario_id}`}
                                              inputProps={{ "aria-label": "A" }}
                                              onClick={(event) =>
                                                viewDetails(
                                                  event,
                                                  row.scenario_id,
                                                  row.opportunity_name,
                                                  row.scenario_name,
                                                  row.updated_on,
                                                  row.view_details
                                                )
                                              }
                                            />
                                          </ThemeProvider>
                                        </Stack>
                                      </TableCell>
                                    ) : //  : i === 4 ? (
                                    //   <TableCell
                                    //     id={labelId}
                                    //     key={i}
                                    //     sx={{
                                    //       fontWeight: "400",
                                    //       fontSize: "16px",
                                    //       lineHeight: "24px",
                                    //       color: "#3F4042",
                                    //       fontStyle: "normal",
                                    //     }}
                                    //     align={bodyCell.align}
                                    //     style={{
                                    //       width: bodyCell.width,
                                    //       minWidth: bodyCell.minWidth,
                                    //     }}
                                    //   >
                                    //     <Link
                                    //       underline="none"
                                    //       to="/dashboard/opportunity/scenario-list"
                                    //     >
                                    //       <ChevronRightIcon
                                    //         sx={{
                                    //           align: "center",
                                    //           cursor: "pointer",
                                    //           color: "#000",
                                    //         }}
                                    //         onClick={(event) =>
                                    //           viewDetails(
                                    //             event,
                                    //             row.scenario_id,
                                    //             row.opportunity_name,
                                    //             row.updated_on,
                                    //             row.scenario_name
                                    //           )
                                    //         }
                                    //         aria-label={
                                    //           ADAConfig.ADA.Opportunities
                                    //             .viewDetailsButton
                                    //         }
                                    //       />
                                    //     </Link>
                                    //   </TableCell>
                                    // )
                                    i === 1 ? (
                                      <TableCell
                                        id={labelId}
                                        key={i}
                                        sx={{
                                          fontWeight: "400",
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                          color: "#3F4042",
                                          fontStyle: "normal",
                                        }}
                                        align={bodyCell.align}
                                        style={{
                                          width: bodyCell.width,
                                          minWidth: bodyCell.minWidth,
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          justifyContent="flex-start"
                                          alignItems="center"
                                        >
                                          {value}
                                        </Stack>
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        id={labelId}
                                        key={i}
                                        sx={{
                                          fontWeight: "400",
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                          color: "#3F4042",
                                          fontStyle: "normal",
                                        }}
                                        align={bodyCell.align}
                                        style={{
                                          width: bodyCell.width,
                                          minWidth: bodyCell.minWidth,
                                        }}
                                      >
                                        {value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    {/* ~~~~~~~~~~~~~~ Table  End ~~~~~~~~~~~~~~ */}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default ShipmentDetail;
