import {
  Box,
  createTheme,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";
import * as Config from "../../../config";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const Insulation = (props) => {
  const enableData = true;
  const shipperDetails = useSelector(shipperDetailsData);
  const shipperClassPCM = shipperDetails?.shipperclass?.insulation;

  const shipperClassPCMInsEnd = shipperDetails?.shipperclass?.insulation?.end;
  const shipperClassPCMInsSide = shipperDetails?.shipperclass?.insulation?.side;
  const shipperClassPCMInsTop = shipperDetails?.shipperclass?.insulation?.top;

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [thicknessTop, setThicknessTop] = useState({
    name: shipperClassPCM?.top?.thickness,
    error: false,
    message: "",
  });

  const [thicknessSide, setThicknessSide] = useState({
    name: shipperClassPCM?.side?.thickness,
    error: false,
    message: "",
  });

  const [thicknessEnd, setThicknessEnd] = useState({
    name: shipperClassPCM?.end?.thickness,
    error: false,
    message: "",
  });

  const [heatTop, setHeatTop] = useState({
    name: shipperClassPCM?.top?.specific_heat,
    error: false,
    message: "",
  });

  const [heatSide, setHeatSide] = useState({
    name: shipperClassPCM?.side?.specific_heat,
    error: false,
    message: "",
  });

  const [heatEnd, setHeatEnd] = useState({
    name: shipperClassPCM?.end?.specific_heat,
    error: false,
    message: "",
  });

  const [densityTop, setDensityTop] = useState({
    name: shipperClassPCM?.top?.density,
    error: false,
    message: "",
  });

  const [densitySide, setDensitySide] = useState({
    name: shipperClassPCM?.side?.density,
    error: false,
    message: "",
  });

  const [densityEnd, setDensityEnd] = useState({
    name: shipperClassPCM?.end?.density,
    error: false,
    message: "",
  });

  const thicknessTopHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setThicknessTop({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const thicknessSideHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setThicknessSide({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const thicknessEndHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setThicknessEnd({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const heatTopHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setHeatTop({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const heatSideHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setHeatSide({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const heatEndHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setHeatEnd({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const densityTopHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setDensityTop({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const densitySideHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setDensitySide({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  const densityEndHandle = (e) => {
    let value = e.target.value;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      setDensityEnd({
        name: value,
        error: false,
        message: "",
      });
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box maxWidth="false" sx={{ width: "100%", display: "flex" }}>
        <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
          <Typography
            component="h6"
            variant="h6"
            sx={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "22px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            Multiwall Detail Definition
          </Typography>
          <Stack direction="column" sx={{ width: "100%" }}>
            {/* Head Row */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#F2F2F3",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              ></Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Top
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Side
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  End
                </Typography>
              </Stack>
            </Stack>

            {/* Row 1  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Thickness
                </Typography>
                <BootstrapTooltip
                  title={
                    Config.CONFIGLIST.shipperDetails.tooltip.insulation
                      .thickness
                  }
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsTop?.thickness}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsSide?.thickness}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsEnd?.thickness}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>

            {/* Row 2  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Specific Heat
                </Typography>
                <BootstrapTooltip
                  title={
                    Config.CONFIGLIST.shipperDetails.tooltip.insulation
                      .specificHeat
                  }
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsTop?.specific_heat}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        J/gK
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsSide?.specific_heat}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        J/gK
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsEnd?.specific_heat}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        J/gK
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>

            {/* Row 3  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Density
                </Typography>
                <BootstrapTooltip
                  title={
                    Config.CONFIGLIST.shipperDetails.tooltip.insulation.density
                  }
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsTop?.density}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        kg/m^3
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsSide?.density}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        kg/m^3
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: "184px",
                    height: "44px",
                    background: "#F2F2F370",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    readonly={enableData}
                    value={shipperClassPCMInsEnd?.density}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        kg/m^3
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default Insulation;
