import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Checkbox,
  Button,
  FormGroup,
  Backdrop,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import probeLocation3d from "../../../assets/images/scenario_prob_locations_3d.png";
import * as ConstantsList from "../../../../src/constants";
import SnackBar from "../../core/SnackBar/SnackBar";
import { scenarioProbelocation, scenarioData } from "../ScenarioSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../core/Loader/Loader";

const mainContainer = {
  width: "100%",
  height: "auto",
  backgroundColor: "#fff",
};

const mainTitle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "30px",
};

const ProbeLocation = (props) => {
  const { setSectionNo, setUpdateProbe } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const scenarioDetails = useSelector(scenarioData);

  const [loaderOpen, setLoaderOpen] = useState(true);
  const handleClose = () => {
    setLoaderOpen(false);
  };

  const [selectProbe, setSelectProbe] = useState(
    scenarioDetails?.probelocation ?? []
  );

  useEffect(() => {
    if (scenarioDetails?.probelocation) {
      setSelectProbe(scenarioDetails?.probelocation);
    }
  }, [scenarioDetails?.probelocation]);

  const [probeData, setProbeData] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "scenario/probelocation/")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            let dataR = data?.data;
            let ReceivedProbe = dataR.sort(function (a, b) {
              return a.id - b.id;
            });

            setProbeData(ReceivedProbe);
            setLoading(false);
          }
        }
      });
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const saveProbeLocationValue = () => {
    if (scenarioDetails?.scenario?.id) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          scenario_probelocation_id: selectProbe,
          scenario_id: scenarioDetails?.scenario?.id,
        }),
      };

      fetch(
        ConstantsList.API_URL + "scenario/probelocation-save/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code === 200) {
            setNotify(true);
            setNotifyMsg(data.data.toString());
            setSectionNo(3);
            setUpdateProbe(true);
          } else if (data.status_code === 400) {
            setNotify(true);
            setNotifyMsg(data.data.toString());
          }
        });
    }
  };

  const selectProductHandle = (e, id) => {
    const selectedIndex = selectProbe.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectProbe, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectProbe.slice(1));
    } else if (selectedIndex === selectProbe.length - 1) {
      newSelected = newSelected.concat(selectProbe.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectProbe.slice(0, selectedIndex),
        selectProbe.slice(selectedIndex + 1)
      );
    }
    setSelectProbe(newSelected);
    isCheckAll();
  };

  const [checkselectAll, setCheckSelectAll] = useState(false);

  useEffect(() => {
    if (scenarioDetails?.probelocation) {
      if (scenarioDetails?.probelocation?.length === 5) {
        setCheckSelectAll(true);
      }
    }
  }, [scenarioDetails?.probelocation]);

  const isCheck = (id) => selectProbe.indexOf(id) !== -1;
  const isCheckAll = () => {
    let allChecked = false;
    if (
      probeData[0]?.id === true &&
      probeData[1]?.id === true &&
      probeData[2]?.id === true &&
      probeData[3]?.id === true &&
      probeData[4]?.id === true
    ) {
      allChecked = true;
    }
    setCheckSelectAll(allChecked);
  };

  const selectAllHandle = () => {
    if (probeData) {
      setCheckSelectAll(!checkselectAll);
      UpdateSelected();
    }
  };

  const UpdateSelected = () => {
    if (checkselectAll) {
      setSelectProbe([]);
    } else {
      const Data = probeData.map((item) => item?.id);
      setSelectProbe(Data);
    }
  };

  const isSave = selectProbe.length > 0 ? false : true;

  useEffect(() => {
    if (selectProbe) {
      let probelocations = selectProbe;
      dispatch(scenarioProbelocation(probelocations));
    }
  }, [selectProbe]);

  return (
    <Box maxWidth="false" sx={mainContainer} px={3} py={4}>
      <Stack direction="row">
        <Stack alignItems="left">
          <Typography variant="h1" sx={mainTitle}>
            Select Probe Locations
          </Typography>
        </Stack>
      </Stack>
      {probeData && (
        <Stack direction="row">
          <Stack
            direction="column"
            sx={{ width: 1 / 5, alignItems: "flex-end" }}
          >
            <FormGroup>
              <FormControlLabel
                onChange={selectAllHandle}
                checked={checkselectAll}
                value="100"
                control={<Checkbox />}
                label="Select All"
                labelPlacement="start"
                sx={{
                  width: "220px",
                  height: "56px",
                  borderRadius: "6px",
                  paddingLeft: "20px",
                  paddingRight: "15px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #B1B2B4",
                  justifyContent: "space-between",
                  marginTop: "102px",
                  marginRight: "0",
                  "&.Mui-checked": {
                    color: "#F26322",
                  },
                }}
                data-testid="select-form-id"
              />

              <FormControlLabel
                value={probeData && probeData[0]?.id}
                control={
                  <Checkbox
                    name={probeData[0]?.id.toString()}
                    onChange={(e) => selectProductHandle(e, probeData[0]?.id)}
                    checked={isCheck(probeData[0]?.id)}
                    {...label}
                  />
                }
                label={probeData.length && probeData[0]?.probelocation}
                labelPlacement="start"
                sx={{
                  width: "220px",
                  height: "56px",
                  borderRadius: "6px",
                  paddingLeft: "20px",
                  paddingRight: "15px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  border: "1px solid #B1B2B4",
                  justifyContent: "space-between",
                  marginTop: "85px",
                  marginRight: "0",
                }}
              />

              <FormControlLabel
                value={probeData && probeData[1]?.id}
                control={
                  <Checkbox
                    name={probeData[1]?.id.toString()}
                    onChange={(e) => selectProductHandle(e, probeData[1]?.id)}
                    checked={isCheck(probeData[1]?.id)}
                    {...label}
                  />
                }
                label={probeData?.length && probeData[1]?.probelocation}
                labelPlacement="start"
                sx={{
                  width: "220px",
                  height: "56px",
                  borderRadius: "6px",
                  paddingLeft: "20px",
                  paddingRight: "15px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #B1B2B4",
                  justifyContent: "space-between",
                  marginTop: "88px",
                  marginRight: "0",
                }}
              />
            </FormGroup>
          </Stack>

          <Stack direction="column" sx={{ width: 3 / 5 }}>
            <Box
              component="img"
              src={probeLocation3d}
              alt="Probe location image"
              sx={{
                width: "100%",
                height: "auto",
                margin: "0",
              }}
            />
          </Stack>

          <Stack direction="column" sx={{ width: 1 / 5 }}>
            <FormControlLabel
              value={probeData && probeData[2]?.id}
              control={
                <Checkbox
                  name={probeData[2]?.id.toString()}
                  onChange={(e) => selectProductHandle(e, probeData[2]?.id)}
                  checked={isCheck(probeData[2]?.id)}
                  {...label}
                />
              }
              label={probeData.length && probeData[2]?.probelocation}
              labelPlacement="start"
              sx={{
                width: "220px",
                height: "56px",
                borderRadius: "6px",
                paddingLeft: "20px",
                paddingRight: "15px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #B1B2B4",
                justifyContent: "space-between",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "102px",
              }}
            />

            <FormControlLabel
              value={probeData && probeData[3]?.id}
              control={
                <Checkbox
                  name={probeData[3]?.id.toString()}
                  onChange={(e) => selectProductHandle(e, probeData[3]?.id)}
                  checked={isCheck(probeData[3]?.id)}
                  {...label}
                />
              }
              label={probeData.length && probeData[3]?.probelocation}
              labelPlacement="start"
              sx={{
                width: "220px",
                height: "56px",
                borderRadius: "6px",
                paddingLeft: "20px",
                paddingRight: "15px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #B1B2B4",
                justifyContent: "space-between",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "90px",
              }}
            />

            <FormControlLabel
              value={probeData && probeData[4]?.id}
              control={
                <Checkbox
                  name={probeData[4]?.id.toString()}
                  onChange={(e) => selectProductHandle(e, probeData[4]?.id)}
                  checked={isCheck(probeData[4]?.id)}
                  {...label}
                />
              }
              label={probeData.length && probeData[4]?.probelocation}
              labelPlacement="start"
              sx={{
                width: "220px",
                height: "56px",
                borderRadius: "6px",
                paddingLeft: "20px",
                paddingRight: "15px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #B1B2B4",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "90px",
                justifyContent: "space-between",
              }}
            />
          </Stack>
        </Stack>
      )}
      {(scenarioDetails?.status === "Optimization Pending" ||
        scenarioDetails?.status === "Optimization Failed" ||
        scenarioDetails?.status === "Optimization Cancelled") && (
        <Stack
          direction="row"
          sx={{
            borderTop: "1px solid #ccc",
            marginTop: "47px",
            paddingTop: "20px",
            justifyContent: "flex-end",
          }}
        >
          <Stack direction="column">
            <Button
              disabled={isSave}
              onClick={() => saveProbeLocationValue()}
              sx={{
                width: "145px",
                height: "44px",
                borderRadius: "6px",
                padding: "0px, 20px, 0px, 20px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                backgroundColor: "#FFFFFF",
              }}
              color="primary"
              style={{
                textTransform: "none",
                border: "1px solid #B1B2B4",
              }}
              data-testid="next-btn"
            >
              Next
            </Button>
          </Stack>
        </Stack>
      )}

      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
            data-testid="backdrop"
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Box>
  );
};

export default ProbeLocation;
