import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Radio,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector, useDispatch } from "react-redux";
import * as ConstantsList from "../../../../src/constants";

import { scenarioStatus, scenarioData } from "../ScenarioSlice";

const steps = [
  "Initializing",
  "Thermal Performance Estimates",
  "Payload Fitment",
  "Order Profile Optimization",
  "Solution Set Analysis",
  "Completed",
];
const stepsActive = {
  "Initializing Completed": 1,
  //"Thermal Performance Estimates Completed": 2,
  "Thermal Analysis Completed": 2,
  "Payload Fitment Completed": 3,
  "Order Profile Optimization Completed": 4,
  "Solution Set Analysis Completed": 5,
  "Optimization Completed": 6,
  "Report Completed": 7,
};

const stepStyle = {
  // boxShadow: 2,
  // backgroundColor: "rgba(0,0,0,0.1)",
  padding: 2,
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      color: "warning.main",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "warning.main",
    },
  },
  "& .Mui-completed": {
    "&.MuiStepIcon-root": {
      color: "green",
      fontSize: "2rem",
    },
    "& .MuiStepConnector-line": {
      borderColor: "green",
    },
  },
};

const TimeLine = (props) => {
  const { setOptimizeClicked, optimizeClicked } = props;
  const dispatch = useDispatch();
  const scenarioDetails = useSelector(scenarioData);
  const [dataList, setDataList] = useState(null);

  const [activeState, setActiveState] = useState(0);
  const [timelineStatus, setTimelineStatus] = useState(
    scenarioDetails?.status ? scenarioDetails.status : "Optimization Pending"
  );
  const [timelineStatusId, setTimelineStatusId] = useState(
    scenarioDetails?.scenario?.id
  );

  // getSynProcessState = () => {
  //   if (this.state.isSyncStart && this.runOnce) {
  //     this.runOnce = false

  //     this.intervalID = setInterval(() => {
  //       axios.get('http://192.168.51.28:8031/process/')
  //       .then(res => {
  //         console.log(res.data)
  //         // this.setState({
  //         //   total: res.data.total,
  //         //   current: res.data.current
  //         // })
  //         // console.log('1: ' +this.state.total)
  //       });
  //     },200);
  //   } else {
  //     clearInterval(this.intervalID)
  //   }
  // }

  const statusCall = () => {
    //if (optimizeClicked) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      ConstantsList.API_URL +
        "Trufit_optimization/milestone/" +
        timelineStatusId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          if (data?.data?.milestone_analysis?.length > 0) {
            setDataList(data?.data);
            setTimelineStatus(
              data?.data?.milestone_analysis[0]?.timeline_status
            );
            let scenario_status = data?.data?.scenario_status;
            let timelineStatus =
              data?.data?.milestone_analysis[0]?.timeline_status;

            let activeState = timelineStatus ? stepsActive[timelineStatus] : 1;

            if (dispatch(scenarioStatus(scenario_status))) {
              setActiveState(activeState);
            }

            if (
              timelineStatus === "Optimization Completed" ||
              timelineStatus === "Optimization Cancelled" ||
              timelineStatus === "Optimization Failed" ||
              timelineStatus === "No Solution Found" ||
              timelineStatus === "Report Created"
            ) {
              setOptimizeClicked(false);
            }
          }
        }
      });
    //}
  };

  useEffect(() => {
    //statusCall();
    var intervals = [];
    //const intervals = setInterval(() => {

    if (
      timelineStatus === "Optimization Completed" ||
      timelineStatus === "Optimization Cancelled" ||
      timelineStatus === "Optimization Failed" ||
      timelineStatus === "No Solution Found" ||
      timelineStatus === "Report Created"
    ) {
      setOptimizeClicked(false);
      intervals.forEach(clearInterval);
    }

    intervals.push(
      setInterval(() => {
        if (optimizeClicked === true) {
          if (
            timelineStatus === "Optimization Completed" ||
            timelineStatus === "Optimization Cancelled" ||
            timelineStatus === "Optimization Failed" ||
            timelineStatus === "No Solution Found" ||
            timelineStatus === "Report Created"
          ) {
            intervals.forEach(clearInterval);
          } else {
            statusCall();
          }
        } else {
          if (
            timelineStatus !== "Optimization Pending" &&
            timelineStatus !== "Optimization Completed" &&
            timelineStatus !== "Optimization Cancelled" &&
            timelineStatus !== "Optimization Failed" &&
            timelineStatus !== "No Solution Found" &&
            timelineStatus !== "Report Created"
          ) {
            statusCall();
          } else {
            intervals.forEach(clearInterval);
          }
        }
      }, 1000)
    );
  }, [timelineStatus, optimizeClicked, setOptimizeClicked, scenarioDetails]);

  //optimizeClicked, scenarioDetails, setOptimizeClicked

  useEffect(() => {
    if (
      timelineStatus === "Optimization Completed" ||
      timelineStatus === "No Solution Found" ||
      timelineStatus === "Report Created"
    ) {
      setActiveState(6);
    }
  }, []);

  useEffect(() => {
    if (
      timelineStatus === "Optimization Completed" ||
      timelineStatus === "Optimization Cancelled" ||
      timelineStatus === "Optimization Failed" ||
      timelineStatus === "No Solution Found" ||
      timelineStatus === "Report Created"
    ) {
      for (let i = 0; i < 10000; i++) {
        window.clearInterval(i);
      }
    }
  }, [timelineStatus]);

  return (
    <Box
      maxWidth="false"
      sx={{ width: "100%", height: "auto" }}
      data-testid="box-outer-container"
    >
      <Stack direction="column" data-testid="stack-outer-container">
        <Stepper
          activeStep={activeState}
          sx={stepStyle}
          alternativeLabel
          data-testid="stepper-container"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </Box>
  );
};

export default TimeLine;
