import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import Radio from "@mui/material/Radio";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import {
  pcmListViewControl,
  pcmListViewData,
} from "../../components/PCM/PCMListViewSlice";
import { useSelector, useDispatch } from "react-redux";

const columns = [
  {
    id: "select",
    label: "",
    width: "80px",
    align: "center",
    numeric: false,
  },
  {
    id: "color",
    label: "",
    width: "80px",
    align: "center",
    numeric: false,
  },
  {
    id: "pcm_name",
    label: "Name",
    width: "15%",
    align: "center",
    numeric: true,
  },
  {
    id: "created_on",
    label: "Creation Date",
    width: "15%",
    align: "center",
    numeric: true,
  },
  {
    id: "updated_on",
    label: "Last Edited",
    width: "15%",
    align: "center",
    numeric: true,
  },
  {
    id: "phase",
    label: "Phase",
    width: "25%",
    align: "center",
    numeric: true,
  },
  {
    id: "viewdetails",
    label: "View Details",
    width: "-webkit-fill-available",

    align: "center",
    numeric: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align="left"
            style={{
              width: headCell.width,
              textAlign: i === 6 ? "center" : "left",
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric && UnfoldMoreIcon}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const ListView = (props) => {
  const dispatch = useDispatch();

  const {
    data,
    dataCount,
    dataPCMColor,
    dataPCMName,
    dataCreationDate,
    dataLastEditedDate,
    dataPCMPhase,
    search,
  } = props;

  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = (
    event,
    id,
    pcmColor,
    pcmName,
    createdDate,
    modifiedDate,
    pcmPhase
  ) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      dataCount(true);
      dataPCMColor("");
      dataPCMName("");
      dataPCMPhase("");
      dataCreationDate("");
      dataLastEditedDate("");
    } else {
      setSelectedValue(id);
      dataCount(false);
      dataPCMColor(pcmColor);
      dataPCMName(pcmName);
      dataPCMPhase(pcmPhase);

      dataCreationDate(createdDate);
      dataLastEditedDate(modifiedDate);

      dispatch(
        pcmListViewControl({
          id: id,
          name: pcmName,
          color: pcmColor,
        })
      );
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("pcm_name");
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  const handleRequestSort = (event, property) => {
    let ascOrDec = orderBy === property && order === "asc" ? "desc" : "asc";

    setOrderBy(property);
    setOrder(ascOrDec);
  };

  const handleClick = (
    event,
    id,
    pcmColor,
    pcmName,
    pcmPhase,
    createdDate,
    modifiedDate
  ) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      dataCount(true);
      dataPCMColor("");
      dataPCMName("");
      dataPCMPhase("");
      dataCreationDate("");
      dataLastEditedDate("");
    } else {
      setSelectedValue(id);
      dataCount(false);
      dataPCMColor(pcmColor);
      dataPCMName(pcmName);
      dataPCMPhase(pcmPhase);

      dataCreationDate(createdDate);
      dataLastEditedDate(modifiedDate);

      dispatch(
        pcmListViewControl({
          id: id,
          name: pcmName,
          color: pcmColor,
        })
      );
    }
  };

  const isSelected = (name) => (selectedValue === name ? true : false);

  const filtered = search
    ? data.filter((user) => {
        return user.name === search.name;
      })
    : data;

  const viewDetails = (event, id, name, color) => {
    dispatch(
      pcmListViewControl({
        id: id,
        name: name,
        color: color,
      })
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="listViewTable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",
            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
            "& .css-1gsj6qz-MuiTableRow-root .css-1e76omk-MuiTableCell-root:last-child":
              {},
            "& .css-1lc2m0x-MuiTableRow-root .css-58eye8-MuiTableCell-root": {
              textAlign: "right",
              paddingRight: "70px",
            },
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filtered.length === 0 && (
              <NoRecordFound
                title="Wait"
                description={
                  <div>
                    Before creating a Shipper it is needed to have at least one
                    PCM.
                    <br />
                    <strong>Click New PCM button to start creating PCMs</strong>
                  </div>
                }
              />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                let selctedId = row.id;
                const isItemSelected = isSelected(selctedId);
                const labelId = `enhanced-table-checkbox-${index}`;
                let pcmColorCode = row.color ? row.color : "#D0021B";
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(
                        event,
                        row.id,
                        pcmColorCode,
                        row.pcm_name,
                        row.created_on,
                        row.updated_on,
                        row.phase
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={selctedId}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                    }}
                  >
                    {columns.map((column, i) => {
                      let value = row[column.id];
                      value =
                        i === 3 || i === 4
                          ? moment(value).format("MMM D, YYYY")
                          : value;
                      value =
                        i === 6 ? (
                          <Link underline="none" to="/dashboard/pcm/view">
                            <ChevronRightIcon
                              sx={{
                                align: "center",
                                cursor: "pointer",
                                color: "#000",
                              }}
                              onClick={(event) =>
                                viewDetails(
                                  event,
                                  row.id,
                                  row.pcm_name,
                                  pcmColorCode
                                )
                              }
                            />
                          </Link>
                        ) : (
                          value
                        );

                      let cellWidth =
                        i === 0 || i === 1
                          ? "2%"
                          : i === 2
                          ? "2%"
                          : i === 3 || i === 4
                          ? "5%"
                          : i === 6
                          ? "-webkit-fill-available"
                          : "10%";
                      return i === 0 ? (
                        <TableCell
                          padding="checkbox"
                          align="center"
                          style={{ width: row[column.width] }}
                        >
                          <Stack direction="row">
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Radio
                                checked={selectedValue === selctedId}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id,
                                    pcmColorCode,
                                    row.pcm_name,
                                    row.created_on,
                                    row.updated_on,
                                    row.phase
                                  )
                                }
                                value={selctedId}
                                name={`radio-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={i}
                          align={i === 6 ? "center" : "left"}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: cellWidth }}
                        >
                          {i === 1 ? (
                            <Box
                              sx={{
                                backgroundColor: "#fff",
                                p: "3px",
                                borderRadius: "4px",
                                border: "1px solid #E6E7E8",
                                display: "inline-block",
                              }}
                            >
                              <Box
                                data-color={value}
                                sx={{
                                  width: 38,
                                  height: 38,
                                  backgroundColor: pcmColorCode,
                                  borderRadius: "4px",
                                }}
                              />
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListView;
