import React, { useState, useEffect } from "react";
import { custData } from "../../Customer/CustomerSlice";
import { oppotunityListViewData } from "../../Opportunities/OppotunityListViewSlice";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import BreadCrumb from "../../core/BreadCrumb";
import { useHistory } from "react-router-dom";
import SnackBar from "../../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../constants";
import Duplicate from "@mui/icons-material/ContentCopy";
import Tune from "@mui/icons-material/Tune";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ListSearch from "../Search";
import Loader from "../../core/Loader/Loader";
import ListView from "../ListView";
import CreateNew from "../CreateNew";
import DeleteRecord from "../DeleteRecord";
import * as ADAConfig from "../../../../src/config/ADAConfig";
import { loginData } from "../../../components/Login/LoginSlice";
import { useSelector, useDispatch } from "react-redux";

const ScenarioList = () => {
  let history = useHistory();
  const loginDetails = useSelector(loginData);
  const customerDetails = useSelector(custData);
  const oppotunityDetails = useSelector(oppotunityListViewData);

  const breadcrumbData = [
    {
      id: 4,
      title: "Scenarios",
      link: "",
    },
    {
      id: 3,
      title: oppotunityDetails?.name,
      link: "/dashboard/opportunity",
    },
    {
      id: 2,
      title: customerDetails?.name,
      link: "/dashboard/opportunity",
    },

    {
      id: 1,
      title: "Home / Customers",
      link: "/dashboard",
    },
  ];

  const theme2 = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState(null);
  const [listDataSearch, setListDataSearch] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const [isScenarioProposed, setIsScenarioProposed] = useState(false);

  const [reload, setReload] = useState(false);
  const [selectedScenariosList, setSelectedScenariosList] = useState([]);
  const [severity, setSeverity] = useState(false);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  useEffect(() => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ opportunity_id: oppotunityDetails?.id }),
    };

    fetch(
      ConstantsList.API_URL + "scenario/scenario-opportunity/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setListData(data);
          setLoading(false);
          setReload(false);
          let isProposed = false;
          data?.data?.map((row, index) => {
            if (row.selected_proposal) {
              isProposed = true;
            }
          });
          setIsScenarioProposed(isProposed);
        }
      });
  }, [reload, oppotunityDetails?.id]);

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [scenarioName, setScenarioName] = useState({});
  const [selectedScenarios, setSelectedScenarios] = useState([]);

  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");

  const handleCdOpen = () => {
    setCdopen(true);
  };

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const isEnabled = selectedScenarios.length > 0 ? false : true;

  useEffect(() => {
    setTimeout(() => {
      for (let i = 0; i < 10000; i++) {
        window.clearInterval(i);
      }
    }, 10000);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme2}>
        <Stack px={6} pt={6} direction="column" sx={{ width: "100%" }}>
          <BreadCrumb data={breadcrumbData} />
        </Stack>
      </ThemeProvider>

      <Box maxWidth="false" p={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            boxShadow:
              "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
          }}
          data-testid="scenario-container-box"
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#FFFFFF",
            }}
            data-testid="scenario-small-box"
          >
            <Typography
              component="h5"
              variant="h5"
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "30px",
                color: "#000000",
                pt: 3,
                pl: 3,
                pr: 3,
              }}
            >
              Scenarios
            </Typography>
            <Box sx={{ pb: 0 }}>
              {loginDetails.role !== "Account Manager" && (
                <ThemeProvider theme={theme}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    sx={{
                      borderColor: "#000000",
                      color: "#000000",
                      pt: 3,
                      pl: 3,
                      pr: 3,
                    }}
                    data-testid="buttongroup"
                  >
                    <Button
                      onClick={handleCddelOpen}
                      data-testid="deletebutton"
                      disabled={isEnabled}
                      startIcon={<DeleteIcon />}
                      style={{
                        textTransform: "none",
                        border: "1px #B1B2B4 solid",
                      }}
                      aria-label={ADAConfig.ADA.scenario_list.delete_btn}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={handleCdOpen}
                      data-testid="addbutton"
                      color="primary"
                      startIcon={<AddIcon />}
                      style={{
                        textTransform: "none",
                        border: "1px #B1B2B4 solid",
                      }}
                      aria-label={ADAConfig.ADA.scenario_list.create_new_btn}
                    >
                      Create New
                    </Button>
                  </ButtonGroup>
                </ThemeProvider>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {listData && (
              <ListSearch data={listData.data} search={setListDataSearch} />
            )}
          </Box>
          {loading && (
            <Box sx={{ display: "flex" }} data-testid="inner-box">
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
                data-testid="backdrop-inner-box"
              >
                <Loader />
              </Backdrop>
            </Box>
          )}

          {listData && (
            <ListView
              data={listData.data}
              setScenarioName={setScenarioName}
              selectedScenarios={selectedScenarios}
              setSelectedScenarios={setSelectedScenarios}
              selectedScenariosList={selectedScenariosList}
              setSelectedScenariosList={setSelectedScenariosList}
              search={listDataSearch !== null ? listDataSearch : false}
              isScenarioProposed={isScenarioProposed}
              setNotify={setNotify}
              setNotifyMsg={setNotifyMsg}
              setReload={setReload}
              setSeverity={setSeverity}
            />
          )}
          {cdopen && (
            <CreateNew
              open={cdopen}
              onClose={handleCdClose}
              setNotify={setNotify}
              setNotifyMsg={setNotifyMsg}
              setCount={setCount}
              setReload={setReload}
              setSeverity={setSeverity}
              selectedScenariosList={selectedScenariosList}
              setSelectedScenariosList={setSelectedScenariosList}
            />
          )}
          {cddelopen && (
            <DeleteRecord
              open={cddelopen}
              onClose={handleCddelClose}
              selectedScenariosList={selectedScenariosList}
              setSelectedScenarios={setSelectedScenarios}
              setSelectedScenariosList={setSelectedScenariosList}
              setCount={setCount}
              setNotify={setNotify}
              setNotifyMsg={setNotifyMsg}
              setReload={setReload}
              setSeverity={setSeverity}
            />
          )}
          {notify && (
            <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ScenarioList;
