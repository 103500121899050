import {
  Box,
  Button,
  Checkbox,
  createTheme,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as ConstantsList from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";
import * as Config from "../../../config";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const Configuration = (props) => {
  const shipperDetails = useSelector(shipperDetailsData);
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip;
  const ticConfig = shipperDetails?.ticconfig;
  const ticConfiguration = shipperDetails?.ticconfig?.configurations;

  const isTextEnabled = true;

  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  return (
    <Box p={0} maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <Box
        px={3}
        pt={3}
        maxWidth="false"
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        data-testid="box-outer-container"
      >
        <Stack
          spacing={1.5}
          direction="row"
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography component="h6" variant="h6" sx={labelStyle}>
            Configuration Name
          </Typography>

          <FormControl
            size="small"
            sx={{
              width: "200px",
              height: "44px",
              padding: "8px, 12px, 8px, 12px",
              background: "#F2F2F370",
              borderRadius: "5px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <OutlinedInput
              type="text"
              value={ticConfig?.tic_type}
              readOnly={isTextEnabled}
              id="ticConfigurationName"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "Class Name",
              }}
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#3F4042",
              }}
            />
          </FormControl>
        </Stack>

        <Stack direction="row">
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isTextEnabled}
                  name="shipperReleaded"
                  checked={shipperDetails?.released ?? false}
                />
              }
              label="Released"
            />
          </Stack>
        </Stack>
      </Box>
      <ThemeProvider theme={customTheme}>
        <Stack
          pt={5}
          direction="row"
          spacing={7}
          px={3}
          pb={5}
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
          }}
        >
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Inner Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.length}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.width}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.height}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Wet Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.weigtht}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.weight}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.cost}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            $
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.topPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.top_pcm}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sidePCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.side_pcm}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.endPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.end_pcm}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Total PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.totalPCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      focused={true}
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.total_pcm}
                        id="totalPCM"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "Total PCM",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem p={0} m={0} />
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Environmental Impacts
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        CO2 Emissions
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.co2Emissions}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.emissions}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Landfill
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.landfill}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.landfill}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Multiwall Detail Definition
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.topWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.top_wall}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sideWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.side_wall}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.endWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                        background: "#F2F2F370",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        disabled={isTextEnabled}
                        readOnly={isTextEnabled}
                        value={ticConfiguration?.end_wall}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>
    </Box>
  );
};

export default Configuration;
