import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Typography, Button, ButtonGroup } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { tab } from "../Navigator/navigateSlice";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function BasicBreadcrumbs(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleBack = (e) => {
    const reLink =
      props.fromPage === "PCM View" ||
      props.fromPage === "PCM Edit" ||
      props.fromPage === "PCM Create New"
        ? "/dashboard/shipper"
        : "/dashboard";
    const activeTab =
      props.fromPage === "PCM View" ||
      props.fromPage === "PCM Edit" ||
      props.fromPage === "PCM Create New"
        ? "1"
        : "0";
    dispatch(tab(activeTab));
    history.push(reLink);
  };

  const handleShipper = (e) => {
    const activeTab = "0";
    dispatch(tab(activeTab));
  };
  return (
    <>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        sx={{
          borderColor: "#000000",
          color: "#000000",
          pr: 3,
          fontSize: "16px",
        }}
      >
        <Button
          onClick={handleBack}
          data-testid="deletebutton"
          startIcon={<ArrowBackIcon />}
          style={{
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            backgroundColor: "#ffffff",
            marginRight: "20px",
          }}
        >
          Back
        </Button>
      </ButtonGroup>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ display: "inline-block" }}
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link underline="none" color="inherit" href="/dashboard">
          Home / Shipper Dictionaries
        </Link>
        {(props?.fromPage?.toString() === "PCM Create New" ||
          props?.fromPage?.toString() === "PCM View" ||
          props.fromPage === "PCM Edit") && (
          <Link
            underline="none"
            color="inherit"
            href="/dashboard"
            onClick={handleShipper}
          >
            {props.selectedVal.name}
          </Link>
        )}
        {(props.fromPage === "PCM Create New" ||
          props.fromPage === "PCM View" ||
          props.fromPage === "PCM Edit") && (
          <Link underline="none" color="inherit" href="/dashboard/shipper">
            PCM's
          </Link>
        )}
        {props.fromPage === "PCM Create New" ? (
          <Typography color="text.primary">New PCM</Typography>
        ) : props.fromPage === "PCM View" ? (
          <Typography color="text.primary">PCM Details</Typography>
        ) : props.fromPage === "PCM Edit" ? (
          <Typography color="text.primary">Edit PCM</Typography>
        ) : (
          <Typography color="text.primary">
            Shipper Dictionary Details
          </Typography>
        )}
      </Breadcrumbs>
      {props.fromPage === "PCM Create New" ||
      props.fromPage === "PCM View" ||
      props.fromPage === "PCM Edit" ? (
        <></>
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          sx={{ p: 3, pl: 0, pb: 0, fontSize: "32px" }}
        >
          {props.selectedVal?.name}
        </Typography>
      )}
    </>
  );
}
