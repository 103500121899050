import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import Loader from "../core/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { loginControl, loginData } from "./LoginSlice";
import Backdrop from "@mui/material/Backdrop";
import { useOktaAuth } from "@okta/okta-react";
const LoginModal = () => {
  const userData = useSelector(loginData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(
    loginData?.loggedIn ? true : false
  );

  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const isOktaAuthenticated = authState?.isAuthenticated;

  // const logout = async () => {
  //   await oktaAuth.signOut();
  // };

  // const handleSignOut = () => {
  //   const UserData = {
  //     username: "",
  //     email: "",
  //     role: "",
  //     active_role: "",
  //     loggedIn: false,
  //   };
  //   dispatch(loginControl(UserData));
  //   logout();
  // };

  useEffect(() => {
    if (userData?.username !== "" && isOktaAuthenticated) {
      history.push("/dashboard");
    } else {
      // setTimeout(() => {
      //   handleSignOut();
      // }, 10000);
    }
  }, [history, isOktaAuthenticated, userData?.username]);

  //const isLoggedIn = userData?.loggedIn ? true : false;
  return (
    <>
      {loader && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
      <Box
        sx={{
          height: "calc(100vh - 225px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Login Form Hidden */}
        {isLoggedIn && isOktaAuthenticated && (
          <Card
            sx={{
              borderRadius: "6px",
              boxShadow: 3,
              display: "block",
            }}
          >
            <CardContent>
              <Stack spacing={2} p={2}>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                    fontSize: "32px",
                    lineHeight: "38px",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Welcome to TruFit!
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        )}
        {!isLoggedIn && isOktaAuthenticated && (
          <Card
            sx={{
              borderRadius: "6px",
              boxShadow: 3,
              display: "block",
            }}
          >
            <CardContent>
              <Stack spacing={2} p={2}>
                <Typography
                  variant="h2"
                  component="h2"
                  sx={{
                    fontSize: "32px",
                    lineHeight: "38px",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Username is not added in the application. Contact admin
                </Typography>

                <Typography
                  variant="h3"
                  component="h3"
                  sx={{
                    fontSize: "22px",
                    lineHeight: "38px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Contact admin
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Box>
    </>
  );
};

export default LoginModal;
