import { AppBar, Backdrop, Box, Button, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Config from "../../config";
import { scenarioData } from "../Scenario/ScenarioSlice";
import { SaveOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import ScatterPlot from "./ScatterPlot";
import BarChart from "./BarChart";
import ShipperSolution from "./ShipperSolution";
import SaveReport from "./SaveReport";
import SnackBar from "../core/SnackBar/SnackBar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as ConstantsList from "../../../src/constants";
import BreadCrumb from "../core/BreadCrumb";
import { Typography } from "@mui/material";
import Loader from "../core/Loader/Loader";

const Reports = () => {
  const params = useParams();
  const scenarioDetails = useSelector(scenarioData);
  const textData = Config?.CONFIGLIST?.reports;
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const [scatterInit, setScatterInit] = useState([]);
  const [barInit, setBarInit] = useState([]);
  const [solutionInitRisk, setSolutionInitRisk] = useState([]);
  const [solutionInitSets, setSolutionInitSets] = useState([]);

  const [scatterData, setScatterData] = useState({
    no_risk: 0,
    low_risk: 0,
    med_risk: 0,
    high_risk: 0,
  });

  const [barData, setBarData] = useState({
    no_risk: 0,
    low_risk: 0,
    med_risk: 0,
    high_risk: 0,
  });

  const [solutionsetData, setSolutionsetData] = useState({
    solution_set: [],
    risk_selected: {
      no_risk: 0,
      low_risk: 0,
      med_risk: 0,
      high_risk: 0,
    },
  });

  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    let Data = {
      scatter_plot_selected_risks: scatterData,
      barchart_selected_risks: barData,
      solution_set_selection_risks: solutionsetData,
    };
    setReportData(Data);
  }, [scatterData, barData, solutionsetData]);

  const buttonContainerStyle = {
    alignItems: "flex-end",
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const getImgValue = async (element) => {
    return await html2canvas(element);
  };

  const download2 = () => {
    setLoading(true);
    const pelicanLogo = document.getElementById("pelicanlogo");
    const headerChart = document.getElementById("headerChartPrint");
    const scatterPlot = document.getElementById("scatterPlotPrint");
    const barChart = document.getElementById("barChartPrint");
    const solChart = document.querySelectorAll(".solChart");

    let DataChart = [];
    DataChart.push(
      new Promise((resolve, reject) => {
        resolve(getImgValue(pelicanLogo));
      })
    );
    DataChart.push(
      new Promise((resolve, reject) => {
        resolve(getImgValue(headerChart));
      })
    );
    DataChart.push(
      new Promise((resolve, reject) => {
        resolve(getImgValue(scatterPlot));
      })
    );
    DataChart.push(
      new Promise((resolve, reject) => {
        resolve(getImgValue(barChart));
      })
    );
    solChart.forEach((element) =>
      DataChart.push(
        new Promise((resolve, reject) => {
          resolve(getImgValue(element));
        })
      )
    );

    Promise.all(DataChart).then((result) => {
      let pdf = new jsPDF("portrait", "pt", "a4");
      let inwidth = pdf.internal.pageSize.getWidth();
      let inheight = pdf.internal.pageSize.getHeight();
      let position = 10;
      let id = 0;

      let MenuID = 4;
      let CoMenuID = 5;
      let MenuID2 = 6;

      let avlheigth = inheight;
      for (let reData of result) {
        const img = reData.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;
        if (id === 0) {
          pdf.addImage(img, "PNG", 10, position, 100.67, 28.98);
          position = position + 28.98 + 10;
          avlheigth = avlheigth - (28.98 + 10);
        } else {
          if (id > 3) {
            if (MenuID === id) {
              pdf.addPage();
              avlheigth = inheight;
              position = 10;

              pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
              position = position + pdfHeight + 10;
              avlheigth = avlheigth - (pdfHeight + 10);
            } else if (CoMenuID === id && CoMenuID === 5) {
              pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
              position = position + pdfHeight + 10;
              avlheigth = avlheigth - (pdfHeight + 10);
              CoMenuID = CoMenuID + 6;
            } else if (CoMenuID === id && CoMenuID !== 5) {
              pdf.addPage();
              avlheigth = inheight;
              position = 10;

              pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
              position = position + pdfHeight + 10;
              avlheigth = avlheigth - (pdfHeight + 10);
              CoMenuID = CoMenuID + 6;
            } else if (MenuID2 === id) {
              pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
              position = position + pdfHeight + 10;
              avlheigth = avlheigth - (pdfHeight + 10);
              MenuID2 = MenuID2 + 6;
            } else {
              pdf.addPage();
              avlheigth = inheight;
              position = 10;

              pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
              position = position + pdfHeight + 10;
              avlheigth = avlheigth - (pdfHeight + 10);
            }
            // pdf.addPage();
            // avlheigth = inheight;
            // position = 10;

            // pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
            // position = position + pdfHeight + 10;
            // avlheigth = avlheigth - (pdfHeight + 10);
          } else {
            pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
            position = position + pdfHeight + 10;
            avlheigth = avlheigth - (pdfHeight + 10);
          }

          // if (avlheigth > pdfHeight) {
          //   pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
          //   position = position + pdfHeight + 10;
          //   avlheigth = avlheigth - (pdfHeight + 10);
          // } else {
          //   pdf.addPage();
          //   avlheigth = inheight;
          //   position = 10;

          //   pdf.addImage(img, "PNG", 10, position, pdfWidth - 20, pdfHeight);
          //   position = position + pdfHeight + 10;
          //   avlheigth = avlheigth - (pdfHeight + 10);
          // }
        }

        id = id + 1;
      }

      const pages = pdf.internal.getNumberOfPages();
      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;
      pdf.setFontSize(10);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        pdf.setPage(j);
        pdf.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      setLoading(false);
      pdf.save(`${scenarioDetails?.scenario?.name}.pdf`);
    });
  };

  useEffect(() => {
    if (params?.id) {
      if (params?.id === "0") {
        setScatterInit(["No Risk", "Low Risk", "Med Risk", "High Risk"]);
        setBarInit(["No Risk", "Low Risk", "Med Risk", "High Risk"]);
        setSolutionInitRisk(["No Risk"]);
        setSolutionInitSets(["1"]);
      } else {
        fetch(
          ConstantsList.API_URL + "summary/getparticular_report/" + params?.id
        )
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              if (data.status_code === 200) {
                let Data = data.data;
                if (Data?.scatter_plot_selected_risks) {
                  let scaPlot = Data?.scatter_plot_selected_risks;
                  let scatData = [];
                  if (scaPlot?.no_risk) {
                    scatData.push("No Risk");
                  }
                  if (scaPlot?.low_risk) {
                    scatData.push("Low Risk");
                  }
                  if (scaPlot?.med_risk) {
                    scatData.push("Med Risk");
                  }
                  if (scaPlot?.high_risk) {
                    scatData.push("High Risk");
                  }
                  setScatterInit(scatData);
                }

                if (Data?.barchart_selected_risks) {
                  let barPlot = Data?.barchart_selected_risks;
                  let barDataValue = [];
                  if (barPlot?.no_risk) {
                    barDataValue.push("No Risk");
                  }
                  if (barPlot?.low_risk) {
                    barDataValue.push("Low Risk");
                  }
                  if (barPlot?.med_risk) {
                    barDataValue.push("Med Risk");
                  }
                  if (barPlot?.high_risk) {
                    barDataValue.push("High Risk");
                  }
                  setBarInit(barDataValue);
                }

                if (Data?.solution_set_selection_risks?.risk_selected) {
                  let solutionPlot =
                    Data?.solution_set_selection_risks?.risk_selected;
                  let solutionData = [];
                  if (solutionPlot?.no_risk) {
                    solutionData.push("No Risk");
                  }
                  if (solutionPlot?.low_risk) {
                    solutionData.push("Low Risk");
                  }
                  if (solutionPlot?.med_risk) {
                    solutionData.push("Med Risk");
                  }
                  if (solutionPlot?.high_risk) {
                    solutionData.push("High Risk");
                  }
                  setSolutionInitRisk(solutionData);
                }

                if (Data?.solution_set_selection_risks?.solution_set) {
                  let solutionPlot =
                    Data?.solution_set_selection_risks?.solution_set;

                  setSolutionInitSets(solutionPlot);
                }
              }
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, [params?.id]);

  const breadcrumbData = [
    {
      id: 5,
      title: params?.name,
      link: "",
    },
    {
      id: 4,
      title: "Reports",
      link: "/dashboard/opportunity/scenario",
    },
    {
      id: 3,
      title: scenarioDetails?.scenario?.name,
      link: "/dashboard/opportunity/scenario-list",
    },
    {
      id: 1,
      title: "Home / " + scenarioDetails?.customer?.name,
      link: "/dashboard",
    },
  ];

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose1 = () => {
    setOpenLoad(false);
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "#F2F2F3",
          width: "100%",
          "& .MuiAppBar-positionSticky": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Stack p={6} direction="row" spacing={1} sx={buttonContainerStyle}>
          <Stack
            direction="column"
            sx={{ width: "100%", backgroundColor: "#F2F2F3" }}
          >
            <BreadCrumb data={breadcrumbData} />
          </Stack>
          <Button
            onClick={handleOpen}
            sx={{
              width: "145px",
              height: "44px",
              borderRadius: "6px",
              padding: "0px, 20px, 0px, 20px",
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              backgroundColor: "#FFFFFF",
            }}
            data-testid="editbutton"
            startIcon={<SaveOutlined />}
            style={{
              textTransform: "none",
              border: "1px solid #B1B2B4",
            }}
          >
            {textData?.saveButton}
          </Button>
          <Button
            onClick={download2}
            sx={{
              width: "240px",
              height: "44px",
              borderRadius: "6px",
              padding: "0px, 20px, 0px, 20px",
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              backgroundColor: "#F26322",
              "&:disabled": {
                opacity: "0.3",
              },
            }}
            data-testid="addbutton"
            color="primary"
            startIcon={<FileDownloadOutlined />}
            style={{
              textTransform: "none",
              border: "1px solid #F26322",
            }}
          >
            {textData?.downloadPDF}
          </Button>
        </Stack>
      </AppBar>

      <Box
        maxWidth="false"
        px={6}
        pb={6}
        sx={{ width: "100%" }}
        id="element-to-print"
      >
        <Stack direction="column" width={1} id="headerChartPrint">
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Roboto",
              fontSize: "26px",
              color: "#F26322",
              fontWeight: "700",
              lineHeight: "32px",
            }}
          >
            {scenarioDetails?.scenario?.name + " > " + params?.name}
          </Typography>
        </Stack>
        <Stack direction="column" width={1}>
          <ScatterPlot
            ID={scenarioDetails?.scenario?.id}
            setData={setScatterData}
            scatterInit={scatterInit}
          />
          <Divider />
          <BarChart
            ID={scenarioDetails?.scenario?.id}
            setData={setBarData}
            barInit={barInit}
          />
          <Divider />
          <ShipperSolution
            ID={scenarioDetails?.scenario?.id}
            setData={setSolutionsetData}
            solutionInitRisk={solutionInitRisk}
            solutionInitSets={solutionInitSets}
          />
        </Stack>

        {open && (
          <SaveReport
            reportData={reportData}
            open={open}
            onClose={handleClose}
            setNotify={setNotify}
            setNotifyMsg={setNotifyMsg}
          />
        )}

        {notify && (
          <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
        )}

        {loading && (
          <Box sx={{ display: "flex" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoad}
              onClick={handleClose1}
            >
              <Loader />
            </Backdrop>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Reports;
