import React from "react";
import { Scatter } from "react-chartjs-2";
import * as ConstantsList from "../../../constants";
import { useState } from "react";
import { useEffect } from "react";
import { Box, Checkbox, Stack, Typography } from "@mui/material";

const ScatterPlot = (props) => {
  const { ID, setData, scatterInit } = props;

  const [dataset, setDataset] = useState(null);
  const [scatterRiskList, setScatterRiskList] = useState([]);
  const [scatterChartData, setScatterChartData] = useState([]);

  useEffect(() => {
    if (ID) {
      fetch(ConstantsList.API_URL + "summary/scatter_plot/" + ID)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setDataset(data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [ID]);

  useEffect(() => {
    if (dataset) {
      setScatterRiskList(dataset.map((data) => data?.risk));
      const TempRisks = ["No Risk", "Low Risk", "Med Risk", "High Risk"];
      let TempRisk = [];
      TempRisks.forEach((RiskName) =>
        dataset
          .map((data) => data?.risk)
          .forEach((risks) => risks === RiskName && TempRisk.push(risks))
      );
      setScatterRiskList(TempRisk);
    }
  }, [dataset]);

  const optionsChart = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        offset: 2,
        rotation: -90,
        anchor: "end",
        font: { size: "7" },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Number of Shipments",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Total Shipped Weight (kg)",
        },
      },
    },
  };

  const data = {
    datasets: scatterChartData,
  };

  const [selected, setSelected] = useState(scatterInit);

  useEffect(() => {
    if (scatterInit) {
      setSelected(scatterInit);
    }
  }, [scatterInit]);

  const isAllSelected =
    scatterRiskList.length > 0 && selected.length === scatterRiskList.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setSelected(
        selected.length === scatterRiskList.length ? [] : scatterRiskList
      );
      return;
    }
    const list = [...selected];
    const index = list.indexOf(value);
    index === -1 ? list.push(value) : list.splice(index, 1);
    setSelected(list);
  };

  const checkBoxFont = {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#3F4042",
  };

  const listItem = scatterRiskList.map((option) => {
    return (
      <Stack key={option} direction="row" spacing={1} alignItems="center">
        <Checkbox
          value={option}
          onChange={handleChange}
          checked={selected.includes(option)}
          sx={{ padding: 0 }}
        />
        <Typography sx={checkBoxFont}>{option}</Typography>
      </Stack>
    );
  });

  const isCheck = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (dataset) {
      const ScatterChartColors = [
        "rgba(251, 214, 22, 1)",
        "rgba(203, 203, 203, 1)",
        "rgba(78, 115, 190, 1)",
        "rgba(251, 144, 72, 1)",
      ];
      setScatterChartData(
        dataset.map(
          (data, i) =>
            isCheck(data.risk) && {
              label: data.risk,
              data: data.data.map((axis) => {
                return {
                  x: axis?.number_of_shipments,
                  y: axis?.total_shipped_weight,
                };
              }),
              backgroundColor: ScatterChartColors[i],
            }
        )
      );
    }
  }, [selected, dataset]);

  useEffect(() => {
    let data = {
      no_risk: selected.find((data) => data === "No Risk") ? 1 : 0,
      low_risk: selected.find((data) => data === "Low Risk") ? 1 : 0,
      med_risk: selected.find((data) => data === "Med Risk") ? 1 : 0,
      high_risk: selected.find((data) => data === "High Risk") ? 1 : 0,
    };

    setData(data);
  }, [selected]);

  return (
    <Stack direction="column" width={1} pt={4} pb={4} id="scatterPlotPrint">
      <Stack direction="column" spacing={4}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Checkbox
              value="all"
              onChange={handleChange}
              checked={isAllSelected}
              sx={{ padding: 0 }}
            />
            <Typography sx={checkBoxFont}>All</Typography>
          </Stack>
          {listItem}
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "26px",
              color: "#235984",
              fontWeight: "700",
              lineHeight: "32px",
            }}
          >
            Scatter Plot for Number of Shipments Vs Total Shipped Weight
          </Typography>
          <Stack direction="row" spacing={1}>
            {scatterChartData &&
              scatterChartData.map((data, i) => {
                const ScatterChartColors = [
                  "rgba(251, 214, 22, 1)",
                  "rgba(203, 203, 203, 1)",
                  "rgba(78, 115, 190, 1)",
                  "rgba(251, 144, 72, 1)",
                ];
                return (
                  <React.Fragment key={`Stack-${i}`}>
                    {isCheck(data.label) && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Box
                          sx={{
                            backgroundColor: ScatterChartColors[i],
                            width: "17.25px",
                            height: "17.25px",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            color: "#000000",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {data.label}
                        </Typography>
                      </Stack>
                    )}
                  </React.Fragment>
                );
              })}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
      >
        <Stack
          pt={10}
          sx={{
            width: "890.23px",
            height: "514.68px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Scatter options={optionsChart} data={data} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ScatterPlot;
