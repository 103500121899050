import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import SalesSearch from "./SalesSearch";
import ListCustomers from "./ListCustomers";
import * as ConstantsList from "../../../src/constants/";

import { useDispatch } from "react-redux";
import { dataControl } from "../../store/reducer";
import * as ADAConfig from "../../../src/config/ADAConfig";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};

const SalesContainer = (props) => {
  const { setCustomer, setOpen, open, setNOpen, reload, setReload } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  useEffect(() => {
    fetch(ConstantsList.API_URL + "cust/customer/")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setReload(false);
      });
  }, [reload, setReload]);

  const handleNewCustomer = (e, id, name) => {
    dispatch(dataControl());
    setOpen(false);
    setNOpen(true);
  };

  const [search, setSearch] = useState("");

  const filtered = data
    ? search
      ? data.data.filter((user) => {
          return user.customer_name === search.customer_name;
        })
      : data.data
    : {};

  return (
    <Box
      maxWidth="false"
      sx={{
        width: "100%",
        minHeight: "calc(100vh - 225px)",
        height: "auto",
        paddingBottom: "48px",
        backgroundColor: "#F2F2F3",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Dialog
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": { maxWidth: "750px" },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            width: "750px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{
              width: "750px",
              padding: "30px 24px 40px 24px",
              minHeight: { xl: "694px", md: "594px" },
            }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <ThemeProvider theme={theme}>
              <Stack spacing={3} p={0} direction="column">
                <Typography component="h3" variant="h3" sx={testStyle}>
                  Select Customer
                </Typography>
                <Stack
                  spacing={0}
                  p={0}
                  direction="row"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  {data && <SalesSearch data={data.data} search={setSearch} />}
                  <Button
                    onClick={handleNewCustomer}
                    variant="outlined"
                    data-testid="addbutton"
                    color="primary"
                    startIcon={<AddIcon />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                      width: "178px",
                    }}
                    aria-label={
                      ADAConfig.ADA.Opportunities.createNewCustomer_button
                    }
                  >
                    New Customer
                  </Button>
                </Stack>
                {data && (
                  <ListCustomers
                    data={filtered}
                    setOpen={setOpen}
                    setCustomer={setCustomer}
                  />
                )}
              </Stack>
            </ThemeProvider>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SalesContainer;
