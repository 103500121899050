import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useState } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import Radio from "@mui/material/Radio";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  shipperDetailsIDControl,
  shipperDetailsData,
} from "../ViewShipper/ViewShipperSlice";

import {
  shipperControl,
  resetData,
  shipperData,
} from "../../components/ShipperDetails/ShipperDetailsSlice";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell, i) => (
          <TableCell
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              fontStyle: "normal",
              backgroundColor: "#F2F2F3",
            }}
            align={headCell.align}
            style={{
              width: headCell.width,
              minWidth: headCell.minWidth,
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.numeric ? (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1 },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={headCell.numeric && UnfoldMoreIcon}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <TableSortLabel
                sx={{
                  "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                  "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                    opacity: 1,
                    display: "none",
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "#000000 !important",
                    display: "none",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const columns = [
  {
    id: "select",
    label: "",
    minWidth: 80,
    width: "7%",
    align: "center",
    numeric: false,
  },
  {
    id: "productline",
    label: "Product Line",
    minWidth: 150,
    width: "18%",
    align: "left",
    numeric: false,
  },
  {
    id: "shipperclass",
    label: "Class",
    minWidth: 150,
    width: "10%",
    align: "left",
    numeric: false,
  },
  {
    id: "pcm",
    label: "PCM",
    minWidth: 150,
    width: "10%",
    align: "center",
    numeric: false,
  },
  {
    id: "ticconfig",
    label: "Configuration",
    minWidth: 150,
    width: "10%",
    align: "left",
    numeric: false,
  },
  {
    id: "created_on",
    label: "Creation Date",
    minWidth: 150,
    width: "15%",
    align: "left",
    numeric: true,
  },
  {
    id: "updated_on",
    label: "Last Edited",
    minWidth: 150,
    width: "15%",
    align: "center",
    numeric: true,
  },
  {
    id: "viewdetails",
    label: "View Details",
    minWidth: "-webkit-fill-available",
    width: "-webkit-fill-available",
    align: "center",
    numeric: false,
  },
];

const ListView = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  //const shipperEditDetails = useSelector(shipperEditData);
  const shipper = useSelector(shipperData);

  const ShipperDetails = useSelector(shipperDetailsData);

  const {
    filter,
    data,
    dataCount,
    dataProductLine,
    dataShipperId,
    dataShipperClass,
    dataPcmType,
    dataConfiguration,
    dataCreationDate,
    dataLastEditedDate,
    search,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState();

  const handleChange = (
    event,
    id,
    productLine,
    shipperClass,
    pcmType,
    configuration,
    createdDate,
    modifiedDate
  ) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      dataShipperId("");
      dataCount(true);
      dataProductLine("");
      dataShipperClass("");
      dataPcmType("");
      dataConfiguration("");
      dataCreationDate("");
      dataLastEditedDate("");
      shipperEditDispatch("");
    } else {
      setSelectedValue(id);
      dataCount(false);
      dataShipperId(id);
      dataProductLine(productLine);
      dataShipperClass(shipperClass);
      dataPcmType(pcmType);
      dataConfiguration(configuration);
      dataCreationDate(createdDate);
      dataLastEditedDate(modifiedDate);
      shipperEditDispatch(id);
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_on");
  const [selected, setSelected] = useState([]);
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const shipperEditDispatch = (id) => {
    dispatch(resetData());
    dispatch(
      shipperControl({ ...shipper, shipper: { ...shipper.shipper, id: id } })
    );
  };

  const isSelected = (name) => (selectedValue === name ? true : false);

  const filtered = search
    ? data.filter((user) => {
        return user.name === search.name;
      })
    : data;

  const viewShipper = (event, shipper) => {
    history.push("/dashboard/shipper/view/" + shipper);
    updateShipperID(shipper);
    if (ShipperDetails?.id === shipper) {
    }
  };

  const updateShipperID = (shipper) => {
    dispatch(shipperDetailsIDControl(shipper));
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",

            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.data.length}
          />
          <TableBody>
            {filter && filtered.length === 0 && (
              <NoRecordFound
                title="No Result Found"
                description=""
              />
            )}
            {!filter && filtered.length === 0 && (
              <NoRecordFound
                title="No shipper found"
                description="Click New Shipper to start creating shipper"
              />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                let selctedId = row.id; //.replace(/\s+/g, "").toLowerCase();
                const isItemSelected = isSelected(selctedId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleChange(
                        event,
                        row.id,
                        row.productline,
                        row.shipperclass,
                        row.pcm,
                        row.ticconfig,
                        row.created_on,
                        row.updated_on
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={selctedId}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                    }}
                  >
                    {columns.map((column, i) => {
                      let value =
                        i === 0 ? (
                          row.id
                        ) : i === 1 ? (
                          row.productline
                        ) : i === 2 ? (
                          row.shipperclass
                        ) : i === 3 ? (
                          row.pcm
                        ) : i === 4 ? (
                          row.ticconfig
                        ) : i === 5 ? (
                          row.created_on
                        ) : i === 6 ? (
                          row.updated_on
                        ) : (
                          <ChevronRightIcon
                            sx={{
                              align: "center",
                              cursor: "pointer",
                              color: "#000",
                            }}
                            onClick={(event) => viewShipper(event, row?.id)}
                          />
                        );

                      let cellWidth =
                        i === 0 ? "5%" : i === 1 || i === 2 ? "20%" : "10%";
                      return i === 0 ? (
                        <TableCell
                          padding="checkbox"
                          align={column.align}
                          style={{ width: column.width, padding: 0 }}
                        >
                          <Stack direction="row">
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Radio
                                checked={selectedValue === selctedId}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    row.id,
                                    row.productline,
                                    row.shipperclass,
                                    row.pcm,
                                    row.ticconfig,
                                    row.created_on,
                                    row.updated_on
                                  )
                                }
                                value={selctedId}
                                name={`radio-${selctedId}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={i}
                          align={column.align}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          style={{ width: column.width }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListView;
