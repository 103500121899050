import React from "react";
import Box from "@mui/material/Box";
import Admin from "../../components/Admin";
import SalesEngineer from "../../components/SalesEngineer";

const MainContainer = (props) => {
  const isBG = props.bg ? props.bg : "transperant";
  return (
    <Box
      maxWidth="false"
      sx={{
        width: "100%",
        minHeight: {
          xs: "calc(100vh - 160px)",
          sm: "calc(100vh - 160px)",
          md: "calc(100vh - 160px)",
          lg: "calc(100vh - 200px)",
          xl: "calc(100vh - 224px)",
        },
        height: "auto",
        backgroundColor: isBG ? "#F2F2F3" : "transperant",
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-testid="outer-box-container"
    >
      {(props.loginDetails.active_role === "Admin" ||
        props.loginDetails.role === "Account Manager") && <Admin />}
      {props.loginDetails.active_role === "Sales Engineer" && <SalesEngineer />}
    </Box>
  );
};

export default MainContainer;
