import { configureStore } from "@reduxjs/toolkit";
import selectTabReducer from "../components/Navigator/navigateSlice";
import LoginReducer from "../components/Login/LoginSlice";
import ShipperDictionaryReducer from "../components/ShipperDictionary/ShipperDictionarySlice";
import PCMListViewReducer from "../components/PCM/PCMListViewSlice";

import NewShipperReducer from "../components/NewShipper/NewShipperSlice";
import ViewShipperReducer from "../components/ViewShipper/ViewShipperSlice";
import EditShipperReducer from "../components/Shipper/EditShipper/EditShipperSlice";
import CustomerReducer from "../components/Customer/CustomerSlice";
import ShipperReducer from "../components/ShipperDetails/ShipperDetailsSlice";
import OppotunityReducer from "../components/Opportunities/OppotunityListViewSlice";
import ScenarioReducer from "../components/Scenario/ScenarioSlice";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import DataReducer from "./reducer";

const reducers = combineReducers({
  scenario: ScenarioReducer,
  usertab: selectTabReducer,
  login: LoginReducer,
  shipperDictionary: ShipperDictionaryReducer,
  pcmListView: PCMListViewReducer,
  data: DataReducer,
  customer: CustomerReducer,
  newshipper: NewShipperReducer,
  shipperdetails: ViewShipperReducer,
  shipper: ShipperReducer,
  oppotunityListView: OppotunityReducer,
  editshipper: EditShipperReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
