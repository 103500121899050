import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ShipperDictionary from "../../pages/ShipperDictionary";
import Customer from "../../pages/Customer";
import Users from "../Users/Users";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./nav.css";
import { useSelector, useDispatch } from "react-redux";
import { tab, selectTab } from "./navigateSlice";

import { loginData } from "../../components/Login/LoginSlice";

const Navigator = () => {
  const loginDetails = useSelector(loginData);
  const selectedTab = useSelector(selectTab);
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });

  const handleChange = (event, newValue) => {
    dispatch(tab(newValue.toString() || "0"));
  };

  const text = {
    shipperDict: "Shipper Dictionaries",
    addUser: "Users",
    custOpp: "Customers",
  };

  useEffect(() => {
    if (loginDetails.role === "Account Manager") {
      dispatch(tab("2"));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        maxWidth="false"
        sx={{
          width: "100%",
          backgroundColor: "#F2F2F3",
          padding: "32px 48px 48px 48px",
        }}
      >
        <TabContext
          value={selectedTab}
          sx={{ "&.Mui-selected": { color: "#19191A", fontWeight: "600" } }}
        >
          <Box
            sx={{
              backgroundColor: "#F2F2F3",
              borderBottom: 0,
              borderColor: "none",
              mb: "24px",
            }}
          >
            <TabList
              fontWeight={600}
              textColor="secondary"
              TabIndicatorProps={{
                sx: {
                  "& .MuiButtonBase-root": { padding: 0 },
                  "& .MuiTabs-indicator": { display: "none !important" },
                  display: "none !important",
                  hidden: true,
                },
                style: { display: "none", hidden: true },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label={text.shipperDict}
                sx={{
                  paddingRight: "24px",
                  padding: 0,
                  textTransform: "none",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#19191A",
                  "& .Mui-selected": { color: "#19191A", fontWeight: "600" },
                  display:
                    loginDetails.role === "Account Manager" ? "none" : "",
                }}
                value="0"
              />
              <Tab
                label={text.addUser}
                sx={{
                  paddingRight: "24px",
                  padding: 0,
                  textTransform: "none",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#19191A",
                  "& .Mui-selected": { color: "#19191A", fontWeight: "600" },
                  display:
                    loginDetails.role === "Account Manager" ? "none" : "",
                }}
                value="1"
              />

              <Tab
                label={text.custOpp}
                sx={{
                  paddingRight: "24px",
                  padding: 0,
                  textTransform: "none",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#19191A",
                  "& .Mui-selected": { color: "#19191A", fontWeight: "600" },
                }}
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel
            value="0"
            sx={{
              padding: "0",
              boxShadow:
                "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
            }}
          >
            <ShipperDictionary text="Shipper Dictionaries" />
          </TabPanel>
          <TabPanel
            value="1"
            sx={{
              padding: "0",
              boxShadow:
                "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Users />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              padding: "0",
              boxShadow:
                "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
            }}
          >
            <Customer text="Customers" />
          </TabPanel>
        </TabContext>
      </Box>
    </ThemeProvider>
  );
};

export default Navigator;
