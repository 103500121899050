import { createSlice } from "@reduxjs/toolkit";

export const OppotunityListViewSlice = createSlice({
  name: "Oppotunity List View",
  initialState: {
    value: {
      id: "",
      name: "",
    },
  },
  reducers: {
    oppotunityListViewControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { oppotunityListViewControl } = OppotunityListViewSlice.actions;

export const oppotunityListViewData = (state) =>
  state?.oppotunityListView?.value;

export default OppotunityListViewSlice.reducer;
