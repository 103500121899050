import { createSlice, createAction } from "@reduxjs/toolkit";

const updatevalue = createAction("update");

const data = {
  released: false,
  shipperdict_id: "",
  productline_name: "----",
  productline_id: "",
  pcm_type: "----",
  shipperclass: {
    id: "",
    class_type: "----",
    outer_dimension: {
      length: "",
      width: "",
      height: "",
      outside_area: "",
    },
    dry_goods: {
      weight: "",
      cost: "",
    },
    pcm: {
      top: {
        od_length: "",
        od_width: "",
      },
      side: {
        od_length: "",
        od_width: "",
      },
      end: {
        od_length: "",
        od_width: "",
      },
    },
    insulation: {
      top: {
        thickness: "",
        specific_heat: "",
        density: "",
      },
      side: {
        thickness: "",
        specific_heat: "",
        density: "",
      },
      end: {
        thickness: "",
        specific_heat: "",
        density: "",
      },
    },
  },
  pcmtype_id: "",
  ticconfig: {
    tic_type: "----",
    configurations: {
      weight: "",
      emissions: "",
      landfill: "",
      cost: "",
      top_pcm: "",
      side_pcm: "",
      end_pcm: "",
      total_pcm: "",
      length: "",
      width: "",
      height: "",
      top_wall: "",
      side_wall: "",
      end_wall: "",
    },
  },
  probeconfig: {
    on_base: {
      probe_summer: {
        top_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        end_face: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
      },
      probe_winter: {
        top_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        end_face: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
      },
    },
    on_side: {
      probe_summer: {
        top_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        end_face: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
      },
      probe_winter: {
        top_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        end_face: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_center: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
        bottom_corner: {
          r_value: "",
          top_wall: "",
          end_wall: "",
          side_wall: "",
        },
      },
    },
  },
};

export const EditShipperSlice = createSlice({
  name: "editshipper",
  initialState: {
    value: data,
  },
  reducers: {
    editShipperControl: (state, action) => {
      state.value = action.payload;
    },    
    shipperIDUpdate: (state, action) => {
      state.value.shipperdict_id = action.payload;
    },
    updateRelease: (state, action) => {
      state.value.released = action.payload;
    },
    productLineUpdate: (state, action) => {
      state.value.productline_id = action.payload;
    },
    productLineNameUpdate: (state, action) => {
      state.value.productline_name = action.payload;
    },
    pcmTypeNameUpdate: (state, action) => {
      state.value.pcm_type = action.payload;
    },
    shipperClassIDUpdate: (state, action) => {
      state.value.shipperclass.id = action.payload;
    },
    shipperClassTypeUpdate: (state, action) => {
      state.value.shipperclass.class_type = action.payload;
    },
    outerDimensionsUpdate: (state, action) => {
      state.value.shipperclass.outer_dimension = action.payload;
    },
    dryGoodsUpdate: (state, action) => {
      state.value.shipperclass.dry_goods = action.payload;
    },
    pcmTabUpdate: (state, action) => {
      state.value.shipperclass.pcm = action.payload;
    },
    insulationUpdate: (state, action) => {
      state.value.shipperclass.insulation = action.payload;
    },
    pcmTypeUpdate: (state, action) => {
      state.value.pcmtype_id = action.payload;
    },
    ticTypeUpdate: (state, action) => {
      state.value.ticconfig.tic_type = action.payload;
    },
    ticConfigurationUpdate: (state, action) => {
      state.value.ticconfig.configurations = action.payload;
    },
    probeConfigurationUpdate: (state, action) => {
      state.value.probeconfig  = action.payload;
    },
    resetData: (state) => {
      state.value = data;
    },
    shipperDetailsControl: (state, action) => {
        state.value = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder.addCase(updatevalue, (state, action) => {});
    builder.addDefaultCase((state, action) => {
      state.otherActions++;
    });
  },
});

export const {
  editShipperControl,
  updateRelease,
  shipperIDUpdate,
  productLineUpdate,
  productLineNameUpdate,
  shipperClassIDUpdate,
  shipperClassTypeUpdate,
  pcmTabUpdate,
  insulationUpdate,
  pcmTypeNameUpdate,
  outerDimensionsUpdate,
  dryGoodsUpdate,
  pcmTypeUpdate,
  ticTypeUpdate,
  ticConfigurationUpdate,
  probeConfigurationUpdate,
  resetData,
  shipperDetailsControl
} = EditShipperSlice.actions;

export const shipperData = (state) => state?.editshipper?.value;

export default EditShipperSlice.reducer;
