import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loginControl, loginData } from "../../components/Login/LoginSlice";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import SnackBar from "../../components/core/SnackBar/SnackBar";
import * as ConstantsList from "../../constants";
import { useDispatch } from "react-redux";
import { tab } from "../../components/Navigator/navigateSlice";
const ViewProfile = () => {
  const userData = useSelector(loginData);
  let history = useHistory();
  const dispatch = useDispatch();
  const [uname, setUname] = useState(userData?.username);
  const [uemail, setEmail] = useState(userData?.email);
  const [role, setRole] = useState(userData?.role);
  const [notify, setNotify] = useState(false);

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const labelStyle = {
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3F4042",
  };

  const changeuname = (e) => {
    setUname(e.target.value);
  };

  const changeuemail = (e) => {
    setEmail(e.target.value);
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const submitButton = () => {
    if (uname.length !== 0) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: uname, email: uemail, role: role }),
      };
      fetch(ConstantsList.API_URL + "trufit/modusr/", requestOptions)
        .then((response) => {
          if (response.ok) {
            setNotify(true);
            dispatch(tab("1"));
            dispatch(loginControl({ ...userData, username: uname }));
            setTimeout(() => {
              history.push("/dashboard");
            }, 1000);
          }
        })
        .then((data) => {
          setNotify(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const cancelButton = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    setTimeout(() => {
      for (let i = 0; i < 10000; i++) {
        window.clearInterval(i);
      }
    }, 10000);
  }, []);

  return (
    <Box
      maxWidth="false"
      p={6}
      sx={{
        minHeight: {
          xs: "calc(100vh - 160px)",
          sm: "calc(100vh - 160px)",
          md: "calc(100vh - 160px)",
          lg: "calc(100vh - 200px)",
          xl: "calc(100vh - 224px)",
        },
        height: "auto",
        backgroundColor: "#F2F2F3",
        borderRadius: "6px",
        boxShadow:
          "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      }}
      data-testid="box-outer-container"
    >
      <Box
        maxWidth="false"
        sx={{
          width: "100%",
          height: "auto",
          borderRadius: "6px",
          backgroundColor: "#FFFFFF",
          boxShadow:
            "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
        }}
        data-testid="box-inner-container"
      >
        <Stack p={3} sx={{ width: "396px" }}>
          <Typography
            component="h5"
            variant="h5"
            sx={{
              paddingTop: "8px",
              fontWeight: "500",
              fontSize: "24px",
              lineHeight: "30px",
              color: "#000000",
            }}
          >
            User Profile
          </Typography>
          <ThemeProvider theme={checkboxTheme}>
            <Stack spacing={4} sx={{ marginTop: "46px" }}>
              <Stack spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  User Name
                </Typography>
                <TextField
                  fullWidth
                  onChange={changeuname}
                  value={uname}
                  placeholder="Enter User Name"
                  id="uname"
                  sx={{
                    "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root": {
                      borderRadius: "6px",
                      height: "44px",
                    },
                    "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                      margin: "0px !important",
                    },
                  }}
                  data-testid="input-box1"
                />
              </Stack>
              <Stack spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Email
                </Typography>
                <TextField
                  disabled={true}
                  fullWidth
                  onChange={changeuemail}
                  value={uemail}
                  placeholder="user.name@companymail.com"
                  id="uemail"
                  sx={{
                    "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root": {
                      borderRadius: "6px",
                      height: "44px",
                    },
                    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                      { padding: "8.2px 14px" },
                    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                      {
                        backgroundColor: "#F8F8F9",
                        borderColor: "#F2F2F3",
                        color: "#F2F2F3",
                      },
                    "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                      margin: "0px !important",
                    },
                  }}
                  data-testid="input-box2"
                />
              </Stack>
              <Stack spacing={1} p={0} direction="column">
                <FormControl fullWidth>
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Role
                  </Typography>
                  <Select
                    disabled={true}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      height: "44px",
                      borderRadius: "6px",
                      marginTop: "8px",
                      "& .css-yb6wv8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        { padding: "8.2px 14px" },
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                        {
                          backgroundColor: "#F8F8F9",
                          borderColor: "#F2F2F3",
                          color: "#F2F2F3",
                        },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                    labelId="role-label"
                    id="profile-role-select"
                    value={role}
                    onChange={handleChange}
                    data-testid="select-option"
                  >
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                    <MenuItem value={"Sales Engineer"}>Sales Engineer</MenuItem>
                    <MenuItem value={"Account Manager"}>
                      Account Manager
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack spacing={2} p={0} direction="row">
                <Button
                  onClick={cancelButton}
                  fullWidth
                  sx={{
                    border: "1px solid #B1B2B4",
                    height: "42px",
                    backgroundColor: "#FFFFFF",
                    color: "#3F4042",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#F26322" },
                  }}
                  data-testid="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  onClick={submitButton}
                  fullWidth
                  sx={{
                    height: "42px",
                    backgroundColor: "#F26322",
                    color: "#3F4042",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#F26322" },
                  }}
                  data-testid="submit-btn"
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </ThemeProvider>
        </Stack>
      </Box>

      {notify && (
        <SnackBar
          open={notify}
          setOpen={setNotify}
          message="Profile Username Updated Successfully!"
        />
      )}
    </Box>
  );
};

export default ViewProfile;
