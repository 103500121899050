import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tab,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Probe3DPic from "../../assets/images/onbase.svg";
import Probe3DSidePic from "../../assets/images/onside.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./index.css";
import * as Config from "../../config";

import {
  shipperData,
  probeConfigurationUpdate,
} from "../NewShipper/NewShipperSlice";
import { useSelector, useDispatch } from "react-redux";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ProbeConfiguration = (props) => {
  const { setProbeCheck } = props;
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const [selectTab, setSelectTab] = useState("0");
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip;
  const handleChange = (event, newValue) => {
    setSelectTab(newValue.toString());
  };

  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [topSummer, setTopSummer] = useState([
    {
      id: 1,
      name: "Top Corner",
      data: [
        {
          id: 11,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.top_corner.r_value,
          error: false,
          message: "",
        },
        {
          id: 12,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.top_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 13,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.top_corner.end_wall,
          error: false,
          message: "",
        },
        {
          id: 14,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.top_corner.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 2,
      name: "End Face",
      data: [
        {
          id: 21,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.end_face
              .r_value,
          error: false,
          message: "",
        },
        {
          id: 22,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.end_face
              .top_wall,
          error: false,
          message: "",
        },
        {
          id: 23,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.end_face
              .end_wall,
          error: false,
          message: "",
        },
        {
          id: 24,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.end_face
              .side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 3,
      name: "Center",
      data: [
        {
          id: 31,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.center
              .r_value,
          error: false,
          message: "",
        },
        {
          id: 32,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.center
              .top_wall,
          error: false,
          message: "",
        },
        {
          id: 33,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.center
              .end_wall,
          error: false,
          message: "",
        },
        {
          id: 34,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer?.center
              .side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 4,
      name: "Bottom Center",
      data: [
        {
          id: 41,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_center.r_value,
          error: false,
          message: "",
        },
        {
          id: 42,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_center.top_wall,
          error: false,
          message: "",
        },
        {
          id: 43,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_center.end_wall,
          error: false,
          message: "",
        },
        {
          id: 44,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_center.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 5,
      name: "Bottom Corner",
      data: [
        {
          id: 51,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_corner.r_value,
          error: false,
          message: "",
        },
        {
          id: 52,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 53,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_corner.end_wall,
          error: false,
          message: "",
        },
        {
          id: 54,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_summer
              ?.bottom_corner.side_wall,
          error: false,
          message: "",
        },
      ],
    },
  ]);
  const [topWinter, setTopWinter] = useState([
    {
      id: 1,
      name: "Top Corner",
      data: [
        {
          id: 11,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.top_corner.r_value,
          error: false,
          message: "",
        },
        {
          id: 12,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.top_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 13,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.top_corner.end_wall,
          error: false,
          message: "",
        },
        {
          id: 14,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.top_corner.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 2,
      name: "End Face",
      data: [
        {
          id: 21,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.end_face
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 22,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.end_face
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 23,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.end_face
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 24,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.end_face
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 3,
      name: "Center",
      data: [
        {
          id: 31,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.center
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 32,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.center
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 33,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.center
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 34,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter?.center
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 4,
      name: "Bottom Center",
      data: [
        {
          id: 41,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_center.r_value,
          error: false,
          message: "",
        },
        {
          id: 42,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_center.top_wall,
          error: false,
          message: "",
        },
        {
          id: 43,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_center.end_wall,
          error: false,
          message: "",
        },
        {
          id: 44,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_center.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 5,
      name: "Bottom Corner",
      data: [
        {
          id: 51,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_corner.r_value,
          error: false,
          message: "",
        },
        {
          id: 52,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 53,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_corner.end_wall,
          error: false,
          message: "",
        },
        {
          id: 54,
          value:
            shipperDetails?.probe_configuration?.on_base?.probe_winter
              ?.bottom_corner.side_wall,
          error: false,
          message: "",
        },
      ],
    },
  ]);
  const [sideSummer, setSideSummer] = useState([
    {
      id: 1,
      name: "Top Corner",
      data: [
        {
          id: 11,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.top_corner?.r_value,
          error: false,
          message: "",
        },
        {
          id: 12,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.top_corner?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 13,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.top_corner?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 14,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.top_corner?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 2,
      name: "End Face",
      data: [
        {
          id: 21,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.end_face
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 22,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.end_face
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 23,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.end_face
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 24,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.end_face
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 3,
      name: "Center",
      data: [
        {
          id: 31,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.center
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 32,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.center
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 33,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.center
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 34,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer?.center
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 4,
      name: "Bottom Center",
      data: [
        {
          id: 41,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_center?.r_value,
          error: false,
          message: "",
        },
        {
          id: 42,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_center?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 43,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_center?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 44,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_center?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 5,
      name: "Bottom Corner",
      data: [
        {
          id: 51,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_corner?.r_value,
          error: false,
          message: "",
        },
        {
          id: 52,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 53,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_corner?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 54,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_summer
              ?.bottom_corner?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
  ]);
  const [sideWinter, setSideWinter] = useState([
    {
      id: 1,
      name: "Top Corner",
      data: [
        {
          id: 11,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.top_corner?.r_value,
          error: false,
          message: "",
        },
        {
          id: 12,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.top_corner?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 13,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.top_corner?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 14,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.top_corner?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 2,
      name: "End Face",
      data: [
        {
          id: 21,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.end_face
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 22,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.end_face
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 23,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.end_face
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 24,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.end_face
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 3,
      name: "Center",
      data: [
        {
          id: 31,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.center
              ?.r_value,
          error: false,
          message: "",
        },
        {
          id: 32,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.center
              ?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 33,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.center
              ?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 34,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter?.center
              ?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 4,
      name: "Bottom Center",
      data: [
        {
          id: 41,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_center?.r_value,
          error: false,
          message: "",
        },
        {
          id: 42,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_center?.top_wall,
          error: false,
          message: "",
        },
        {
          id: 43,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_center?.end_wall,
          error: false,
          message: "",
        },
        {
          id: 44,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_center?.side_wall,
          error: false,
          message: "",
        },
      ],
    },
    {
      id: 5,
      name: "Bottom Corner",
      data: [
        {
          id: 51,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_corner?.r_value,
          error: false,
          message: "",
        },
        {
          id: 52,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_corner.top_wall,
          error: false,
          message: "",
        },
        {
          id: 53,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_corner.end_wall,
          error: false,
          message: "",
        },
        {
          id: 54,
          value:
            shipperDetails?.probe_configuration?.on_side?.probe_winter
              ?.bottom_corner.side_wall,
          error: false,
          message: "",
        },
      ],
    },
  ]);

  const handleChangeTopSummer = (e, rIndex, cIndex) => {
    const inputData = [...topSummer];
    inputData[rIndex].data[cIndex].value = !isNaN(parseFloat(e.target.value))
      ? parseFloat(e.target.value)
      : "";
    setTopSummer(inputData);
  };

  const handleChangeTopWinter = (e, rIndex, cIndex) => {
    const inputData = [...topWinter];
    inputData[rIndex].data[cIndex].value = !isNaN(parseFloat(e.target.value))
      ? parseFloat(e.target.value)
      : "";
    setTopWinter(inputData);
  };

  const handleChangeSideSummer = (e, rIndex, cIndex) => {
    const inputData = [...sideSummer];
    inputData[rIndex].data[cIndex].value = !isNaN(parseFloat(e.target.value))
      ? parseFloat(e.target.value)
      : "";
    setSideSummer(inputData);
  };

  const handleChangeSideWinter = (e, rIndex, cIndex) => {
    const inputData = [...sideWinter];
    inputData[rIndex].data[cIndex].value = !isNaN(parseFloat(e.target.value))
      ? parseFloat(e.target.value)
      : "";
    setSideWinter(inputData);
  };

  const [selectedCheck, setSelectedCheck] = useState([]);

  const handleCheck = (e, id, name) => {
    const selectedIndex = selectedCheck.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCheck, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCheck.slice(1));
    } else if (selectedIndex === selectedCheck.length - 1) {
      newSelected = newSelected.concat(selectedCheck.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCheck.slice(0, selectedIndex),
        selectedCheck.slice(selectedIndex + 1)
      );
    }
    setSelectedCheck(newSelected);
  };
  const isSelected = (name) => selectedCheck.indexOf(name) !== -1;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });

  useEffect(() => {
    const ProbeConfig = {
      on_base: {
        probe_summer: {
          top_corner: {
            r_value: topSummer[0].data[0].value,
            top_wall: topSummer[0].data[1].value,
            end_wall: topSummer[0].data[2].value,
            side_wall: topSummer[0].data[3].value,
          },
          end_face: {
            r_value: topSummer[1].data[0].value,
            top_wall: topSummer[1].data[1].value,
            end_wall: topSummer[1].data[2].value,
            side_wall: topSummer[1].data[3].value,
          },
          center: {
            r_value: topSummer[2].data[0].value,
            top_wall: topSummer[2].data[1].value,
            end_wall: topSummer[2].data[2].value,
            side_wall: topSummer[2].data[3].value,
          },
          bottom_center: {
            r_value: topSummer[3].data[0].value,
            top_wall: topSummer[3].data[1].value,
            end_wall: topSummer[3].data[2].value,
            side_wall: topSummer[3].data[3].value,
          },
          bottom_corner: {
            r_value: topSummer[4].data[0].value,
            top_wall: topSummer[4].data[1].value,
            end_wall: topSummer[4].data[2].value,
            side_wall: topSummer[4].data[3].value,
          },
        },
        probe_winter: {
          top_corner: {
            r_value: topWinter[0].data[0].value,
            top_wall: topWinter[0].data[1].value,
            end_wall: topWinter[0].data[2].value,
            side_wall: topWinter[0].data[3].value,
          },
          end_face: {
            r_value: topWinter[1].data[0].value,
            top_wall: topWinter[1].data[1].value,
            end_wall: topWinter[1].data[2].value,
            side_wall: topWinter[1].data[3].value,
          },
          center: {
            r_value: topWinter[2].data[0].value,
            top_wall: topWinter[2].data[1].value,
            end_wall: topWinter[2].data[2].value,
            side_wall: topWinter[2].data[3].value,
          },
          bottom_center: {
            r_value: topWinter[3].data[0].value,
            top_wall: topWinter[3].data[1].value,
            end_wall: topWinter[3].data[2].value,
            side_wall: topWinter[3].data[3].value,
          },
          bottom_corner: {
            r_value: topWinter[4].data[0].value,
            top_wall: topWinter[4].data[1].value,
            end_wall: topWinter[4].data[2].value,
            side_wall: topWinter[4].data[3].value,
          },
        },
      },
      on_side: {
        probe_summer: {
          top_corner: {
            r_value: sideSummer[0].data[0].value,
            top_wall: sideSummer[0].data[1].value,
            end_wall: sideSummer[0].data[2].value,
            side_wall: sideSummer[0].data[3].value,
          },
          end_face: {
            r_value: sideSummer[1].data[0].value,
            top_wall: sideSummer[1].data[1].value,
            end_wall: sideSummer[1].data[2].value,
            side_wall: sideSummer[1].data[3].value,
          },
          center: {
            r_value: sideSummer[2].data[0].value,
            top_wall: sideSummer[2].data[1].value,
            end_wall: sideSummer[2].data[2].value,
            side_wall: sideSummer[2].data[3].value,
          },
          bottom_center: {
            r_value: sideSummer[3].data[0].value,
            top_wall: sideSummer[3].data[1].value,
            end_wall: sideSummer[3].data[2].value,
            side_wall: sideSummer[3].data[3].value,
          },
          bottom_corner: {
            r_value: sideSummer[4].data[0].value,
            top_wall: sideSummer[4].data[1].value,
            end_wall: sideSummer[4].data[2].value,
            side_wall: sideSummer[4].data[3].value,
          },
        },
        probe_winter: {
          top_corner: {
            r_value: sideWinter[0].data[0].value,
            top_wall: sideWinter[0].data[1].value,
            end_wall: sideWinter[0].data[2].value,
            side_wall: sideWinter[0].data[3].value,
          },
          end_face: {
            r_value: sideWinter[1].data[0].value,
            top_wall: sideWinter[1].data[1].value,
            end_wall: sideWinter[1].data[2].value,
            side_wall: sideWinter[1].data[3].value,
          },
          center: {
            r_value: sideWinter[2].data[0].value,
            top_wall: sideWinter[2].data[1].value,
            end_wall: sideWinter[2].data[2].value,
            side_wall: sideWinter[2].data[3].value,
          },
          bottom_center: {
            r_value: sideWinter[3].data[0].value,
            top_wall: sideWinter[3].data[1].value,
            end_wall: sideWinter[3].data[2].value,
            side_wall: sideWinter[3].data[3].value,
          },
          bottom_corner: {
            r_value: sideWinter[4].data[0].value,
            top_wall: sideWinter[4].data[1].value,
            end_wall: sideWinter[4].data[2].value,
            side_wall: sideWinter[4].data[3].value,
          },
        },
      },
    };

    dispatch(probeConfigurationUpdate(ProbeConfig));
  }, [topSummer, topWinter, sideWinter, sideSummer, dispatch]);

  const ValidateData = (ValueArray) => {
    let TestData = true;
    for (let i = 0; i < ValueArray.length; i++) {
      let Data =
        ValueArray[i]?.value.toString().trim().length > 0 ? true : false;
      if (!Data) {
        TestData = false;
      }
    }
    return TestData;
  };

  useEffect(() => {
    if (selectedCheck) {
      const TestDatas = selectedCheck.map((data) => data.split("-"));
      let data = true;
      for (let i = 0; i < TestDatas.length; i++) {
        if (TestDatas[i][0] === "Base") {
          if (TestDatas[i][1] === "ProbeSummer") {
            data = ValidateData(topSummer[TestDatas[i][2]]?.data);
          } else if (TestDatas[i][1] === "ProbeWinter") {
            data = ValidateData(topWinter[TestDatas[i][2]]?.data);
          }
        } else if (TestDatas[i][0] === "Side") {
          if (TestDatas[i][1] === "ProbeSummer") {
            data = ValidateData(sideSummer[TestDatas[i][2]]?.data);
          } else if (TestDatas[i][1] === "ProbeWinter") {
            data = ValidateData(sideWinter[TestDatas[i][2]]?.data);
          }
        }
      }

      if (selectedCheck.length > 0) {
      } else {
        data = false;
      }

      setProbeCheck(data);
    }
  }, [selectedCheck, topSummer, topWinter, sideSummer, sideWinter]);

  return (
    <Box maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <ThemeProvider theme={theme}>
        <Box maxWidth="false" px={3} pb={5}>
          <TabContext
            value={selectTab}
            sx={{
              "&.Mui-selected": {
                color: "#19191A",
                fontWeight: "600",
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderBottom: 0,
                borderColor: "none",
                mb: "24px",
              }}
            >
              <TabList
                fontWeight={600}
                textColor="secondary"
                TabIndicatorProps={{
                  sx: {
                    "& .MuiButtonBase-root": { padding: 0 },
                    "& .MuiTabs-indicator": {
                      display: "none !important",
                    },
                    display: "none !important",
                    hidden: true,
                  },
                  style: { display: "none", hidden: true },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  disableRipple={true}
                  label="On Base"
                  sx={{
                    alignItems: "flex-start",
                    paddingRight: "24px",
                    padding: 0,
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#19191A",
                    "& .Mui-selected": {
                      color: "#19191A",
                      fontWeight: "600",
                    },
                  }}
                  value="0"
                />
                <Tab
                  disableRipple={true}
                  label="On Side"
                  sx={{
                    alignItems: "flex-start",
                    paddingRight: "24px",
                    padding: 0,
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#19191A",
                    "& .Mui-selected": {
                      color: "#19191A",
                      fontWeight: "600",
                    },
                  }}
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel
              value={"0"}
              sx={{
                padding: "0",
              }}
            >
              <ThemeProvider theme={customTheme}>
                <Stack direction="column" spacing={3}>
                  <Stack direction="row" spacing={6}>
                    <Box
                      component="img"
                      src={Probe3DPic}
                      alt="Upload Pic"
                      sx={{
                        width: "238px",
                        height: "394px",
                      }}
                    />

                    <Stack
                      spacing={3}
                      direction="column"
                      sx={{ width: "-webkit-fill-available" }}
                    >
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Probe - Summer
                      </Typography>
                      <Stack
                        direction="column"
                        sx={{ border: "1px solid #E6E7E8" }}
                      >
                        {/* Row 0 */}
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          ></Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              R-Value
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.rvalue}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Top Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.topWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Side Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.sideWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              End Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.endWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>
                        </Stack>

                        {/* Row 1 */}

                        {topSummer &&
                          topSummer.map((row, i) => {
                            const RowName = `Base-ProbeSummer-${i}`;
                            const IsChecked = isSelected(RowName);
                            return (
                              <Stack
                                key={row.id}
                                direction="row"
                                sx={{
                                  width: "100%",
                                  height: "64px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                <Stack
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      onClick={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      checked={IsChecked}
                                      p={0}
                                      value={selectedCheck}
                                      name={`Check ${row.id}`}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Stack>
                                {row.data.map((column, j) => {
                                  return (
                                    <Stack
                                      width={1 / 5}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <FormControl
                                        disabled={!IsChecked}
                                        fullWidth
                                        size="small"
                                        sx={{
                                          width: "11.5rem",
                                          height: "44px",
                                        }}
                                        variant="outlined"
                                      >
                                        <OutlinedInput
                                          type="number"
                                          onInput={(e) =>
                                            handleChangeTopSummer(e, i, j)
                                          }
                                          id={`Prode-Config-Input-Top-Summer-${i}-${j}`}
                                          value={column.value}
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "28px",
                                                width: "auto",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "4px",
                                                backgroundColor: "#F2F2F3",
                                              }}
                                            >
                                              <Typography
                                                variant="p"
                                                mx={1.5}
                                                my={0.75}
                                                sx={{
                                                  fontFamily: "Roboto",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "16px",
                                                  letterSpacing: "5%",
                                                  color: "#19191A",
                                                }}
                                              >
                                                {j === 0 ? (
                                                  <>ft&sup2;·°F·h/BTU</>
                                                ) : (
                                                  "mW/mK"
                                                )}
                                              </Typography>
                                            </InputAdornment>
                                          }
                                          onKeyPress={(event) => {
                                            if (
                                              event?.key === "-" ||
                                              event?.key === "+"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                          sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            color: "#3F4042",
                                          }}
                                        />
                                      </FormControl>
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            );
                          })}
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#FFFFFF",
                          }}
                        ></Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={6}>
                    <Box
                      component="div"
                      alt="Upload Pic"
                      sx={{
                        minWidth: "238px",
                        height: "394px",
                      }}
                    />

                    <Stack
                      spacing={3}
                      direction="column"
                      sx={{ width: "-webkit-fill-available" }}
                    >
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Probe - Winter
                      </Typography>
                      <Stack
                        direction="column"
                        sx={{ border: "1px solid #E6E7E8" }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          ></Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              R-Value
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.rvalue}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Top Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.topWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Side Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.sideWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              End Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.endWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>
                        </Stack>

                        {/* Row 1 */}

                        {topWinter &&
                          topWinter.map((row, i) => {
                            const RowName = `Base-ProbeWinter-${i}`;
                            const IsChecked = isSelected(RowName);
                            return (
                              <Stack
                                key={row.id}
                                direction="row"
                                sx={{
                                  width: "100%",
                                  height: "64px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                <Stack
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      onClick={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      checked={IsChecked}
                                      p={0}
                                      value={selectedCheck}
                                      name={`Check ${row.id}`}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Stack>
                                {row.data.map((column, j) => {
                                  return (
                                    <Stack
                                      width={1 / 5}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <FormControl
                                        disabled={!IsChecked}
                                        fullWidth
                                        size="small"
                                        sx={{
                                          width: "11.5rem",
                                          height: "44px",
                                        }}
                                        variant="outlined"
                                      >
                                        <OutlinedInput
                                          type="number"
                                          onInput={(e) =>
                                            handleChangeTopWinter(e, i, j)
                                          }
                                          id={`Prode-Config-Input-${i}-${j}`}
                                          value={column.value}
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "28px",
                                                width: "auto",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "4px",
                                                backgroundColor: "#F2F2F3",
                                              }}
                                            >
                                              <Typography
                                                variant="p"
                                                mx={1.5}
                                                my={0.75}
                                                sx={{
                                                  fontFamily: "Roboto",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "16px",
                                                  letterSpacing: "5%",
                                                  color: "#19191A",
                                                }}
                                              >
                                                {j === 0 ? (
                                                  <>ft&sup2;·°F·h/BTU</>
                                                ) : (
                                                  "mW/mK"
                                                )}
                                              </Typography>
                                            </InputAdornment>
                                          }
                                          onKeyPress={(event) => {
                                            if (
                                              event?.key === "-" ||
                                              event?.key === "+"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                          sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            color: "#3F4042",
                                          }}
                                        />
                                      </FormControl>
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            );
                          })}
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#FFFFFF",
                          }}
                        ></Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </ThemeProvider>
            </TabPanel>
            <TabPanel
              value={"1"}
              sx={{
                padding: "0",
              }}
            >
              <ThemeProvider theme={customTheme}>
                <Stack direction="column" spacing={3}>
                  <Stack direction="row" spacing={6}>
                    <Box
                      component="img"
                      src={Probe3DSidePic}
                      alt="Side Probe Configuration"
                      sx={{
                        width: "238px",
                        height: "394px",
                      }}
                    />

                    <Stack
                      spacing={3}
                      direction="column"
                      sx={{ width: "-webkit-fill-available" }}
                    >
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Probe - Summer
                      </Typography>
                      <Stack
                        direction="column"
                        sx={{ border: "1px solid #E6E7E8" }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          ></Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              R-Value
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.rvalue}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Top Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.topWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Side Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.sideWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              End Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.endWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>
                        </Stack>

                        {/* Row 1 */}

                        {sideSummer &&
                          sideSummer.map((row, i) => {
                            const RowName = `Side-ProbeSummer-${i}`;
                            const IsChecked = isSelected(RowName);
                            return (
                              <Stack
                                key={row.id}
                                direction="row"
                                sx={{
                                  width: "100%",
                                  height: "64px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                <Stack
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      onClick={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      checked={IsChecked}
                                      p={0}
                                      value={selectedCheck}
                                      name={`Check ${row.id}`}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Stack>
                                {row.data.map((column, j) => {
                                  return (
                                    <Stack
                                      width={1 / 5}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <FormControl
                                        disabled={!IsChecked}
                                        fullWidth
                                        size="small"
                                        sx={{
                                          width: "11.5rem",
                                          height: "44px",
                                        }}
                                        variant="outlined"
                                      >
                                        <OutlinedInput
                                          type="number"
                                          onInput={(e) =>
                                            handleChangeSideSummer(e, i, j)
                                          }
                                          id={`Prode-Config-Input-${i}-${j}`}
                                          value={column.value}
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "28px",
                                                width: "auto",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "4px",
                                                backgroundColor: "#F2F2F3",
                                              }}
                                            >
                                              <Typography
                                                variant="p"
                                                mx={1.5}
                                                my={0.75}
                                                sx={{
                                                  fontFamily: "Roboto",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "16px",
                                                  letterSpacing: "5%",
                                                  color: "#19191A",
                                                }}
                                              >
                                                {j === 0 ? (
                                                  <>ft&sup2;·°F·h/BTU</>
                                                ) : (
                                                  "mW/mK"
                                                )}
                                              </Typography>
                                            </InputAdornment>
                                          }
                                          onKeyPress={(event) => {
                                            if (
                                              event?.key === "-" ||
                                              event?.key === "+"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                          sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            color: "#3F4042",
                                          }}
                                        />
                                      </FormControl>
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            );
                          })}
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#FFFFFF",
                          }}
                        ></Stack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={6}>
                    <Box
                      component="div"
                      alt="Upload Pic"
                      sx={{
                        minWidth: "238px",
                        height: "394px",
                      }}
                    />

                    <Stack
                      spacing={3}
                      direction="column"
                      sx={{ width: "-webkit-fill-available" }}
                    >
                      <Typography component="h6" variant="h6" sx={labelStyle}>
                        Probe - Winter
                      </Typography>
                      <Stack
                        direction="column"
                        sx={{ border: "1px solid #E6E7E8" }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#F2F2F3",
                          }}
                        >
                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          ></Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              R-Value
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.rvalue}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Top Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.topWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              Side Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.sideWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>

                          <Stack
                            width={1 / 5}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              component="h6"
                              variant="h6"
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                                fontWeight: "400",
                              }}
                            >
                              End Wall
                            </Typography>
                            <BootstrapTooltip
                              title={tooltips?.probeconfiguration?.endWall}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  color: "#7D7F82",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            </BootstrapTooltip>
                          </Stack>
                        </Stack>

                        {/* Row 1 */}

                        {sideWinter &&
                          sideWinter.map((row, i) => {
                            const RowName = `Side-ProbeWinter-${i}`;
                            const IsChecked = isSelected(RowName);
                            return (
                              <Stack
                                key={`Side-Winter-${row.id}`}
                                direction="row"
                                sx={{
                                  width: "100%",
                                  height: "64px",
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                <Stack
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <ThemeProvider theme={checkboxTheme}>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      onClick={(e) =>
                                        handleCheck(e, RowName, RowName)
                                      }
                                      checked={IsChecked}
                                      p={0}
                                      value={selectedCheck}
                                      name={`Check ${row.id}`}
                                      inputProps={{ "aria-label": "A" }}
                                    />
                                  </ThemeProvider>
                                  <Typography
                                    component="h6"
                                    variant="h6"
                                    sx={{
                                      fontFamily: "Roboto",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                      color: "#000000",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {row.name}
                                  </Typography>
                                </Stack>
                                {row.data.map((column, j) => {
                                  return (
                                    <Stack
                                      width={1 / 5}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <FormControl
                                        disabled={!IsChecked}
                                        fullWidth
                                        size="small"
                                        sx={{
                                          width: "11.5rem",
                                          height: "44px",
                                        }}
                                        variant="outlined"
                                      >
                                        <OutlinedInput
                                          type="number"
                                          onInput={(e) =>
                                            handleChangeSideWinter(e, i, j)
                                          }
                                          id={`Prode-Config-Input-Side-Winter-${i}-${j}`}
                                          value={column.value}
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "28px",
                                                width: "auto",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "4px",
                                                backgroundColor: "#F2F2F3",
                                              }}
                                            >
                                              <Typography
                                                variant="p"
                                                mx={1.5}
                                                my={0.75}
                                                sx={{
                                                  fontFamily: "Roboto",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  lineHeight: "16px",
                                                  letterSpacing: "5%",
                                                  color: "#19191A",
                                                }}
                                              >
                                                {j === 0 ? (
                                                  <>ft&sup2;·°F·h/BTU</>
                                                ) : (
                                                  "mW/mK"
                                                )}
                                              </Typography>
                                            </InputAdornment>
                                          }
                                          onKeyPress={(event) => {
                                            if (
                                              event?.key === "-" ||
                                              event?.key === "+"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          aria-describedby="outlined-weight-helper-text"
                                          inputProps={{
                                            "aria-label": "weight",
                                          }}
                                          sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            color: "#3F4042",
                                          }}
                                        />
                                      </FormControl>
                                    </Stack>
                                  );
                                })}
                              </Stack>
                            );
                          })}
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            height: "64px",
                            backgroundColor: "#FFFFFF",
                          }}
                        ></Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </ThemeProvider>
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default ProbeConfiguration;
