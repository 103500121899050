import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginControl, loginData } from "../../components/Login/LoginSlice";
import { useHistory } from "react-router-dom";
//import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import * as ConstantsList from "../../../src/constants/";
import { useOktaAuth } from "@okta/okta-react";

const TopHeader = () => {
  let history = useHistory();
  const userData = useSelector(loginData);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };
  const logout = async () => {
    await oktaAuth.signOut();
  };

  useEffect(() => {
    const handleSignOut = () => {
      const UserData = {
        username: "",
        email: "",
        role: "",
        active_role: "",
        loggedIn: false,
      };
      dispatch(loginControl(UserData));
      logout();
    };

    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
      if (authState?.isAuthenticated === false) {
        const UserData = {
          username: "",
          email: "",
          role: "",
          active_role: "",
          loggedIn: false,
        };
        dispatch(loginControl(UserData));
        login();
      }
    } else {
      setUserInfo(authState.idToken.claims);
      let oktaAuthEmail = authState?.idToken?.claims?.email;
      if (userData?.username === "") {
        if (oktaAuthEmail.length !== 0) {
          setLoader(true);
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: oktaAuthEmail.toString() }),
          };
          fetch(ConstantsList.API_URL + "trufit/userauth/", requestOptions)
            .then((response) => response.json())
            .then((data) => {
              setLoader(false);
              if (!data.error_code) {
                const UserData = {
                  username: data.name,
                  email: data.email,
                  role: data.role,
                  active_role: data.role,
                  loggedIn: true,
                };

                dispatch(loginControl(UserData));
                setLoader(false);
                history.push("/dashboard");
              }
            });
        } else {
          handleSignOut();
        }
      }
    }
  }, [authState, dispatch, oktaAuth, userData?.username]);

  useEffect(() => {
    const pathList = [
      "/dashboard/opportunity/scenario-list",
      "/dashboard",
      "/dashboard/opportunity",
      "/view-profile",
    ];
    if (pathList.includes(history.location.pathname)) {
      setTimeout(() => {
        for (let i = 0; i < 10000; i++) {
          window.clearInterval(i);
        }
      }, 10000);
    }
  }, [history.location.pathname]);

  // return <>{userText}</>;
  return <>{""}</>;
};

export default TopHeader;
