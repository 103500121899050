import { createSlice } from "@reduxjs/toolkit";

export const navigateSlice = createSlice({
  name: "usertab",
  initialState: {
    value: "0",
  },
  reducers: {
    tab: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { tab } = navigateSlice.actions;

export const selectTab = (state) => state?.usertab?.value;

export default navigateSlice.reducer;
