import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Add, Close } from "@mui/icons-material";
import NoRecordFound from "../../core/NoRecordFound/NoRecordFound";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Stack,
  Button,
  ButtonGroup,
  Checkbox,
  TextField,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import ShipmentDetail from "../OrderProfile";

import { scenarioPayload, scenarioData } from "../ScenarioSlice";

import * as ConstantsList from "../../../constants";
import SnackBar from "../../core/SnackBar/SnackBar";

//Styles start here
const mainContainer = {
  width: "100%",
  backgroundColor: "#ffffff",
};

const mainTitle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "30px",
};

const buttonContainerStyle = {
  alignItems: "flex-end",
};

const ButtonStyle = {
  border: "1px solid #B1B2B4",
};

const nextButtonStyle = {
  border: "1px solid #B1B2B4",
  color: "#000000",
  marginTop: "20px",
  textTransform: "capitalize",
  width: "120px",
  height: "44px",
};
const detailsButtonStyle = {
  border: "1px solid #B1B2B4",
  color: "#000000",
  textTransform: "capitalize",
  width: "130px",
  height: "44px",
};

const tableContainerStyle = {
  maxHeight: "260px",
  "&::-webkit-scrollbar": {
    height: 5,
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#98A2B3",
    borderRadius: 8,
  },
};

const tableStyle = {
  borderSpacing: "0 0",
  height: "max-content",

  "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
    {
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      border: 1,
    },

  "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },

  "& .Mui-selected": {
    borderWidth: "1px",
    backgroundColor: "#FFFFFF",
  },

  "& .Mui-selected td": {
    borderTop: "1px solid #F26322",
    borderBottom: "1px solid #F26322",
    backgroundColor: "#FFFFFF",
  },

  "& .Mui-selected td:first-of-type ": {
    borderLeft: "1px solid #F26322",
  },

  "& .Mui-selected td:last-child ": {
    borderRight: "1px solid #F26322",
  },
  "& .MuiFormControl-root > .MuiInputBase-root > .MuiSelect-select": {
    padding: "8px 12px",
    borderRadius: "6px",
    border: "1px solid #E6E7E8",
  },
};

const tableRowStyle = {
  "& td:first-of-type": {
    padding: 0,
  },

  "&:hover td:first-of-type": {
    borderLeft: "1px solid #F26322",
    width: "10px",
  },

  "&:hover td:last-child": {
    borderRight: "1px solid #F26322",
  },

  "&:hover td": {
    borderTop: "1px solid #F26322",
    borderBottom: "1px solid #F26322",
    backgroundColor: "#FFFFFF",
  },
  "&:selected": { backgroundColor: "#FFFFFF" },
};

const tableCellStyle = {
  padding: "0 12px",
};

const inputAdornmentContainerStyle = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#3F4042",
};

const inputAdornmentStyle = {
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: "#F2F2F3",
  height: "100%",
  padding: "6px 12px",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16px",
  letterSpacing: "5%",
  color: "#19191A",
};
//styles ends here

const columnsName = [
  { id: "select", label: "" },
  { id: "payloadName", label: "Name" },
  { id: "payloadLength", label: "Length" },
  { id: "payloadWidth", label: "Width" },
  { id: "payloadHeight", label: "Height" },
  { id: "payloadWeight", label: "Weight" },
  { id: "payloadOrderProfile", label: "Order Profile" },
  { id: "payloadMinTemp", label: "Min Temp" },
  { id: "payloadMaxTemp", label: "Max Temp" },
  { id: "payloadDuration", label: "Duration" },
  { id: "payloadPcmType", label: "PCM Type" },
  { id: "payloadSummerProfile", label: "Summer Profile" },
  { id: "payloadWinterProfile", label: "Winter Profile" },
  { id: "payloadLoadTemp", label: "Load Temp" },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {columnsName.map((headCell, i) =>
          i === 1 ? (
            <TableCell
              width="200px"
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#000000",
                fontStyle: "normal",
                backgroundColor: "#F2F2F3",
              }}
              align={headCell.align}
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ) : (
            <TableCell
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#000000",
                fontStyle: "normal",
                backgroundColor: "#F2F2F3",
              }}
              align={headCell.align}
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

// Theme styles here
const theme = createTheme({
  palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
});

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const selectBg = {
  width: "10px",
  height: "64px",
  backgroundColor: "#F26322",
};
const noselectBg = {
  width: "10px",
  height: "64px",
  backgroundColor: "#FFFFFF",
};

const Payload = (props) => {
  const { updateReload, setUpdateReload, setSectionNo } = props;
  const scenarioDetails = useSelector(scenarioData);
  const [showShipmentDetails, setShowShipmentDetails] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  const [summerProfileList, setSummerProfileList] = useState(null);
  const [winterProfileList, setWinterProfileList] = useState(null);

  const dispatch = useDispatch();
  const [rows, setRows] = useState(scenarioDetails?.payload ?? []);

  useEffect(() => {
    if (scenarioDetails?.payload) {
      setRows(scenarioDetails?.payload);
    }
  }, [scenarioDetails?.payload]);

  const handleAddRow = () => {
    const index = rows.length;
    const preId = index - 1;
    let datasValue = {};
    if (index > 0) {
      datasValue = {
        payload: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        mintemp: rows[preId]?.mintemp,
        maxtemp: rows[preId]?.maxtemp,
        duration: rows[preId]?.duration,
        pcm: rows[preId]?.pcm,
        summer: rows[preId]?.summer,
        winter: rows[preId]?.winter,
        loadtemp: rows[preId]?.loadtemp,
        orderprofile: {
          id: null,
          orientation: [],
          orderprofile: [],
        },
      };
    } else {
      datasValue = {
        id: null,
        checkbox: false,
        payload: "",
        length: "",
        width: "",
        height: "",
        weight: "",
        orderprofile: {
          id: null,
          orientation: [],
          orderprofile: [],
        },
        mintemp: "",
        maxtemp: "",
        duration: "",
        pcm: "",
        summer: "",
        winter: "",
        loadtemp: "",
      };
    }

    setRows([...rows, { id: null, ...datasValue }]);
  };

  const handleChange = (e, index) => {
    if (rowSelected === index) {
      setRowSelected(null);
    } else {
      setRowSelected(index);
    }
  };

  const isSelected = (id) => (id === rowSelected ? true : false);

  const handleClose = () => {
    setShowShipmentDetails(false);
  };

  const [inValue, setInValue] = useState(0);

  const handleShowModal = (e, index) => {
    setInValue(index);
    setShowShipmentDetails(true);
  };

  useEffect(() => {
    if (scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
        profile_season_id: 1,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "scenario/payload_season/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setSummerProfileList(data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [
    scenarioDetails?.scenario?.id,
    scenarioDetails?.temperature,
    updateReload,
  ]);

  useEffect(() => {
    if (scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
        profile_season_id: 2,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "scenario/payload_season/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setWinterProfileList(data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [
    scenarioDetails?.scenario?.id,
    scenarioDetails?.temperature,
    updateReload,
  ]);

  const handleRowChange = (event, index) => {
    const { value, name } = event.target;
    const re = /^[0-9.-]+$/;
    if (value === "" || re.test(value)) {
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], [name]: value };
      setRows(newRows);
    }
  };

  const [payloadList, setPayloadList] = useState(null);

  const [payloadData, setPayloadData] = useState(null);

  useEffect(() => {
    var raw = JSON.stringify({
      customer_id: scenarioDetails?.customer?.id,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "scenario/payload_search/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setPayloadData(data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [scenarioDetails?.customer?.id, updateReload]);

  const [pcmData, setPCMData] = useState(null);

  useEffect(() => {
    if (scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "scenario/payload-pcm/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setPCMData(data.data);
              setUpdateReload(false);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [
    scenarioDetails?.scenario?.id,
    scenarioDetails?.temperature,
    updateReload,
  ]);

  const [payload, setPayload] = useState([]);

  useEffect(() => {
    if (payloadData) {
      const DataList = rows;
      let Data = payloadData;
      for (let i = 0; i < DataList.length; i++) {
        const Value = DataList[i]?.payload?.name ?? DataList[i]?.payload;
        Data = Data.filter((user) => {
          return user.name !== Value;
        });
      }
      setPayloadList(Data);
    }
  }, [payloadData, rows]);

  useEffect(() => {
    const Data = [];
    for (let i = 0; i < rows.length; i++) {
      let data = {
        id: rows[i]?.id ?? null,
        payload: {
          id: rows[i]["payload"]?.id ?? null,
          name: rows[i]["payload"]?.name ?? rows[i]["payload"],
          length: rows[i]["length"],
          width: rows[i]["width"],
          height: rows[i]["height"],
          weight: rows[i]["weight"],
        },
        quantities_shipped: {
          id: rows[i]?.orderprofile?.id ?? null,
          orientation: rows[i]?.orderprofile?.orientation,
          orderprofile: rows[i]?.orderprofile?.orderprofile,
        },
        min_range: rows[i]["mintemp"],
        max_range: rows[i]["maxtemp"],
        duration: rows[i]["duration"],
        pcm_type: rows[i]["pcm"],
        summer_profile: rows[i]["summer"],
        winter_profile: rows[i]["winter"],
        payload_temp: rows[i]["loadtemp"],
      };
      Data.push(data);
    }
    setPayload(Data);
  }, [rows]);

  const handleNext = () => {
    if (rows.length > 0) {
      let data = true;
      for (let j = 0; j < rows.length; j++) {
        const NameTest = rows[j]?.payload?.name
          ? rows[j]?.payload?.name?.toString()?.trim().length > 0
            ? true
            : false
          : rows[j]?.payload?.toString()?.trim().length > 0
          ? true
          : false;
        const LenghtTest =
          rows[j]?.length?.toString().length > 0 ? true : false;
        const WidthTest = rows[j]?.width?.toString().length > 0 ? true : false;
        const HeightTest =
          rows[j]?.height?.toString().length > 0 ? true : false;
        const WeightTest =
          rows[j]?.weight?.toString().length > 0 ? true : false;
        const MinTest = rows[j]?.mintemp?.toString().length > 0 ? true : false;
        const MaxTest = rows[j]?.maxtemp?.toString().length > 0 ? true : false;
        const DurationTest =
          rows[j]?.duration.toString().length > 0 ? true : false;
        const PCMTest = rows[j]?.pcm.toString().length > 0 ? true : false;
        const SummerTest = rows[j]?.summer.toString().length > 0 ? true : false;
        const WinterTest = rows[j]?.winter.toString().length > 0 ? true : false;
        const LoadTest = rows[j]?.loadtemp.toString().length > 0 ? true : false;

        const OrderProfile =
          rows[j]?.orderprofile?.orderprofile.length > 0 ? true : false;
        const Orientation =
          rows[j]?.orderprofile?.orientation.length > 0 ? true : false;

        let TestOrder = true;
        for (let u = 0; u < rows[j]?.orderprofile?.orderprofile.length; u++) {
          let OrderData = rows[j]?.orderprofile?.orderprofile[u];
          if (OrderData?.payload.length > 0 && OrderData?.shipment.length > 0) {
          } else {
            TestOrder = false;
          }
        }
        const OrderDataPro = TestOrder;

        if (
          OrderDataPro &&
          OrderProfile &&
          Orientation &&
          NameTest &&
          LenghtTest &&
          WidthTest &&
          HeightTest &&
          WeightTest &&
          MinTest &&
          MaxTest &&
          DurationTest &&
          PCMTest &&
          SummerTest &&
          WinterTest &&
          LoadTest
        ) {
        } else {
          data = false;
        }
      }

      if (data) {
        var raw = JSON.stringify({
          customer_id: scenarioDetails?.customer?.id,
          opportunity_id: scenarioDetails?.opportunity?.id,
          scenario_id: scenarioDetails?.scenario?.id,
          payload: payload,
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: raw,
        };
        fetch(ConstantsList.API_URL + "scenario/payload/", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              dispatch(scenarioPayload(rows));
              if (data.status_code === 200) {
                setNotify(true);
                setNotifyMsg(data.data.toString());
                setSectionNo(5);
                setSeverity("success");
              } else {
                setNotify(true);
                setNotifyMsg(data.data.toString());
                setSeverity("error");
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        setNotify(true);
        setNotifyMsg("Please fill all the required fields");
        setSeverity("error");
      }
    }
  };

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [severity, setSeverity] = useState("success");

  const removeValue = () => {
    let newRows = [...rows];
    newRows.splice(rowSelected, 1);
    setRows(newRows);
    setRowSelected(null);
  };

  const isRemove = rowSelected ? false : true;

  const handleSelectValue = (e, value, index) => {
    let preId = index - 1;
    const newRows = [...rows];

    if (index > 0) {
      newRows[index] = {
        ...newRows[index],
        payload: value,
        length: value?.length,
        width: value?.width,
        height: value?.height,
        weight: value?.weight,
        mintemp: rows[preId]?.mintemp,
        maxtemp: rows[preId]?.maxtemp,
        duration: rows[preId]?.duration,
        pcm: rows[preId]?.pcm,
        summer: rows[preId]?.summer,
        winter: rows[preId]?.winter,
        loadtemp: rows[preId]?.loadtemp,
        orderprofile: {
          id: null,
          orientation: [],
          orderprofile: [],
        },
      };
    } else {
      newRows[index] = {
        ...newRows[index],
        payload: value,
        length: value?.length,
        width: value?.width,
        height: value?.height,
        weight: value?.weight,
      };
    }
    setRows(newRows);
  };

  const handleInputText = (e, value, index) => {
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], payload: value };
    setRows(newRows);
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box maxWidth="false" sx={mainContainer} px={3} py={4}>
          <Stack direction="row">
            <Stack width={1 / 2}>
              <Stack alignItems="left">
                <Typography variant="h5" sx={mainTitle}>
                  Define Payload
                </Typography>
              </Stack>
            </Stack>
            <Stack width={1 / 2} sx={buttonContainerStyle}>
              <ThemeProvider theme={theme}>
                {scenarioDetails?.status === "Optimization Pending" && (
                  <ButtonGroup>
                    <Button
                      startIcon={<Add />}
                      sx={ButtonStyle}
                      onClick={handleAddRow}
                      data-testid="add-row-btn"
                    >
                      Add Row
                    </Button>
                    <Button
                      startIcon={<Close />}
                      disabled={isRemove}
                      sx={ButtonStyle}
                      onClick={removeValue}
                      data-testid="remove-btn"
                    >
                      Remove
                    </Button>
                  </ButtonGroup>
                )}
              </ThemeProvider>
            </Stack>
          </Stack>

          <Stack direction="row" sx={{ marginTop: "50px" }}>
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={tableContainerStyle}>
                <Table stickyHeader aria-label="sticky table" sx={tableStyle}>
                  <EnhancedTableHead />
                  <TableBody>
                    {rows && rows.length === 0 ? (
                      <NoRecordFound
                        title="No result found"
                        description=""
                        count={13}
                      />
                    ) : (
                      <>
                        {rows &&
                          rows.map((row, index) => {
                            let selctedId = index;
                            const isItemSelected = isSelected(selctedId);
                            const rowIndex = index;
                            const isEnableVal = rows[index]["payload"]?.id
                              ? true
                              : false;
                            return (
                              <TableRow
                                hover
                                key={row?.row_id ?? index}
                                sx={tableRowStyle}
                              >
                                <TableCell sx={tableCellStyle} p={0}>
                                  <Stack direction="row" p={0}>
                                    <Box
                                      sx={
                                        isItemSelected ? selectBg : noselectBg
                                      }
                                    ></Box>
                                    <ThemeProvider theme={checkboxTheme}>
                                      <Checkbox
                                        name="checkbox"
                                        checked={isItemSelected}
                                        onChange={(e) => handleChange(e, index)}
                                        value={rowSelected}
                                      />
                                    </ThemeProvider>
                                  </Stack>
                                </TableCell>

                                <TableCell sx={tableCellStyle} width="200px">
                                  <Stack
                                    sx={{ width: "200px" }}
                                    direction="row"
                                  >
                                    <Autocomplete
                                      fullWidth
                                      id="free-solo-demo"
                                      name="payload"
                                      size="small"
                                      value={
                                        rows[index]?.payload?.name
                                          ? rows[index]?.payload
                                          : {
                                              name: (
                                                rows[index]?.payload ?? ""
                                              ).toString(),
                                            }
                                      }
                                      onInputChange={(event, value) =>
                                        handleInputText(event, value, rowIndex)
                                      }
                                      onChange={(event, newValue) =>
                                        handleSelectValue(
                                          event,
                                          newValue,
                                          rowIndex
                                        )
                                      }
                                      freeSolo
                                      getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                      }
                                      options={payloadList}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "136px" }}
                                  >
                                    <OutlinedInput
                                      disabled={isEnableVal}
                                      name="length"
                                      id="outlined-adornment-payloadLength"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          mm
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["length"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "136px" }}
                                  >
                                    <OutlinedInput
                                      disabled={isEnableVal}
                                      name="width"
                                      id="outlined-adornment-payloadWidth"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          mm
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["width"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "136px" }}
                                  >
                                    <OutlinedInput
                                      disabled={isEnableVal}
                                      name="height"
                                      id="outlined-adornment-payloadHeight"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          mm
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["height"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "136px" }}
                                  >
                                    <OutlinedInput
                                      disabled={isEnableVal}
                                      name="weight"
                                      id="outlined-adornment-payloadWeight"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          g
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["weight"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <ThemeProvider theme={theme}>
                                    <Button
                                      sx={detailsButtonStyle}
                                      onClick={(e) => handleShowModal(e, index)}
                                      data-testid="details-btn"
                                    >
                                      Details
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "120px" }}
                                  >
                                    <OutlinedInput
                                      name="mintemp"
                                      id="outlined-adornment-payloadOrderMinTemp"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          &#8451;
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["mintemp"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "120px" }}
                                  >
                                    <OutlinedInput
                                      name="maxtemp"
                                      id="outlined-adornment-payloadOrderMaxTemp"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          &#8451;
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["maxtemp"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "120px" }}
                                  >
                                    <OutlinedInput
                                      name="duration"
                                      id="outlined-adornment-payloadDuration"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          hr
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["duration"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                      onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    sx={{ m: 1, minWidth: 186 }}
                                    size="small"
                                  >
                                    <Select
                                      defaultValue=""
                                      name="pcm"
                                      id="Payload-PCM"
                                      value={(pcmData && row["pcm"]) ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    >
                                      <MenuItem disabled value="">
                                        Select PCM
                                      </MenuItem>
                                      {pcmData &&
                                        pcmData.map((pcm, index) => {
                                          return (
                                            <MenuItem
                                              key={pcm?.pcm_id}
                                              value={pcm?.pcm_id}
                                            >
                                              {pcm?.pcm_name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    sx={{ m: 1, minWidth: 186 }}
                                    size="small"
                                  >
                                    <Select
                                      defaultValue=""
                                      name={"summer"}
                                      id="Payload-Summer-Profile"
                                      value={
                                        (summerProfileList && row["summer"]) ??
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    >
                                      <MenuItem disabled value="">
                                        Select Summer Profile
                                      </MenuItem>
                                      {summerProfileList &&
                                        summerProfileList[1]?.Season_details.map(
                                          (data, i) => {
                                            return (
                                              <MenuItem
                                                key={data.tp_id}
                                                value={data.tp_id}
                                              >
                                                {data.profile_name}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                    </Select>
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    sx={{ m: 1, minWidth: 186 }}
                                    size="small"
                                  >
                                    <Select
                                      defaultValue=""
                                      name={"winter"}
                                      id="Payload-Winter-Profile"
                                      value={
                                        (winterProfileList && row["winter"]) ??
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    >
                                      <MenuItem disabled value="">
                                        Select Winter Profile
                                      </MenuItem>
                                      {winterProfileList &&
                                        winterProfileList[1]?.Season_details.map(
                                          (data, i) => {
                                            return (
                                              <MenuItem
                                                key={data.tp_id}
                                                value={data.tp_id}
                                              >
                                                {data.profile_name}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                    </Select>
                                  </FormControl>
                                </TableCell>

                                <TableCell sx={tableCellStyle}>
                                  <FormControl
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: "120px" }}
                                  >
                                    <OutlinedInput
                                      name="loadtemp"
                                      id="outlined-adornment-payloadLoadTemp"
                                      sx={inputAdornmentContainerStyle}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={inputAdornmentStyle}
                                        >
                                          &#8451;
                                        </InputAdornment>
                                      }
                                      inputProps={{
                                        maxLength: 8,
                                      }}
                                      value={row["loadtemp"] ?? ""}
                                      onChange={(e) =>
                                        handleRowChange(e, index)
                                      }
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Stack>
          {(scenarioDetails?.status === "Optimization Pending" ||
            scenarioDetails?.status === "Optimization Failed" ||
            scenarioDetails?.status === "Optimization Cancelled") && (
            <Stack sx={buttonContainerStyle}>
              <ThemeProvider theme={theme}>
                <Button
                  sx={nextButtonStyle}
                  onClick={handleNext}
                  data-testid="next-btn"
                >
                  Next
                </Button>
              </ThemeProvider>
            </Stack>
          )}
        </Box>
      </ThemeProvider>

      {notify && (
        <SnackBar
          open={notify}
          setOpen={setNotify}
          message={notifyMsg}
          severity={severity}
        />
      )}

      {showShipmentDetails && (
        <ShipmentDetail
          rows={rows}
          setRows={setRows}
          inValue={inValue}
          open={showShipmentDetails}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default Payload;
