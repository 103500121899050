import React from "react";
import LoginModal from "../../components/Login/LoginModal";
import { Footer, Header } from "../../layouts";
import BG from "../../assets/images/background.png";
import { Box } from "@mui/material";

const Login = () => {
  return (
    <Box
      maxWidth="false"
      sx={{
        width: "100%",
        heigh: "100vh",
        backgroundImage: `url(${BG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Header bgColor="transparent" />
      <LoginModal />
      <Footer bgColor="transparent" />
    </Box>
  );
};

export default Login;
