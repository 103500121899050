import React, { useEffect, useState } from "react";
import SalesContainer from "./SalesContainer";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputBase from "@mui/material/InputBase";
import { Stack } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Filter from "./Filter";
import AddCustomers from "../Customers/AddCustomers";
import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../src/constants/";
import Opportunities from "../Opportunities";
import * as ADAConfig from "../../../src/config/ADAConfig";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 6,
    backgroundColor: "#FFFFFF",
    border: "1px solid #F2F2F3",
    padding: "8px 12px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#646568",
    "&:focus": {
      borderRadius: 6,
      borderColor: "#F2F2F3",
    },
  },
}));

const testStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#646568",
  letterSpacing: "5%",
};

const SalesEngineer = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");

  const [fopen, setfopen] = useState(false);
  const [customer, setCustomer] = useState({
    id: 0,
    name: "Select a customer",
  });
  const [open, setOpen] = useState(true);
  const [nopen, setNOpen] = useState(false);
  const handleChange = (e) => {
    setOpen(true);
  };

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (customer.name.length !== 0) {
      if (customer.id.toString() !== "0") {
        setLoading(true);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ customer_id: customer.id }),
        };
        fetch(
          ConstantsList.API_URL + "opp/opportunity-customer/",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              setData(data.data);
              setLoading(false);
              setReload(false);
            }
          });
      } else {
        setLoading(true);
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        fetch(ConstantsList.API_URL + "opp/opportunity", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              let dataBind = {
                customer: {
                  id: null,
                  customer_name: "All",
                },
                opportunity: data.data,
              };
              setData(dataBind);
              setLoading(false);
              setReload(false);
            }
          });
      }
    }
  }, [customer, reload]);

  const OpportunityData = data ? data.opportunity : {};

  return (
    <>
      <Stack spacing={1} p={6} direction="column">
        <Stack spacing={1} p={0} direction="column">
          <Typography component="h3" variant="h3" sx={testStyle}>
            Customer
          </Typography>
          <FormControl sx={{ m: 1 }} variant="standard">
            <Select
              placeholder="Select a customer"
              sx={{ width: "444px", height: "44px" }}
              open={false}
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={customer.id}
              onOpen={handleChange}
              input={<BootstrapInput />}
              aria-label={ADAConfig?.ADA?.customer_input}
            >
              <MenuItem value="0">
                <em>Select a customer</em>
              </MenuItem>
              <MenuItem style={{ display: "none" }} value={customer.id}>
                {customer.name}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {data && (
          <Opportunities
            data={OpportunityData}
            customer={customer}
            setReload={setReload}
          />
        )}

        {open && (
          <SalesContainer
            reload={reload}
            setReload={setReload}
            setCustomer={setCustomer}
            setOpen={setOpen}
            open={open}
            nopen={nopen}
            setNOpen={setNOpen}
          />
        )}
        {fopen && <Filter open={fopen} onOpen={setfopen} />}
        {nopen && !open && (
          <AddCustomers
            setCustomer={setCustomer}
            setReload={setReload}
            open={nopen}
            setSales={setOpen}
            setOpen={setNOpen}
            setNotify={setNotify}
            setNotifyMsg={setNotifyMsg}
          />
        )}
        {notify && (
          <SnackBar message={notifyMsg} setOpen={setNotify} open={notify} />
        )}
      </Stack>
    </>
  );
};

export default SalesEngineer;
