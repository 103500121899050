import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { scenarioControl, scenarioData } from "../ScenarioSlice";

import { useSelector, useDispatch } from "react-redux";
import * as ConstantsList from "../../../constants";

import {
  Box,
  Typography,
  Stack,
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import SnackBar from "../../core/SnackBar/SnackBar";

const mainTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
  marginBottom: "40px",
};

const subTitle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#000000",
  margin: "40px 0 16px 0",
};

const tableContainerStyle = {
  "& .MuiTableCell-head": {
    border: 0,
  },
  "& .css-1ex1afd-MuiTableCell-root": {
    border: 0,
  },
};

const ButtonStyle = {
  width: "100%",
  height: "44px",
  backgroundColor: "#F26322",
  color: "#3F4042",
  textTransform: "none",
  "&:hover": { backgroundColor: "#F26322" },
  cursor: "pointer",
  margin: "40px 0",
};

const inputAdornmentContainerStyle = {
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#3F4042",
};

const inputAdornmentStyle = {
  alignItems: "center",
  borderRadius: "4px",
  backgroundColor: "#F2F2F3",
  height: "100%",
  padding: "6px 12px",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16px",
  letterSpacing: "5%",
  color: "#19191A",
};

const ThermalSimulation = (props) => {
  const {
    open,
    onClose,
    thermal,
    setThermal,
    reload,
    setReload,
    thermalInitValue,
    setThermalInitValue,
  } = props;
  const [save, setSave] = useState(true);
  const scenarioDetails = useSelector(scenarioData);
  const handleClose = () => {
    onClose(false);
  };

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);

  const changeButton = () => {};

  const [thermalValue, setThermalValue] = useState("");

  const handleChange = (e) => {
    setThermalInitValue(e.target.value);
  };

  const [thermalData, setThermalData] = useState(null);

  const [thermalSave, setThermalSave] = useState(false);

  useEffect(() => {
    if (scenarioDetails?.scenario?.id) {
      fetch(
        ConstantsList.API_URL +
          "scenario/thermal/" +
          scenarioDetails?.scenario?.id
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setThermalData(data.data);
              setThermalInitValue(
                data?.data?.thermal?.insulatorinitial
                  ? data?.data?.thermal?.insulatorinitial
                  : 20
              );
              setReload(false);
            }
            
          }
        })
        .catch((err) => console.log(err));
    }
  }, [scenarioDetails?.scenario?.id, reload, setReload]);

  const ThermalDataValue = thermal ? thermal : null;

  const handleSave = () => {
    if (scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        insulatorinitial: thermalInitValue,
        id: ThermalDataValue,
        scenario_id: scenarioDetails?.scenario?.id,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "scenario/thermal/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setThermalSave(true);
              setThermal(data?.data?.id);
              setNotify(true);
              setNotifyMsg(data?.data?.message.toString());
              onClose(false);
            } else if (data.status_code === 400) {
              setNotify(true);
              setNotifyMsg(data.data.toString());
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ width: "100%" }}
        data-testid="dailog-container"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
            width: "512px",
          }}
          data-testid="box-outer-container"
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px", width: "100%" }}
            data-testid="stack-outer-container"
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
                data-testid="box-inner-container"
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-btn"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}

            <Stack>
              <Typography component="h3" variant="h3" sx={mainTitle}>
                Thermal Simulation Settings
              </Typography>

              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Stack sx={{ width: "120px", marginRight: "20px" }}>
                  <FormControl variant="outlined" size="small">
                    <OutlinedInput
                      sx={inputAdornmentContainerStyle}
                      endAdornment={
                        <InputAdornment position="end" sx={inputAdornmentStyle}>
                          &#8451;
                        </InputAdornment>
                      }
                      inputProps={{
                        maxLength: 8,
                      }}
                      value={thermalInitValue}
                      onInput={handleChange}
                      data-testid="outlined-input"
                    />
                  </FormControl>
                </Stack>
                <Stack>Insulator Initial Temperature</Stack>
              </Stack>

              <Typography component="h3" variant="h3" sx={subTitle}>
                PCM start Temperature
              </Typography>

              <TableContainer sx={tableContainerStyle}>
                <Table sx={{ width: "100%" }} aria-label="table" m={0}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "40%" }}></TableCell>
                      <TableCell>Summer</TableCell>
                      <TableCell>Winter</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {thermalData &&
                      thermalData?.pcm_data.map((data, i) => {
                        return (
                          <TableRow key={data?.id} sx={{ border: 0 }}>
                            <TableCell>
                              <Typography>{data?.pcm_name}</Typography>
                            </TableCell>

                            <TableCell>
                              <FormControl variant="outlined" size="small">
                                <OutlinedInput
                                  disabled
                                  sx={inputAdornmentContainerStyle}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={inputAdornmentStyle}
                                    >
                                      &#8451;
                                    </InputAdornment>
                                  }
                                  inputProps={{
                                    maxLength: 8,
                                  }}
                                  value={data?.pcmstart_temp[0]?.summer}
                                />
                              </FormControl>
                            </TableCell>

                            <TableCell>
                              <FormControl variant="outlined" size="small">
                                <OutlinedInput
                                  disabled
                                  sx={inputAdornmentContainerStyle}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={inputAdornmentStyle}
                                    >
                                      &#8451;
                                    </InputAdornment>
                                  }
                                  inputProps={{
                                    maxLength: 8,
                                  }}
                                  value={data?.pcmstart_temp[0]?.winter}
                                  data-testid="outlined-button-2"
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {(scenarioDetails?.status === "Optimization Pending" ||
                scenarioDetails?.status === "Optimization Failed" ||
                scenarioDetails?.status === "Optimization Cancelled") && (
                <Button sx={ButtonStyle} onClick={handleSave} data-testid="save-btn">
                  Save
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Dialog>

      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </div>
  );
};

export default ThermalSimulation;
