import React from "react";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Footer, Header, ScenarioList as ScenarioListLayout } from "../../layouts";

const ShipperList = () => {
    const theme = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 1280,
            md: 1440,
            lg: 1600,
            xl: 1920,
          },
        },
        palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
      });
    
      return (
        <ThemeProvider theme={theme}>
          <Header bgColor="#FFFFFF" />
          <Box
            maxWidth="false"
            sx={{
              width: "100%",
              minHeight: {
                xs: "calc(100vh - 160px)",
                sm: "calc(100vh - 160px)",
                md: "calc(100vh - 160px)",
                lg: "calc(100vh - 200px)",
                xl: "calc(100vh - 224px)",
              },
              height: "auto",
              backgroundColor: "#F2F2F3",
              direction: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ScenarioListLayout />
          </Box>
          <Footer bgColor="#1D3869" />
        </ThemeProvider>
      );
}

export default ShipperList