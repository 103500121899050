import React, { useEffect, useState } from "react";
import {
  Box,
  createTheme,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import * as Config from "../../../config";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useSelector, useDispatch } from "react-redux";
import { pcmTabUpdate, shipperData } from "./EditShipperSlice";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const PCMTab = (props) => {
  const { enableData } = props;
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip?.pcm;
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [odLengthTop, setODLengthTop] = useState({
    name: shipperDetails?.shipperclass?.pcm?.top?.od_length,
    error: false,
    message: "",
  });

  const [odLengthSide, setODLengthSide] = useState({
    name: shipperDetails?.shipperclass?.pcm?.side?.od_length,
    error: false,
    message: "",
  });

  const [odLengthEnd, setODLengthEnd] = useState({
    name: shipperDetails?.shipperclass?.pcm?.end?.od_length,
    error: false,
    message: "",
  });

  const [odWidthTop, setODWidthTop] = useState({
    name: shipperDetails?.shipperclass?.pcm?.top?.od_width,
    error: false,
    message: "",
  });

  const [odWidthSide, setODWidthSide] = useState({
    name: shipperDetails?.shipperclass?.pcm?.side?.od_width,
    error: false,
    message: "",
  });

  const [odWidthEnd, setODWidthEnd] = useState({
    name: shipperDetails?.shipperclass?.pcm?.end?.od_width,
    error: false,
    message: "",
  });

  const odLengthTopHandle = (e) => {
    let value = e.target.value;
    setODLengthTop({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    })
  };

  const odLengthSideHandle = (e) => {
    let value = e.target.value;
    setODLengthSide({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const odLengthEndHandle = (e) => {
    let value = e.target.value;
    setODLengthEnd({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const odWidthTopHandle = (e) => {
    let value = e.target.value;
    setODWidthTop({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const odWidthSideHandle = (e) => {
    let value = e.target.value;
    setODWidthSide({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const odWidthEndHandle = (e) => {
    let value = e.target.value;
    setODWidthEnd({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  useEffect(() => {
    const PCMData = {
      top: {
        od_length: odLengthTop?.name?.toString(),
        od_width: odWidthTop?.name?.toString(),
      },
      side: {
        od_length: odLengthSide?.name?.toString(),
        od_width: odWidthSide?.name?.toString(),
      },
      end: {
        od_length: odLengthEnd?.name?.toString(),
        od_width: odWidthEnd?.name?.toString(),
      },
    };

    dispatch(pcmTabUpdate(PCMData));
  }, [
    odLengthTop,
    odWidthTop,
    odLengthSide,
    odWidthSide,
    odLengthEnd,
    odWidthEnd,
    dispatch,
  ]);


  return (
    <ThemeProvider theme={customTheme}>
      <Box maxWidth="false" sx={{ width: "100%", display: "flex" }}>
        <Stack direction="column" spacing={3} sx={{ width: "100%" }}>
          <Typography
            component="h6"
            variant="h6"
            sx={{
              fontFamily: "Roboto",
              fontSize: "20px",
              lineHeight: "22px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            Multiwall Detail Definition
          </Typography>
          <Stack direction="column" sx={{ width: "100%" }}>
            {/* Head Row */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#F2F2F3",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              ></Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Top
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Side
                </Typography>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  End
                </Typography>
              </Stack>
            </Stack>

            {/* Row 1  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  OD Length
                </Typography>
                <BootstrapTooltip
                  title={tooltips?.length}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odLengthTopHandle}
                    onPaste={odLengthTopHandle}
                    value={odLengthTop.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                  {odLengthTop.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odLengthTop.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odLengthSideHandle}
                    onPaste={odLengthSideHandle}
                    value={odLengthSide.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                  {odLengthSide.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odLengthSide.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odLengthEndHandle}
                    onPaste={odLengthEndHandle}
                    value={odLengthEnd.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                  {odLengthEnd.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odLengthEnd.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>

            {/* Row 2  */}
            <Stack
              direction="row"
              sx={{
                width: "100%",
                height: "64px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  OD Width
                </Typography>
                <BootstrapTooltip
                  title={tooltips?.width}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: "#7D7F82",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </BootstrapTooltip>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odWidthTopHandle}
                    onPaste={odWidthTopHandle}
                    value={odWidthTop.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        <Typography
                          variant="p"
                          mx={1.5}
                          my={0.75}
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "5%",
                            color: "#19191A",
                          }}
                        >
                          mm
                        </Typography>
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#3F4042",
                    }}
                  />
                  {odWidthTop.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odWidthTop.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odWidthSideHandle}
                    onPaste={odWidthSideHandle}
                    value={odWidthSide.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                  {odWidthSide.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odWidthSide.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack
                width={1 / 4}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ width: "11.5rem", height: "44px" }}
                  variant="outlined"
                >
                  <OutlinedInput
                    disabled={enableData}
                    type="number"
                    onChange={odWidthEndHandle}
                    onPaste={odWidthEndHandle}
                    value={odWidthEnd.name}
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          padding: "6px, 12px, 6px, 12px",
                          height: "28px",
                          width: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "4px",
                          backgroundColor: "#F2F2F3",
                        }}
                      >
                        mm
                      </InputAdornment>
                    }
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                  {odWidthEnd.error && (
                    <FormHelperText sx={{ m: 0 }}>
                      {odWidthEnd.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default PCMTab;
