import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { Footer, Header } from "../../layouts";
import ShipperTabView from "../../components/Navigator/ShipperNavigatior";
import Box from "@mui/material/Box";
const Shipper = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header bgColor="#FFFFFF" />
        <Box
          maxWidth="false"
          sx={{
            width: "100%",
            minHeight: {
              xs: "calc(100vh - 160px)",
              sm: "calc(100vh - 160px)",
              md: "calc(100vh - 160px)",
              lg: "calc(100vh - 200px)",
              xl: "calc(100vh - 224px)",
            },
            height: "auto",
            backgroundColor: "#F2F2F3",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "48px 48px 24px 48px",
          }}
        >
          <ShipperTabView />
        </Box>
        <Footer bgColor="#1D3869" />
      </ThemeProvider>
    </>
  );
};

export default Shipper;
