import {
  
  Box,
  
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  
  OutlinedInput,
  
  Stack,
  styled,
  
  Tooltip,
  tooltipClasses,
  Typography,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateShipperClass from "../../ShipperClass/CreateShipperClass";
import SnackBar from "../../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../constants";
import Insulation from "./Insulation";

import { useSelector, useDispatch } from "react-redux";
import {
  shipperClassTypeUpdate,
  shipperClassIDUpdate,
  outerDimensionsUpdate,
  dryGoodsUpdate,
  shipperData,
} from "./EditShipperSlice";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { createTheme, Tab, ThemeProvider } from "@mui/material";
import PCMTab from "./PCMTab";
import Loader from "../../core/Loader/Loader";

import * as Config from "../../../config";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ShipperClass = (props) => {
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const isEnable = shipperDetails?.productline?.id ? true : false;
  const ProID = shipperDetails?.productline?.id;
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip?.shipperClass;
  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [classData, setClassData] = useState(null);
  const [selectedClassType, setSelectedClassType] = useState("Select");
  const handleShipperClass = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (isEnable) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          productline_id: ProID,
        }),
      };
      fetch(
        ConstantsList.API_URL + "shipdict/shipper_class_productline/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setClassData(data.data);
            setReload(false);
          }
        });
    }
    setSelectedClassType(shipperDetails?.shipperclass?.class_type);
  }, [ProID, reload, isEnable]);

  const handleSelectClass = (e, id, name) => {
    setSelectedClassType(e.target.value);
    const classDataVal = classData && classData.shippers;

    if (e.target.value !== "Select") {
      let index = classDataVal.find(
        (x) => x.class_type === e.target.value.toString()
      );
      setEnableData(false);
      dispatch(shipperClassTypeUpdate(e.target.value.toString()));
      dispatch(shipperClassIDUpdate(index.id.toString()));
    } else {
      dispatch(shipperClassTypeUpdate("----"));
      dispatch(shipperClassIDUpdate(""));
      setEnableData(true);
    }
  };

  const [selectTab, setSlectTab] = useState("0");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });
  const handleTab = (e, newValue) => {
    setSlectTab(newValue);
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const [outerLength, setOuterLength] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.length,
    error: false,
    message: "",
  });
  const [outerWidth, setOuterWidth] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.width,
    error: false,
    message: "",
  });
  const [outerHeight, setOuterHeight] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.height,
    error: false,
    message: "",
  });
  const [outerOutsideArea, setOuterOutsideArea] = useState({
    name: shipperDetails?.shipperclass?.outer_dimension.outside_area,
    error: false,
    message: "",
  });

  const [dryWeight, setDryWidth] = useState({
    name: shipperDetails?.shipperclass?.dry_goods.weight,
    error: false,
    message: "",
  });
  const [dryCost, setDryCost] = useState({
    name: shipperDetails?.shipperclass?.dry_goods.cost,
    error: false,
    message: "",
  });

  const outerLengthHandle = (e) => {
    let value = e.target.value;
    setOuterLength({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const outerWidthHandle = (e) => {
    let value = e.target.value;
    setOuterWidth({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const outerHeightHandle = (e) => {
    let value = e.target.value;
    setOuterHeight({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const outerOutsideAreaHandle = (e) => {
    let value = e.target.value;
    setOuterOutsideArea({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const dryWeightHandle = (e) => {
    let value = e.target.value;
    setDryWidth({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const dryCostHandle = (e) => {
    let value = e.target.value;
    setDryCost({
      name: !isNaN(parseFloat(value))?parseFloat(value):"",
      error: false,
      message: "",
    });
  };

  const [enableData, setEnableData] = useState(true);

  useEffect(() => {
    dispatch(
      outerDimensionsUpdate({
        length: outerLength?.name?.toString(),
        width: outerWidth?.name?.toString(),
        height: outerHeight?.name?.toString(),
        outside_area: outerOutsideArea?.name?.toString(),
      })
    );
    dispatch(
      dryGoodsUpdate({
        weight: dryWeight?.name?.toString(),
        cost: dryCost?.name?.toString(),
      })
    );
  }, [
    outerLength,
    outerWidth,
    outerHeight,
    outerOutsideArea,
    dryWeight,
    dryCost,
    dispatch,
  ]);

  const isTextFieldEnable = false;

  return (
    <Box maxWidth="false" sx={{ width: "100%", height: "auto" }} data-testid="box-outer-container">
      <Stack direction="column" spacing={5}>
        <Box
          maxWidth="false"
          px={3}
          pt={3}
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ThemeProvider theme={customTheme}>
            <Stack spacing={1.5} p={0} direction="row" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Class Name
                </Typography>
              </Stack>

              <FormControl
                size="small"
                sx={{
                  width: "200px",
                  height: "54px",
                  padding: "8px, 12px, 8px, 12px",
                }}
              >
                <InputLabel
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={{
                    background: "#F2F2F3",
                    width: "200px",
                    height: "44px",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {selectedClassType}
                </InputLabel>
              </FormControl>
            </Stack>
          </ThemeProvider>

          
        </Box>
        <ThemeProvider theme={customTheme}>
          <Stack
            direction="row"
            spacing={7}
            px={3}
            pb={5}
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
            }}
          >
            <Stack width={1 / 2} direction="column" spacing={2}>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Outer Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onInput={outerLengthHandle}
                        value={outerLength.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerLength.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerLength.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerWidthHandle}
                        onPaste={outerWidthHandle}
                        value={outerWidth.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerWidth.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerWidth.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerHeightHandle}
                        onPaste={outerHeightHandle}
                        value={outerHeight.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerHeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerHeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Outside Area
                      </Typography>
                      <BootstrapTooltip title={tooltips?.outsideArea}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={outerOutsideAreaHandle}
                        onPaste={outerOutsideAreaHandle}
                        value={outerOutsideArea.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              mm&sup2;
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {outerOutsideArea.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {outerOutsideArea.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={3} direction="column" pt={6}>
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Dry Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip title={tooltips?.weigth}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={dryWeightHandle}
                        onPaste={dryWeightHandle}
                        value={dryWeight.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              kg
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {dryWeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {dryWeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ width: "11.5rem", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        onChange={dryCostHandle}
                        onPaste={dryCostHandle}
                        value={dryCost.name}
                        disabled={isTextFieldEnable}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              height: "28px",
                              width: "auto",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            <Typography
                              variant="p"
                              mx={1.5}
                              my={0.75}
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "5%",
                                color: "#19191A",
                              }}
                            >
                              $
                            </Typography>
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#3F4042",
                        }}
                      />
                      {dryCost.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {dryCost.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem p={0} m={0} />
            <Stack width={1 / 2} direction="column" spacing={2}>
              <ThemeProvider theme={theme}>
                <Box
                  maxWidth="false"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TabContext
                    value={selectTab}
                    sx={{
                      "&.Mui-selected": { color: "#19191A", fontWeight: "600" },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: 0,
                        borderColor: "none",
                        mb: "24px",
                      }}
                    >
                      <TabList
                        fontWeight={600}
                        textColor="secondary"
                        TabIndicatorProps={{
                          sx: {
                            "& .MuiButtonBase-root": { padding: 0 },
                            "& .MuiTabs-indicator": {
                              display: "none !important",
                            },
                            display: "none !important",
                            hidden: true,
                          },
                          style: { display: "none", hidden: true },
                        }}
                        onChange={handleTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          disableRipple={true}
                          label={"PCM"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="0"
                        />
                        <Tab
                          disableRipple={true}
                          label={"Insulation"}
                          sx={{
                            alignItems: "flex-start",
                            paddingRight: "24px",
                            padding: 0,
                            textTransform: "none",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#19191A",
                            "& .Mui-selected": {
                              color: "#19191A",
                              fontWeight: "600",
                            },
                          }}
                          value="1"
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="0"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <PCMTab enableData={isTextFieldEnable} />
                    </TabPanel>
                    <TabPanel
                      value="1"
                      sx={{
                        padding: "0",
                      }}
                    >
                      <Insulation enableData={isTextFieldEnable} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </ThemeProvider>
            </Stack>
          </Stack>
        </ThemeProvider>
      </Stack>

      {open && ProID && (
        <CreateShipperClass
          open={open}
          setOpen={setOpen}
          productID={ProID}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default ShipperClass;
