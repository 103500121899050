import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stack from "@mui/material/Stack";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import NoRecordImg from "../../assets/images/norecordfound.png";

import { custControl } from "../../components/Customer/CustomerSlice";
import { useDispatch } from "react-redux";

export default function ListCustomers(props) {
  const dispatch = useDispatch();
  const { setCustomer, setOpen, data } = props;
  const testStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const testStyle1 = {
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#323334",
    padding: "4px 12px",
    backgroundColor: "#E6E7E8",
    borderRadius: "4px",
    verticalAlign: "middle",
  };

  const handleSelect = (e, id, name) => {
    dispatch(custControl({ id: id, name: name }));
    setCustomer({ id: id, name: name });
    setOpen(false);
  };

  return (
    <Paper
      elevation={0}
      style={{
        maxHeight: 400,
        overflow: "auto",
        width: "100%",
        bgcolor: "background.paper",
      }}
    >
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",

          overflowY: "auto",
          margin: 0,
          padding: 0,
          listStyle: "none",
          height: "100%",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
            outline: "1px solid #98A2B3",
          },
        }}
      >
        {data.map((user, i) => {
          return (
            <ListItem
              sx={{ cursor: "pointer" }}
              onClick={(e) => handleSelect(e, user.id, user.customer_name)}
              disablePadding={true}
              key={user.id}
            >
              <Stack p={0} direction="row" sx={{ width: "100%" }}>
                <Stack
                  height={60}
                  sx={{ borderBottom: "1px solid #E6E7E8" }}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  width={2.5 / 4}
                >
                  <Typography component="h3" variant="h3" sx={testStyle}>
                    {user.customer_name}
                  </Typography>
                </Stack>
                <Stack
                  height={60}
                  sx={{ borderBottom: "1px solid #E6E7E8" }}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  width={1 / 4}
                >
                  <Typography
                    justifyContent="center"
                    component="h3"
                    variant="h3"
                    align="center"
                    sx={testStyle1}
                  >
                    {`${user.opportunity_count} OPPORTUNITIES`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  width={0.5 / 4}
                >
                  <Stack
                    height={60}
                    sx={{ borderBottom: "1px solid #E6E7E8" }}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    width={1 / 2}
                  >
                    <ArrowForwardIosIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Stack>
                  <Stack
                    height={60}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  ></Stack>
                </Stack>
              </Stack>
            </ListItem>
          );
        })}

        {data.length === 0 && (
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={NoRecordImg}
              alt="No Record Found"
              sx={{ width: "97.96px;", height: "96px" }}
            />
            <Typography
              component="h4"
              variant="h4"
              sx={{
                paddingTop: "16px",
                paddingBottom: "8px",
                fontWeight: "700",
                fontSize: "26px",
                lineHeight: "32px",
                color: "#323334",
              }}
            >
              No Customers yet
            </Typography>
            <Typography
              component="h4"
              variant="h4"
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#3F4042",
              }}
            >
              Click New Customer to start creating Customer
            </Typography>
          </Box>
        )}
      </List>
    </Paper>
  );
}
