import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createTheme,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { ExpandMore } from "@mui/icons-material";
import OutputThermal from "./OutputThermal";
import OutputPayloadFitment from "./OutputPayloadFitment";
import OutputOrderProfileOptimization from "./OutputOrderProfileOptimization";

export default function ScenarioOutput(props) {
  const { setOptimizeClicked, optimizeClicked } = props;
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const AccordStyle = {
    boxShadow:
      "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
  };

  const scenarioMainContainer = {
    "& .css-14w0e8k-MuiStack-root>:not(style)+:not(style)": {
      margin: 0,
      marginTop: "8px",
    },
    "&.css-1kktnqc-MuiButtonBase-root-MuiAccordionSummary-root": {
      borderRadius: "6px",
    },
    "& .css-1kktnqc-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "auto",
      height: "48px",
      borderRadius: "6px 6px 0 0",
    },
    "& .css-fpp7t5-MuiPaper-root-MuiAccordion-root": {
      backgroundColor: "transparent",
    },
  };

  const AccordSummaryStyle = {
    paddingLeft: "24px",
    borderRadius: "6px",
    backgroundColor: "#235984",
    color: "#FFFFFF",
    "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
      color: "#FFFFFF",
    },
  };

  const buttonContainerStyle = {
    alignItems: "flex-end",
  };

  const ButtonStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "capitalize",
    padding: "10px 20px",
    width: "290px",
    boxSizing: "border-box",
    border: "1px solid #B1B2B4",
    borderRadius: "6px",
    color: "#000000",
  };

  const breadcrumbData = [
    {
      id: 2,
      title: "ACME - Super Very Long Opportunity Name 3",
      link: "",
    },
    { id: 3, title: "New Scenario", link: "" },
    {
      id: 1,
      title: "Home / Acme Corporation",
      link: "",
    },
  ];
  return (
    <>
      <ThemeProvider theme={theme}>
        <Stack spacing={3} direction="column" px={0} pb={5}>
          <Stack spacing={6}>
            {/* Thermal Performance Estimated */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="Product Line"
                id="Thermal-Performance-Estimated"
              >
                <Typography>Thermal Performance Estimated</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {/* Thermal Performance Estimated Here*/}
                <OutputThermal />
              </AccordionDetails>
            </Accordion>

            {/* Payload Fitment */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="Payload Fitment"
                id="Payload-Fitment"
              >
                <Typography>Payload Fitment</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <OutputPayloadFitment />
              </AccordionDetails>
            </Accordion>

            {/* Order Profile Optimization */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="Order Profile Optimization"
                id="Order-Profile-Optimization"
              >
                <Typography>Order Profile Optimization</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <OutputOrderProfileOptimization />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </ThemeProvider>
    </>
  );
}
