import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as ConstantsList from "../../../src/constants/";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  width: "395px",
};

const DeleteRecord = (props) => {
  const {
    onClose,
    open,
    setSelectedCustomers,
    selectedCustomersList,
    setNotify,
    setNotifyMsg,
    setReload,
    setSeverity,
    setCount,
  } = props;
  const [data, setData] = useState("");
  // commented as part of code smell fix
  // const [selectedValue, setSelectedValue] = React.useState("");
  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  // };
  const customerNames = [];
  selectedCustomersList.map((selectedItem, index) => {
    customerNames[index] = selectedItem.customer_name;
  });
  const handleClose = () => {
    onClose(false);
  };

  const deleteuser = () => {
    selectedCustomersList.map((selectedItem, index) => {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        ConstantsList.API_URL + "opp/customer/" + selectedItem?.id,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setData(data);
            if (data.status_code === 200) {
              setReload(true);
              setNotifyMsg(data.data.toString());
              setNotify(true);
              setSeverity(true);
              setCount(true);
              onClose(false);
              setSelectedCustomers([]);
            } else if (data.status_code === 400) {
              setNotifyMsg(data.data.toString());
              setSeverity("error");
              setNotify(true);
              setCount(true);
              onClose(false);
            } else if (data.status_code === 500) {
              setNotifyMsg("Customer not found");
              setSeverity("error");
              setNotify(true);
              setCount(true);
              onClose(false);
            } else {
              setNotify(false);
              setData(data);
            }
          }
        })
        .catch((err) => console.log("Error ", err));
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="delete-close-icon"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Delete Customer
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                Please confirm you want to delete this Customer :
              </Typography>
              <Stack
                spacing={0}
                p={0}
                direction="row"
                sx={{ maxHeight: "42px" }}
              >
                <Box
                  p={0}
                  sx={{
                    backgroundColor: "#E34949",
                    width: "6px",
                    height: "42px",
                  }}
                ></Box>
                <Box container sx={{ height: "42px", marginLeft: "10px" }}>
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    {customerNames?.toString()}
                  </Typography>
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    {/* {noOfOpportunities} Opportunities */}
                  </Typography>
                </Box>
              </Stack>

              <Typography component="h6" variant="h6" sx={labelStyle}>
                All information related to this customer will also be deleted,
                do you want to proceed?
              </Typography>

              <Button
                onClick={deleteuser}
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                // disabled="true"
                data-testid="delete-customer-btn"
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeleteRecord;
