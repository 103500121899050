import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { Footer, Header, Reports as ReportsLayout } from "../../layouts";

const Reports = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  return (
    <ThemeProvider theme={theme}>
      <Header bgColor="#FFFFFF" />
      <ReportsLayout />
      <Footer bgColor="#1D3869" />
    </ThemeProvider>
  );
};

export default Reports;
