import React from "react";
import { Footer, MainContainer, Header } from "../../layouts";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { loginData } from "../../components/Login/LoginSlice";

const Dashboard = () => {
  const loginDetails = useSelector(loginData);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header bgColor="#FFFFFF" />
        <MainContainer bg="#F2F2F3" loginDetails={loginDetails} />
        <Footer bgColor="#1D3869" />
      </ThemeProvider>
    </>
  );
};

export default Dashboard;
