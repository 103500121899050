export const ADA = {
  customer_input: "Select a customer",
  headerPage: {
    logo_image: "This is Pelicon Image",
  },
  Opportunities: {
    settings_button: "This is Settings Button",
    filter_button: "This is filter Button",
    edit_button: "This is edit Button",
    delete: "This is delete Button",
    addApportunities_button: "Click here to add new Opportunities",
    searchApportunities_input: "Click here to search Opportunities",
    viewDetailsButton: "Click here to see scenarios mapped to this Opportunity",
    selectedRow: "You have selected on of the listed Opportunities",
    searchScenario_input:
      "Click here to search for different scenarios for the selected Opportunities",
    createNewScenario_button: "Click here to add new scenario",
    deleteScenario_button: "Click here to delete scenario",
    duplicateScenario_button: "Click here to duplicate this scenario",
    filterScenario_button: "Click here to filter  scenario",
    back_button: "Click here to go to back page",
    createNewCustomer_button: "Click here to add new customer",
    close_button: "Click here to close the search customer container",
    createNewCustomeSave_button: "Click here to save the new customer",
  },
  footer: {
    home_dictionaries: "Click here to go to Home/Shipper Dictionaries Page",
    users: "Click here to go to Users Page",
    customers: "Click here to go to Customers Page",
    faq: "Click here to go to FAQ Page",
  },
  shipper_dictionaries: {
    delete_btn: "Click here to delete the shipper",
    create_new_btn: "Click here to add a new shipper",
    search_input: "Click here to search for shipping dictionaries",
    save: "Click to save the new shipper",
  },
  users: {
    search_input: "Click here to search for user names",
    new_user: "Click here to add new user",
    edit_btn: " Click here to edit the user",
    delete_btn: "Click here to delete the user",
    save: "Click to save the new user",
  },
  customers: {
    new_customer_btn: "Click here to add new customer",
    delete_customer_btn: "Click here to delete customer",
    duplicate_customer_btn: "Click here to duplicate this customer",
    filter_filter_btn: "Click here to filter  customer",
  },
  scenario_list: {
    filter_btn: "Click here to filter",
    duplicate_btn: "Click here to duplicate",
    delete_btn: "Click here to delete ",
    save: "Click to save the new user",
    create_new_btn: "Click here to add a new shipper",
  },
  product_line: {
    view_sheet: "Click here to view product sheet",
    next: "Click here to go for next step",
  },
  temperature_profiles: {
    graph_prifile: "Click here to see graph profile",
    next: "Click here to go for next step",
    viewbtn: "Click here to view details",
  },
  probe_locations: {
    next: "Click here to go for next step",
  },
  payload: {
    next: "Click here to go for next step",
  },
  preferences: {
    thermal_setting_btn: "Click here to see Thermal setting",
  },
  optimize_scenario: {
    optimize: "Click here to optimize scenario",
    save: "Click here to save the settings",
  },
};
