import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, Button, ButtonGroup } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ListView from "../../components/Customer/ListView";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../../components/core/Loader/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DeleteRecord from "../../components/Customer/DeleteRecord";
import ListSearch from "../../components/Customer/Search";
import SnackBar from "../../components/core/SnackBar/SnackBar";
import EditRecord from "../../components/Customer/EditRecord";
import * as ConstantsList from "../../../src/constants/";
import AddCustomers from "../../components/Customers/AddCustomers";
import * as ADAConfig from "../../../src/config/ADAConfig";

import { useSelector } from "react-redux";
import { loginData } from "../../components/Login/LoginSlice";

const Customer = () => {
  const loginDetails = useSelector(loginData);
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState(null);
  const [listDataSearch, setListDataSearch] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const [cdeditopen, setCdeditopen] = React.useState(false);
  const [severity, setSeverity] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "cust/customer/")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setListData(data);
          setLoading(false);
          setReload(false);
        }
      });
  }, [reload]);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [customerName, setCustomerName] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedCustomersList, setSelectedCustomersList] = useState([]);
  const [notifyMsg, setNotifyMsg] = useState("");

  const handleCdOpen = () => {
    setCdopen(true);
  };

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const handleCdeditOpen = () => {
    setCdeditopen(true);
  };

  const handleCdeditClose = (value) => {
    setCdeditopen(false);
  };

  const [notify, setNotify] = useState(false);

  const isEdit = selectedCustomers.length === 1 ? false : true;

  const isEnabled = selectedCustomers.length > 0 ? false : true;

  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            pt: 3,
            pl: 3,
            pr: 3,
          }}
        >
          Customers
        </Typography>
        <Box sx={{}}>
          {loginDetails?.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#000000",
                  color: "#000000",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                }}
              >
                <Button
                  onClick={handleCdeditOpen}
                  data-testid="editbutton"
                  disabled={isEdit}
                  startIcon={<EditIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                  aria-label={ADAConfig.ADA.customers.filter_filter_btn}
                >
                  Edit
                </Button>
                <Button
                  onClick={handleCddelOpen}
                  data-testid="deletebutton"
                  disabled={isEnabled}
                  startIcon={<DeleteIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                  aria-label={ADAConfig.ADA.customers.delete_customer_btn}
                >
                  Delete
                </Button>
                <Button
                  onClick={handleCdOpen}
                  data-testid="addbutton"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                  aria-label={ADAConfig.ADA.customers.new_customer_btn}
                >
                  Create New
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {listData && (
          <ListSearch data={listData.data} search={setListDataSearch} />
        )}
      </Box>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      {listData && (
        <ListView
          data={listData.data}
          setCustomerName={setCustomerName}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          selectedCustomersList={selectedCustomersList}
          setSelectedCustomersList={setSelectedCustomersList}
          search={listDataSearch !== null ? listDataSearch : false}
        />
      )}
      {cdopen && (
        <AddCustomers
          open={cdopen}
          setOpen={handleCdClose}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
        />
      )}
      {cddelopen && (
        <DeleteRecord
          open={cddelopen}
          onClose={handleCddelClose}
          customerName={customerName}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          selectedCustomersList={selectedCustomersList}
          setSelectedCustomersList={setSelectedCustomersList}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
          setSeverity={setSeverity}
          setCount={setCount}
        />
      )}
      {cdeditopen && listData && (
        <EditRecord
          open={cdeditopen}
          data={listData?.data ?? []}
          onClose={handleCdeditClose}
          setCustomerName={setCustomerName}
          customerName={customerName}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
          setSeverity={setSeverity}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default Customer;
