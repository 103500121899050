import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tab,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Probe3DPic from "../../../assets/images/onbase.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useSelector } from "react-redux";
import { shipperDetailsData } from "../../ViewShipper/ViewShipperSlice";
import * as Config from "../../../config";
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#19191A",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        "&$selected": {
          fontWeight: "600",
          color: "#19191A",
          textDecoration: "underline !important",
          textDecorationThickness: "4px",
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&$selected": {
          fontWeight: "600",
          color: "#19191A",
        },
      },
    },
  },
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ProbeConfiguration = (props) => {
  const shipperDetails = useSelector(shipperDetailsData);

  const [selectTab, setSelectTab] = useState("0");
  const handleChange = (event, newValue) => {
    setSelectTab(newValue.toString());
  };

  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const DataValues = ["r_value", "top_wall", "end_wall", "side_wall"];
  const Names = [
    "top_corner",
    "end_face",
    "center",
    "bottom_center",
    "bottom_corner",
  ];
  const ProbeSeason = ["probe_summer", "probe_winter"];
  const ProbePosition = ["on_base", "on_side"];

  let TestVal = [];

  const DD = ProbePosition.map((position) => {
    const seasons = {};

    ProbeSeason.map((season) => {
      const positions = {};
      Names.map((name) => {
        const datapositions = {};
        DataValues.map(
          (values) =>
            (datapositions[values] = { value: "", error: false, message: "" })
        );
        positions[name] = datapositions;
        return name;
      });
      seasons[season] = positions;

      return positions;
    });
    return (TestVal[position] = seasons);
  });

  const GetVale = (base, season, corner, value) => {
    let dd = shipperDetails?.probeconfig;
    return dd[base][season][corner][value]?.toString() ?? "";
  };

  const CornerValues = [
    { name: "Top Corner", attr: "top_corner" },
    { name: "End Face", attr: "end_face" },
    { name: "Center", attr: "center" },
    { name: "Bottom Center", attr: "bottom_center" },
    { name: "Bottom Corner", attr: "bottom_corner" },
  ];
  const AreaData = ["r_value", "top_wall", "end_wall", "side_wall"];

  const [OnBaseSummer, setOnBaseSummer] = useState([]);
  const [OnBaseWinter, setOnBaseWinter] = useState([]);
  const [OnSideSummer, setOnSideSummer] = useState([]);
  const [OnSideWinter, setOnSideWinter] = useState([]);

  const [selectedCheck, setSelectedCheck] = useState([]);

  const removeDuplicateData = (arr) => {
    let unique = [];
    for (let i = 0; i < arr.length; i++) {
      if (unique.indexOf(arr[i]) === -1) {
        unique.push(arr[i]);
      }
    }
    return unique;
  };

  useEffect(() => {
    if (shipperDetails?.probeconfig) {
      const OnBaseSummer1 = shipperDetails?.probeconfig?.on_base?.probe_summer;
      const OnBaseWinter1 = shipperDetails?.probeconfig?.on_base?.probe_winter;
      const OnSideSummer1 = shipperDetails?.probeconfig?.on_side?.probe_summer;
      const OnSideWinter1 = shipperDetails?.probeconfig?.on_side?.probe_winter;

      let DS = [];
      CornerValues.map((corner, i) => {
        return AreaData.map((data) => {
          return OnBaseSummer1[corner.attr][data].toString().trim().length > 0
            ? DS.push(`Base-ProbeSummer-${i}`)
            : false;
        });
      });

      CornerValues.map((corner, i) => {
        return AreaData.map((data) => {
          return OnBaseWinter1[corner.attr][data].toString().trim().length > 0
            ? DS.push(`Base-ProbeWinter-${i}`)
            : false;
        });
      });

      CornerValues.map((corner, i) => {
        return AreaData.map((data) => {
          return OnSideSummer1[corner.attr][data].toString().trim().length > 0
            ? DS.push(`Side-ProbeSummer-${i}`)
            : false;
        });
      });

      CornerValues.map((corner, i) => {
        return AreaData.map((data) => {
          return OnSideWinter1[corner.attr][data].toString().trim().length > 0
            ? DS.push(`Side-ProbeWinter-${i}`)
            : false;
        });
      });

      let CheckData = removeDuplicateData(DS);

      setSelectedCheck(CheckData);
      setOnBaseSummer(
        CornerValues.map((corner, i) => {
          return {
            id: i + 1,
            name: corner.name,
            data: AreaData.map((area, j) => {
              return {
                id: j + 10,
                value: GetVale("on_base", "probe_summer", corner.attr, area),
                error: false,
                message: "",
              };
            }),
          };
        })
      );

      setOnBaseWinter(
        CornerValues.map((corner, i) => {
          return {
            id: i + 1,
            name: corner.name,
            data: AreaData.map((area, j) => {
              return {
                id: j + 10,
                value: GetVale("on_base", "probe_winter", corner.attr, area),
                error: false,
                message: "",
              };
            }),
          };
        })
      );

      setOnSideSummer(
        CornerValues.map((corner, i) => {
          return {
            id: i + 1,
            name: corner.name,
            data: AreaData.map((area, j) => {
              return {
                id: j + 10,
                value: GetVale("on_side", "probe_summer", corner.attr, area),
                error: false,
                message: "",
              };
            }),
          };
        })
      );

      setOnSideWinter(
        CornerValues.map((corner, i) => {
          return {
            id: i + 1,
            name: corner.name,
            data: AreaData.map((area, j) => {
              return {
                id: j + 10,
                value: GetVale("on_side", "probe_winter", corner.attr, area),
                error: false,
                message: "",
              };
            }),
          };
        })
      );
    }
  }, [shipperDetails?.probeconfig]);

  const isSelected = (name) => selectedCheck.indexOf(name) !== -1;

  const isDisabled = true;

  return (
    <Box maxWidth="false" sx={{ width: "100%", height: "auto" }}>
      <ThemeProvider theme={customTheme}>
        <Box maxWidth="false" px={3} pb={5}>
          <TabContext
            value={selectTab}
            sx={{
              "&.Mui-selected": {
                color: "#19191A",
                fontWeight: "600",
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderBottom: 0,
                borderColor: "none",
                mb: "24px",
              }}
            >
              <TabList
                fontWeight={600}
                textColor="secondary"
                TabIndicatorProps={{
                  sx: {
                    "& .MuiButtonBase-root": { padding: 0 },
                    "& .MuiTabs-indicator": {
                      display: "none !important",
                    },
                    display: "none !important",
                    hidden: true,
                  },
                  style: { display: "none", hidden: true },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  disableRipple={true}
                  label="On Base"
                  sx={{
                    alignItems: "flex-start",
                    paddingRight: "24px",
                    padding: 0,
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#19191A",
                    "& .Mui-selected": {
                      color: "#19191A",
                      fontWeight: "600",
                    },
                  }}
                  value="0"
                />
                <Tab
                  disableRipple={true}
                  label="On Side"
                  sx={{
                    alignItems: "flex-start",
                    paddingRight: "24px",
                    padding: 0,
                    textTransform: "none",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#19191A",
                    "& .Mui-selected": {
                      color: "#19191A",
                      fontWeight: "600",
                    },
                  }}
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel
              value={"0"}
              sx={{
                padding: "0",
              }}
            >
              <Stack direction="row" spacing={6}>
                <Box
                  component="img"
                  src={Probe3DPic}
                  alt="Upload Pic"
                  sx={{
                    width: "238px",
                    height: "394px",
                  }}
                />

                <Stack
                  spacing={3}
                  direction="column"
                  sx={{ width: "-webkit-fill-available" }}
                >
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Probe - Summer
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{ border: "1px solid #E6E7E8" }}
                  >
                    {/* Row 0 */}
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#F2F2F3",
                      }}
                    >
                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      ></Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          R-Value
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.rvalue
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Top Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.topWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Side Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.sideWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          End Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.endWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>
                    </Stack>

                    {/* Row 1 */}

                    {OnBaseSummer &&
                      OnBaseSummer?.map((row, i) => {
                        const RowName = `Base-ProbeSummer-${i}`;
                        const IsChecked = isSelected(RowName);
                        return (
                          <Stack
                            key={row.id}
                            direction="row"
                            sx={{
                              width: "100%",
                              height: "64px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            <Stack
                              width={1 / 5}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <ThemeProvider theme={checkboxTheme}>
                                <Checkbox
                                  disabled={isDisabled}
                                  checked={IsChecked}
                                  p={0}
                                  value={selectedCheck}
                                  name={`Check ${row.id}`}
                                  inputProps={{ "aria-label": "A" }}
                                />
                              </ThemeProvider>
                              <Typography
                                component="h6"
                                variant="h6"
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#000000",
                                  fontWeight: "400",
                                }}
                              >
                                {row.name}
                              </Typography>
                            </Stack>
                            {row.data.map((column, j) => {
                              return (
                                <Stack
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <FormControl
                                    disabled={isDisabled}
                                    fullWidth
                                    size="small"
                                    sx={{
                                      width: "11.5rem",
                                      height: "44px",
                                    }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      type="number"
                                      id={`Prode-Config-Input-Top-Summer-${i}-${j}`}
                                      value={column.value}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            height: "28px",
                                            width: "auto",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "4px",
                                            backgroundColor: "#F2F2F3",
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            mx={1.5}
                                            my={0.75}
                                            sx={{
                                              fontFamily: "Roboto",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "5%",
                                              color: "#19191A",
                                            }}
                                          >
                                            {j === 0 ? (
                                              <>ft&sup2;·°F·h/BTU</>
                                            ) : (
                                              "mW/mK"
                                            )}
                                          </Typography>
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        color: "#3F4042",
                                      }}
                                    />
                                  </FormControl>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#FFFFFF",
                      }}
                    ></Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={6}>
                <Box
                  component="div"
                  alt="Upload Pic"
                  sx={{
                    minWidth: "238px",
                    height: "394px",
                  }}
                />

                <Stack
                  spacing={3}
                  direction="column"
                  sx={{ width: "-webkit-fill-available" }}
                >
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Probe - Winter
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{ border: "1px solid #E6E7E8" }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#F2F2F3",
                      }}
                    >
                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      ></Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          R-Value
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.rvalue
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Top Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.topWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Side Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.sideWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          End Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.endWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>
                    </Stack>

                    {/* Row 1 */}

                    {OnBaseWinter &&
                      OnBaseWinter?.map((row, i) => {
                        const RowName = `Base-ProbeWinter-${i}`;
                        const IsChecked = isSelected(RowName);
                        return (
                          <Stack
                            key={row.id}
                            direction="row"
                            sx={{
                              width: "100%",
                              height: "64px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            <Stack
                              width={1 / 5}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <ThemeProvider theme={checkboxTheme}>
                                <Checkbox
                                  disabled={isDisabled}
                                  checked={IsChecked}
                                  p={0}
                                  value={selectedCheck}
                                  name={`Check ${row.id}`}
                                  inputProps={{ "aria-label": "A" }}
                                />
                              </ThemeProvider>
                              <Typography
                                component="h6"
                                variant="h6"
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#000000",
                                  fontWeight: "400",
                                }}
                              >
                                {row.name}
                              </Typography>
                            </Stack>
                            {row.data.map((column, j) => {
                              return (
                                <Stack
                                  key={`Base-ProbeWinter-Column-${j}`}
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <FormControl
                                    disabled={isDisabled}
                                    fullWidth
                                    size="small"
                                    sx={{
                                      width: "11.5rem",
                                      height: "44px",
                                    }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      type="number"
                                      id={`Prode-Config-Input-Top-Summer-${i}-${j}`}
                                      value={column.value}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            height: "28px",
                                            width: "auto",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "4px",
                                            backgroundColor: "#F2F2F3",
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            mx={1.5}
                                            my={0.75}
                                            sx={{
                                              fontFamily: "Roboto",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "5%",
                                              color: "#19191A",
                                            }}
                                          >
                                            {j === 0 ? (
                                              <>ft&sup2;·°F·h/BTU</>
                                            ) : (
                                              "mW/mK"
                                            )}
                                          </Typography>
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        color: "#3F4042",
                                      }}
                                    />
                                  </FormControl>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#FFFFFF",
                      }}
                    ></Stack>
                  </Stack>
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel
              value={"1"}
              sx={{
                padding: "0",
              }}
            >
              <Stack direction="row" spacing={6}>
                <Box
                  component="img"
                  src={Probe3DPic}
                  alt="Upload Pic"
                  sx={{
                    width: "238px",
                    height: "394px",
                  }}
                />

                <Stack
                  spacing={3}
                  direction="column"
                  sx={{ width: "-webkit-fill-available" }}
                >
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Probe - Summer
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{ border: "1px solid #E6E7E8" }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#F2F2F3",
                      }}
                    >
                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      ></Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          R-Value
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.rvalue
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Top Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.topWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Side Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.sideWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          End Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.endWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>
                    </Stack>

                    {/* Row 1 */}

                    {OnSideSummer &&
                      OnSideSummer?.map((row, i) => {
                        const RowName = `Side-ProbeSummer-${i}`;
                        const IsChecked = isSelected(RowName);
                        return (
                          <Stack
                            key={row.id}
                            direction="row"
                            sx={{
                              width: "100%",
                              height: "64px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            <Stack
                              width={1 / 5}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <ThemeProvider theme={checkboxTheme}>
                                <Checkbox
                                  disabled={isDisabled}
                                  checked={IsChecked}
                                  p={0}
                                  value={selectedCheck}
                                  name={`Check ${row.id}`}
                                  inputProps={{ "aria-label": "A" }}
                                />
                              </ThemeProvider>
                              <Typography
                                component="h6"
                                variant="h6"
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#000000",
                                  fontWeight: "400",
                                }}
                              >
                                {row.name}
                              </Typography>
                            </Stack>
                            {row.data.map((column, j) => {
                              return (
                                <Stack
                                  key={`Side-ProbeSummer-Column-${j}`}
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <FormControl
                                    disabled={isDisabled}
                                    fullWidth
                                    size="small"
                                    sx={{
                                      width: "11.5rem",
                                      height: "44px",
                                    }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      type="number"
                                      id={`Prode-Config-Input-Top-Summer-${i}-${j}`}
                                      value={column.value}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            height: "28px",
                                            width: "auto",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "4px",
                                            backgroundColor: "#F2F2F3",
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            mx={1.5}
                                            my={0.75}
                                            sx={{
                                              fontFamily: "Roboto",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "5%",
                                              color: "#19191A",
                                            }}
                                          >
                                            {j === 0 ? (
                                              <>ft&sup2;·°F·h/BTU</>
                                            ) : (
                                              "mW/mK"
                                            )}
                                          </Typography>
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        color: "#3F4042",
                                      }}
                                    />
                                  </FormControl>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#FFFFFF",
                      }}
                    ></Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={6}>
                <Box
                  component="div"
                  alt="Upload Pic"
                  sx={{
                    minWidth: "238px",
                    height: "394px",
                  }}
                />

                <Stack
                  spacing={3}
                  direction="column"
                  sx={{ width: "-webkit-fill-available" }}
                >
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Probe - Winter
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{ border: "1px solid #E6E7E8" }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#F2F2F3",
                      }}
                    >
                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      ></Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          R-Value
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.rvalue
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Top Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.topWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          Side Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.sideWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>

                      <Stack
                        width={1 / 5}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#000000",
                            fontWeight: "400",
                          }}
                        >
                          End Wall
                        </Typography>
                        <BootstrapTooltip
                          title={
                            Config.CONFIGLIST.shipperDetails.tooltip
                              .probeconfiguration.endWall
                          }
                        >
                          <InfoOutlinedIcon
                            sx={{
                              color: "#7D7F82",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Stack>
                    </Stack>

                    {/* Row 1 */}

                    {OnSideWinter &&
                      OnSideWinter?.map((row, i) => {
                        const RowName = `Side-ProbeWinter-${i}`;
                        const IsChecked = isSelected(RowName);
                        return (
                          <Stack
                            key={row.id}
                            direction="row"
                            sx={{
                              width: "100%",
                              height: "64px",
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            <Stack
                              width={1 / 5}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <ThemeProvider theme={checkboxTheme}>
                                <Checkbox
                                  disabled={isDisabled}
                                  checked={IsChecked}
                                  p={0}
                                  value={selectedCheck}
                                  name={`Check ${row.id}`}
                                  inputProps={{ "aria-label": "A" }}
                                />
                              </ThemeProvider>
                              <Typography
                                component="h6"
                                variant="h6"
                                sx={{
                                  fontFamily: "Roboto",
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#000000",
                                  fontWeight: "400",
                                }}
                              >
                                {row.name}
                              </Typography>
                            </Stack>
                            {row.data.map((column, j) => {
                              return (
                                <Stack
                                  key={`Side-ProbeWinter-Column-${j}`}
                                  width={1 / 5}
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <FormControl
                                    disabled={isDisabled}
                                    fullWidth
                                    size="small"
                                    sx={{
                                      width: "11.5rem",
                                      height: "44px",
                                    }}
                                    variant="outlined"
                                  >
                                    <OutlinedInput
                                      type="number"
                                      id={`Prode-Config-Input-Top-Summer-${i}-${j}`}
                                      value={column.value}
                                      endAdornment={
                                        <InputAdornment
                                          position="end"
                                          sx={{
                                            height: "28px",
                                            width: "auto",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "4px",
                                            backgroundColor: "#F2F2F3",
                                          }}
                                        >
                                          <Typography
                                            variant="p"
                                            mx={1.5}
                                            my={0.75}
                                            sx={{
                                              fontFamily: "Roboto",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "5%",
                                              color: "#19191A",
                                            }}
                                          >
                                            {j === 0 ? (
                                              <>ft&sup2;·°F·h/BTU</>
                                            ) : (
                                              "mW/mK"
                                            )}
                                          </Typography>
                                        </InputAdornment>
                                      }
                                      aria-describedby="outlined-weight-helper-text"
                                      inputProps={{
                                        "aria-label": "weight",
                                      }}
                                      sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        color: "#3F4042",
                                      }}
                                    />
                                  </FormControl>
                                </Stack>
                              );
                            })}
                          </Stack>
                        );
                      })}
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        height: "64px",
                        backgroundColor: "#FFFFFF",
                      }}
                    ></Stack>
                  </Stack>
                </Stack>
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default ProbeConfiguration;
