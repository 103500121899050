import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import { Route, Switch, useHistory } from "react-router-dom";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import OktaHead from "./layouts/Header/oktaHead";
import PageNotFound from "../src/pages/404";
import Dashboard from "../src/pages/Dashboard";
import Login from "../src/pages/Login";
import Profile from "../src/pages/Profile";
import Shipper from "../src/pages/Shipper";
import PcmNew from "../src/pages/PCM/Create";
import PcmView from "../src/pages/PCM/View";
import PcmEdit from "../src/pages/PCM/Edit";
import ShipperNew from "../src/pages/NewShipper";
import Opportunities from "../src/pages/Opportunities";
import AccessDenied from "../src/pages/401";
import ShipperEdit from "../src/pages/EditShipper";
import ShipperView from "../src/pages/ViewShipper";
import ShipperDetails from "../src/pages/ShipperDetails";
import ShipperList from "../src/pages/ShipperList";
import Scenario from "../src/pages/Scenario";
import ScenarioList from "../src/pages/ScenarioList";
import Reports from "../src/pages/Reports";
import FAQ from "../src/pages/FAQ";
import * as ConstantsList from "../src/constants/";

import Test from "./components/Test/Test";

const oktaAuth = new OktaAuth({
  issuer: ConstantsList.OKTA_ISSUER,
  clientId: ConstantsList.OKTA_CLIENTID,
  redirectUri: window.location.origin + "/login/callback",
});

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const onAuthRequired = function () {
    history.push("/login");
  };
  return (
    <>
      <CssBaseline />
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={onAuthRequired}
      >
        <OktaHead />
        <Switch>
          <Route path="/" exact={true} component={Login} />
          <Route path="/login" exact={true} component={Login} />
          <SecureRoute path="/dashboard" exact={true} component={Dashboard} />
          <SecureRoute exact path="/test" component={Test} />
          <SecureRoute
            exact
            path="/dashboard/opportunity"
            component={Opportunities}
          />
          <SecureRoute
            exact
            path="/dashboard/opportunity/scenario-list"
            component={ScenarioList}
          />
          <SecureRoute
            exact
            path="/dashboard/opportunity/scenario"
            component={Scenario}
          />
          <SecureRoute
            exact
            path="/dashboard/opportunity/scenario/reports/:id/:name"
            component={Reports}
          />
          <SecureRoute exact path="/dashboard/shipper" component={Shipper} />
          <SecureRoute
            exact
            path="/dashboard/shipper-list"
            component={ShipperList}
          />
          <SecureRoute
            exact
            path="/dashboard/shipper/shipper-details"
            component={ShipperDetails}
          />
          <SecureRoute
            exact
            path="/dashboard/shipper/new"
            component={ShipperNew}
          />
          <SecureRoute
            exact
            path="/dashboard/shipper/edit/:id"
            component={ShipperEdit}
          />
          <SecureRoute
            exact
            path="/dashboard/shipper/view/:id"
            component={ShipperView}
          />

          <SecureRoute exact path="/dashboard/pcm/new" component={PcmNew} />
          <SecureRoute exact path="/dashboard/pcm/view" component={PcmView} />
          <SecureRoute exact path="/dashboard/pcm/edit" component={PcmEdit} />

          <SecureRoute exact path="/view-profile" component={Profile} />
          <SecureRoute exact path="/faq" component={FAQ} />
          <SecureRoute exact path="/access-denied" component={AccessDenied} />

          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute exact path="*" component={PageNotFound} />
        </Switch>
      </Security>
    </>
  );
};

export default App;
