import { Box } from '@mui/material'
import React from 'react'
import ReportsComponent from "../../components/Reports"

const Reports = () => {
  return (
    <>
      <Box
        maxWidth="false"
        sx={{
          width: "100%",
          minHeight: {
            xs: "calc(100vh - 160px)",
            sm: "calc(100vh - 160px)",
            md: "calc(100vh - 160px)",
            lg: "calc(100vh - 200px)",
            xl: "calc(100vh - 224px)",
          },
          height: "auto",
          backgroundColor: "#F2F2F3",
          direction: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        data-testid="box-outer-container"

      >
        <ReportsComponent />
      </Box>
    </>
  )
}

export default Reports