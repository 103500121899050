import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    color: "#19191A",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#FFFFFF",
    color: "#19191A",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableData = (props) => {
  const { columns, rows } = props;

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 700, width: "auto" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {columns.map((data, i) => {
              return (
                <StyledTableCell
                  key={`Table-Head-${i}`}
                  align={i === 0 ? "left" : "center"}
                >
                  {data}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              {columns.map((column, j) => {
                return (
                  <>
                    {j === 0 ? (
                      <StyledTableCell key={`Column-${j}`} align="left">
                        {row[j]}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell key={`Column-${j}`} align="center">
                        {row[j]}
                      </StyledTableCell>
                    )}
                  </>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableData;
