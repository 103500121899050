import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
const accordionStyle = {
  padding: "10px 0",
  boxShadow: "none",
  backgroundColor: "transparent",
};
const accordionSummaryStyle = {
  padding: "0",
};
const accordionTitle = {
  fontWeight: "500",
  fontSize: "24px",
};
const accordionDetails = {
  color: "#3F4042",
  fontSize: "16px",
  lineHeight: "24px",
};
const faqMainContainer = {
  backgroundColor: "#f2f2f3",
  paddingBottom: "60px",
  "& .css-1omva8h-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type": {
    borderBottom: "0",
  },
  "& Button.MuiButtonBase-root.Mui-selected": {
    color: "#000",
  },
  "& .css-1aquho2-MuiTabs-indicator": {
    backgroundColor: "white",
  },
  "& .MuiAccordion-root": {
    borderBottom: "1px solid #ddd !important",
    margin: "0px!important",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FaqTabs() {
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={faqMainContainer} p={6}>
      <Stack spacing={4}>
        <Stack direction="row" sx={{ width: "100%" }}>
          <Stack sx={{ alignItems: "center" }}>
            <Button
              variant="outlined"
              aria-label="outlined button group"
              data-testid="faq-back-btn"
              startIcon={<ArrowBackIcon />}
              sx={{
                "&:hover": { border: "1px solid #F26322" },
                backgroundColor: "#FFFFFF",
              }}
              style={{
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 400,
                borderRadius: "6px",
                color: "black",
              }}
              onClick={(e) => {
                history.push("/");
              }}
            >
              Back
            </Button>
          </Stack>
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "32px" }}>
              Frequently Asked Questions
            </Typography>
          </Stack>
        </Stack>

        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            backgroundColor: "#fff",
            boxShadow:
              "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{
              width: "70%",
              margin: "0 auto",
              padding: "25px 0",
              minHeight: "500px",
            }}
          >
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="General"
                  {...a11yProps(0)}
                  sx={{ fontSize: "20px", textTransform: "none" }}
                />
                <Tab
                  label="Shipper Dictionary"
                  {...a11yProps(1)}
                  sx={{ fontSize: "20px", textTransform: "none" }}
                />
                <Tab
                  label="Program Optimization"
                  {...a11yProps(2)}
                  sx={{ fontSize: "20px", textTransform: "none" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div>
                <Accordion sx={accordionStyle} elevation={0}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What is TruFit™?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      TruFit™ is a powerful optimization software that evaluates
                      customer requirements for thermal packaging and provides
                      thermal packing solution recommendations to reduce freight
                      costs and environmental impact.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle} elevation={0}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What are the user roles and the features they have access
                      to?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      Administrator - manages users, manages the shipper
                      dictionary, creates customers, creates opportunities,
                      creates optimization scenarios, and edits reports.
                    </Typography>
                    <Typography sx={accordionDetails}>
                      Sales Engineer - creates customers, creates opportunities,
                      creates optimization scenarios, and edits reports.
                    </Typography>
                    <Typography sx={accordionDetails}>
                      Account Manager - views reports
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div>
                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What is a shipper dictionary?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      A shipper dictionary is the digital data representing each
                      shipping solution Peli BioThermal has to offer. Shipper
                      dictionaries may also exist for competitors and unreleased
                      Peli products involved in the NPD process.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What defines a shipper?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      A shipper is identified by 4 key components: Product line,
                      Class, PCM, and Configuration. For example, Credo Go,
                      152914, Series 4, 6-TIC is unique from Credo Go, 152914,
                      Series 4, 4-TIC. These shippers share many features but
                      will have different payload size, weights, and performance
                      characteristics. There is a multitude of variables that
                      define a shipper and are used during optimization
                      calculations.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div>
                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={accordionTitle}>
                      How is a customer defined?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      Customers should be defined as a single global entity,
                      even if there are numerous global subsidiaries.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      How is an opportunity defined?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      An opportunity references a specific sales opportunity
                      within the customer organization.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      How is a scenario defined?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      A scenario is an instance that the optimization algorithm
                      can be run against. A single opportunity may have multiple
                      scenarios created to see how one product line may compare
                      to another, or how a product line defined in a competitor
                      dictionary may compare to a Peli dictionary. Different
                      scenarios may also have different settings to control the
                      optimization routine or constrain the payload input.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What is the output screen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      When a scenario has been submitted for optimization, the
                      output screen is used to track optimization progress and
                      view intermediate results.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What is the report screen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      The report screen is where the optimal solution outputs
                      can be viewed in graphical and tabular detail.Filtered
                      reports can be saved as a new report for narrowed scope
                      and future retrieval.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What does ‘Selected Proposal’ mean on the Scenario screen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      Only one scenario may be identified as the ‘Selected
                      Proposal’ within each opportunity.This is to be used as a
                      flag to indicate which shippers were finally proposed to
                      the customer so that the details could be accessed quickly
                      at any point in the future.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      What does ‘Shared with Customer’ mean on the Report
                      screen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      TruFit™ output data that is shared with the customer can
                      be flagged within the software for future
                      reference.Multiple reports can be flagged as shared within
                      scenario. The report description can be used to add notes
                      with reference to the customer (date or specific contact).
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={accordionStyle}>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={accordionTitle}>
                      Why are rows highlighted on the output screen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={accordionDetails}>
                      Rows that are highlighted indicate that the shipper did
                      not meet the requirements of the analysis and were not
                      passed on to the next step of optimization.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </TabPanel>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
