import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Footer, Opportunity, Header } from "../../layouts";
import { useSelector } from "react-redux";
import { custData } from "../../components/Customer/CustomerSlice";
import * as ConstantsList from "../../../src/constants/";
import { Box } from "@mui/material";

const Opportunities = () => {
  const customerDetails = useSelector(custData);

  const [reload, setReload] = useState(false);
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 1280,
        md: 1440,
        lg: 1600,
        xl: 1920,
      },
    },
  });

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const customerID = customerDetails?.id;
  useEffect(() => {
    setLoading(true);
    if (customerID) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer_id: customerID }),
      };
      fetch(ConstantsList.API_URL + "opp/opportunity-customer/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setData(data.data);
            setLoading(false);
            setReload(false);
            setRefresh(false);
          }
        });
    }
  }, [reload, customerID, refresh]);

  const OpportunityData = data ? data.opportunity : {};

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header bgColor="#FFFFFF" />
        <Box
          maxWidth="false"
          sx={{
            width: "100%",
            minHeight: {
              xs: "calc(100vh - 160px)",
              sm: "calc(100vh - 160px)",
              md: "calc(100vh - 160px)",
              lg: "calc(100vh - 200px)",
              xl: "calc(100vh - 224px)",
            },
            height: "auto",
            backgroundColor: "#F2F2F3",
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {data && (
            <Opportunity
              data={OpportunityData}
              setReload={setReload}
              customer={customerDetails}
            />
          )}
        </Box>

        <Footer bgColor="#1D3869" />
      </ThemeProvider>
    </>
  );
};

export default Opportunities;
