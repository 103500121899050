import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    value: {
        customer_id: '',
        customer_name: '',
        opportunity_id: '',
        opportunity_name: '',
        scenario_name: '',
        description: ''

    },
  },
  reducers: {
    dataControl: (state, action) => {
        state.value = action.payload;
    },
  },
});

export const { dataControl } = dataSlice.actions;

export const userData = state => state.data.value;

export default dataSlice.reducer;
