import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Button,
  ButtonGroup,
  Stack,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  SwipeableDrawer,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Filter from "@mui/icons-material/Tune";
import Duplicate from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ListView from "../../components/Shipper/ListView";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../../components/core/Loader/Loader";
import CreateNew from "../../components/Shipper/CreateNew";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DeleteRecord from "../../components/Shipper/DeleteRecord";
import SnackBar from "../../components/core/SnackBar/SnackBar";
import * as ConstantsList from "../../../src/constants/";
import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  shipperIDUpdate,
  resetData,
} from "../../components/NewShipper/NewShipperSlice";

import CloseIcon from "@mui/icons-material/Close";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

import {
  resetData as resetEditData,
  editShipperControl,
} from "../../components/Shipper/EditShipper/EditShipperSlice";
import { loginData } from "../../components/Login/LoginSlice";

import ClearIcon from "@mui/icons-material/Clear";

const Shipper = (props) => {
  let history = useHistory();
  const loginDetails = useSelector(loginData);
  const dispatch = useDispatch();
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [shipperList, setShipperList] = useState([]);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const shipperDictionary = useSelector(shipperDictionaryData);
  const [reload, setReload] = useState(false);

  const ShipperID = shipperDictionary?.id;

  useEffect(() => {
    setLoading(true);
    if (ShipperID) {
      const requestOptions = {
        method: "GET",
      };
      fetch(
        ConstantsList.API_URL + "shipdict/shipperdict-filter/" + ShipperID,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setShipperList(data);
              setLoading(false);
              setReload(false);
            }
          }
        });
    }
  }, [reload, ShipperID]);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [shipperId, setShipperId] = useState("");
  const [productLine, setProductLine] = useState("");
  const [shipperClass, setShipperClass] = useState("");
  const [pcmType, setPcmType] = useState("");
  const [configuration, setConfiguration] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [lastEditedDate, setLastEditedDate] = useState("");

  const [notifyMsg, setNotifyMsg] = useState("");

  const [severity, setSeverity] = useState(false);

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const createNew = () => {
    dispatch(resetData());
    dispatch(shipperIDUpdate(ShipperID.toString()));
    history.push("/dashboard/shipper/new");
  };

  const editNew = () => {
    dispatch(resetEditData());

    fetch(ConstantsList.API_URL + "shipdict/shipper/" + shipperId)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code.toString() === "200") {
          setLoading(false);
          if (dispatch(editShipperControl(data.data))) {
            history.push("/dashboard/shipper/edit/" + shipperId.toString());
          }
        }
      });
  };

  const [notify, setNotify] = useState(false);

  const [settingOpen, setSettingOpen] = useState(false);

  const handleSettingOpen = () => {
    setSettingOpen(true);
  };

  const handleSettingClose = () => {
    setSettingOpen(false);
  };

  const labelStyle = {
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3F4042",
  };

  const [enableSave, setEnableSave] = useState(false);

  const [productLines, setProductLines] = useState([]);
  const [classType, setClassType] = useState([]);
  const [PCMType, setPCMType] = useState([]);
  const [ticType, setTICType] = useState([]);

  useEffect(() => {
    if (ShipperID)
      var raw = JSON.stringify({
        shipperdict_id: ShipperID,
      });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    fetch(ConstantsList.API_URL + "prodline/productline_type/", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            let DD = [];
            //DD.push({ id: 0, name: "All" });
            data.data.map((dataValues) => {
              return dataValues?.type_details?.map((dataV) => {
                return DD.push({ name: dataV?.name, id: dataV?.id });
              });
            });
            setProductLines(DD);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [ShipperID]);

  const [proLine, setProLine] = useState(0);
  const [proClass, setProClass] = useState("");
  const [proPCM, setProPCM] = useState("");
  const [proTIC, setProTIC] = useState("");

  const [filter, setFilter] = useState(false);

  const [filterList, setFilterList] = useState([]);

  const handleProLine = (e) => {
    setProLine(e.target.value);
  };

  const handleClassType = (e) => {
    setProClass(e.target.value);
  };

  const handlePCM = (e) => {
    setProPCM(e.target.value);
  };

  const handleTIC = (e) => {
    setProTIC(e.target.value);
  };

  useEffect(() => {
    if (parseInt(proLine)) {
      var raw = JSON.stringify({
        productline_id: parseInt(proLine),
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(
        ConstantsList.API_URL + "shipdict/shipper_class_productline/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              let DD = [];
              data.data?.shippers?.map((classType) => {
                return DD.push({
                  id: classType?.id,
                  name: classType?.class_type,
                });
              });
              setClassType(DD);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [proLine]);

  useEffect(() => {
    if (proClass !== "" && ShipperID !== "") {
      var raw = JSON.stringify({
        shipperdict_id: ShipperID,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "pcm/getphase-pcm/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              let DD = [];
              data.data.map((testData) =>
                testData?.phase_details?.map((details) =>
                  DD.push({ name: details?.pcm_name, id: details?.id })
                )
              );
              setPCMType(DD);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [proClass, ShipperID]);

  useEffect(() => {
    if (parseInt(proClass)) {
      var raw = JSON.stringify({
        shipperclass_id: parseInt(proClass),
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(
        ConstantsList.API_URL + "shipdict/tic_configuration_shipperclass/",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              let DD = [];
              data?.data?.tic_configuration.map((tic) =>
                DD.push({ id: tic?.id, name: tic?.tic_type })
              );
              setTICType(DD);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [proClass]);

  const [createDate, setCreateDate] = useState(null);
  const [lastEdited, setLastEdited] = useState(null);

  const [shipperFilterList, setShipperFilterList] = useState([]);
  const [shipperFilter, setShipperFilter] = useState(false);

  const [ShipperListData, setShipperListData] = useState([]);

  const FromDate = createDate
    ? moment(createDate?.toDate?.()).format("MMM DD, YYYY")
    : "";
  const ToDate = lastEdited
    ? moment(lastEdited?.toDate?.()).format("MMM DD, YYYY")
    : "";
  const [value, setValue] = React.useState("create");

  useEffect(() => {
    if (shipperList) setShipperListData(shipperList?.data?.Shipper_data);
  }, [shipperList]);

  useEffect(() => {
    if (shipperFilter) {
      const DateValid = FromDate?.length > 0 && ToDate?.length > 0;
      const ProLineValid = proLine !== 0 ? true : false;
      const CheckTest =
        (DateValid || ProLineValid) && filter
          ? shipperFilterList
          : shipperList?.data?.Shipper_data ?? [];

      setShipperListData(CheckTest);
      setShipperFilter(false);
    }
  }, [
    filter,
    FromDate,
    ToDate,
    shipperFilterList,
    shipperList?.data?.Shipper_data,
    shipperFilter,
    proLine,
  ]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });
  const [filterAction, setFilterAction] = useState(false);

  const isApply =
    (createDate && lastEdited) ||
    (proLine && proLine !== 0) ||
    (proClass && proClass.length > 0) ||
    (proPCM && proPCM.length > 0) ||
    (proTIC && proTIC.length > 0)
      ? true
      : false;

  const handleClearFilter = (e, index) => {
    let listFilter = [...filterList];
    switch (listFilter[index]?.id) {
      case 1:
        setCreateDate(null);
        setLastEdited(null);
        break;
      case 2:
        setProLine(0);
        setProClass(null);
        setProClass("");
        setProPCM("");
        setProTIC("");
        break;
      case 3:
        setProClass("");
        setProPCM("");
        setProTIC("");
        break;
      case 4:
        setProPCM("");
        setProTIC("");
        break;
      case 5:
        setProTIC("");
        break;
      default:
        break;
    }
    listFilter.splice(index, 1);
    if (listFilter.length === 0) {
      setFilter(false);
      setShipperFilter(true);
    }
    setFilterList(listFilter);
    setFilterAction(true);
  };

  useEffect(() => {
    if (filterAction) {
      var raw = JSON.stringify({
        shipperdict_id: ShipperID,
        create_from_date: value === "create" ? FromDate : "",
        create_to_date: value === "create" ? ToDate : "",
        updated_from_date: value === "last" ? FromDate : "",
        updated_to_date: value === "last" ? ToDate : "",
        productline: proLine !== 0 ? [proLine] : [""],
        pcm: [proPCM],
        configurations: [proTIC],
        shipperclass: [proClass],
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "shipdict/shipper-filter/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setShipperFilterList(data?.data?.data);
              setShipperFilter(true);
              setFilter(true);
              setSettingOpen(false);
            }
            setFilterAction(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [filterAction]);

  const handleApply = () => {
    setFilterAction(true);
  };

  useEffect(() => {
    if (filter && shipperFilter) {
      let dateLabel = "Creation Date:";
      if (value === "create") {
        dateLabel = "Creation Date:";
      }
      if (value === "last") {
        dateLabel = "Last Edited:";
      }
      let DataV = [];
      if (createDate && lastEdited) {
        DataV.push({
          id: 1,
          label: dateLabel,
          filter: `${moment(createDate?.toDate?.()).format(
            "MMM DD, YYYY"
          )} - ${moment(lastEdited?.toDate?.()).format("MMM DD, YYYY")}`,
        });
      }
      if (shipperList) {
        if (proLine !== 0 && proLine) {
          DataV.push({
            id: 2,
            label: "Product Line",
            filter: shipperList?.data?.Shipper_data?.find(
              (data) => data?.productline_id === proLine
            )?.productline,
          });
        }

        if (proClass !== 0 && proClass) {
          DataV.push({
            id: 3,
            label: "Shipper Class",
            filter: shipperList?.data?.Shipper_data?.find(
              (data) => data?.shipperclass_id === proClass
            )?.shipperclass,
          });
        }

        if (proPCM !== 0 && proPCM) {
          DataV.push({
            id: 4,
            label: "PCM Type",
            filter: shipperList?.data?.Shipper_data?.find(
              (data) => data?.pcmtype_id === proPCM
            )?.pcm,
          });
        }

        if (proTIC !== 0 && proTIC) {
          DataV.push({
            id: 5,
            label: "Configuration",
            filter: shipperList?.data?.Shipper_data?.find(
              (data) => data?.ticconfig_id === proTIC
            )?.ticconfig,
          });
        }
      }
      setFilterList(DataV);
      setShipperFilter(false);
    }
  }, [filter, shipperFilter]);

  const list = (anchor) => (
    <Box sx={{ width: 339, height: "100vh" }} role="presentation">
      <Stack
        width={1}
        height={1}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Stack
          width={1}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="end"
          >
            <IconButton
              aria-label="delete"
              onClick={handleSettingClose}
              sx={{ padding: 0 }}
            >
              <CloseIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Stack>
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              width={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#19191A",
                  lineHeight: "38px",
                }}
              >
                Filters
              </Typography>
            </Stack>
          </Stack>
          <ThemeProvider theme={checkboxTheme}>
            <Stack width={1} direction="column" pt={7} spacing={5}>
              <Stack width={1} spacing={1} p={0} direction="row">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="create"
                      control={<Radio />}
                      label="Creation"
                    />
                    <FormControlLabel
                      value="last"
                      control={<Radio />}
                      label="Last Edited"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>

              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  From Date
                </Typography>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{
                    height: "44px",
                    borderRadius: "6px",
                    border: "1px solid #E6E7E8",
                  }}
                >
                  <DatePicker
                    label="Select Dates"
                    sx={{
                      "& .css-1nhy3yb-MuiInputBase-root-MuiOutlinedInput-root":
                        { height: "44px", width: "291px" },
                    }}
                    value={createDate}
                    onChange={(newValue) => {
                      handleFromDate(newValue);
                    }}
                    disableFuture
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  To Date
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{
                    height: "44px",
                    borderRadius: "6px",
                    border: "1px solid #E6E7E8",
                  }}
                >
                  <DatePicker
                    label="Select Dates"
                    sx={{
                      "& .css-1nhy3yb-MuiInputBase-root-MuiOutlinedInput-root":
                        { height: "44px", width: "291px" },
                    }}
                    value={lastEdited}
                    // onChange={(newValue) => {
                    //   setLastEdited(newValue);
                    // }}
                    minDate={createDate}
                    onChange={(newValue) => {
                      handleToDate(newValue);
                    }}
                    disableFuture
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Product Line
                </Typography>
                <FormControl fullWidth>
                  <Select
                    sx={{ height: "44px", width: "291px" }}
                    defaultValue={0}
                    id="product-line-select"
                    value={proLine}
                    onChange={handleProLine}
                  >
                    <MenuItem value="" disabled>
                      <em>Select ProductLine</em>
                    </MenuItem>
                    <MenuItem value={0}>All</MenuItem>
                    {productLines?.map((productline) => {
                      return (
                        <MenuItem key={productline?.id} value={productline?.id}>
                          {productline?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Class
                </Typography>
                <FormControl fullWidth disabled={proLine !== 0 ? false : true}>
                  <Select
                    defaultValue={0}
                    sx={{ height: "44px", width: "291px" }}
                    id="Class-Type-Select"
                    value={proClass}
                    onChange={handleClassType}
                  >
                    <MenuItem value={0} disabled>
                      <em>Select Class Type</em>
                    </MenuItem>
                    {classType?.map((classData) => {
                      return (
                        <MenuItem key={classData?.id} value={classData?.id}>
                          {classData?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  PCM Type
                </Typography>
                <FormControl
                  fullWidth
                  disabled={proClass !== 0 && proClass ? false : true}
                >
                  <Select
                    defaultValue=""
                    id="PCM-Select"
                    value={proPCM}
                    onChange={handlePCM}
                    sx={{ height: "44px", width: "291px" }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select PCM</em>
                    </MenuItem>
                    {PCMType?.map((pcmDetails) => {
                      return (
                        <MenuItem key={pcmDetails?.id} value={pcmDetails?.id}>
                          {pcmDetails?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack width={1} spacing={1} p={0} direction="column">
                <Typography component="h6" variant="h6" sx={labelStyle}>
                  Configuration
                </Typography>
                <FormControl
                  fullWidth
                  disabled={proPCM !== 0 && proPCM ? false : true}
                >
                  <Select
                    defaultValue=""
                    id="TIC-select"
                    value={proTIC}
                    onChange={handleTIC}
                    sx={{ height: "44px", width: "291px" }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select TIC Type</em>
                    </MenuItem>
                    {ticType?.map((tictype) => {
                      return (
                        <MenuItem key={tictype?.id} value={tictype?.id}>
                          {tictype?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </ThemeProvider>
        </Stack>
        <Stack width={1}>
          <Button
            disabled={!isApply}
            variant="contained"
            data-testid="saveButton"
            fullWidth
            sx={{
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 400,
              height: "44px",
              background: "#F26322",
              color: "#000000",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#F2632290",
              },
            }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  const handleFromDate = (date) => {
    setCreateDate(date);
    setLastEdited(date);
  };

  const handleToDate = (date) => {
    setLastEdited(date);
  };

  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            p: 3,
          }}
        >
          List of Shippers
        </Typography>
        <Box sx={{}}>
          {loginDetails.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#000000",
                  color: "#000000",
                  p: 3,
                }}
              >
                <Button
                  onClick={handleSettingOpen}
                  data-testid="filterbutton"
                  startIcon={<Filter style={{ transform: "rotate(90deg)" }} />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                >
                  Filter
                </Button>
                <Button
                  data-testid="duplicatebutton"
                  disabled={count}
                  startIcon={<Duplicate />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                >
                  Duplicate
                </Button>
                <Button
                  onClick={editNew}
                  data-testid="editbutton"
                  disabled={count}
                  startIcon={<EditIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                >
                  Edit
                </Button>
                <Button
                  onClick={handleCddelOpen}
                  data-testid="deletebutton"
                  disabled={count}
                  startIcon={<DeleteIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                >
                  Delete
                </Button>
                <Button
                  onClick={createNew}
                  data-testid="addbutton"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                >
                  Create New
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>

      {filter && filterList.length > 0 && (
        <Stack
          width={1}
          direction="row"
          alignItems="center"
          justifyContent="end"
          height="32px"
          spacing={1.5}
          sx={{ px: 3, mb: 3 }}
        >
          <Typography
            component="h5"
            variant="h5"
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              color: "#3F4042",
            }}
          >
            Active filters:
          </Typography>

          {filterList.map((dataElement, index) => {
            return (
              <Stack
                key={dataElement?.id}
                direction="row"
                alignItems="center"
                justifyContent="center"
                height="32px"
                sx={{
                  border: "1px solid #CBCCCD",
                  borderRadius: "6px",
                  padding: "0px 12px",
                }}
                spacing={1}
              >
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#3F4042",
                  }}
                >
                  {dataElement?.label}
                </Typography>
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#000000",
                  }}
                >
                  {dataElement?.filter}
                </Typography>
                <IconButton
                  aria-label="delete"
                  onClick={(e) => handleClearFilter(e, index)}
                >
                  <ClearIcon sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      )}

      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
      {ShipperListData && (
        <ListView
          filter={filter}
          data={ShipperListData}
          dataCount={setCount}
          dataShipperId={setShipperId}
          dataProductLine={setProductLine}
          dataShipperClass={setShipperClass}
          dataPcmType={setPcmType}
          dataConfiguration={setConfiguration}
          dataCreationDate={setCreationDate}
          dataLastEditedDate={setLastEditedDate}
        />
      )}
      {cdopen && (
        <CreateNew
          open={cdopen}
          onClose={handleCdClose}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setSeverity={setSeverity}
          setCount={setCount}
        />
      )}
      {cddelopen && (
        <DeleteRecord
          open={cddelopen}
          onClose={handleCddelClose}
          shipperId={shipperId}
          productLine={productLine}
          shipperClass={shipperClass}
          pcmType={pcmType}
          configuration={configuration}
          creationDate={creationDate}
          setReload={setReload}
          setCount={setCount}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setSeverity={setSeverity}
        />
      )}
      {notify && (
        <SnackBar
          open={notify}
          severity={severity ? "success" : "error"}
          setOpen={setNotify}
          message={notifyMsg}
        />
      )}

      <SwipeableDrawer
        anchor="right"
        open={settingOpen}
        onClose={handleSettingClose}
        onOpen={handleSettingOpen}
      >
        {list("right")}
      </SwipeableDrawer>
    </Box>
  );
};

export default Shipper;
