import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography, Button, ButtonGroup } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import UsersList from "./UsersList";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../core/Loader/Loader";
import CreateUsers from "../UserDialog/CreateUsers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DeleteUsers from "../UserDialog/DeleteUsers";
import EditUsers from "../UserDialog/EditUsers";
import SearchUsers from "../UserDialog/SearchUsers";
import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../../src/constants/";
import * as ADAConfig from "../../../src/config/ADAConfig";

import { useSelector } from "react-redux";
import { loginData } from "../../components/Login/LoginSlice";

const Users = () => {
  const loginDetails = useSelector(loginData);
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userlist, setUserList] = useState(null);
  // Search Users
  const [searchuserData, setSearchUserData] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const [cdeditopen, setCdeditopen] = React.useState(false);
  const isReload = cddelopen || cdopen;
  const [reload, setReload] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "trufit/lstusrs")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          let usersRemoveCurrent = [];
          data?.user_info?.map((element, index) => {
            if (element.email !== loginDetails.email) {
              usersRemoveCurrent.push(element);
            }
          });
          setUserList(usersRemoveCurrent);
          setLoading(false);
          setReload(false);
        }
      });
  }, [isReload, cdeditopen, reload]);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [urole, setUrole] = useState("");
  const [notifyMsg, setNotifyMsg] = useState("");

  const handleCdOpen = () => {
    setCdopen(true);
  };

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const handleCdeditOpen = () => {
    setCdeditopen(true);
  };

  const handleCdeditClose = (value) => {
    setCdeditopen(false);
  };

  const [notify, setNotify] = useState(false);

  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            pt: 3,
            pl: 3,
            pr: 3,
          }}
        >
          Users
        </Typography>
        <Box sx={{ pb: 0 }}>
          {loginDetails.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#000000",
                  color: "#000000",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                }}
              >
                <Button
                  onClick={handleCdeditOpen}
                  data-testid="editbutton"
                  disabled={count}
                  startIcon={<EditIcon />}
                  style={{ textTransform: "none", border: "1px solid #B1B2B4" }}
                  aria-label={ADAConfig.ADA.users.edit_btn}
                >
                  Edit
                </Button>
                <Button
                  onClick={handleCddelOpen}
                  data-testid="deletebutton"
                  disabled={count}
                  startIcon={<DeleteIcon />}
                  style={{ textTransform: "none", border: "1px solid #B1B2B4" }}
                  aria-label={ADAConfig.ADA.users.delete_btn}
                >
                  Delete
                </Button>
                <Button
                  onClick={handleCdOpen}
                  data-testid="addbutton"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none", border: "1px solid #B1B2B4" }}
                  aria-label={ADAConfig.ADA.users.new_user}
                >
                  New User
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "auto",
          justifyContent: "space-between",
        }}
      >
        {userlist && <SearchUsers data={userlist} search={setSearchUserData} />}
      </Box>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      {userlist && (
        <UsersList
          setSelectValue={setSelectValue}
          data={userlist}
          setCount={setCount}
          setUserName={setUname}
          setEmail={setUemail}
          setRole={setUrole}
          search={searchuserData !== null ? searchuserData : false}
        />
      )}
      {cdopen && (
        <CreateUsers
          open={cdopen}
          onClose={handleCdClose}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setCount={setCount}
        />
      )}
      {cddelopen && selectValue && (
        <DeleteUsers
          selectValue={selectValue}
          open={cddelopen}
          onClose={handleCddelClose}
          setCount={setCount}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {cdeditopen && selectValue && (
        <EditUsers
          setSelectValue={setSelectValue}
          selectValue={selectValue}
          setReload={setReload}
          open={cdeditopen}
          onClose={handleCdeditClose}
          setCount={setCount}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default Users;
