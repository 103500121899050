import { Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";

const PayloadEfficiency = (props) => {
  const { data } = props;
  const [labelsShipments, setLabelsShipments] = useState([]);
  const [chartShipmentsData, setChartShipmentsData] = useState([]);

  useEffect(() => {
    if (data?.payload_summary) {
      let labels = [];
      data?.payload_summary.map((data) => {
        return labels.push(data?.payload_name);
      });
      setLabelsShipments(labels);

      let datas = [];
      data?.payload_summary.map((data) => {
        return datas.push((parseFloat(data?.fill_efficiency) * 100).toFixed(2));
      });
      setChartShipmentsData(datas);
    }
  }, [data]);

  const barData = {
    labels: labelsShipments,
    datasets: [
      {
        data: chartShipmentsData,
        backgroundColor: [
          "rgba(245, 66, 209)",
          "rgba(224, 16, 40)",
          "rgba(242, 227, 10)",
          "rgba(14, 227, 31)",
          "rgba(27, 94, 209)",
        ],
        hoverOffset: 4,
        barPercentage: 0.5,
        barThickness: 16,
        maxBarThickness: 8,
      },
    ],
  };

  const barConfig = {
    type: "bar",
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: 100,
      },
    },
  };
  return (
    <Stack direction="column" width={1} spacing={2}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "48px", backgroundColor: "#CCCCCC" }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "20px",
            fontWeight: "500",
            lineHeight: "22px",
            color: "#000000",
          }}
        >
          Fill Efficiency by Payload
        </Typography>
      </Stack>
      <Stack alignItems="center" justifyItems="center" height="250px">
        <Bar options={barConfig} data={barData} />
      </Stack>
    </Stack>
  );
};

export default PayloadEfficiency;
