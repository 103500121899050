import {
  Box,
  Button,
  createTheme,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TextField,
  ThemeProvider,
  Tooltip,
  tooltipClasses,
  Typography,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SnackBar from "../../core/SnackBar/SnackBar";

import * as ConstantsList from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import {
  ticTypeUpdate,
  ticConfigurationUpdate,
  shipperData,
  updateRelease,
} from "./EditShipperSlice";
import * as Config from "../../../config";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const Configuration = (props) => {
  const dispatch = useDispatch();
  const shipperDetails = useSelector(shipperData);
  const tooltips = Config?.CONFIGLIST?.shipperDetails?.tooltip;
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const ShipID = shipperDetails && shipperDetails.shipperclass.id;
  const isEnable = ShipID?.length > 0 ? false : true;

  const isTextEnabled =
    shipperDetails?.ticconfig?.tic_type === "----" ? true : false;

  const labelStyle = {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: "20px",
    lineHeight: "22px",
    color: "#000000",
  };

  const handleCreateTic = () => {
    setOpen(true);
  };

  const [innerLength, setInnerLength] = useState({
    name: shipperDetails?.ticconfig?.configurations?.length,
    error: false,
    message: "",
  });
  const [innerWidth, setInnerWidth] = useState({
    name: shipperDetails?.ticconfig?.configurations?.width,
    error: false,
    message: "",
  });
  const [innerHeight, setInnerHeight] = useState({
    name: shipperDetails?.ticconfig?.configurations?.height,
    error: false,
    message: "",
  });

  const [wetWeight, setWetWeight] = useState({
    name: shipperDetails?.ticconfig?.configurations?.weight,
    error: false,
    message: "",
  });
  const [wetCost, setWetCost] = useState({
    name: shipperDetails?.ticconfig?.configurations?.cost,
    error: false,
    message: "",
  });
  const [wetTopPCM, setWetTopPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.top_pcm,
    error: false,
    message: "",
  });
  const [wetSidePCM, setWetSidePCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.side_pcm,
    error: false,
    message: "",
  });
  const [wetEndPCM, setWetEndPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.end_pcm,
    error: false,
    message: "",
  });
  const [wetTotalPCM, setWetTotalPCM] = useState({
    name: shipperDetails?.ticconfig?.configurations?.total_pcm,
    error: false,
    message: "",
  });

  const [envCo2, setEnvCo2] = useState({
    name: shipperDetails?.ticconfig?.configurations?.emissions,
    error: false,
    message: "",
  });
  const [envLandfill, setEnvLandfill] = useState({
    name: shipperDetails?.ticconfig?.configurations?.landfill,
    error: false,
    message: "",
  });

  const [multiTopWall, setMultiTopWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.top_wall,
    error: false,
    message: "",
  });
  const [multiSideWall, setMultiSideWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.side_wall,
    error: false,
    message: "",
  });
  const [multiEndWall, setMultiEndWall] = useState({
    name: shipperDetails?.ticconfig?.configurations?.end_wall,
    error: false,
    message: "",
  });

  const co2Handle = (e) => {
    setEnvCo2({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const landfillHandle = (e) => {
    setEnvLandfill({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiTopHandle = (e) => {
    setMultiTopWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiSideHandle = (e) => {
    setMultiSideWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const multiEndHandle = (e) => {
    setMultiEndWall({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const innerLengthHandle = (e) => {
    setInnerLength({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const innerWidthHandle = (e) => {
    setInnerWidth({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      err: false,
      message: "",
    });
  };

  const innerHeightHandle = (e) => {
    setInnerHeight({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetWeightHandle = (e) => {
    setWetWeight({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetCostHandle = (e) => {
    setWetCost({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetTopPCMHandle = (e) => {
    setWetTopPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetSidePCMHandle = (e) => {
    setWetSidePCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      err: false,
      message: "",
    });
  };

  const wetEndPCMHandle = (e) => {
    setWetEndPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const wetTotalPCMHandle = (e) => {
    setWetTotalPCM({
      name: !isNaN(parseFloat(e.target.value))
        ? parseFloat(e.target.value)
        : "",
      error: false,
      message: "",
    });
  };

  const [selectedType, setSelectedType] = useState("Select");

  const handleSelect = (e) => {
    if (e.target.value !== "Select") {
      dispatch(ticTypeUpdate(e.target.value.toString()));
      setSelectedType(e.target.value.toString());
    } else {
      setSelectedType("Select");
      dispatch(ticTypeUpdate("----"));
    }
  };

  const [ticConfigData, setTicConfigData] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        shipperclass_id: ShipID,
      }),
    };
    fetch(
      ConstantsList.API_URL + "shipdict/tic_configuration_shipperclass/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTicConfigData(data.data);
          setReload(false);
        }
      });
    setSelectedType(shipperDetails?.ticconfig?.tic_type);
  }, [ShipID, reload]);

  const [check, setCheck] = useState(shipperDetails?.released ?? false);

  useEffect(() => {
    const TicConfig = {
      weight: wetWeight?.name?.toString(),
      emissions: envCo2?.name?.toString(),
      landfill: envLandfill?.name?.toString(),
      cost: wetCost?.name?.toString(),
      top_pcm: wetTopPCM?.name?.toString(),
      side_pcm: wetSidePCM?.name?.toString(),
      end_pcm: wetEndPCM?.name?.toString(),
      total_pcm: wetTotalPCM?.name?.toString(),
      length: innerLength?.name?.toString(),
      width: innerWidth?.name?.toString(),
      height: innerHeight?.name?.toString(),
      top_wall: multiTopWall?.name?.toString(),
      side_wall: multiSideWall?.name?.toString(),
      end_wall: multiEndWall?.name?.toString(),
    };
    dispatch(ticConfigurationUpdate(TicConfig));
    dispatch(updateRelease(check));
  }, [
    check,
    wetWeight,
    envCo2,
    envLandfill,
    wetCost,
    wetTopPCM,
    wetSidePCM,
    wetEndPCM,
    wetTotalPCM,
    innerLength,
    innerWidth,
    innerHeight,
    multiTopWall,
    multiSideWall,
    multiEndWall,
    dispatch,
  ]);
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const releasedHandle = () => {
    setCheck(!check);
  };

  return (
    <Box
      p={0}
      maxWidth="false"
      sx={{ width: "100%", height: "auto" }}
      data-testid="box-outer-container"
    >
      <Box
        px={3}
        pt={3}
        maxWidth="false"
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Stack
          spacing={1.5}
          direction="row"
          sx={{ width: "100%", alignItems: "center" }}
        >
          <Typography component="h6" variant="h6" sx={labelStyle}>
            Configuration Name
          </Typography>
          <FormControl
            size="small"
            sx={{
              width: "200px",
              height: "54px",
              padding: "8px 12px 8px 12px",
              border: "none",
            }}
          >
            <InputLabel
              labelId="demo-select-small"
              id="demo-select-small"
              value={selectedType}
              sx={{
                background: "#F2F2F3",
                width: "200px",
                height: "44px",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {selectedType}
            </InputLabel>
          </FormControl>
        </Stack>

        <Stack direction="row">
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  name="shipperReleaded"
                  onChange={releasedHandle}
                  checked={check}
                />
              }
              label="Released"
            />
          </Stack>
        </Stack>
      </Box>
      <ThemeProvider theme={customTheme}>
        <Stack
          pt={5}
          direction="row"
          spacing={7}
          px={3}
          pb={5}
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
          }}
        >
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Inner Dimensions
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Length
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.length}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={innerLengthHandle}
                        onPaste={innerLengthHandle}
                        value={innerLength.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerLength.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerLength.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Width
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.width}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={innerWidthHandle}
                        onPaste={innerWidthHandle}
                        value={innerWidth.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerWidth.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerWidth.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Height
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.height}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={innerHeightHandle}
                        onPaste={innerHeightHandle}
                        value={innerHeight.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {innerHeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {innerHeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Wet Goods
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Weight
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.weigtht}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetWeightHandle}
                        onPaste={wetWeightHandle}
                        value={wetWeight.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetWeight.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetWeight.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Cost
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.cost}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetCostHandle}
                        onPaste={wetCostHandle}
                        value={wetCost.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            $
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetCost.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetCost.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.topPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetTopPCMHandle}
                        onPaste={wetTopPCMHandle}
                        value={wetTopPCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetTopPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetTopPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sidePCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetSidePCMHandle}
                        onPaste={wetSidePCMHandle}
                        value={wetSidePCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetSidePCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetSidePCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End PCM
                      </Typography>
                      <BootstrapTooltip title={tooltips?.configuration?.endPCM}>
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetEndPCMHandle}
                        onPaste={wetEndPCMHandle}
                        value={wetEndPCM.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {wetEndPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetEndPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Total PCM
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.totalPCM}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      //focused={true}
                      size="small"
                      sx={{
                        width: "184px",
                        height: "44px",
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={wetTotalPCMHandle}
                        onPaste={wetTotalPCMHandle}
                        value={wetTotalPCM.name}
                        id="totalPCM"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            g
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "Total PCM",
                        }}
                      />

                      {wetTotalPCM.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {wetTotalPCM.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem p={0} m={0} />
          <Stack width={1 / 2} direction="column" spacing={5}>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Environmental Impacts
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        CO2 Emissions
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.co2Emissions}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={co2Handle}
                        onPaste={co2Handle}
                        value={envCo2.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {envCo2.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {envCo2.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Landfill
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.landfill}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={landfillHandle}
                        onPaste={landfillHandle}
                        value={envLandfill.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {envLandfill.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {envLandfill.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ width: "100%" }}>
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    lineHeight: "22px",
                    color: "#000000",
                    fontWeight: "500",
                  }}
                >
                  Multiwall Detail Definition
                </Typography>
                <Stack spacing={2} direction="row" sx={{ width: "auto" }}>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Top Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.topWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={multiTopHandle}
                        onPaste={multiTopHandle}
                        value={multiTopWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiTopWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiTopWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        Side Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.sideWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={multiSideHandle}
                        onPaste={multiSideHandle}
                        value={multiSideWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            kg
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiSideWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiSideWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        component="h6"
                        variant="h6"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#3F4042",
                          fontWeight: "400",
                        }}
                      >
                        End Wall
                      </Typography>
                      <BootstrapTooltip
                        title={tooltips?.configuration?.endWall}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#7D7F82",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </BootstrapTooltip>
                    </Stack>

                    <FormControl
                      size="small"
                      sx={{ width: "184px", height: "44px" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        type="number"
                        disabled={isTextEnabled}
                        onChange={multiEndHandle}
                        onPaste={multiEndHandle}
                        value={multiEndWall.name}
                        id="outlined-adornment-weight"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              padding: "6px, 12px, 6px, 12px",
                              height: "28px",
                              width: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              backgroundColor: "#F2F2F3",
                            }}
                          >
                            mm
                          </InputAdornment>
                        }
                        onKeyPress={(event) => {
                          if (event?.key === "-" || event?.key === "+") {
                            event.preventDefault();
                          }
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                      {multiEndWall.error && (
                        <FormHelperText sx={{ m: 0 }}>
                          {multiEndWall.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ThemeProvider>

      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default Configuration;
