import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import * as Config from "../../../src/config/";
import * as ConstantsList from "../../../src/constants/";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
  Dialog,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { oppotunityListViewData } from "../../components/Opportunities/OppotunityListViewSlice";
import { custData } from "../../components/Customer/CustomerSlice";
import { useHistory } from "react-router-dom";
import { scenarioControl, scenarioData, scenarioReset } from "./ScenarioSlice";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

export default function CreateNew(props) {
  const dispatch = useDispatch();
  const scenarioDetails = useSelector(scenarioData);
  const oppotunityListView = useSelector(oppotunityListViewData);
  const custDataList = useSelector(custData);

  const {
    onClose,
    open,
    setNotify,
    setNotifyMsg,
    setCount,
    setReload,
    setSeverity,
    selectedScenarioList,
    setSelectedScenarioList,
  } = props;
  let history = useHistory();
  const [scenarioName, setScenarioName] = useState(null);
  const [save, setSave] = useState(true);
  const [errorMsg, setErrorMsg] = useState(false);
  const [validate, setValidate] = useState(false);
  const [data, setData] = useState();
  const [dictionaryList, setDictionaryList] = useState([]);
  const [scenarioNameError, setScenarioNameError] = useState(false);
  const [scenarioNameErrorMsg, setScenarioNameErrorMsg] = useState("");

  const [dictionaryNameError, setDictionaryNameError] = useState(false);
  const [dictionaryNameErrorMsg, setDictionaryNameErrorMsg] = useState("");

  const [selectedDictionary, setSelectedDictionary] = useState("Select");
  const [selectedDictionaryId, setSelectedDictionaryId] = useState("");

  const changeScenarioName = (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9 \.]/g, "");
    const re = /^[A-Za-z0-9 ]+$/;
    if (value === "" || re.test(value)) {
      setScenarioName(value);
    }

    if (value.length > 0 && value.trim().length > 0) {
      setSave(false);
      setScenarioNameError(false);
      setScenarioNameErrorMsg("");
    }
    if (selectedDictionary !== "Select") {
      setSave(false);
      setScenarioNameError(false);
      setScenarioNameErrorMsg("");
    }
    if (selectedDictionary === "Select" || value.trim().length === 0) {
      setSave(true);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleNextButton = () => {
    if (scenarioName.length === 0) {
      setScenarioNameError(true);
      setScenarioNameErrorMsg("Please Enter Scenario Name!");
    } else if (selectedDictionary === "Select") {
      setDictionaryNameError(true);
      setScenarioNameErrorMsg("Please Select Dictionary!");
    } else {
      setScenarioNameError(false);
      setScenarioNameErrorMsg("");

      setDictionaryNameError(false);
      setScenarioNameErrorMsg("");

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          shipperdict_id: selectedDictionaryId,
          scenario_name: scenarioName,
          customer_id: custDataList?.id,
          opportunity_id: oppotunityListView?.id,
        }),
      };
      fetch(ConstantsList.API_URL + "scenario/scenario/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setData(data);
            if (data?.status_code === 200) {
              //history.push("/dashboard/opportunity/scenario/");

              setNotify(true);
              setNotifyMsg(data?.data?.message.toString());
              onClose(false);
              //
              //setErrorMsg(false);
              //setReload(true);
              setSeverity(false);
              //setScenarioNameError(false);
              //setScenarioNameErrorMsg("");

              let payload = {
                ...scenarioDetails,
                scenario: { id: data?.data?.id, name: scenarioName },
                opportunity: {
                  id: oppotunityListView?.id,
                  name: oppotunityListView?.name,
                },
                dictionary: {
                  id: selectedDictionaryId,
                  name: selectedDictionary,
                },
                customer: { id: custDataList?.id, name: custDataList?.name },
                type: "New",

                tab: "0",
                status: "Optimization Pending",
                productline: [],
                probelocation: [],
                temperature: [],
                payload: [],
                preference: {},
              };
              dispatch(scenarioControl(payload));
              setTimeout(() => {
                history.push("/dashboard/opportunity/scenario/");
              }, 2000);
              //dispatch(scenarioControl(payload));
            } else if (data.status_code === 400) {
              setErrorMsg(true);
              setSeverity(true);
              setScenarioNameError(true);
              setScenarioNameErrorMsg(data.data.toString());
            } else {
              setData(data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    //resetValue();
  }, [scenarioName, selectedDictionary, selectedDictionaryId]);

  const handleChangeDictionary = (e, dictionaryList) => {
    setSelectedDictionaryId("");
    setSelectedDictionary("Select");
    setSave(true);
    dictionaryList &&
      dictionaryList.map((row, i) => {
        if (e.target.value === row.name) {
          setSelectedDictionaryId(row.id);
          setSelectedDictionary(row.name);
          if (scenarioName.trim() !== "") {
            setSave(false);
            setScenarioNameError(false);
            setScenarioNameErrorMsg("");
          }
        }
      });
  };

  useEffect(() => {
    fetch(ConstantsList.API_URL + "shipdict/shipper_dict/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code.toString() === "200") {
          setDictionaryList(data.data);
        }
      });
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-btn"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                {Config.CONFIGLIST.scenario.createNew.title}
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                {Config.CONFIGLIST.scenario.createNew.requiredInfo}
              </Typography>
              <ThemeProvider theme={checkboxTheme}>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    {Config.CONFIGLIST.scenario.createNew.field.field1.label} *
                  </Typography>
                  <TextField
                    fullWidth
                    error={scenarioNameError}
                    helperText={scenarioNameErrorMsg}
                    onChange={changeScenarioName}
                    value={scenarioName}
                    placeholder={
                      Config.CONFIGLIST.scenario.createNew.field.field1
                        .placeHoler
                    }
                    id="scenarioName"
                    inputProps={{
                      maxLength:
                        Config.CONFIGLIST.scenario.createNew.field.field1
                          .maxLenght,
                    }}
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>

                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    {Config.CONFIGLIST.scenario.createNew.field.field2.label} *
                  </Typography>
                  <Select
                    //onChange={handleChangeDictionary}
                    onChange={(e) => handleChangeDictionary(e, dictionaryList)}
                    labelId="dictionarySelection"
                    id="dictionarySelection"
                    defaultValue="Select"
                    value={selectedDictionary}
                    name={"dictionarySelection"}
                    error={dictionaryNameError}
                    helperText={dictionaryNameErrorMsg}
                  >
                    <MenuItem key={"-1"} value="Select">
                      <em style={{ fontStyle: "normal" }}>Select</em>
                    </MenuItem>
                    {dictionaryList &&
                      dictionaryList.map((row, i) => {
                        return (
                          <MenuItem key={row.id} value={row.name}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Stack>
              </ThemeProvider>

              <Button
                disabled={save}
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                onClick={handleNextButton}
                data-testid="next-btn"
              >
                Next
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
}
