import React, { useState } from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as ConstantsList from "../../../src/constants";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const EditOpportunity = (props) => {
  const {
    open,
    setOpen,
    setNotify,
    setNotifyMsg,
    customer,
    setReload,
    selectedItem,
    setSelectedItem,
  } = props;
  const [savebutton, setSavebutton] = useState(false);
  const [oppName, setOppName] = useState(
    selectedItem ? selectedItem?.name : ""
  );
  const [oppDescription, setOppDescription] = useState(
    selectedItem ? selectedItem?.description : ""
  );
  const [oppscenarioName, setOppscenarioName] = useState(
    selectedItem ? selectedItem?.scenarioName : ""
  );

  const [oppNameErr, setOppNameErr] = useState(false);
  const [oppDesErr, setOppDesErr] = useState(false);

  const [oppNameErrMsg, setOppNameErrMsg] = useState("");
  const [oppDesErrMsg, setOppDesErrMsg] = useState("");

  const changeOppName = (event, eventType) => {
    const value =
      eventType === "change" ? event.target.value : event.target.value.trim();
    const re = /^[A-Za-z0-9 ]+$/;
    if (value === "" || re.test(value)) {
      setOppName(value);
    }

    if (oppName.length > 0) {
      setOppNameErr(false);
      setOppNameErrMsg("");
      setSavebutton(false);
    } else {
      setSavebutton(true);
    }
  };

  const changeOppDescription = (e) => {
    setOppDescription(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (oppName.length !== 0) {
      setOppNameErrMsg("");
      setOppNameErr(false);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer_id: customer.id,
          opportunity_name: oppName,
          description: oppDescription,
          scenario_name: oppscenarioName,
        }),
      };
      fetch(
        ConstantsList.API_URL + "opp/opportunity/" + selectedItem.id,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setNotify(true);
            setNotifyMsg(data.data);
            setSelectedItem({
              id: selectedItem.id,
              name: oppName,
              description: oppDescription,
              scenarioName: oppscenarioName,
            });
            setOpen(false);
            setReload(true);
          } else {
            setOppNameErr(true);
            setOppNameErrMsg(data.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setOppNameErr(true);
      setOppNameErrMsg("Please Enter Opportunity Name.");
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSave();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Edit Opportunity
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                [ * ] Required fields
              </Typography>
              <ThemeProvider theme={checkboxTheme}>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Opportunity Name *
                  </Typography>
                  <TextField
                    fullWidth
                    error={oppNameErr}
                    helperText={oppNameErrMsg}
                    onChange={(event) => changeOppName(event, "change")}
                    onBlur={(event) => changeOppName(event, "blur")}
                    onKeyUp={(event) => handleKeyUp(event)}
                    value={oppName}
                    placeholder="Enter Opportunity Name"
                    id="opportunityName"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1} p={0} direction="column">
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    error={oppDesErr}
                    helperText={oppDesErrMsg}
                    onChange={changeOppDescription}
                    onBlur={changeOppDescription}
                    value={oppDescription}
                    placeholder="Enter Opportunity Description"
                    id="emailId"
                    sx={{
                      "& .css-167z6iw-MuiInputBase-root-MuiOutlinedInput-root":
                        { borderRadius: "6px", height: "44px" },
                      "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
                        margin: "0px !important",
                      },
                    }}
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    Max allowed character should be 64
                  </Typography>
                </Stack>
              </ThemeProvider>

              <Button
                disabled={savebutton}
                onClick={handleSave}
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                data-testid="edit-opportinity-btn"
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default EditOpportunity;
