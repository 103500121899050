import { createSlice } from "@reduxjs/toolkit";

export const ViewShipperSlice = createSlice({
  name: "shipperdetails",
  initialState: {
    value: {
      id: "",
      data: [],
    },
  },
  reducers: {
    shipperDetailsIDControl: (state, action) => {
      state.value.id = action.payload;
    },
    shipperDetailsControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { shipperDetailsControl, shipperDetailsIDControl } =
  ViewShipperSlice.actions;

export const shipperDetailsData = (state) => state?.shipperdetails?.value;

export default ViewShipperSlice.reducer;
