import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
  Typography,
  Button,
  AppBar,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { SaveOutlined, ExpandMore, ClearOutlined } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ProductLine from "./ProductLine";
import ShipperClass from "./ShipperClass";
import PCMType from "./PCMType";
import Configuration from "./Configuration";
import ProbeConfiguration from "./ProbeConfiguration";

import BreadCrumb from "../../core/BreadCrumb";
import { shipperDictionaryData } from "../../../components/ShipperDictionary/ShipperDictionarySlice";

import { resetData, shipperData, editShipperControl } from "./EditShipperSlice";

import * as ConstantsList from "../../../constants";
import SnackBar from "../../core/SnackBar/SnackBar";
import * as Config from "../../../config";

const EditShipper = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const shipperDetails = useSelector(shipperData);

  let history = useHistory();
  const breadCrumbText = Config?.CONFIGLIST;
  const [productLine, setProductLine] = useState("----");
  const [shipperClass, setShipperClass] = useState("----");
  const [series, setSeries] = useState("----");
  const [config, setConfig] = useState("----");
  const shipperDictionary = useSelector(shipperDictionaryData);
  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);

  const [updateShipper, setUpdateShipper] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (params?.id) {
      fetch(ConstantsList.API_URL + "shipdict/shipper/" + params?.id)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setData(data.data);
            setLoading(false);
            setReload(false);
          }
        });
    }
  }, [params?.id]);

  useEffect(() => {
    if (data) {
      dispatch(editShipperControl(data));
    }
  }, [data]);


  useEffect(() => {
    if (data) {
      if (shipperDetails) {
        const DataUpdate = {
          released: shipperDetails?.released,
          shipperdict_id: shipperDetails?.shipperdict_id?.id,
          productline_id: shipperDetails?.productline?.id,
          shipperclass: {
            id: shipperDetails?.shipperclass?.id,
            class_type: shipperDetails?.shipperclass?.class_type,
            outer_dimension: {
              length: shipperDetails?.shipperclass?.outer_dimension?.length,
              width: shipperDetails?.shipperclass?.outer_dimension?.width,
              height: shipperDetails?.shipperclass?.outer_dimension?.height,
              outside_area:
                shipperDetails?.shipperclass?.outer_dimension?.outside_area,
            },
            dry_goods: {
              weight: shipperDetails?.shipperclass?.dry_goods?.weight,
              cost: shipperDetails?.shipperclass?.dry_goods?.cost,
            },
            pcm: {
              top: {
                od_length: shipperDetails?.shipperclass?.pcm?.top?.od_length,
                od_width: shipperDetails?.shipperclass?.pcm?.top?.od_width,
              },
              side: {
                od_length: shipperDetails?.shipperclass?.pcm?.side?.od_length,
                od_width: shipperDetails?.shipperclass?.pcm?.side?.od_width,
              },
              end: {
                od_length: shipperDetails?.shipperclass?.pcm?.end?.od_length,
                od_width: shipperDetails?.shipperclass?.pcm?.end?.od_width,
              },
            },
            insulation: {
              top: {
                thickness:
                  shipperDetails?.shipperclass?.insulation?.top?.thickness,
                specific_heat:
                  shipperDetails?.shipperclass?.insulation?.top?.specific_heat,
                density: shipperDetails?.shipperclass?.insulation?.top?.density,
              },
              side: {
                thickness:
                  shipperDetails?.shipperclass?.insulation?.side?.thickness,
                specific_heat:
                  shipperDetails?.shipperclass?.insulation?.side?.specific_heat,
                density:
                  shipperDetails?.shipperclass?.insulation?.side?.density,
              },
              end: {
                thickness:
                  shipperDetails?.shipperclass?.insulation?.end?.thickness,
                specific_heat:
                  shipperDetails.shipperclass?.insulation?.end?.specific_heat,
                density: shipperDetails?.shipperclass?.insulation?.end?.density,
              },
            },
          },
          pcmtype_id: shipperDetails?.pcmtype.id,
          ticconfig: {
            id: shipperDetails?.ticconfig?.id,
            tic_type: shipperDetails?.ticconfig?.tic_type,
            configurations: shipperDetails?.ticconfig?.configurations,
          },
          probe_configuration: shipperDetails?.probeconfig,
        };
        setUpdateShipper(DataUpdate);
        setProductLine(shipperDetails?.productline?.name ?? "----");
        setShipperClass(shipperDetails?.shipperclass?.class_type ?? "----");
        setSeries(shipperDetails?.pcmtype?.pcm_name ?? "----");
        setConfig(shipperDetails?.ticconfig?.tic_type ?? "----");
      }
    }
  }, [shipperDetails, data]);

  const AccordStyle = {
    boxShadow:
      "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
  };

  const AccordSummaryStyle = {
    paddingLeft: "24px",
    borderRadius: "6px",
    backgroundColor: "#235984",
    color: "#FFFFFF",
    "& .css-yw020d-MuiAccordionSummary-expandIconWrapper": {
      color: "#FFFFFF",
    },
  };

  const breadcrumbData = [
    { id: 3, title: "Edit Shipper", link: "" },
    {
      id: 2,
      title: shipperDictionary.name,
      link: "/dashboard/shipper",
    },
    {
      id: 1,
      title: "Home / Shipper Dictionaries",
      link: "/dashboard",
    },
  ];

  const [saveButton, setSaveButton] = useState(true);

  const cancelButton = () => {
    dispatch(resetData());
    history.push("/dashboard/shipper");
  };
  const [severity, setSeverity] = useState(false);

  const saveShipper = () => {
    setLoading(true);
    if (updateShipper) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updateShipper),
      };
      let updateId = shipperDetails.id;
      fetch(
        ConstantsList.API_URL + "shipdict/shipper/" + updateId,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code.toString() === "200") {
            setLoading(true);
            setReload(false);
            setSeverity(true);
            setNotify(true);
            setNotifyMsg(data.data);
            setTimeout(() => {
              history.push("/dashboard/shipper/");
            }, 2000);
          } else {
            setSeverity(false);
            setNotify(true);
            setNotifyMsg(data.data);
          }
        });
    }
  };


  const ValidationValue = (Value) => {
    return Value?.length > 0 ? true : false;
  };

  const [probeCheck, setProbeCheck] = useState(false);

  useEffect(() => {
    if (data) {
      if (shipperDetails) {
        const ShipperClassTest = shipperDetails?.shipperclass?.outer_dimension;

        const OutterDimension = shipperDetails?.shipperclass?.outer_dimension;
        const DryGoods = shipperDetails?.shipperclass?.dry_goods;
        const PCM = shipperDetails?.shipperclass?.pcm;
        const Insulation = shipperDetails?.shipperclass?.insulation;

        const Configuration = shipperDetails?.ticconfig?.configurations;

        const ProbeConfiguration = shipperDetails?.probe_configuration;

        const ConfigurationData =
          ValidationValue(Configuration?.cost?.toString().trim()) &&
          ValidationValue(Configuration?.emissions?.toString().trim()) &&
          ValidationValue(Configuration?.end_pcm?.toString().trim()) &&
          ValidationValue(Configuration?.end_wall?.toString().trim()) &&
          ValidationValue(Configuration?.height?.toString().trim()) &&
          ValidationValue(Configuration?.landfill?.toString().trim()) &&
          ValidationValue(Configuration?.length?.toString().trim()) &&
          ValidationValue(Configuration?.side_pcm?.toString().trim()) &&
          ValidationValue(Configuration?.side_wall?.toString().trim()) &&
          ValidationValue(Configuration?.top_pcm?.toString().trim()) &&
          ValidationValue(Configuration?.top_wall?.toString().trim()) &&
          ValidationValue(Configuration?.total_pcm?.toString().trim()) &&
          ValidationValue(Configuration?.weight?.toString().trim()) &&
          ValidationValue(Configuration?.width?.toString().trim());

        const OutterDimensionData =
          ValidationValue(OutterDimension?.length?.toString().trim()) &&
          ValidationValue(OutterDimension?.width?.toString().trim()) &&
          ValidationValue(OutterDimension?.height?.toString().trim()) &&
          ValidationValue(OutterDimension?.outside_area?.toString().trim());

        const DryGoodsData =
          ValidationValue(DryGoods?.cost?.toString().trim()) &&
          ValidationValue(DryGoods?.weight?.toString().trim());

        const PCMData =
          ValidationValue(PCM?.end?.od_length?.toString().trim()) &&
          ValidationValue(PCM?.end?.od_width?.toString().trim()) &&
          ValidationValue(PCM?.side?.od_length?.toString().trim()) &&
          ValidationValue(PCM?.side?.od_width?.toString().trim()) &&
          ValidationValue(PCM?.top?.od_length?.toString().trim()) &&
          ValidationValue(PCM?.top?.od_width?.toString().trim());

        const InsulationData =
          ValidationValue(Insulation?.side?.thickness?.toString().trim()) &&
          ValidationValue(Insulation?.side?.specific_heat?.toString().trim()) &&
          ValidationValue(Insulation?.side?.density?.toString().trim()) &&
          ValidationValue(Insulation?.top?.thickness?.toString().trim()) &&
          ValidationValue(Insulation?.top?.specific_heat?.toString().trim()) &&
          ValidationValue(Insulation?.top?.density?.toString().trim()) &&
          ValidationValue(Insulation?.end?.thickness?.toString().trim()) &&
          ValidationValue(Insulation?.end?.specific_heat?.toString().trim()) &&
          ValidationValue(Insulation?.end?.density?.toString().trim());

        const ShipperClassData =
          OutterDimensionData && DryGoodsData && PCMData && InsulationData;
        const EnableDataTest =
          ConfigurationData &&
          ShipperClassData &&
          InsulationData &&
          PCMData &&
          DryGoodsData &&
          OutterDimensionData;

        const checkButton = ShipperClassData && EnableDataTest;
        setSaveButton(checkButton);
      }
    }
  }, [shipperDetails, probeCheck, data]);

  return (
    <>
      <>
        <ThemeProvider theme={theme}>
          <Stack px={6} pt={6} direction="column" sx={{ width: "100%" }}>
            <BreadCrumb data={breadcrumbData} />
          </Stack>
        </ThemeProvider>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: "#F2F2F3",
            width: "100%",
            "& .MuiAppBar-positionSticky": {
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Stack
            px={6}
            pt={6}
            pb={3.5}
            direction="column"
            spacing={3}
            sx={{ width: "100%" }}
          >
            <Box
              maxWidth="false"
              sx={{
                position: "sticky",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "auto",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  fontSize: "32px",
                  lineHeight: "38px",
                  color: "#19191A",
                }}
              >
                Edit Shipper
              </Typography>
              <ThemeProvider theme={theme}>
                <Stack direction="row" spacing={1}>
                  <Button
                    onClick={cancelButton}
                    sx={{
                      width: "145px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#FFFFFF",
                    }}
                    data-testid="cancelbutton"
                    startIcon={<ClearOutlined />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                    }}
                  >
                    Cancel
                  </Button>

                  {/* <Button
                    sx={{
                      width: "145px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#FFFFFF",
                    }}
                    data-testid="importbutton"
                    startIcon={<ImportExportOutlined />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                    }}
                  >
                    Import
                  </Button> */}

                  <Button
                    onClick={saveShipper}
                    disabled={!saveButton}
                    sx={{
                      width: "145px",
                      height: "44px",
                      borderRadius: "6px",
                      padding: "0px, 20px, 0px, 20px",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      backgroundColor: "#F26322",
                      "&:disabled": {
                        opacity: "0.3",
                      },
                    }}
                    data-testid="addbutton"
                    color="primary"
                    startIcon={<SaveOutlined />}
                    style={{
                      textTransform: "none",
                      border: "1px solid #B1B2B4",
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </ThemeProvider>
            </Box>

            <Box
              maxWidth="false"
              p={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "56px",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#000000",
                  fontFamily: "Roboto",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                Edit Shipper
              </Typography>
              <Typography
                sx={{
                  color: "#646568",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >{`${productLine} / ${shipperClass} / ${series} / ${config}`}</Typography>
            </Box>
          </Stack>
        </AppBar>
      </>
      <ThemeProvider theme={theme}>
        <Stack spacing={3} direction="column" px={6} pb={5}>
          <Stack spacing={6}>
            {/* Product Line */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="Product Line"
                id="Product-Line-Accordion"
              >
                <Typography>Product Line</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ProductLine />
              </AccordionDetails>
            </Accordion>

            {/* Shipper Class */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Class</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ShipperClass />
              </AccordionDetails>
            </Accordion>

            {/* PCM Type */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>PCM Type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PCMType />
              </AccordionDetails>
            </Accordion>

            {/* Configuration */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Configuration />
              </AccordionDetails>
            </Accordion>

            {/* Probe Configuration */}
            <Accordion sx={AccordStyle} defaultExpanded={true}>
              <AccordionSummary
                sx={AccordSummaryStyle}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Probe Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <ProbeConfiguration />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>

        {notify && (
          <SnackBar
            open={notify}
            severity={severity ? "success" : "error"}
            setOpen={setNotify}
            message={notifyMsg}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default EditShipper;
