import {
  Backdrop,
  Box,
  Button,
  createTheme,
  Radio,
  Checkbox,
  Stack,
  ThemeProvider,
  Typography,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ProductImg from "../../assets/images/product_line.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";
import SnackBar from "../core/SnackBar/SnackBar";
import * as ConstantsList from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../core/Loader/Loader";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import * as Config from "../../config";
import { useHistory, redirect } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { scenarioData } from "../Scenario/ScenarioSlice";

const mainContainer = {
  width: "100%",
  backgroundColor: "#ffffff",
};
const buttonContainerStyle = {
  alignItems: "flex-end",
};

const ButtonStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "capitalize",
  padding: "10px 20px",
  width: "290px",
  boxSizing: "border-box",
  border: "1px solid #B1B2B4",
  borderRadius: "6px",
  color: "#000000",
};

const listTextStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "capitalize",
  color: "#000",
};

const tableStyle = {
  "&.MuiTable-root td": {
    padding: "0.75rem 1.875rem 0.75rem 0",
    border: "0",
  },
  "&.MuiTable-root td:fist-child": {
    width: "60%",
  },
  "&.MuiTable-root td:last-child": {
    width: "150px",
  },
  "& .MuiFormControl-root > .MuiInputBase-root > .MuiSelect-select": {
    padding: "0.75rem",
    borderRadius: "6px",
    border: "1px solid #E6E7E8",
  },
};

const binTableStyle = {
  marginTop: "40px",
  "& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root":
    {
      border: "0",
      textAlign: "center",
      width: "40px",
      padding: "0 5px",
      verticalAlign: "bottom",
    },
  "& .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root":
    {
      width: "49px",
      height: "44px",
      marginBottom: "15px",
    },
  "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      border: "1px solid #348DBA",
      width: "49px",
      height: "44px",
    },
};

const textFieldStyle = {
  "& .css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root input": {
    padding: "8px 12px",
    width: "107px",
    height: "30px",
  },
};

const labelStyle = {
  fontWeight: "500",
  fontFamily: "Roboto",
  fontSize: "16px",
  color: "#3F4042",
  marginBottom: "15px",
};

// Theme styles here
const theme = createTheme({
  palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
});

const OutputThermal = () => {
  let history = useHistory();
  const scenarioDetails = useSelector(scenarioData);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [summaryColumnList, setSummaryColumnList] = useState([]);
  const [durationList, setDurationList] = useState([]);

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const [loading, setLoading] = useState(false);
  const [outputData, setOutputData] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (
      scenarioDetails?.status === "Optimization Completed" ||
      scenarioDetails?.status === "No Solution Found" ||
      scenarioDetails?.status === "Report Created"
    ) {
      setLoading(false);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          scenario_id: scenarioDetails?.scenario?.id,
        }),
      };
      fetch(ConstantsList.API_URL + "summary/outputsummary/", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setOutputData(data?.data);
              setDurationList([data?.data[0]?.duration]);
              setLoading(false);
              setReload(false);
              // ~~~~~~~~~~~~~~ Duration Summary Column Create Start ~~~~~~~~~~~~~~

              let tblDefaultColumns = ["Line", "Class", "PCM", "Config"];
              let tblColumnList = [];

              data.data[0].duration?.map((row1, index1) => {
                if (index1 === 0) {
                  const sparseKeys = Object.keys(row1);
                  tblDefaultColumns?.map((column, columnIndex) => {
                    const exists1 = tblDefaultColumns.includes(column);
                    if (exists1) {
                      tblColumnList.push(column);
                    }
                  });

                  sparseKeys?.map((column, columnIndex) => {
                    const exists2 = tblColumnList.includes(column);
                    if (!exists2 && column !== "risk") {
                      tblColumnList.push(column);
                    }
                  });
                }
              });

              setSummaryColumnList([tblColumnList]);
              // ~~~~~~~~~~~~~~ Duration Summary Column Create End ~~~~~~~~~~~~~~
            }
          }
        });
    } else {
    }
  }, [scenarioDetails?.status]);

  const [loaderOpen, setLoaderOpen] = useState(true);
  const handleClose = () => {
    setLoaderOpen(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#F2F2F3",
      color: "#000000",
    },
  }));
  return (
    <Box
      p={0}
      maxWidth="false"
      sx={{ width: "100%", height: "auto", margin: "20px" }}
    >
      <Stack
        direction="row"
        sx={{
          display: { xs: "block", md: "flex" },
          mt: "20px",
          mb: "20px",
          justifyContent: "space-between",
          gap: { xs: "20px", xl: "204px" },
        }}
      >
        <Typography variant="h5">
          {Config.CONFIGLIST.scenario.view.thermal.title}
        </Typography>
      </Stack>
      {(scenarioDetails?.status === "Optimization Completed" || scenarioDetails?.status === "No Solution Found" ||
        scenarioDetails?.status === "Report Created") && (
        <div>
          <Stack
            direction="row"
            sx={{
              display: { xs: "block", md: "flex" },
              mt: "20px",
              mb: "20px",
              justifyContent: "space-between",
              gap: { xs: "20px", xl: "204px" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* Left side Paper */}

                <Grid container>
                  <Grid item xs={8} sx={{ padding: "5px" }}>
                    <Typography component="div" variant="div" sx={labelStyle}>
                      {
                        Config.CONFIGLIST.scenario.view.thermal.field.field1
                          .label
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: "5px" }}>
                    <Typography
                      component="div"
                      variant="div"
                      sx={{
                        width: "96px",
                        height: "35px",
                        padding: "8px",
                        background: "#F2F2F3",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {outputData !== null &&
                        outputData[0]?.total_shippers_evaluated}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={8} sx={{ padding: "5px" }}>
                    <Typography component="div" variant="div" sx={labelStyle}>
                      {
                        Config.CONFIGLIST.scenario.view.thermal.field.field2
                          .label
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: "5px" }}>
                    <Typography
                      component="div"
                      variant="div"
                      sx={{
                        width: "96px",
                        height: "35px",
                        padding: "8px",
                        background: "#F2F2F3",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {outputData !== null &&
                        outputData[0]?.thermal_threshold_met}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                {/* Right side Paper */}

                <Grid container>
                  <Grid item xs={8} sx={{ padding: "5px" }}>
                    <Typography component="div" variant="div" sx={labelStyle}>
                      {
                        Config.CONFIGLIST.scenario.view.thermal.field.field3
                          .label
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: "5px" }}>
                    <Typography
                      component="div"
                      variant="div"
                      sx={{
                        width: "96px",
                        height: "35px",
                        padding: "8px",
                        background: "#F2F2F3",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {outputData !== null &&
                        outputData[0]?.low_duration_rejected}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={8} sx={{ padding: "5px" }}>
                    <Typography component="div" variant="div" sx={labelStyle}>
                      {
                        Config.CONFIGLIST.scenario.view.thermal.field.field4
                          .label
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ padding: "5px" }}>
                    <Typography
                      component="div"
                      variant="div"
                      sx={{
                        width: "96px",
                        height: "35px",
                        padding: "8px",
                        background: "#F2F2F3",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {outputData !== null &&
                        outputData[0]?.high_duration_rejected}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          <Stack>
            <Typography
              component="div"
              variant="div"
              sx={{
                height: "auto",
                textDecoration: "underline",
                textUnderlineOffset: "6px",
                textDecorationThickness: "4px",
                textDecorationColor: "#F26322",
              }}
            >
              Duration
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: { xs: "block", md: "flex" },
              mt: "20px",
              mb: "20px",
              justifyContent: "space-between",
              gap: { xs: "20px", xl: "204px" },
            }}
          >
            {summaryColumnList && (
              <TableContainer component={Paper} sx={{ width: "95%" }}>
                <Table
                  sx={{ minWidth: 650, width: "100%", height: "200px" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        {"SHIPPER"}
                      </StyledTableCell>
                      <StyledTableCell
                        colSpan={
                          summaryColumnList[0]
                            ? summaryColumnList[0]?.length - 4
                            : 0
                        }
                        align="center"
                      >
                        {"PAYLOAD"}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {summaryColumnList[0]?.map((C, I) => {
                        return <StyledTableCell>{C}</StyledTableCell>;
                      })}
                    </TableRow>
                    {durationList[0]?.map((C, I) => {
                      return (
                        <TableRow>
                          {summaryColumnList[0]?.map((C1, I1) => {
                            return (
                              <TableCell
                                sx={{ color: C["risk"] ? "red" : "black" }}
                              >
                                {C[C1]}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </div>
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loaderOpen}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}
    </Box>
  );
};

export default OutputThermal;
