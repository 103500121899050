import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SettingsOutlined } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import ThermalSimulationSettings from "../ThermalSimulation";
//import SwitchControl from "../../core/SwitchControl/SwitchControl";
import SwitchControl from "@mui/material/Switch";

import LShapeIcon from "../../../assets/images/LShapeIcon.svg";
import * as ConstantsList from "../../../constants";

import { scenarioPreference, scenarioData } from "../ScenarioSlice";

import { useSelector, useDispatch } from "react-redux";
import * as ADAConfig from "../../../../src/config/ADAConfig";

import css from "./index.css";

const mainContainer = {
  width: "100%",
  backgroundColor: "#ffffff",
};

const mainTitle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "30px",
};

const buttonContainerStyle = {
  alignItems: "flex-end",
};

const ButtonStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "capitalize",
  padding: "10px 20px",
  width: "290px",
  boxSizing: "border-box",
  border: "1px solid #B1B2B4",
  borderRadius: "6px",
  color: "#000000",
};

const listTextStyle = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "capitalize",
  color: "#000",
};

const tableStyle = {
  "&.MuiTable-root td": {
    padding: "0.75rem 1.875rem 0.75rem 0",
    border: "0",
  },
  "&.MuiTable-root td:fist-child": {
    width: "60%",
  },
  "&.MuiTable-root td:last-child": {
    width: "150px",
  },
  "& .MuiFormControl-root > .MuiInputBase-root > .MuiSelect-select": {
    padding: "0.75rem",
    borderRadius: "6px",
    border: "1px solid #E6E7E8",
  },
};

const binTableStyle = {
  marginTop: "40px",
  height: "max-content",
  "& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root":
    {
      border: "0",
      textAlign: "center",
      width: "40px",
      padding: "0 5px",
      verticalAlign: "bottom",
    },
  "& .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root":
    {
      width: "49px",
      height: "44px",
      marginBottom: "15px",
    },
  "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      border: "1px solid #348DBA",
      width: "49px",
      height: "44px",
    },
  "& .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: "3px 3px",
      textAlign: "center",
    },
};

const textFieldStyle = {
  "& .css-5u9m9r-MuiInputBase-root-MuiOutlinedInput-root input": {
    padding: "8px 12px",
    width: "107px",
    height: "30px",
  },
};

const theme = createTheme({
  palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
});

const Preferences = (props) => {
  const dispatch = useDispatch();
  const { saveData, setData } = props;
  const scenarioDetails = useSelector(scenarioData);
  const [showThermalSimulation, setShowThermalSimulation] = useState(false);

  const [dropDownValue, setDropDownValue] = useState(
    parseInt(scenarioDetails?.preference?.max_numofshipper ?? "4")
  );

  const [reload, setReload] = useState(false);

  const handleShowModal = () => {
    setReload(true);
    setShowThermalSimulation(true);
  };

  const handleClose = () => {
    setShowThermalSimulation(false);
  };

  const shipperPerShip = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
  ];

  const [checked, setChecked] = useState(
    scenarioDetails?.preference?.limit_releaseproduct ?? false
  );

  const [year, setYear] = useState(
    scenarioDetails?.preference?.min_numofyear_shipper
      ? scenarioDetails?.preference?.min_numofyear_shipper
      : "1200"
  );
  const [shipper, setShipper] = useState(
    scenarioDetails?.preference?.max_numofshipper_pership
      ? scenarioDetails?.preference?.max_numofshipper_pership
      : "2"
  );

  const [p1, setP1] = useState(
    parseInt(scenarioDetails?.preference?.performance_bins?.not_met ?? "-12")
  );
  const [p2, setP2] = useState(
    parseInt(scenarioDetails?.preference?.performance_bins?.med_risk ?? "0")
  );
  const [p3, setP3] = useState(
    parseInt(scenarioDetails?.preference?.performance_bins?.no_risk ?? "12")
  );
  const [p4, setP4] = useState(
    parseInt(scenarioDetails?.preference?.performance_bins?.exceed ?? "36")
  );

  const [thermal, setThermal] = useState(
    scenarioDetails?.preference?.thermal ?? null
  );

  const [thermalInitValue, setThermalInitValue] = useState(
    scenarioDetails?.preference?.initialThermal
      ? scenarioDetails?.preference?.initialThermal
      : "20"
  );

  useEffect(() => {
    if (scenarioDetails?.preference) {
      let data = scenarioDetails?.preference;
      data?.limit_releaseproduct &&
        setChecked(scenarioDetails?.preference?.limit_releaseproduct);
      data?.max_numofshipper?.toString() &&
        setDropDownValue(
          parseInt(scenarioDetails?.preference?.max_numofshipper ?? 0)
        );
      data?.min_numofyear_shipper?.toString() &&
        setYear(
          parseInt(scenarioDetails?.preference?.min_numofyear_shipper ?? "1200")
        );
      data?.max_numofshipper_pership?.toString() &&
        setShipper(
          parseInt(scenarioDetails?.preference?.max_numofshipper_pership ?? "2")
        );
      data?.performance_bins?.not_met?.toString() &&
        setP1(
          parseInt(
            scenarioDetails?.preference?.performance_bins?.not_met ?? "-12"
          )
        );
      data?.performance_bins?.med_risk?.toString() &&
        setP2(
          parseInt(
            scenarioDetails?.preference?.performance_bins?.med_risk ?? "0"
          )
        );
      data?.performance_bins?.no_risk?.toString() &&
        setP3(
          parseInt(
            scenarioDetails?.preference?.performance_bins?.no_risk ?? "12"
          )
        );
      data?.performance_bins?.exceed?.toString() &&
        setP4(
          parseInt(
            scenarioDetails?.preference?.performance_bins?.exceed ?? "36"
          )
        );
      scenarioDetails?.preference?.thermal?.toString() &&
        setThermal(scenarioDetails?.preference?.thermal ?? null);
      scenarioDetails?.preference?.initialThermal?.toString() &&
        setThermalInitValue(
          scenarioDetails?.preference?.initialThermal ?? "20"
        );
    }
  }, [scenarioDetails?.preference]);

  const handleYear = (event) => {
    const { value, name } = event.target;
    const re = /^[0-9]+$/;
    if (value === "" || re.test(value)) {
      setYear(value);
    }
  };

  const handleShipper = (event) => {
    const { value, name } = event.target;
    const re = /^[0-9]+$/;
    if (value === "" || re.test(value)) {
      setShipper(value);
    }
  };

  const checkBins = (e, textType) => {
    let value = e.target.value;
    const re = /^[0-9-]+$/;
    if (value === "" || re.test(value)) {
      if (textType === "P1") {
        setP1(value);
      }
      if (textType === "P2") {
        setP2(value);
      }
      if (textType === "P3") {
        setP3(value);
      }
      if (textType === "P4") {
        setP4(value);
      }
    }
  };

  const checkBinsConvert = (e, textType) => {
    let value = e.target.value;
    if (value === "-") {
      value = "";
    }
    if (value !== "-" && value !== "") {
      value = parseFloat(value);
    }

    if (textType === "P1") {
      setP1(value);
    }
    if (textType === "P2") {
      setP2(value);
    }
    if (textType === "P3") {
      setP3(value);
    }
    if (textType === "P4") {
      setP4(value);
    }
  };

  const [notifyMsg, setNotifyMsg] = useState("");
  const [notify, setNotify] = useState(false);

  useEffect(() => {
    let data = {
      id: scenarioDetails?.preference?.id ?? null,
      limit_releaseproduct: checked,
      max_numofshipper: dropDownValue,
      min_numofyear_shipper: year,
      max_numofshipper_pership: shipper,
      performance_bins: [
        { not_met: p1, med_risk: p2, no_risk: p3, exceed: p4 },
      ],
      thermal: thermal,
      initialThermal: thermalInitValue,
    };
    dispatch(scenarioPreference(data));
  }, [
    scenarioDetails?.preference?.id,
    checked,
    dropDownValue,
    year,
    shipper,
    p1,
    p2,
    p3,
    p4,
    thermal,
    thermalInitValue,
    dispatch,
  ]);

  useEffect(() => {
    if (thermal && scenarioDetails?.scenario?.id) {
      var raw = JSON.stringify({
        scenario_id: scenarioDetails?.scenario?.id,
        id: scenarioDetails?.preference?.id ?? null,
        limit_releaseproduct: checked,
        max_numofshipper: dropDownValue,
        min_numofyear_shipper: year,
        max_numofshipper_pership: shipper,
        performance_bins: [
          { not_met: p1, med_risk: p2, no_risk: p3, exceed: p4 },
        ],
        thermal: thermal,
      });
      setData(raw);
    }
  }, [
    scenarioDetails?.preference,
    scenarioDetails?.scenario?.id,
    checked,
    dropDownValue,
    year,
    shipper,
    p1,
    p2,
    p3,
    p4,
    thermal,
    setData,
  ]);

  const handleValue = (e) => {
    setDropDownValue(e.target.value);
  };

  return (
    <>
      <Box maxWidth="false" sx={mainContainer} px={3} py={4}>
        <Stack direction="row">
          <Stack width={1 / 2}>
            <Stack alignItems="left">
              <Typography variant="h5" sx={mainTitle}>
                Preferences
              </Typography>
            </Stack>
          </Stack>
          <Stack width={1 / 2} sx={buttonContainerStyle}>
            <ThemeProvider theme={theme}>
              <Button
                startIcon={<SettingsOutlined />}
                sx={ButtonStyle}
                onClick={handleShowModal}
                aria-label={ADAConfig.ADA.preferences.thermal_setting_btn}
                data-testid="thermal-btn"
              >
                Thermal Simulation Settings
              </Button>
            </ThemeProvider>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{
            display: { xs: "block", md: "flex" },
            marginTop: { xs: "0", sm: "50px", md: "70px" },
            justifyContent: "space-between",
            gap: { xs: "20px", xl: "204px" },
          }}
        >
          <Stack sx={{ width: { xs: "100%", md: "50%", xl: "40%" } }}>
            <TableContainer>
              <Table aria-label="caption table" sx={tableStyle}>
                <TableBody>
                  <TableRow p={0}>
                    <TableCell>
                      <Typography sx={listTextStyle}>
                        Limit to Released Product Only
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <SwitchControl
                        // logic changed for SONAR bug fixing
                        // variant={checked ? "outlined" : "outlined"}
                        variant="outlined"
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        setChecked={setChecked}
                        defaultChecked
                        color="warning"
                        inputProps={{
                          "aria-label": "Limit to Released Product Only",
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography sx={listTextStyle}>
                        Max Number of Shippers in Solution
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ m: 1, minWidth: 186 }} size="small">
                        <Select
                          defaultValue={4}
                          name={"profile"}
                          id="demo-select-small"
                          value={dropDownValue}
                          onChange={handleValue}
                        >
                          <MenuItem disabled value={0}>
                            <em>Select Profile</em>
                          </MenuItem>
                          {shipperPerShip?.map((data, i) => {
                            return (
                              <MenuItem
                                key={`Shipper-${data?.id}`}
                                value={data?.id}
                              >
                                {data?.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography sx={listTextStyle}>
                        Minimum Number of Yearly Shipments per Shipper
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={textFieldStyle}
                        value={year}
                        onInput={handleYear}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography sx={listTextStyle}>
                        Maximum Number of Shippers per Shipment
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        sx={textFieldStyle}
                        value={shipper}
                        onInput={handleShipper}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>

          <Stack
            sx={{
              border: "1px solid #ccc",
              borderRadius: "6px",
              padding: "1.5rem 2.5rem 2.5rem 2.5rem",
              width: { xs: "100%", md: "50%", xl: "60%" },
              marginTop: { xs: "50px", sm: "50px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "22px",
              }}
            >
              Performance Bins
            </Typography>

            <TableContainer
              sx={{
                height: "160px",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                },
              }}
            >
              <Table aria-label="caption table" sx={binTableStyle}>
                <TableBody>
                  <TableRow p={0}>
                    <TableCell></TableCell>

                    <TableCell>
                      <TextField
                        type="text"
                        value={p1}
                        onInput={(Event) => checkBins(Event, "P1")}
                        onBlur={(Event) => checkBinsConvert(Event, "P1")}
                        inputProps={{ maxLength: 3 }}
                      ></TextField>
                    </TableCell>

                    <TableCell></TableCell>

                    <TableCell>
                      <TextField
                        type="text"
                        value={p2}
                        onInput={(Event) => checkBins(Event, "P2")}
                        onBlur={(Event) => checkBinsConvert(Event, "P2")}
                        inputProps={{ maxLength: 3 }}
                      ></TextField>
                    </TableCell>

                    <TableCell></TableCell>

                    <TableCell>
                      <TextField
                        type="text"
                        value={p3}
                        onInput={(Event) => checkBins(Event, "P3")}
                        onBlur={(Event) => checkBinsConvert(Event, "P3")}
                        inputProps={{ maxLength: 3 }}
                      ></TextField>
                    </TableCell>

                    <TableCell></TableCell>

                    <TableCell>
                      <TextField
                        type="text  "
                        value={p4}
                        onInput={(Event) => checkBins(Event, "P4")}
                        onBlur={(Event) => checkBinsConvert(Event, "P4")}
                        inputProps={{ maxLength: 3 }}
                      ></TextField>
                    </TableCell>

                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow p={0}>
                    <TableCell>
                      <Typography
                        style={{
                          width: "91px",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        Not Met
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Stack component="img" src={LShapeIcon}></Stack>
                    </TableCell>

                    <TableCell>
                      <Typography
                        style={{
                          width: "91px",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        {" "}
                        Medium Risk
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Stack component="img" src={LShapeIcon}></Stack>
                    </TableCell>

                    <TableCell>
                      <Typography
                        style={{
                          width: "91px",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        Low Risk
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Stack component="img" src={LShapeIcon}></Stack>
                    </TableCell>

                    <TableCell>
                      <Typography
                        style={{
                          width: "91px",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        No Risk
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Stack component="img" src={LShapeIcon}></Stack>
                    </TableCell>

                    <TableCell>
                      <Typography
                        style={{
                          width: "91px",
                          fontSize: "14px",
                          lineHeight: "1",
                        }}
                      >
                        Exceeded
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Box>

      <ThermalSimulationSettings
        thermalInitValue={thermalInitValue}
        setThermalInitValue={setThermalInitValue}
        reload={reload}
        setReload={setReload}
        thermal={thermal}
        setThermal={setThermal}
        open={showThermalSimulation}
        onClose={handleClose}
      />
    </>
  );
};

export default Preferences;
