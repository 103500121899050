import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { shipperControl, resetData, shipperData } from "./ShipperDetailsSlice";

const ShipperDetails = () => {
  const dispatch = useDispatch();
  dispatch(resetData());
  const shipper = useSelector(shipperData);
  return <div>index</div>;
};

export default ShipperDetails;
