export const CONFIGLIST = {
  auth: {
    name: "",
  },
  pageNotFound: {
    message1: "Sorry, the page you were looking for was not found.",
    message2: "Here are some useful links that can help you:",
  },
  accessDenied: {
    message1: "Sorry, You don't have permission to access this page.",
    message2: "Here are some useful links that can help you:",
  },
  shipperDetails: {
    tooltip: {
      insulation: {
        thickness: "Thickness of the insulation associated with the wall",
        specificHeat:
          "Specific heat of the insulation associated with the wall",
        density: "Density of the insulation associated with the wall",
      },
      shipperClass: {
        length: "Outermost length dimension of the shipper",
        width: "Outermost width dimension of the shipper",
        height: "Outermost height dimension of the shipper",
        outsideArea: "Outside area of the insulation",
        weigth: "Weight of the Dry Goods used in the system",
        cost: "Internal Peli cost of the Dry Goods used in the system (optional)",
      },
      pcm: {
        length: "Length of the PCM associated with the wall",
        width: "Width of the PCM associated with the wall",
      },
      configuration: {
        weigtht: "Weight of the Wet Goods (TIC system) used in the shipper",
        co2Emissions:
          "Estimated CO2 emissions generated from a single use of the shipper",
        landfill:
          "Estimated landfill generated from a single use of the shipper",
        cost: "Internal Peli cost of the TIC system used in the shipper (optional)",
        length: "Length of the usable customer payload space",
        width: "Width of the usable customer payload space",
        height: "Height of the usable customer payload space",
        topPCM:
          "Mass of PCM placed at a single top wall of the shipper. An equal mass is assumed at the bottom.",
        sidePCM:
          "Mass of PCM placed at a single side wall of the shipper. An equal mass is assumed at the other side.",
        endPCM:
          "Mass of PCM placed at a single end wall of the shipper. An equal mass is assumed at the other end.",
        totalPCM: "Total mass of PCM in the shipper",
        topWall: "Thickness of the TIC at the top wall of the shipper",
        sideWall: "Thickness of the TIC at the side wall of the shipper",
        endWall: "Thickness of the TIC at the end wall of the shipper",
      },
      probeconfiguration: {
        rvalue:
          "system R-value of the probe location as calculated for strict range control i.e. 2-8, 15-25, etc.",
        topWall:
          "Thermal conductivity value used to model the top and bottom insulator walls",
        sideWall:
          "Thermal conductivity value used to model the side insulator walls",
        endWall:
          "Thermal conductivity value used to model the end insulator walls",
      },
    },
  },
  breadcrumb: {
    backText: "Back",
  },
  newShipper: {
    pcmTypeText: "PCM Type",
    selectPCMTypeText: "Select PCM Type",
    aboveZeroText: "Above Zero",
    belowZeroText: "Below Zero",
  },
  shipperDictionary: {
    listView: {
      title: "Shipper Dictionaries",
      searchPlaceholder: "Search Dictionary",
    },
    addNew: {
      title: "New Shipper Dictionary",
      field: {
        field1: {
          name: "Dictionary Name",
          label: "Dictionary Name",
          placeHoler: "New Shipper Dictionary",
          maxLenght: 32,
          required: true,
        },
      },
    },
  },
  pcm: {
    listView: {},
    addNew: {
      title: "Create PCM",
      field: {
        field1: {
          name: "PCM Name",
          label: "PCM Name",
          placeHoler: "Enter PCM Name",
          maxLenght: 32,
          required: true,
        },
        field2: {
          name: "Color",
          label: "Color",
        },
      },
    },
  },
  opportunityStatus: {},
  tooltip: {
    pcm: {
      tcd: "Temperature and thermal conductivity related to full solid and liquid transition. Linearized between input temperatures.",
      eshd: "Effective specific heat value at each temperature. Linearized between input temperatures",
    },
  },
  scenario: {
    accordion: {
      productLine: "Product Line(s)",
      probeLocation: "Probe Locations",
      temperatureProfile: "Temperature Profiles",
      payload: "Payload",
      preferences: "Preferences",
    },
    listView: {
      title: "Scenario",
      searchPlaceholder: "Search Scenario",
    },
    createNew: {
      title: "New Scenario",
      requiredInfo: "[ * ] Required fields",
      field: {
        field1: {
          name: "Scenario Name",
          label: "Scenario Name",
          placeHoler: "New Scenario",
          maxLenght: 32,
          required: true,
        },
        field2: {
          name: "Dictionary",
          label: "Dictionary",
          placeHoler: "Select Dictionary",
          maxLenght: 32,
          required: true,
        },
      },
    },
    view: {
      thermal: {
        title: "Summary",
        field: {
          field1: {
            name: "Total shippers evaluated",
            label: "Total shippers evaluated",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field2: {
            name: "Total minimum thermal performance risk threshold met",
            label: "Total minimum thermal performance risk threshold met",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field3: {
            name: "Shippers rejected for too low durations",
            label: "Shippers rejected for too low durations",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field4: {
            name: "Shippers rejected for too high durations",
            label: "Shippers rejected for too high durations",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
        },
      },
      payload: {
        title: "Summary",
        field: {
          field1: {
            name: "Total shippers evaluated",
            label: "Total shippers evaluated",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field2: {
            name: "Total shippers meeting fitment requirements",
            label: "Total shippers meeting fitment requirements",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field3: {
            name: "Shippers rejected for being too small",
            label: "Shippers rejected for being too small",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field4: {
            name: "Shippers rejected for being too large",
            label: "Shippers rejected for being too large",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
        },
      },
      orderProfile: {
        title: "Summary",
        field: {
          field1: {
            name: "Total shippers evaluated",
            label: "Total shippers evaluated",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
          field2: {
            name: "Shippers determined to be optimal and used for the solution set analysis.",
            label:
              "Shippers determined to be optimal and used for the solution set analysis.",
            placeHoler: "",
            maxLenght: 4,
            required: true,
          },
        },
      },
    },
  },
  reports: {
    saveButton: "Save",
    downloadPDF: "Download as PDF",
  },
};
