import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NoteFoundImage from "../../../src/assets/images/404.svg";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import * as Config from "../../config";
import { useDispatch } from "react-redux";
import { tab } from "../Navigator/navigateSlice";
export default function NotFound() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLink = (e) => {
    dispatch(tab("0"));
    history.push("/dashboard");
  };

  return (
    <Box
      sx={{
        minWidth: "100%",
        height: "calc(100vh - 176px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#F2F2F3",
        p: "56px",
      }}
    >
      <Box component="div" sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          align="center"
          alignItems="center"
          sx={{ width: "337.6px", height: "auto" }}
          alt="Page Not Found"
          src={NoteFoundImage}
        />
      </Box>
      <Typography
        align="center"
        variant="h6"
        component="div"
        sx={{ fontWeight: 500, fontSize: "24px", mt: "16px" }}
      >
        {Config.CONFIGLIST.pageNotFound.message1}
      </Typography>
      <Typography
        align="center"
        variant="h6"
        component="div"
        sx={{ fontWeight: 500, fontSize: "20px", m: 1 }}
      >
        {Config.CONFIGLIST.pageNotFound.message2}
      </Typography>

      <Link
        to="/"
        align="center"
        variant="h6"
        component="div"
        underline="none"
        style={{
          color: "#4C8CB6",
          fontSize: "16px",
          fontWeight: 600,
          m: 1,
          cursor: "pointer",
        }}
        onClick={handleLink}
      >
        Home/Shipper Dictionaries
      </Link>

      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        align="center"
        sx={{
          background: "transparent",
          mt: "56px",
          display: "none",
        }}
      >
        <Button
          sx={{
            background: "#fff",
            color: "#000",
            border: "1px solid #B1B2B4",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            width: "183px",
          }}
          path="/dashboard"
        >
          Shipper Dictionaries
        </Button>
        <Button
          sx={{
            background: "#fff",
            color: "#000",
            border: "1px solid #B1B2B4",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            width: "183px",
          }}
        >
          Users
        </Button>
        <Button
          sx={{
            background: "#fff",
            color: "#000",
            border: "1px solid #B1B2B4",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            width: "183px",
          }}
        >
          Customers
        </Button>
      </ButtonGroup>
    </Box>
  );
}
