import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import * as ConstantsList from "../../../constants";
import TableData from "../TableData";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Shipments = (props) => {
  const { data, selected, sets } = props;
  Chart.register(ChartDataLabels);

  const [shipments, setShipments] = useState(null);
  const [chartLabel, setChartLabel] = useState([]);

  const [chartData, setChartData] = useState([]);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const colorValues = [
    "#4E73BE",
    "#FB9048",
    "#868885",
    "#79C54B",
    "#7CC0FF",
    "#FAC50A",
    "#9AD9A7",
    "#DDD7D7",
  ];

  const [chartValue, setChartValue] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    setChartValue({
      labels: chartLabel,
      datasets: chartData,
    });
  }, [chartLabel, chartData]);

  const barConfig = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        offset: 2,
        rotation: -90,
        anchor: "end",
        font: { size: "7" },
        formatter: function (value, context) {},
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Payloads",
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: "Total Shipped Weight (kg)",
        },
        stacked: true,
        ticks: {
          max: 100,
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  useEffect(() => {
    if (data) {
      var raw = JSON.stringify({
        scenario_id: parseInt(data?.scenario),
        set_size: parseInt(data?.setsize),
        risk: data?.risk,
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      };
      fetch(ConstantsList.API_URL + "summary/solution_set/ ", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setShipments(data.data);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [data]);

  const [dataColor, setDataColor] = useState([]);

  useEffect(() => {
    if (shipments?.solution_set) {
      let drfg = [];
      shipments?.solution_set?.shipper.map((shipper, i) =>
        drfg.push({ name: shipper?.name, color: colorValues[i] })
      );
      setDataColor(drfg);
      let PayloadNames = [];
      shipments?.solution_set?.shipper.forEach(
        (data) => (PayloadNames = data?.payload.map((data) => data?.name))
      );
      setChartLabel(PayloadNames);
      let noPayload = shipments?.solution_set?.no_payload;
      let noShipper = shipments?.solution_set?.no_shipper;
      let DataForChart = [];
      let Rows = [];
      for (let i = 0; i < noPayload; i++) {
        let data = { x: PayloadNames[i] };
        let rowValue = { 0: `Payload ${i + 1}` };
        let total = 0;
        for (let j = 0; j < noShipper; j++) {
          rowValue[0] = shipments?.solution_set?.shipper[j]?.payload[i]?.name;
          total =
            total +
            parseInt(
              shipments?.solution_set?.shipper[j]?.payload[i]?.shipments
            );
        }
        for (let j = 0; j < noShipper; j++) {
          data[j.toString()] =
            (parseInt(
              shipments?.solution_set?.shipper[j]?.payload[i]?.shipments
            ) /
              total) *
            100;
          rowValue[(j + 1).toString()] =
            shipments?.solution_set?.shipper[j]?.payload[i]?.shipments;
        }
        DataForChart.push(data);
        Rows.push(rowValue);
      }
      setRows(Rows);
      let BarData = [];
      let Columns = ["Payload Name"];
      let DatasetsTest = [];
      for (let i = 0; i < shipments?.solution_set?.shipper.length; i++) {
        DatasetsTest.push({
          label: shipments?.solution_set?.shipper[i]?.name,
          data: DataForChart,
          parsing: {
            yAxisKey: i.toString(),
          },
          stack: "Stack 0",
          barPercentage: 0.5,
          barThickness: 16,
          maxBarThickness: 8,
          backgroundColor: colorValues[i],
        });
      }
      setChartData(DatasetsTest);
      for (let i = 0; i < shipments?.solution_set?.shipper.length; i++) {
        let payload = { x: (i + 1).toString() };
        Columns.push(shipments?.solution_set?.shipper[i]?.name);
        for (
          let j = 0;
          j < shipments?.solution_set?.shipper[i]?.payload.length;
          j++
        ) {
          payload[j.toString()] =
            shipments?.solution_set?.shipper[i].payload[j]?.shipments;
        }
        BarData = [...BarData, payload];
      }
      setColumns(Columns);
    }
  }, [shipments?.solution_set, selected, sets]);

  return (
    <Stack direction="column" pt={3.8} pb={3} className="solChart">
      <Stack direction="column">
        <Stack direction="column" spacing={4}>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "20px",
                color: "#3F4042",
                fontWeight: "500",
                lineHeight: "22px",
              }}
            >
              Shipments
            </Typography>
            <Stack direction="row" spacing={1}>
              {dataColor &&
                dataColor.map((data, i) => {
                  return (
                    <React.Fragment key={`Stack-${i}`}>
                      {
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Box
                            sx={{
                              backgroundColor: data?.color,
                              width: "17.25px",
                              height: "17.25px",
                            }}
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              color: "#000000",
                              fontWeight: "400",
                              lineHeight: "20px",
                            }}
                          >
                            {data.name}
                          </Typography>
                        </Stack>
                      }
                    </React.Fragment>
                  );
                })}
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ width: "100%", alignItems: "center", justifyContent: "center" }}
        >
          <Stack
            pt={10}
            sx={{
              width: "890.23px",
              height: "514.68px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {chartValue?.datasets.length > 0 ? (
              <Bar options={barConfig} data={chartValue} />
            ) : (
              <Typography>No Record Found</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack width={1} direction="row" justifyContent="center" pt={2}>
        <Stack width="auto" direction="row" alignItems="center">
          <TableData columns={columns} rows={rows} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Shipments;
