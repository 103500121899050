import React, { useEffect, useState } from "react";

import TabContext from "@mui/lab/TabContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { selectTab } from "../../components/Navigator/navigateSlice";
import Breadcrumb from "../../components/Breadcrumb/index";
import SnackBar from "../core/SnackBar/SnackBar";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";

import { shipperDictionaryData } from "../../components/ShipperDictionary/ShipperDictionarySlice";
import { pcmListViewData } from "../../components/PCM/PCMListViewSlice";

import * as Config from "../../../src/config/";
import * as ConstantsList from "../../../src/constants/";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const Navigator = (props) => {
  const selectedTab = useSelector(selectTab);
  const shipperDictionary = useSelector(shipperDictionaryData);
  const pcmListView = useSelector(pcmListViewData);

  const [pcmColor, setPCMColor] = useState("#D0021B");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");

  const [pcmName, setPCMName] = useState("");
  const [formTCDValues, setFormTCDValues] = useState([
    { temperature: "", value: "" },
    { temperature: "", value: "" },
  ]);

  const [formMeltValues, setFormMeltValues] = useState([
    { temperature: "", value: "" },
  ]);
  const [formFreezeValues, setFormFreezeValues] = useState([
    { temperature: "", value: "" },
  ]);

  const [formPCMStartTemperatureValues, setFormPCMStartTemperatureValues] =
    useState([{ summer: "", winter: "" }]);

  const [eshdMeltCheck, setESHDMeltCheck] = useState(true);
  const [eshdFreezeCheck, setESHDFreezeCheck] = useState(false);
  var pcmListViewId = pcmListView?.id;
  var pcmListViewName = pcmListView?.name;
  var pcmListViewColor = pcmListView?.color;

  const [fetchDataList, setFetchDataList] = useState("");
  const [fromPage, setFromPage] = useState("PCM View");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#19191A",
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
            textDecoration: "underline !important",
            textDecorationThickness: "4px",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&$selected": {
            fontWeight: "600",
            color: "#19191A",
          },
        },
      },
    },
  });

  let addFormFields = (eshdType) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      setFormMeltValues([...formMeltValues, { temperature: "", value: "" }]);
    }
    if (eshdType === "Freeze" && eshdFreezeCheck === true) {
      setFormFreezeValues([
        ...formFreezeValues,
        { temperature: "", value: "" },
      ]);
    }
  };
  let removeFormFields = (eshdType, i) => {
    if (eshdType === "Melt" && eshdMeltCheck === true) {
      let newFormMeltValues = [...formMeltValues];
      newFormMeltValues.splice(i, 1);
      setFormMeltValues(newFormMeltValues);
    }
    if (eshdType === "Freeze" && eshdFreezeCheck === true) {
      let newFormFreezeValues = [...formFreezeValues];
      newFormFreezeValues.splice(i, 1);
      setFormFreezeValues(newFormFreezeValues);
    }
  };

  let handleChange = (eshdType, i, e) => {
    if (eshdType === "Melt") {
      let value = e.target.value;
      let re = /^[0-9.-]+$/;
      if (value === "" || re.test(value)) {
        let newFormMeltValues = [...formMeltValues];
        newFormMeltValues[i][e.target.name] = e.target.value;
        setFormMeltValues(newFormMeltValues);
      }
    }
    if (eshdType === "Freeze") {
      let value = e.target.value;
      let re = /^[0-9.-]+$/;
      if (value === "" || re.test(value)) {
        let newFormFreezeValues = [...formFreezeValues];
        newFormFreezeValues[i][e.target.name] = e.target.value;
        setFormFreezeValues(newFormFreezeValues);
      }
    }
    if (eshdType === "PCMName") {
      let value = e.target.value;
      let re = /^[A-Za-z0-9 ]+$/;
      if (value === "" || re.test(value)) {
        setPCMName(e.target.value);
      }
    }
    if (eshdType === "TCDTemp") {
      const value = e.target.value;
      const re = /^[0-9.-]+$/;
      if (value === "" || re.test(value)) {
        let newFormTCDValues = [...formTCDValues];
        newFormTCDValues[i][e.target.name] = e.target.value;
        setFormTCDValues(newFormTCDValues);
      }
    }
    if (eshdType === "TCDK") {
      let value = e.target.value;
      let re = /^[0-9.]+$/;
      if (value === "" || re.test(value)) {
        let newFormTCDValues = [...formTCDValues];
        newFormTCDValues[i][e.target.name] = e.target.value;
        setFormTCDValues(newFormTCDValues);
      }
    }
    if (eshdType === "CheckboxMelt") {
      setESHDMeltCheck(!eshdMeltCheck);
    }
    if (eshdType === "CheckboxFreeze") {
      setESHDFreezeCheck(!eshdFreezeCheck);
    }
  };

  let handleBlur = (eshdType, i, e) => {
    let textValue =
      e.target.value && e.target.value !== "-" ? e.target.value : 0;

    if (eshdType === "TCDTemp") {
      let newFormTCDValues = [...formTCDValues];
      newFormTCDValues[i][e.target.name] = parseFloat(textValue).toFixed(2);
      setFormTCDValues(newFormTCDValues);
    }
    if (eshdType === "TCDK") {
      let newFormTCDValues = [...formTCDValues];
      newFormTCDValues[i][e.target.name] = parseFloat(textValue).toFixed(2);
      setFormTCDValues(newFormTCDValues);
    }
    if (eshdType === "Melt") {
      let newFormMeltValues = [...formMeltValues];
      newFormMeltValues[i][e.target.name] = parseFloat(textValue).toFixed(2);
      setFormMeltValues(newFormMeltValues);
    }
    if (eshdType === "Freeze") {
      let newFormFreezeValues = [...formFreezeValues];
      newFormFreezeValues[i][e.target.name] = parseFloat(textValue).toFixed(2);
      setFormFreezeValues(newFormFreezeValues);
    }
  };

  let fetchData = () => {
    fetch(ConstantsList.API_URL + "pcm/pcm/" + pcmListViewId)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setFetchDataList(data);
            setFormTCDValues([...data.data.TCD]);
            setESHDMeltCheck(data.data.ESHD[0].melt);
            setESHDFreezeCheck(data.data.ESHD[1].freeze);

            setFormMeltValues([...data.data.ESHD[0].temp_data]);
            setFormFreezeValues([...data.data.ESHD[1].temp_data]);
            setFormPCMStartTemperatureValues([...data.data.pcmstart_temp]);
          } else if (data.status_code === 400) {
            setNotify(true);
            setNotifyMsg(data.data.toString());
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [""]);
  return (
    <ThemeProvider theme={theme}>
      <Box maxWidth="false" sx={{ width: "100%", typography: "body1" }}>
        <TabContext
          value={selectedTab}
          sx={{ "&.Mui-selected": { color: "#19191A", fontWeight: "600" } }}
        >
          <Box
            sx={{
              borderBottom: 0,
              borderColor: "none",
            }}
          >
            <Breadcrumb
              fromPage={fromPage}
              selectedVal={{ ...shipperDictionary }}
            />
          </Box>
        </TabContext>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          margin: "24px 0 24px 0",
          borderRadius: 1,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ p: 3, pl: 0, pb: 0, fontSize: "32px", display: "flex" }}
            >
              {fromPage === "PCM View" ? pcmListViewName : "Edit PCM"}
            </Typography>
            <Box
              sx={{
                backgroundColor: "#fff",
                p: "3px",
                borderRadius: "4px",
                border: "1px solid #E6E7E8",
                display: "inline-block",
                margin: "auto 0",
              }}
            >
              <Box
                sx={{
                  width: 74,
                  height: 38,

                  backgroundColor: pcmListViewColor,
                  borderRadius: "4px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ margin: "auto", textAlign: "right" }}>
            <Button
              //onClick={handleCddelOpen}
              href="/dashboard/pcm/edit"
              data-testid="editButton"
              //disabled={count}
              startIcon={<EditIcon />}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                fontWeight: 400,
                height: "44px",
                mr: 0,
                pr: 4,
                pl: 4,
                background: "#FFFFFF",
                border: "1px #b1b2b4 solid",
                "&:hover": {
                  backgroundColor: "#F2F2F3",
                },
              }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box maxWidth="false" sx={{ width: "100%", typography: "body1" }}>
        <Box
          sx={{
            borderBottom: 0,
            borderColor: "none",
            margin: "48px 0 24px 0",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* Left side Paper */}
              {/* ~~~~~~~~~~  Thermal Conductivity Definition Start ~~~~~~~~~~~~~~  */}
              <Paper
                elevation={3}
                sx={{
                  boxShadow:
                    "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
                }}
              >
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    Thermal Conductivity Definition{" "}
                    <BootstrapTooltip title={Config.CONFIGLIST.tooltip.pcm.tcd}>
                      <InfoOutlinedIcon
                        sx={{
                          width: "14px",
                          height: "auto",
                          color: "#7D7F82",
                          margin: "0px 0 -2px auto",
                          cursor: "pointer",
                        }}
                      ></InfoOutlinedIcon>
                    </BootstrapTooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      sx={{
                        border: "1px #E6E7E8 solid",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          background: "#F2F2F3",
                          fontSize: "16px",
                          fontWeight: 400,
                          p: 1,
                          borderRadius: "5px 5px 0px 0px",
                        }}
                      >
                        <Grid item xs={6} sx={{ p: 1 }}>
                          Temperature
                        </Grid>
                        <Grid item xs={6} sx={{ p: 1, fontStyle: "italic" }}>
                          k
                        </Grid>
                      </Grid>
                      <Grid container sx={{ background: "#fff" }}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-tcdTemp1"
                              name="temperature"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  °C
                                </InputAdornment>
                              }
                              aria-describedby="outlined-TCDTemp-helper-text"
                              inputProps={{
                                "aria-label": "TCDTemp",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              // disabled="disabled"
                              readOnly={1}
                              value={formTCDValues[0].temperature}
                              onChange={(e) => handleChange("TCDTemp", 0, e)}
                              onBlur={(e) => handleBlur("TCDTemp", 0, e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-tcdK1"
                              name="value"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  W/m-K
                                </InputAdornment>
                              }
                              aria-describedby="outlined-TCDK-helper-text"
                              inputProps={{
                                "aria-label": "TCDK",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              // disabled="disabled"
                              readOnly={1}
                              value={formTCDValues[0].value}
                              onChange={(e) => handleChange("TCDK", 0, e)}
                              onBlur={(e) => handleBlur("TCDK", 0, e)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ background: "#fff" }}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-tcdTemp2"
                              name="temperature"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  °C
                                </InputAdornment>
                              }
                              aria-describedby="outlined-temperature-helper-text"
                              inputProps={{
                                "aria-label": "Temperature",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              // disabled="disabled"
                              readOnly={1}
                              value={formTCDValues[1].temperature}
                              onChange={(e) => handleChange("TCDTemp", 1, e)}
                              onBlur={(e) => handleBlur("TCDTemp", 1, e)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          >
                            <OutlinedInput
                              id="outlined-adornment-tcdK2"
                              name="value"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  W/m-K
                                </InputAdornment>
                              }
                              aria-describedby="outlined-temperature-helper-text"
                              inputProps={{
                                "aria-label": "Temperature",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              // disabled="disabled"
                              readOnly={1}
                              value={formTCDValues[1].value}
                              onChange={(e) => handleChange("TCDK", 1, e)}
                              onBlur={(e) => handleBlur("TCDK", 1, e)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              {/* ~~~~~~~~~~  Thermal Conductivity Definition End ~~~~~~~~~~~~~~  */}

              {/* ~~~~~~~~~~  PCM Start Temperature Start ~~~~~~~~~~~~~~  */}
              <Paper
                elevation={3}
                sx={{
                  marginTop: "24px",
                  boxShadow:
                    "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
                }}
              >
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    PCM Start Temperature{" "}
                    <BootstrapTooltip title={Config.CONFIGLIST.tooltip.pcm.tcd}>
                      <InfoOutlinedIcon
                        sx={{
                          width: "14px",
                          height: "auto",
                          color: "#7D7F82",
                          margin: "0px 0 -2px auto",
                          cursor: "pointer",
                        }}
                      ></InfoOutlinedIcon>
                    </BootstrapTooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      container
                      sx={{
                        border: "1px #E6E7E8 solid",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          background: "#F2F2F3",
                          fontSize: "16px",
                          fontWeight: 400,
                          p: 1,
                          borderRadius: "5px 5px 0px 0px",
                        }}
                      >
                        <Grid item xs={6} sx={{ p: 1 }}>
                          Summer
                        </Grid>
                        <Grid item xs={6} sx={{ p: 1 }}>
                          Winter
                        </Grid>
                      </Grid>
                      <Grid container sx={{ background: "#fff" }}>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-pcm-start-summer"
                              name="summer"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  °C
                                </InputAdornment>
                              }
                              aria-describedby="outlined-PCM-Start-Summer-helper-text"
                              inputProps={{
                                "aria-label": "PCM Start Summer",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              readOnly={1}
                              value={formPCMStartTemperatureValues[0].summer}
                              onChange={(e) =>
                                handleChange("PCMStartTemperatureSummer", 0, e)
                              }
                              onBlur={(e) =>
                                handleBlur("PCMStartTemperatureSummer", 0, e)
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            sx={{
                              m: 1,
                              background: "#F2F2F370",
                              borderRadius: "5px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-pcm-start-winter"
                              name="winter"
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  sx={{
                                    background: "#F2F2F3",
                                    height: "100%",
                                    padding: "6px 12px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  °C
                                </InputAdornment>
                              }
                              aria-describedby="outlined-PCM-Start-Winter-helper-text"
                              inputProps={{
                                "aria-label": "PCM Start Winter",
                                maxLength: 8,
                                style: {
                                  padding: 8,
                                },
                              }}
                              readOnly={1}
                              value={formPCMStartTemperatureValues[0].winter}
                              onChange={(e) =>
                                handleChange("PCMStartTemperatureWinter", 0, e)
                              }
                              onBlur={(e) =>
                                handleBlur("PCMStartTemperatureWinter", 0, e)
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              {/* ~~~~~~~~~~  PCM Start Temperature End ~~~~~~~~~~~~~~  */}
            </Grid>
            <Grid item xs={8}>
              {/* Right side Paper */}
              <Paper
                elevation={3}
                xs={8}
                sx={{
                  marginLeft: "24px",
                  boxShadow:
                    "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
                }}
              >
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    Effective Specific Heat Definition{" "}
                    <BootstrapTooltip
                      title={Config.CONFIGLIST.tooltip.pcm.eshd}
                    >
                      <InfoOutlinedIcon
                        sx={{
                          width: "14px",
                          height: "auto",
                          color: "#7D7F82",
                          margin: "0px 0 -2px auto",
                          cursor: "pointer",
                        }}
                      ></InfoOutlinedIcon>
                    </BootstrapTooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Checkbox
                      value="melt"
                      name={`checkbox-melt`}
                      inputProps={{ "aria-label": "A" }}
                      disabled="disabled"
                      checked={eshdMeltCheck}
                      onChange={(e) => handleChange("CheckboxMelt", 0, e)}
                    />
                    Melt
                    <Grid item xs={12}>
                      <Grid
                        container
                        sx={{
                          border: "1px #E6E7E8 solid",
                          borderRadius: "5px 5px 0px 0px",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            background: "#F2F2F3",
                            fontSize: "16px",
                            fontWeight: 400,
                            p: 1,
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <Grid item xs={1} sx={{ p: 1 }}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={5} sx={{ p: 1 }}>
                            Temperature
                          </Grid>
                          <Grid item xs={5} sx={{ p: 1, fontStyle: "italic" }}>
                            Cp,eff
                          </Grid>
                          <Grid item xs={1} sx={{ p: 1 }}>
                            &nbsp;
                          </Grid>
                        </Grid>

                        {/* ~~~~~~~~~~~~~~~~~~~~~ Melt Start ~~~~~~~~~~~~~~~~~~~~~ */}
                        {formMeltValues.map((element, index) => (
                          <Grid
                            container
                            sx={{ background: "#fff" }}
                            key={index}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {index + 1}
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl
                                sx={{
                                  m: 1,
                                  background: "#F2F2F370",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                <OutlinedInput
                                  id="outlined-adornment-temperature"
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        background: "#F2F2F3",
                                        height: "100%",
                                        padding: "6px 12px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      °C
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-temperature-helper-text"
                                  inputProps={{
                                    "aria-label": "temperature",
                                    maxLength: 8,
                                    style: {
                                      padding: 8,
                                    },
                                  }}
                                  // disabled="disabled"
                                  readOnly={1}
                                  name="temperature"
                                  value={element.temperature || ""}
                                  onChange={(e) =>
                                    handleChange("Melt", index, e)
                                  }
                                  onBlur={(e) => handleBlur("Melt", index, e)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl
                                sx={{
                                  m: 1,
                                  background: "#F2F2F370",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                <OutlinedInput
                                  id="outlined-adornment-value"
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        background: "#F2F2F3",
                                        height: "100%",
                                        padding: "6px 12px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      J/g-°C
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-value-helper-text"
                                  inputProps={{
                                    "aria-label": "Value",
                                    maxLength: 8,
                                    style: {
                                      padding: 8,
                                    },
                                  }}
                                  // disabled="disabled"
                                  readOnly={1}
                                  name="value"
                                  value={element.value || ""}
                                  onChange={(e) =>
                                    handleChange("Melt", index, e)
                                  }
                                  onBlur={(e) => handleBlur("Melt", index, e)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={1} sx={{ display: "flex" }}>
                              <Box
                                component="div"
                                sx={{
                                  display:
                                    fromPage === "PCM View" ? "none" : "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {formMeltValues.length - 1 !== index ? (
                                  <CloseIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      removeFormFields("Melt", index)
                                    }
                                  ></CloseIcon>
                                ) : (
                                  <AddIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => addFormFields("Melt")}
                                  ></AddIcon>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                        {/* ~~~~~~~~~~~~~~~~~~~~~ Melt end ~~~~~~~~~~~~~~~~~~~~~ */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Checkbox
                      value="freeze"
                      name={`checkbox-freeze`}
                      inputProps={{ "aria-label": "B" }}
                      disabled="disabled"
                      checked={eshdFreezeCheck}
                      onChange={(e) => handleChange("CheckboxFreeze", 0, e)}
                    />
                    Freeze
                    <Grid item xs={12}>
                      <Grid
                        container
                        sx={{
                          border: "1px #E6E7E8 solid",
                          borderRadius: "5px 5px 0px 0px",
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            background: "#F2F2F3",
                            fontSize: "16px",
                            fontWeight: 400,
                            p: 1,
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          <Grid item xs={1} sx={{ p: 1 }}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={5} sx={{ p: 1 }}>
                            Temperature
                          </Grid>
                          <Grid item xs={5} sx={{ p: 1, fontStyle: "italic" }}>
                            Cp,eff
                          </Grid>
                          <Grid item xs={1} sx={{ p: 1 }}>
                            &nbsp;
                          </Grid>
                        </Grid>

                        {/* ~~~~~~~~~~~~~~~~~~~~~ Freeze Start ~~~~~~~~~~~~~~~~~~~~~ */}
                        {formFreezeValues.map((element, index) => (
                          <Grid
                            container
                            sx={{ background: "#fff" }}
                            key={index}
                          >
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {index + 1}
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl
                                sx={{
                                  m: 1,
                                  background: "#F2F2F370",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                <OutlinedInput
                                  id="outlined-adornment-temperature"
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        background: "#F2F2F3",
                                        height: "100%",
                                        padding: "6px 12px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      °C
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-temperature-helper-text"
                                  inputProps={{
                                    "aria-label": "temperature",
                                    maxLength: 8,
                                    style: {
                                      padding: 8,
                                    },
                                  }}
                                  // disabled="disabled"
                                  readOnly={1}
                                  name="temperature"
                                  value={element.temperature || ""}
                                  onChange={(e) =>
                                    handleChange("Freeze", index, e)
                                  }
                                  onBlur={(e) => handleBlur("Freeze", index, e)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl
                                sx={{
                                  m: 1,
                                  background: "#F2F2F370",
                                  borderRadius: "5px",
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                  },
                                }}
                              >
                                <OutlinedInput
                                  id="outlined-adornment-value"
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        background: "#F2F2F3",
                                        height: "100%",
                                        padding: "6px 12px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      J/g-°C
                                    </InputAdornment>
                                  }
                                  aria-describedby="outlined-value-helper-text"
                                  inputProps={{
                                    "aria-label": "value",
                                    maxLength: 8,
                                    style: {
                                      padding: 8,
                                    },
                                  }}
                                  // disabled="disabled"
                                  readOnly={1}
                                  name="value"
                                  value={element.value || ""}
                                  onChange={(e) =>
                                    handleChange("Freeze", index, e)
                                  }
                                  onBlur={(e) => handleBlur("Freeze", index, e)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={1} sx={{ display: "flex" }}>
                              <Box
                                component="div"
                                sx={{
                                  display:
                                    fromPage === "PCM View" ? "none" : "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                {formFreezeValues.length - 1 !== index ? (
                                  <CloseIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      removeFormFields("Freeze", index)
                                    }
                                  ></CloseIcon>
                                ) : (
                                  <AddIcon
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => addFormFields("Freeze")}
                                  ></AddIcon>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                        {/* ~~~~~~~~~~~~~~~~~~~~~ Freeze end ~~~~~~~~~~~~~~~~~~~~~ */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </ThemeProvider>
  );
};

export default Navigator;
