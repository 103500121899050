import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography, Button, ButtonGroup } from "@mui/material";
import * as ConstantsList from "../../../src/constants/";
import { SettingsOutlined, Delete, Edit, Add } from "@mui/icons-material";
import DeleteOpportunity from "./DeleteOpportunity";
import CreateOpportunity from "./CreateOpportunity";
import EditOpportunity from "./EditOpportunity";
import SearchOpportunity from "./SearchOpportunity";
import ListOpportunity from "./ListOpportunity";
import SnackBar from "../core/SnackBar/SnackBar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../core/Loader/Loader";
import { loginData } from "../../components/Login/LoginSlice";
import { useSelector } from "react-redux";
const Opportunities = (props) => {
  const loginDetails = useSelector(loginData);
  const { customer, setReload, data } = props;

  const [searchuserData, setSearchUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [severity, setSeverity] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notifyMsg, setNotifyMsg] = useState("");
  const [open, setOpen] = useState(true);
  const [count, setCount] = useState(true);

  const [delDialog, setDelDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const Columns = [
    {
      id: "select",
      label: "",
      minWidth: 80,
      width: "7%",
      align: "center",
      numeric: false,
    },
    {
      id: "opportunity_name",
      label: "Name",
      minWidth: 200,
      width: "20%",
      align: "left",
      numeric: false,
    },
    {
      id: "created_on",
      label: "Creation Date",
      minWidth: 200,
      width: "15%",
      align: "left",
      numeric: true,
    },
    {
      id: "updated_on",
      label: "Last Edited Date",
      minWidth: 200,
      width: "15%",
      align: "left",
      numeric: true,
    },
    {
      id: "opportunity_status",
      label: "Opportunity Status",
      minWidth: 200,
      width: "15%",
      align: "left",
      numeric: true,
    },
    {
      id: "scenario_name",
      label: "Scenarios",
      minWidth: 100,
      width: "10%",
      align: "center",
      numeric: false,
    },
    {
      id: "view_details",
      label: "View Details",
      width: "-webkit-fill-available",
      minWidth: "-webkit-fill-available",
      align: "center",
      numeric: false,
    },
  ];

  const deleteOpportunity = () => {
    setDelDialog(true);
  };

  const addOpportunity = () => {
    setAddDialog(true);
  };

  const editOpportunity = () => {
    setEditDialog(true);
  };

  const isEnabled = props.data && props.data.length !== 0 ? false : true;

  let isSelectedVal = selectedItem ? false : true;

  if (!open) {
    isSelectedVal = false;
  }

  const [settingOpen, setSettingOpen] = useState(false);

  const handleSettingOpen = () => {
    setSettingOpen(true);
  };

  const handleSettingClose = () => {
    setSettingOpen(false);
  };

  const labelStyle = {
    fontWeight: "400",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3F4042",
  };

  const [settingsData, setSettingsData] = useState(null);

  const [openLoad, setOpenLoad] = useState(true);
  const handleClose = () => {
    setOpenLoad(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "cust/settings/" + customer?.id)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setSettingsData(data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, [customer?.id]);

  const [units, setUnits] = useState(settingsData?.units ?? "MKS");
  const [shipWeightUnit, setShipWeightUnit] = useState(
    settingsData?.ship_weight_unit ?? "kg"
  );
  const [dimensionalWeight, setDimensionalWeight] = useState(
    settingsData?.dim_weight_divisor
      ? parseFloat(settingsData.dim_weight_divisor) / 1000
      : ""
  );
  const [thermalOrientation, setThermalOrientation] = useState(
    settingsData?.thermal_orientation ?? "On Base"
  );
  const [currency, setCurrency] = useState(settingsData?.currency ?? "USD");
  const [outfreight, setoutfreight] = useState(
    settingsData?.outbond_freight_rate ?? "2"
  );
  const [returnfreight, setReturnfreight] = useState(
    settingsData?.return_freight_rate ?? "0.5"
  );

  useEffect(() => {
    if (settingsData) {
      setUnits(settingsData?.units ?? "MKS");
      setShipWeightUnit(settingsData?.ship_weight_unit ?? "kg");
      setDimensionalWeight(settingsData?.dim_weight_divisor ?? "");
      setThermalOrientation(settingsData?.thermal_orientation ?? "On Base");
      setCurrency(settingsData?.currency ?? "USD");
      setoutfreight(settingsData?.outbond_freight_rate ?? "2");
      setReturnfreight(settingsData?.return_freight_rate ?? "0.5");
    }
  }, [settingsData]);

  const handleUnits = (e) => {
    setUnits(e.target.value);
  };

  const handleShipWeightUnit = (e) => {
    setShipWeightUnit(e.target.value);
  };

  const handleDimensionalWeight = (e) => {
    setDimensionalWeight(e.target.value);
  };

  const handleThermalOrientation = (e) => {
    setThermalOrientation(e.target.value);
  };

  const handleCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const handleoutfreight = (e) => {
    setoutfreight(e.target.value);
  };

  const handlereturnfreight = (e) => {
    setReturnfreight(e.target.value);
  };

  const [selectUnits, setSelectUnits] = useState("cm3/kg");

  useEffect(() => {
    if (shipWeightUnit) {
      setSelectUnits(shipWeightUnit === "kg" ? "cm3/kg" : "in3/lb");
      setDimensionalWeight(shipWeightUnit === "kg" ? 5000 : 139);
    }
  }, [shipWeightUnit]);

  const handleSave = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_id: customer?.id,
        units: units,
        dim_weight_divisor: parseFloat(dimensionalWeight) * 1000,
        thermal_orientation: thermalOrientation,
        currency: currency,
        ship_weight_unit: shipWeightUnit,
        outbond_freight_rate: outfreight,
        return_freight_rate: returnfreight,
      }),
    };

    fetch(
      ConstantsList.API_URL + "cust/settings/" + customer?.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.status_code === 200) {
            setNotify(true);
            setNotifyMsg(data.data);
            setSettingOpen(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const list = (anchor) => (
    <Box sx={{ width: 339, height: "100vh" }} role="presentation">
      <Stack
        width={1}
        height={1}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Stack
          width={1}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            width={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              width={1}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "#19191A",
                  lineHeight: "38px",
                }}
              >
                Settings
              </Typography>
            </Stack>
            <Stack>
              <IconButton aria-label="delete" onClick={handleSettingClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack width={1} direction="column" pt={7} spacing={5}>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Units
              </Typography>
              {/* <FormControl fullWidth>
                <Select id="unit-select" value={units} onChange={handleUnits}>
                  <MenuItem value="MKS" selected>MKS</MenuItem>
                  <MenuItem value="IPS">IPS</MenuItem> 
                </Select>
              </FormControl> */}

              <FormControl fullWidth>
                <Select
                  disabled={true}
                  id="unit-select"
                  name="unit-select"
                  value={units}
                  onChange={handleUnits}
                >
                  <MenuItem value="MKS" selected>
                    MKS
                  </MenuItem>
                  {/* <MenuItem value="IPS">IPS</MenuItem>  */}
                </Select>
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Ship weight unit
              </Typography>
              {/* <FormControl fullWidth>
                <Select
                  id="ship-weight-select"
                  value={shipWeightUnit}
                  onChange={handleShipWeightUnit}
                >
                  <MenuItem value="kg">kg</MenuItem>
                  <MenuItem value="Lbs">Lbs</MenuItem>
                </Select>
              </FormControl> */}

              <FormControl fullWidth>
                <Select
                  disabled={true}
                  id="ship-weight-select"
                  name="ship-weight-select"
                  value={shipWeightUnit}
                  onChange={handleShipWeightUnit}
                >
                  <MenuItem value="kg">kg</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Dimensional Weight Divisor
              </Typography>
              <FormControl sx={{ m: 1 }} variant="outlined">
                <OutlinedInput
                  type="number"
                  id="outlined-adornment-temperature"
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        background: "#F2F2F3",
                        height: "100%",
                        padding: "6px 12px",
                        borderRadius: "5px",
                      }}
                    >
                      {selectUnits}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-temperature-helper-text"
                  inputProps={{
                    "aria-label": "hours",
                    maxLength: 2,
                    style: {
                      padding: 8,
                    },
                  }}
                  name="hours"
                  value={dimensionalWeight}
                  onInput={handleDimensionalWeight}
                />
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Thermal Orientation
              </Typography>
              <FormControl fullWidth>
                <Select
                  id="thermal-orientation-select"
                  value={thermalOrientation}
                  onChange={handleThermalOrientation}
                >
                  <MenuItem value="On Base">On Base</MenuItem>
                  <MenuItem value="On End or Side">On End or Side</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Currency
              </Typography>
              <FormControl fullWidth>
                <Select
                  disabled={true}
                  id="currency-select"
                  value={currency}
                  onChange={handleCurrency}
                >
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Outbound Freight Rate
              </Typography>
              <FormControl sx={{ m: 1 }} variant="outlined">
                <OutlinedInput
                  type="number"
                  id="outlined-adornment-temperature"
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        background: "#F2F2F3",
                        height: "100%",
                        padding: "6px 12px",
                        borderRadius: "5px",
                      }}
                    >
                      $/{shipWeightUnit === "kg" ? "kg" : "lbs"}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-temperature-helper-text"
                  inputProps={{
                    "aria-label": "hours",
                    maxLength: 2,
                    style: {
                      padding: 8,
                    },
                  }}
                  name="hours"
                  value={outfreight}
                  onInput={handleoutfreight}
                />
              </FormControl>
            </Stack>
            <Stack width={1} spacing={1} p={0} direction="column">
              <Typography component="h6" variant="h6" sx={labelStyle}>
                Return Freight Rate
              </Typography>
              <FormControl sx={{ m: 1 }} variant="outlined">
                <OutlinedInput
                  type="number"
                  id="outlined-adornment-temperature"
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        background: "#F2F2F3",
                        height: "100%",
                        padding: "6px 12px",
                        borderRadius: "5px",
                      }}
                    >
                      $/{shipWeightUnit === "kg" ? "kg" : "lbs"}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-temperature-helper-text"
                  inputProps={{
                    "aria-label": "hours",
                    maxLength: 2,
                    style: {
                      padding: 8,
                    },
                  }}
                  name="hours"
                  value={returnfreight}
                  onInput={handlereturnfreight}
                />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <Stack width={1} pt={3}>
          <Button
            variant="contained"
            data-testid="saveButton"
            fullWidth
            sx={{
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 400,
              height: "44px",
              background: "#F26322",
              color: "#000000",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#F2632290",
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        boxShadow:
          "0px 32px 19px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03), 0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            pt: 3,
            pl: 3,
            pr: 3,
          }}
        >
          Opportunities
        </Typography>
        <Box sx={{ pb: 0 }}>
          {loginDetails?.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#000000",
                  color: "#000000",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                }}
              >
                <Button
                  onClick={handleSettingOpen}
                  data-testid="settings-btn"
                  disabled={isEnabled}
                  startIcon={<SettingsOutlined />}
                  style={{
                    textTransform: "none",
                    border: "1px solid #B1B2B4",
                  }}
                >
                  Settings
                </Button>
                <Button
                  onClick={editOpportunity}
                  data-testid="editbutton"
                  disabled={isSelectedVal}
                  startIcon={<Edit />}
                  style={{
                    textTransform: "none",
                    border: "1px solid #B1B2B4",
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={deleteOpportunity}
                  data-testid="editbutton"
                  disabled={isSelectedVal}
                  startIcon={<Delete />}
                  style={{
                    textTransform: "none",
                    border: "1px solid #B1B2B4",
                  }}
                >
                  Delete
                </Button>
                <Button
                  onClick={addOpportunity}
                  data-testid="deletebutton"
                  disabled={customer?.id !== 0 ? false : true}
                  startIcon={<Add />}
                  style={{
                    textTransform: "none",
                    border: "1px solid #B1B2B4",
                  }}
                >
                  New Opportunity
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>
      {props.data && (
        <SearchOpportunity
          search={setSearchUserData}
          data={props.data}
          title="Search Opportunity"
        />
      )}
      {data && (
        <ListOpportunity
          setSelectedItem={setSelectedItem}
          orderInitm="updated_on"
          search={searchuserData !== null ? searchuserData : false}
          data={props.data}
          columns={Columns}
          norecord={{
            title: "No opportunities yet",
            description: "",
          }}
        />
      )}
      {delDialog && (
        <DeleteOpportunity
          onClose={setDelDialog}
          open={delDialog}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          setCount={setCount}
          setReload={setReload}
          setNotify={setNotify}
          setSeverity={setSeverity}
          setNotifyMsg={setNotifyMsg}
        />
      )}
      {addDialog && (
        <CreateOpportunity
          open={addDialog}
          setOpen={setAddDialog}
          customer={customer?.id}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
        />
      )}
      {editDialog && !isSelectedVal && (
        <EditOpportunity
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          customer={customer}
          open={editDialog}
          setOpen={setEditDialog}
          setReload={setReload}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
        />
      )}

      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}

      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openLoad}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      <SwipeableDrawer
        anchor="right"
        open={settingOpen}
        onClose={handleSettingClose}
        onOpen={handleSettingOpen}
      >
        {list("right")}
      </SwipeableDrawer>
    </Box>
  );
};

export default Opportunities;
