import React, { useState, useEffect } from "react";
import { Box, Typography, Button, ButtonGroup, Backdrop } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ListView from "../../components/ShipperDictionary/ListView";
import Loader from "../../components/core/Loader/Loader";
import CreateNew from "../../components/ShipperDictionary/CreateNew";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DeleteRecord from "../../components/ShipperDictionary/DeleteRecord";
import ListSearch from "../../components/ShipperDictionary/Search";
import SnackBar from "../../components/core/SnackBar/SnackBar";
import * as Config from "../../../src/config/";
import * as ConstantsList from "../../../src/constants/";
import * as ADAConfig from "../../../src/config/ADAConfig";

import { useSelector } from "react-redux";
import { loginData } from "../../components/Login/LoginSlice";

const ShipperDictionary = () => {
  const loginDetails = useSelector(loginData);
  const [count, setCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState(null);
  const [listDataSearch, setListDataSearch] = useState(null);
  const [cdopen, setCdopen] = React.useState(false);
  const [cddelopen, setCddelopen] = React.useState(false);
  const [severity, setSeverity] = useState(false);
  const [reload, setReload] = useState(false);
  const isReload = cddelopen || cdopen;

  useEffect(() => {
    setLoading(true);
    fetch(ConstantsList.API_URL + "shipdict/shipper_dict")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setListData(data);
          setLoading(false);
        }
      });
  }, [isReload]);

  const theme = createTheme({
    palette: { primary: { main: "#000000" }, secondary: { main: "#00fff0" } },
  });

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [shipperDictSelected, setShipperDictSelected] = useState("");
  const [shipperName, setShipperName] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [lastEditedDate, setLastEditedDate] = useState("");

  const [notifyMsg, setNotifyMsg] = useState("");

  const handleCdOpen = () => {
    setCdopen(true);
  };

  const handleCdClose = (value) => {
    setCdopen(false);
  };

  const handleCddelOpen = () => {
    setCddelopen(true);
  };

  const handleCddelClose = (value) => {
    setCddelopen(false);
  };

  const [notify, setNotify] = useState(false);

  return (
    <Box
      maxWidth="false"
      sx={{
        flexDirection: "column",
        display: "flex",
        width: "100%",
        borderRadius: "6px;",
        height: "auto",
        backgroundColor: "#FFFFFF",
        p: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "24px",
            lineHeight: "30px",
            color: "#000000",
            pt: 3,
            pl: 3,
            pr: 3,
          }}
        >
          {Config.CONFIGLIST.shipperDictionary.listView.title}
        </Typography>
        <Box sx={{}}>
          {loginDetails.role !== "Account Manager" && (
            <ThemeProvider theme={theme}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  borderColor: "#B1B2B4",
                  color: "#000000",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                }}
              >
                <Button
                  onClick={handleCddelOpen}
                  data-testid="deletebutton"
                  disabled={count}
                  startIcon={<DeleteIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                  aria-label={ADAConfig.ADA.shipper_dictionaries.delete_btn}
                >
                  Delete
                </Button>
                <Button
                  onClick={handleCdOpen}
                  data-testid="addbutton"
                  color="primary"
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none", border: "1px #B1B2B4 solid" }}
                  aria-label={ADAConfig.ADA.shipper_dictionaries.create_new_btn}
                >
                  Create New
                </Button>
              </ButtonGroup>
            </ThemeProvider>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {listData && (
          <ListSearch data={listData.data} search={setListDataSearch} />
        )}
      </Box>
      {loading && (
        <Box sx={{ display: "flex" }}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
          >
            <Loader />
          </Backdrop>
        </Box>
      )}

      {listData && (
        <ListView
          data={listData.data}
          dataCount={setCount}
          dataShipperName={setShipperName}
          dataCreationDate={setCreationDate}
          dataLastEditedDate={setLastEditedDate}
          dataShipperDictSelected={setShipperDictSelected}
          search={listDataSearch !== null ? listDataSearch : false}
        />
      )}
      {cdopen && (
        <CreateNew
          open={cdopen}
          onClose={handleCdClose}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setCount={setCount}
        />
      )}
      {cddelopen && (
        <DeleteRecord
          open={cddelopen}
          onClose={handleCddelClose}
          shipperName={shipperName}
          creationDate={creationDate}
          shipperDictSelected={shipperDictSelected}
          setCount={setCount}
          setNotify={setNotify}
          setNotifyMsg={setNotifyMsg}
          setReload={setReload}
          setSeverity={setSeverity}
        />
      )}
      {notify && (
        <SnackBar open={notify} setOpen={setNotify} message={notifyMsg} />
      )}
    </Box>
  );
};

export default ShipperDictionary;
