import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Radio,
  Stack,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import NoRecordFound from "../core/NoRecordFound/NoRecordFound";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as ADAConfig from "../../../src/config/ADAConfig";

import { oppotunityListViewControl } from "./OppotunityListViewSlice";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme, ownerState }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px 4px 4px 0px",
    backgroundColor: "#005A88",
    margin: 0,
  },
}));

const ListOpportunity = (props) => {
  const dispatch = useDispatch();

  const { columns, data, norecord, orderInitm, search, setSelectedItem } =
    props;

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(orderInitm);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [selectedValue, setSelectedValue] = React.useState();

  const [selected, setSelected] = useState([]);
  const selectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#F26322",
  };
  const noselectBg = {
    width: "10px",
    height: "64px",
    backgroundColor: "#FFFFFF",
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {}
          {columns.map((headCell) => (
            <TableCell
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#000000",
                fontStyle: "normal",
                backgroundColor: "#F2F2F3",
              }}
              align={headCell.align}
              style={{ width: headCell.width, minWidth: headCell.minWidth }}
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.numeric ? (
                <TableSortLabel
                  sx={{
                    "& MuiTableSortLabel-icon": { opacity: 1 },
                    "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                      opacity: 1,
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "#000000 !important",
                    },
                  }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={headCell.numeric ? UnfoldMoreIcon : false}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel
                  sx={{
                    "& MuiTableSortLabel-icon": { opacity: 1, display: "none" },
                    "& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon": {
                      opacity: 1,
                    },
                    "& .MuiTableSortLabel-icon": {
                      color: "#000000 !important",
                      display: "none",
                    },
                  }}
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const checkboxTheme = createTheme({
    palette: {
      primary: {
        main: "#F26322",
      },
    },
  });

  const isSelected = (name) => (selectedValue === name ? true : false);

  const filtered =
    search && data.length > 0
      ? data.filter((user) => {
          return user.opportunity_name === search.opportunity_name;
        })
      : data;

  const handleClick = (e, id, name, description, scenarioName) => {
    if (selectedValue === id) {
      setSelectedValue(null);
      setSelectedItem(null);
    } else {
      setSelectedValue(id);
      setSelectedItem({
        id: id,
        name: name,
        description: description,
        scenarioName: scenarioName,
      });
    }
  };

  const viewDetails = (event, id, name, description, scenarioName) => {
    dispatch(
      oppotunityListViewControl({
        id: id,
        name: name,
        description: description,
        scenarioName: scenarioName,
      })
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}
    >
      <TableContainer
        sx={{
          maxHeight: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#98A2B3",
            borderRadius: 8,
          },
        }}
      >
        <Table
          data-testid="userlisttable"
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "0 0",
            height: "max-content",
            "& .MuiTableRow-root:hover,& .MuiTableRow-root:selected,& .MuiTableRow-root:focused":
              {
                backgroundColor: "#FFFFFF",
                cursor: "pointer",
                border: 1,
              },
            "& .MuiTableRow-root": { border: 1, backgroundColor: "#FFFFFF" },
            "& .Mui-selected": {
              borderWidth: "1px",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td": {
              borderTop: "1px solid #F26322",
              borderBottom: "1px solid #F26322",
              backgroundColor: "#FFFFFF",
            },
            "& .Mui-selected td:first-of-type ": {
              borderLeft: "1px solid #F26322",
            },
            "& .Mui-selected td:last-child ": {
              borderRight: "1px solid #F26322",
            },
          }}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data.length !== 0 ? (
              filtered.length === 0 ? (
                <NoRecordFound
                  count={columns.length}
                  title={"No result found"}
                  description={norecord.description}
                />
              ) : (
                <></>
              )
            ) : (
              <NoRecordFound
                count={columns.length}
                title={"No opportunity added yet"}
                description={norecord.description}
              />
            )}
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.id.toString());
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleClick(
                        event,
                        row.id.toString(),
                        row.opportunity_name,
                        row.description,
                        row.scenario_name
                      )
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                        width: "10px",
                      },
                      "&:hover td:last-child ": {
                        borderRight: "1px solid #F26322",
                      },
                      "& .Mui-selected td:first-of-type ": {
                        borderLeft: "1px solid #F26322",
                      },
                      "&:hover td ": {
                        borderTop: "1px solid #F26322",
                        borderBottom: "1px solid #F26322",
                        backgroundColor: "#FFFFFF",
                      },
                      "&:selected": { backgroundColor: "#FFFFFF" },
                      "& .css-1nf681w-MuiTableCell-root": { padding: 0 },
                    }}
                    aria-label={ADAConfig.ADA.Opportunities.selectedRow}
                  >
                    {columns.map((bodyCell, i) => {
                      const value = row[bodyCell.id];
                      return i === 0 ? (
                        <TableCell
                          padding="checkbox"
                          align={bodyCell.align}
                          style={{
                            padding: 0,
                            width: bodyCell.width,
                            minWidth: bodyCell.minWidth,
                          }}
                        >
                          <Stack direction="row">
                            <Box
                              sx={isItemSelected ? selectBg : noselectBg}
                            ></Box>
                            <ThemeProvider theme={checkboxTheme}>
                              <Radio
                                checked={selectedValue === row.id.toString()}
                                value={row.id.toString()}
                                name={`radio-${row.id}`}
                                inputProps={{ "aria-label": "A" }}
                              />
                            </ThemeProvider>
                          </Stack>
                        </TableCell>
                      ) : i === 4 ? (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={bodyCell.align}
                          style={{
                            width: bodyCell.width,
                            minWidth: bodyCell.minWidth,
                          }}
                        >
                          <CircleIcon
                            sx={{
                              align: "left",
                              cursor: "pointer",
                              width: "10px",
                              height: "auto",
                              mr: 1,
                              color:
                                value === "Open"
                                  ? "#4C8CB6"
                                  : value === "Proposed"
                                  ? "#F59B15"
                                  : "#ffffff",
                            }}
                          />
                          {value}
                        </TableCell>
                      ) : i === 6 ? (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={bodyCell.align}
                          style={{
                            width: bodyCell.width,
                            minWidth: bodyCell.minWidth,
                          }}
                        >
                          <Link
                            underline="none"
                            to="/dashboard/opportunity/scenario-list"
                          >
                            <ChevronRightIcon
                              sx={{
                                align: "center",
                                cursor: "pointer",
                                color: "#000",
                              }}
                              onClick={(event) =>
                                viewDetails(
                                  event,
                                  row.id,
                                  row.opportunity_name,
                                  row.description,
                                  row.scenario_name
                                )
                              }
                              aria-label={
                                ADAConfig.ADA.Opportunities.viewDetailsButton
                              }
                            />
                          </Link>
                        </TableCell>
                      ) : i === 1 ? (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={bodyCell.align}
                          style={{
                            width: bodyCell.width,
                            minWidth: bodyCell.minWidth,
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {value}
                            {row.description && (
                              <BootstrapTooltip title={row.description}>
                                <InfoOutlinedIcon
                                  sx={{
                                    marginLeft: "8px",
                                    color: "#7D7F82",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              </BootstrapTooltip>
                            )}
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell
                          id={labelId}
                          key={i}
                          sx={{
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#3F4042",
                            fontStyle: "normal",
                          }}
                          align={bodyCell.align}
                          style={{
                            width: bodyCell.width,
                            minWidth: bodyCell.minWidth,
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListOpportunity;
