import { createSlice } from "@reduxjs/toolkit";

export const CustomerSlice = createSlice({
  name: "customer",
  initialState: {
    value: {
      id: "",
      name: "",
    },
  },
  reducers: {
    custControl: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { custControl } = CustomerSlice.actions;
export const custData = (state) => state?.customer?.value;
export default CustomerSlice.reducer;
