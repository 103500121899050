import React, { useState } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import DialogContentText from "@mui/material/DialogContentText";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Snackbar from "@mui/material/Snackbar";
import * as ConstantsList from "../../../src/constants/";

const testStyle = {
  fontWeight: "600",
  fontFamily: "Roboto",
  fontSize: "28px",
  lineHeight: "38px",
  color: "#19191A",
};
const testStyle1 = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
  letterSpacing: "0.05em",
  marginTop: "40px",
  marginBottom: "32px",
};
const labelStyle = {
  fontWeight: "400",
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#3F4042",
};

const checkboxTheme = createTheme({
  palette: {
    primary: {
      main: "#F26322",
    },
  },
});

const DeleteRecord = (props) => {
  const {
    open,
    onClose,
    selectedScenariosList,
    setSelectedScenarios,
    setSelectedScenariosList,
    setCount,
    setNotify,
    setNotifyMsg,
    setReload,
    setSeverity,
  } = props;
  let scenarioNames = [];
  selectedScenariosList?.map((row, index) => {
    scenarioNames[index] = row.scenario_name;
  });

  const handleClose = () => {
    onClose(false);
  };
  const handleDelete = () => {
    selectedScenariosList?.map((selectedItem, index) => {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        ConstantsList.API_URL + "scenario/scenario/" + selectedItem?.id,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            if (data.status_code === 200) {
              setReload(true);
              setNotifyMsg(data.data.toString());
              setNotify(true);
              setSeverity(true);
              setCount(true);
              onClose(false);
              setSelectedScenarios([]);
              setSelectedScenariosList([]);
            } else if (data.status_code === 400) {
              setNotifyMsg(data.data.toString());
              setSeverity("error");
              setNotify(true);
              setCount(true);
              onClose(false);
            } else if (data.status_code === 500) {
              setNotifyMsg("Scenario not found");
              setSeverity("error");
              setNotify(true);
              setCount(true);
              onClose(false);
            } else {
              setNotify(false);
            }
          }
        })
        .catch((err) => console.log("Error ", err));
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="dailog-outer-container"
      >
        <Box
          maxWidth="false"
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            direction: "row",
            borderRadius: "6px",
          }}
        >
          <Stack
            spacing={0}
            p={3}
            direction="column"
            sx={{ padding: "30px 24px 40px 24px" }}
          >
            {open ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    width: "12px",
                    height: "12px",
                    justifyContent: "flex-end",
                    padding: 0,
                    color: "#000000",
                  }}
                  data-testid="icon-btn"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : null}
            <Stack spacing={3} p={0} direction="column">
              <Typography component="h3" variant="h3" sx={testStyle}>
                Delete scenario
              </Typography>
              <Typography component="h6" variant="h6" sx={testStyle1}>
                Are you sure you want to delete this scenario? <br />
                <b>All data entered will be lost,</b> do you want to proceed?
              </Typography>
              <Stack
                spacing={0}
                p={0}
                direction="row"
                sx={{ maxHeight: "42px" }}
              >
                <Box
                  p={0}
                  sx={{
                    backgroundColor: "#E34949",
                    width: "6px",
                    height: "42px",
                  }}
                ></Box>
                <Box container sx={{ height: "42px", marginLeft: "10px" }}>
                  <Typography component="h6" variant="h6" sx={labelStyle}>
                    {scenarioNames?.toString()}
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={labelStyle}
                  ></Typography>
                </Box>
              </Stack>

              <Button
                onClick={handleDelete}
                sx={{
                  width: "395px",
                  height: "44px",
                  backgroundColor: "#F26322",
                  color: "#3F4042",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#F26322" },
                }}
                data-testid="confirm-btn"
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
};

export default DeleteRecord;
